module.exports = {
    cryptoPass: "InktBitjfnWUtYpbD",


    // Oxhain Local
      //  refurl: "http://localhost:3000/",
      //  imageUrl: "http://localhost:3528/",
      //  baseUrl: "http://localhost:3528/",
      //  socketUrl: "http://localhost:3528/",
      //  Recaptchakey: "6LdpeoQUAAAAAHwFEDfpcA-W5-leSH8548lZWWeb",

    // Oxhain Demo

      // refurl: "https://bellexchange.maticz.in/",
      // imageUrl: "https://bellexchange.maticz.com/api/",
      // baseUrl: "https://bellexchange.maticz.com/api/",
      // socketUrl: "https://bellexchange.maticz.com/api/",
      // Recaptchakey: "6Lf0DBMkAAAAAAByb9S-uDMQLHeY5l2J4Jc2wuxu",



      refurl: "https://bellexchange.maticz.in/",
      imageUrl: "https://bellexchange.maticz.com/api/",
      baseUrl: "https://bellexchange.maticz.com/api/",
      socketUrl: "https://bellexchange.maticz.com/api/",
      Recaptchakey: "6LcAn-YkAAAAANnuNOMZ4fIeUbPle0lGZAwu2PCR",
    //   6LcAn-YkAAAAANnuNOMZ4fIeUbPle0lGZAwu2PCR
      

};