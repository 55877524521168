/* eslint-disable */
import React, { Component } from 'react'
import '../css/style-trade.css';
import clsx from 'classnames';
import {
  MenuItem, Select
} from "@material-ui/core/";

// import '../js/main.js';
import { withTranslation } from 'react-i18next';
import TradeHeader from './TradeHeader'
import Footer from './Footer'
import Navtradebar from './Navtradebar'
import LogoNewFixHead from "../images/logo.png"
import LogoLightTheme from "../images/logo.png"
import Selicon1 from "../images/selicon1.png"
import Selicon2 from "../images/selicon2.png"
import Selicon4 from "../images/selicon4.png"
import Selicon5 from "../images/selicon5.png"
import Selicon6 from "../images/selicon6.png"
import { getPertual, orderPlacing,getTradeData, getuserTradeData, cancelTrade, logoutUser, getPricevalue, triggerstop, changeopenpositions } from "../actions/authActions";
// import component
import UnrealizedProfitLoss from './UnrealizedProfitLoss';

import GreenPinkDot from "../images/btn-green-pink-dot.png"
import PinkDot from "../images/btn-pink-dot.png"
import GreenDot from "../images/btn-green-dot.png"
import TradeChart from "../images/tradeChart.jpg"
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';

import axios from "axios";
import { Link, withRouter } from 'react-router-dom';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { store } from 'react-notifications-component';
import { Modal, Button, Dropdown ,OverlayTrigger,Tooltip} from 'react-bootstrap/';
import ReactSelect from 'react-select';
import io from "socket.io-client";
import { Scrollbars } from 'react-custom-scrollbars';
import { TVChartContainer } from './TVChartContainer';
import keys from "../actions/config";
import CryptoJS from "crypto-js"
import * as moment from "moment";

// import lib
import isEmpty from '../lib/isEmpty';
import { toFixed } from '../lib/roundOf'
import { inverseOrderCost, inversePositionMargin } from '../lib/bybit';

const cryptoPass = keys.cryptoPass
const url = keys.baseUrl;

const renderTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
   {props}
  </Tooltip>
);

const menuProps = {
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  }
}

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    color: "white",
    backgroundColor: "#000",
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: '52px',
    padding: '0 6px',
    backgroundColor: "#03081f",
    borderColor: '#81c8f6',
  borderRadius: 10,
  borderStyle: 'solid',
  borderWidth: '1px'
   
  }),
  control: (provided, state) => ({
    ...provided,
    height: '52px',
    borderRadius:10,
    backgroundColor: "#03081f",
    border:'none'
   
  }),
  indicatorsContainer: (provided, state) => ({
    ...provided,
    height: '52px',
    position: 'absolute',
    right: 0,
    top: 0,
    color:'#fff' 
  }),    
  singleValue: (provided, state) => ({
    ...provided,
    color: "#fff"
  })
}
const options1 = [
  { value: 'Beshare', label: 'Beshare' },
  { value: '3Commas', label: '3Commas' },
  { value: 'AIcoin', label: 'AIcoin' },
  { value: 'alpha-algo', label: 'alpha-algo' },
  { value: 'Cornix', label: 'Cornix' },
  { value: '合约帝', label: '合约帝' },
];

const options = [
  { value: 'GTC', label: 'GoodTillCancelled' },
  { value: 'IOC', label: 'ImmediateOrCancel' },
  { value: 'FOK', label: 'FillOrKill' },
];

class Trade extends Component {

  constructor(props) {

    super(props);
    console.log(props,"props")
    ////console.log('typeof this.props.match.params.currency');
    // //console.log(typeof this.props.match.params.currency);
    if (typeof this.props.match.params.currency == 'undefined') {
      var currency = 'BTC-USD';
    } else {
      var currency = this.props.match.params.currency == 'login'? 'BTC-USDT': this.props.match.params.currency;
      this.props.history.push("/trade/" +currency )
      console.log("currency",currency)
      localStorage.setItem('curpair', currency)
    }



    currency = currency.toUpperCase();

    var date = new Date();
    var curhour = date.getHours();
    var timeuntillfunding = (curhour > 0 && curhour < 8) ? (8 - curhour) : (curhour > 8 && curhour < 16) ? (16 - curhour) : (curhour > 16 && curhour < 24) ? (24 - curhour) : 0;

    this.state = {
      id: '',
      value: (currency == 'BTC-USD' || currency == 'ETH-USD') ? 150 : 20,
      leverage: (currency == 'BTC-USD' || currency == 'ETH-USD') ? 150 : 20,
      dummyleverage: 10,
      curcontract: currency,
      chartpair: currency.replace("-", ""),
      secondcurrency: currency.split('-')[1],
      firstcurrency: currency.split('-')[0],
      chartpair: currency.replace("-", ""),
      price: 7800,
      instantprice: 7800,
      index_price: 7800,
      rescentcount: 50,
      markprice: 0,
      quantity: 0.00000000,
      order_value: 0.00000000,
      order_cost: 0.00000000,
      btcprice: 0,
      balance: 0,
      firstbalance: 0,
      secondbalance: 0,
      last_price: 0,
      maxquantity: 0,
      minquantity: 0,
      minmargin: 0,
      close_quantity: 0,
      btcvolume: 0,
      close_price: 0,
      prevoiusprice: 0,
      maxleverage: 0,
      floating: (currency == 'XRP-USD') ? 4 : 2,
      taker_fee: 0.075,
      maker_fee: 0.075,
      disable: true,
      buydisable: true,
      selldisable: true,
      readOnly: false,
      records: [],
      assetOverview: [],
      tradeTableAll: [],
      buyOrder: [],
      prevbuyOrder: [],
      sellOrder: [],
      prevsellOrder: [],
      orderHistory: [],
      Rescentorder: [],
      Histroydetails: [],
      position_details: [],
      allposition_details: [],
      closed_positions: [],
      Conditional_details: [],
      Filleddetails: [],
      lastpricedet: "",
      daily_details: [],
      errors: {},
      spotpricecolor: 'greenText',
      orderbookshow: 'greenpink',
      ordertype: 'Market',
      triggerordertype: 'limit',
      theme: (document.getElementById("root").classList.contains("darktheme")? "Dark" : "White"),
      limit: false,
      conditional: false,
      reduce_only: false,
      post_only: false,
      show: false,
      tpshow: false,
      confirmcancel:false,
      show1: false,
      tpreadOnly: false,
      trailreadOnly: true,
      stopreadOnly: false,
      readOnly1: true,
      update: true,
      timeinforcetype: { value: 'GTC', label: 'GoodTillCancelled' },
      perpetual: '',
      username: '',
      message: '',
      trailingstopdistance: 0,
      alertmessage: '',
      TradingView: '',
      messages: [],
      Liqprice: 0,
      takeprofit: 0,
      stopprice: 0,
      stopper: 0,
      tptrigger_type: null,
      stoptrigger_type: null,
      blocktime: '',
      change: 0,
      buylimit: 0,
      selllimit: 0,
      timeuntillfunding: timeuntillfunding,
      buyorsell: 'buy',
      alertmessage1: '',
      alertmessage2: '',
      orderQty: 0,
      orderPrice: 0,
      closedPositionPage:1,
      orderHistoryPage: 1,
      historyDetailsPage: 1,
      filledDetailsPage:1,
      orderHistoryCnt: 0,
      historyDetailsCnt: 0,
      filledDetailsCnt: 0,
      closedPositionCnt: 0,
      positionDetails: {},
      pairData: {
        last: 0,
        volume: 0,
        change: 0,
        high: 0,
        low: 0,
      },
      botstatus: 'Off',
      profitpercentage: "0",
      stoplosspercentage: "0"

    };
    // this.socket = io(keys.socketUrl,{secure: true,transports:['polling'],jsonp:false,rejectUnauthorized:false}); //live
    this.socket = io(keys.socketUrl);

    this.socket.on('RECEIVE_MESSAGE', function (data) {
      addMessage(data);
    });

    this.socket.on('TRADE', function (data) {
      getPricevalue();
      // if( data.contractdetails !== undefined && data.contractdetails.first_currency !== undefined &&  data.contractdetails.first_currency==this.state.first_currency)
      // {
      appenddata(data);
      // }
    });
// this.socket.on('DeActivated',function (data) {
//   console.log('lsjk bhfbsjfjs',data)
// })

    this.socket.on('USERTRADE', function (data) {
      console.log('USERTRADE',data)
      // let userid = this.props.auth.user.id;
      userappenddata(data);
    });

    this.socket.on('PRICEDETAILS', function (data) {
      priceappenddata(data);
    });



    this.socket.on('NOTIFICATION', function (data) {
      store.addNotification({
        title: t("SUCCESS"),
        message: data,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: true
        }
      });
    });

    this.socket.on('perpetualMarketPrice', (result) => {
      console.log("perpetualMarketPrice",result)
      indexPriceWS(result)
    })

    const indexPriceWS = (result) => {
      const { firstcurrency, secondcurrency } = this.state;
      if (result && result.first_currency == firstcurrency && result.second_currency == secondcurrency) {
        this.setState({ markprice: parseFloat(result.data.markprice) });
      }
    }

    this.socket.on('marketPrice', (result) => {
      marketPriceWS(result)
    })

    const marketPriceWS = (result) => {
      const { firstcurrency, secondcurrency } = this.state;
      if (result && result.first_currency == firstcurrency && result.second_currency == secondcurrency) {
        this.setState((prevData) => {
          if (prevData && prevData.pairData) {
            if (prevData['pairData']) prevData['pairData']['last'] = parseFloat(result.last)
            if (prevData['pairData']) prevData['pairData']['volume'] = parseFloat(result.volume)
            if (prevData['pairData']) prevData['pairData']['change'] = parseFloat(result.change)
            if (prevData['pairData']) prevData['pairData']['high'] = parseFloat(result.high)
            if (prevData['pairData']) prevData['pairData']['low'] = parseFloat(result.low)
          }
          return prevData
        })
      }
    }

    this.socket.on('perpetualOrderBook', (result) => {
      orderBookWS(result)
    })

    const orderBookWS = (result) => {
      const { firstcurrency, secondcurrency } = this.state;
      if (result && result.first_currency == firstcurrency && result.second_currency == secondcurrency) {
        this.setState({
          buyOrder: result.buyOrder,
          sellOrder: result.sellOrder,
        });
      }
    }

    this.socket.on('perpetualRecentTrade', (result) => {
      recentTradeWS(result)
    })

    const recentTradeWS = (result) => {
      const { firstcurrency, secondcurrency } = this.state;
      if (result && result.first_currency == firstcurrency && result.second_currency == secondcurrency) {
        this.setState({ "Rescentorder": result.recentTrade })
      }
    }

    this.socket.on('perpetualPositionOrder', (result) => {
      if(result.message){
        console.log("perpetualPositionOrder",result)
        positionOrderWS(result)
      }
      //for empty positiondetails set 
      else{
        console.log("perpetualPositionOrder",result)
        positionOrderWS(result)
      }
      
    })

    const positionOrderWS = (result) => {
      const { firstcurrency, secondcurrency } = this.state;
      if (result && result.first_currency == firstcurrency && result.second_currency == secondcurrency) {
        this.setState({ "positionDetails": result.data })
      }
      //for empty positiondetails set
      else{
        this.setState({ "positionDetails": result.data })
      }
    }

    const appendbin = data => {
      if (data.message == 'tradeTableAll' && data.buyOrder !== undefined && data.sellOrder !== undefined) {

        if (data.firstCurrency == this.state.firstcurrency && data.secondCurrency == this.state.secondcurrency) {
          var buyOrder = data.buyOrder;
          var sellOrder = data.sellOrder;
          this.setState({
            buyOrder: buyOrder,
            sellOrder: sellOrder,
          });
        }
      }
    }

    const appenddata = data => {
      this.setState({ prevbuyOrder: this.state.buyOrder, prevsellOrder: this.state.sellOrder });
      if (data.message == 'tradeTableAll' && data.buyOrder !== undefined && data.sellOrder !== undefined && data.contractdetails !== undefined && data.Rescentorder !== undefined) {
        if (data.contractdetails.first_currency == this.state.firstcurrency) {
          var assetdetails = data.assetdetails;
          var contractdetails = data.contractdetails;

          var buyOrder = data.buyOrder;
          var sellOrder = data.sellOrder;
          var Rescentorder = data.Rescentorder;
          this.setState({
            buyOrder: buyOrder,
            sellOrder: sellOrder,
            // Rescentorder: Rescentorder,
            buylimit: (buyOrder.length > 0) ? buyOrder[0]._id : this.state.markprice,
            selllimit: (sellOrder.length > 0) ? sellOrder[sellOrder.length - 1]._id : this.state.markprice,
            mainmargin: (contractdetails.maint_margin != null ? contractdetails.maint_margin / 100 : 0),

          });
        }


      }
    };


    const priceappenddata = data => {
      return
      if (data && data.tiker_root == 'BTCUSDT') {
        this.setState({ btcprice: parseFloat(data.markprice).toFixed(2) })
        this.calculateMarginImpact();
      }
      if (data && (data.tiker_root.replace("USDT", "USD") == this.state.chartpair || data.tiker_root == this.state.chartpair)) {
        var floating = (this.state.chartpair == 'XRPUSD') ? 4 : 2;
        this.setState({ markprice: parseFloat(data.markprice).toFixed(floating), last_price: data.last });
        if (this.state.prevoiusprice != 0 && this.state.prevoiusprice < data.markprice) {
          this.setState({ spotpricecolor: "greenText" });
        }
        else {
          this.setState({ spotpricecolor: "pinkText" });
        }
        this.setState({ prevoiusprice: this.state.markprice, floating: floating });
      }
    }
    const userappenddata = data => {
      if (data.message == 'tradeTableAll' && data.orderHistory !== undefined && data.assetdetails !== undefined && data.Conditional_details !== undefined && data.position_details !== undefined && data.daily_details !== undefined && data.closed_positions !== undefined) {
        console.log("orderHistory",data.orderHistory,"")
        var orderHistory = data.orderHistory;
        var Histroydetails = data.Histroydetails;
        var Filleddetails = data.Filleddetails;
        // var lastpricedet        = data.lastpricedet;
        var assetdetails = data.assetdetails;
        var Conditional_details = data.Conditional_details;
        var position_details = data.position_details;
        var allposition_details = data.allposition_details;
        var daily_details = data.daily_details;
        var closed_positions = data.closed_positions;
        var orderHistoryCnt = data.orderHistoryCnt;// for count the pending order 

        this.setState({
          orderHistory: orderHistory,
          Histroydetails: Histroydetails,
          // lastpricedet        : lastpricedet,
          // position_details: position_details,
          Filleddetails: Filleddetails,
          assetOverview: assetdetails,
          Conditional_details: Conditional_details,
          allposition_details: allposition_details,
          daily_details: (daily_details.length > 0) ? daily_details : [],
          // instantprice        : (lastpricedet)?lastpricedet.price:0,
          closed_positions: closed_positions,
          orderHistoryCnt:orderHistoryCnt
        });
      }
    };


    //get message
    axios
      .get(url + "cryptoapi/chat-data")
      .then(res => {
        if (res.status === 200) {
          let filterrecords = [];
          for (var i = 0; i < res.data.length; i++) {
            var chatdata = {};
            chatdata.message = res.data[i].message;
            if (res.data[i].userId != null && res.data[i].userId.name != '') {
              chatdata.author = res.data[i].userId.name;
            } else {
              if (res.data[i].userId != null) {
                var split_name = res.data[i].userId.email.split("@");
                chatdata.author = split_name[0];
              }
            }
            chatdata.moderator = (typeof res.data[i].userId.moderator != 'undefined') ? res.data[i].userId.moderator : 0;
            filterrecords.push(chatdata);
          }
          this.setState({ messages: filterrecords })

        }
      })
      .catch();
    //get message

    const addMessage = data => {
      this.setState({ messages: [...this.state.messages, data] });
    };
    const userTradeFun = data => {

      this.setState({ userTrade: [...this.state.userTrade, data] });

    };

    this.sendMessage = ev => {
      ev.preventDefault();
      //save Chat Message to database
      if (this.state.message != '') {
        var chatdata = {};
        chatdata.message = this.state.message;
        chatdata.userId = this.props.auth.user.id;
        axios
          .post(url + "cryptoapi/chat-add", chatdata)
          .then(res => {
            if (res.status === 200) {
            }
          })
          .catch();
        var name = "";
        if (this.props.auth.user.name != "") {
          this.state.username = this.props.auth.user.name;
        } else {
          var split_name = this.props.auth.user.email.split("@");
          this.state.username = split_name[0];
        }
        var fmoderator = (typeof this.props.auth.user.moderator != 'undefined') ? this.props.auth.user.moderator : 0;
        this.socket.emit('SEND_MESSAGE', {
          author: this.state.username,
          message: this.state.message,
          moderator: fmoderator,
        })
        this.setState({ message: '' });
      }
      else {
        store.addNotification({
          title: t("WARNING"),
          message: t("YOU_CANNOT_POST_EMPTY_MESSAGE"),
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 1500,
            onScreen: true
          }
        });
      }
    }
    this.sendTradeMessage = ev => {
      ev.preventDefault();
      this.socket.emit('username', this.props.auth.user.id);
      this.socket.emit('SEND_TRADE', {
        toid: this.props.auth.user.id,
        id: this.props.auth.user.id,
        message: this.state.userTradedata
      })
      this.socket.emit('disconnect', {
        id: this.props.auth.user.id,
      })
    }
  }

  styles = {
    option: (provided, state) => ({
      ...provided,
      color: "white",
      backgroundColor: "#000",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '52px',
      padding: '0 6px',
      backgroundColor: "#03081f",
      borderColor: '#81c8f6',
    borderRadius: 10,
    borderStyle: 'solid',
    borderWidth: '1px'
    }),
    control: (provided, state) => ({
      ...provided,
      height: '52px',
      borderRadius:10,
      backgroundColor: "#03081f",
      border:'none'
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '52px',
      position: 'absolute',
      right: 0,
      top: 0,
      color:'#fff' 
    }),    
    singleValue: (provided, state) => ({
      ...provided,
      color: "#fff"
    })
  };

  toggleMode = e => {

    if (document.body.classList == "") {
      document.body.classList.add('themeLight');
      this.setState({ theme: "White" })
    }
    else {
      document.body.classList.remove('themeLight');
      this.setState({ theme: "Dark" })
    }
  }
  onLogoutClick = e => {
    e.preventDefault();
    store.addNotification({
      title: t("LOGOUT_SUCCESS"),
      message: t("COME_JOIN"),
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 2000,
        onScreen: true
      }
    });
    let data = {userId:this.props.auth.user.id}
    this.props.logoutUser(data);
    window.location.href = "";
  };

  loadmoreRescentorder = () => {
    axios
      .post(url + "cryptoapi/loadmoreRescentorder", { pair: this.state.firstcurrency + this.state.secondcurrency, rescentcount: this.state.rescentcount + 50 })
      .then(res => {
        this.setState({ Rescentorder: res.data.data, rescentcount: (this.state.rescentcount + 50) })
      });
  }
  handleChangeStart = () => {
    // console.log('Change event started')
  };

  handleChange = value => {
    this.setState({ dummyleverage: value });
  };

  async fetchPositionData(firstcurrency,secondcurrency) {
    try {
     console.log("pairName",firstcurrency+secondcurrency)
      let respData = await axios({
        'method': 'post',
        'url': `${url}cryptoapi/perpetual/positionOrder`,
        data: {
          "pairName": firstcurrency+secondcurrency,
          "userId": this.props.auth.user.id
        }
      });
      console.log("fetchPositionData",respData.data.result)
      this.setState({ "positionDetails": respData.data.result })

      console.log("respData", respData.data)

    } catch (err) {
    }
  }

  calculateMarginImpact = (qty, priceValue, callFrom = '') => {
    console.log(qty, priceValue, callFrom = '',"qty, priceValue, callFrom = ''")
    // const { orderQty, orderPrice } = this.state;
    // let quantity = orderQty, price = orderPrice;
    var quantity = qty;
    var price = priceValue;
    if (callFrom == 'onChange') {
      quantity = qty;
      price = priceValue;
    }
    if (quantity > 0) {
      // var order_value1 = parseFloat(quantity * price).toFixed(8);
      // var order_value = parseFloat(order_value1 / this.state.btcprice).toFixed(8);
      // var required_margin = parseFloat(order_value1) / this.state.value;
      // var fee = parseFloat(order_value1) * this.state.taker_fee / 100;
      // var margininbtc = parseFloat(required_margin) / parseFloat(this.state.btcprice);
      // var feeinbtc = parseFloat(fee) / parseFloat(this.state.btcprice);
      // var order_cost = parseFloat(margininbtc) + parseFloat(feeinbtc);
      // this.setState({ order_cost: parseFloat(order_cost).toFixed(8), order_value: order_value });
     var orderprice = this.state.ordertype == 'Market' ? this.state.markprice : this.state.price;
      var order_value = parseFloat(quantity / orderprice).toFixed(8);
      this.setState({ orderQty: quantity, orderPrice: orderprice, order_value });
        // this.calculateMarginImpact(quantity, orderprice, 'onchange')
        this.calculateOrderCost(orderprice, quantity, this.state.leverage)
    } else {
      this.setState({ order_cost: 0, order_value: 0 });
    }
  }

  calculateOrderCost = (price, quantity, leverage) => {
    console.log(price, quantity, leverage,'price, quantity, leverage')
    if (!isEmpty(price) && !isEmpty(quantity) && !isEmpty(leverage)) {
      console.log(this.state.buyorsell,"calculateOrderCost");
      let orderCost = inverseOrderCost({
        price,
        quantity,
        leverage,
        takerFee: this.state.taker_fee,
        buyorsell: this.state.buyorsell
      }).toFixed(8)

      this.setState({ "order_cost": orderCost })

    }
  }

  handleChangeComplete = value => {
    if (this.state.leverage != value) {
      if (this.state.position_details.length > 0) {
        this.setState({ update: false })
        this.setState({
          "value":value,
          leverage: value
        });
        this.changeopenpositions(value);
        this.calculateOrderCost(this.state.price, this.state.quantity, value)
      }
      else {
        this.setState({
          "value":value,
          leverage: value
        });
        this.calculateOrderCost(this.state.price, this.state.quantity, value)
      }

    }
  };

  balanceperc = e => {

    var orderprice = this.state.markprice;
    var balanceperc = e.target.innerHTML.replace("%", "");

    var initial_balance = parseFloat(this.state.balance) * parseFloat(balanceperc) / 100;

    var leveragebal = parseFloat(this.state.value) * parseFloat(this.state.balance);

    var openfee = parseFloat(leveragebal) * parseFloat(this.state.taker_fee) / 100;

    var closefee = parseFloat(openfee) * parseFloat(this.state.value) / parseFloat(this.state.value + 1);

    var btcval = parseFloat(initial_balance) - (parseFloat(openfee) + parseFloat(closefee));
    var quanti = parseFloat(btcval) * parseFloat(orderprice);

    this.setState({ quantity: parseFloat(quanti).toFixed() });
    this.calculation();

  };
  quantitydivide = e => {
    var quantperc = e.target.innerHTML.replace("%", "");
    const { positionDetails } = this.state;

    var est_quant = parseFloat(!isEmpty(positionDetails) ? positionDetails.positionFilled : 0) * parseFloat(quantperc) / 100;

    this.setState({ close_quantity: parseFloat(est_quant).toFixed(8) });
    this.calculation();

  };

  triggerPrice = (param) => {
    var type = param.target.innerHTML;
    var trigger_price = (type == 'Last') ? this.state.last_price : (type == 'Index') ? this.state.index_price : this.state.markprice;
    this.setState({ trigger_price: trigger_price, trigger_type: type });
  }
  triggertp = (param) => {
    var type = param.target.innerHTML;
    var trigger_price = (type == 'Last') ? this.state.last_price : (type == 'Index') ? this.state.index_price : this.state.markprice;
    this.setState({ takeprofit: trigger_price, tptrigger_type: type });
  }
  triggerstop = (param) => {
    var type = param.target.innerHTML;
    var trigger_price = (type == 'Last') ? this.state.last_price : (type == 'Index') ? this.state.index_price : this.state.markprice;
    this.setState({ stopprice: trigger_price, stoptrigger_type: type });
  }

  conditionalPrice = (param) => {
    var type = param.target.innerHTML;
    (type == 'Limit') ? this.setState({ readOnly: false, triggerordertype: type }) : this.setState({ readOnly: true, triggerordertype: type });
  }
  ordertype_changes = (param) => {
    var type = param.target.innerHTML;
    if (type == 'Limit') {
      this.setState({ limit: true, conditional: false, ordertype: type, price: this.state.markprice });
    }
    else if (type == 'Conditional') {
      this.setState({ conditional: true, ordertype: type, limit: false });
    }
    else {
      this.setState({ limit: false, conditional: false, ordertype: type });
    }
  }
  tpClose = (param) => {
    this.setState({ tpshow: false });
  }

  confirmcancelClose = (param) => {
    this.setState({ confirmcancel: false });
  }
  handleClose1 = (param) => {
    this.setState({ show1: false });
  }
  handleShow1 = (param) => {
    var type = param.target.innerHTML;
    console.log('typeoqwrqwerqwer',type)
    this.setState({ show1: true, popuptype: type, close_quantity: (this.state.position_details.length > 0 && this.state.position_details[0].quantity) ? Math.abs(this.state.position_details[0].quantity) : 0, close_price: (this.state.position_details.length > 0 && this.state.position_details[0].price > 0) ? this.state.position_details[0].price : 0 });
  }

  tpshow = (param) => {
    var type = param.target.innerHTML;
    this.setState({ tpshow: true });
  }


  confirmcancel = (param) => {
    var type = param.target.innerHTML;
    this.setState({ confirmcancel: true });
  }
  

  handleShow = (buyorsell) => {
    // console.log("eeeeeeeeeeeeeeeeeeee",e.target.innerHTML);
    // var type = e.target.innerHTML;
    this.setState({ buyorsell: buyorsell, price: this.state.markprice, alertmessage: "" });
  }
  handleShow22 = (type) => {
    if (this.state.buyorsell == 'buy') {
      // console.log("CHECKSELLORBUY",this.state.buyorsell)
      var buyselltype = "buy";
      var orderprice = this.state.ordertype == 'Market' ? this.state.markprice : this.state.price;
      console.log("Value",this.state.value,this.state.leverage)
      var Liqprice = (+orderprice * +this.state.leverage) / ((+this.state.leverage + 1) - (+this.state.mainmargin * +this.state.leverage));
    }
    else {
      // console.log("CHECKSELLORBUY",this.state.buyorsell)
      var orderprice = this.state.ordertype == 'Market' ? this.state.markprice : this.state.price;
      var buyselltype = "sell";
      var Liqprice = (+orderprice * +this.state.leverage) / ((+this.state.leverage - 1) + (+this.state.mainmargin * +this.state.leverage));
      console.log("Value",this.state.value,orderprice,this.state.mainmargin,Liqprice,this.state.leverage)
    }

    // if(this.state.ordertype=='Limit' && buyselltype=="buy" && parseFloat(this.state.price) > parseFloat(this.state.selllimit))
    // {
    //     this.setState({alertmessage:"Entry price you set must be lower or equal to "+this.state.selllimit})
    //     return false;
    // }
    // else if(this.state.ordertype=='Limit' && buyselltype=="sell" && parseFloat(this.state.price) < parseFloat(this.state.buylimit))
    // {
    //   this.setState({alertmessage:"Entry price you set must be higher or equal to "+this.state.buylimit})
    //   return false;
    // }
    // if(this.state.ordertype=='Market' && buyselltype=="sell" && parseFloat(this.state.buyOrder.length) < 1 && this.state.value>20)
    // {
    //   this.setState({alertmessage:"There is no taker for this order, try again later"})
    //   return false;
    // }
    // else if(this.state.ordertype=='Market' && buyselltype=="buy" && parseFloat(this.state.sellOrder.length) < 1 && this.state.value>20)
    // {
    //   this.setState({alertmessage:"There is no taker for this order, try again later"})
    //   return false;
    // }
    // else if(this.state.ordertype=='Limit' && buyselltype=="buy" && parseFloat(Liqprice) > parseFloat(this.state.price))
    // {
    //   this.setState({alertmessage:"Opening this position may cause immediate liquidation as the system predicts that the position's Liquidation price will be above Mark Price if the order is fulfilled."})
    //   return false;
    // }
    //  else if(this.state.ordertype=='Limit' && buyselltype=="sell" && parseFloat(Liqprice) < parseFloat(this.state.price))
    // {
    //   this.setState({alertmessage:"Opening this position may cause immediate liquidation as the system predicts that the position's Liquidation price will be below Mark Price if the order is fulfilled."})
    //   return false;
    // }
    // else{
    //   this.setState({alertmessage:""})
    // }
    if (parseFloat(this.state.quantity) < parseFloat(this.state.minquantity)) {
      store.addNotification({
        title: t("ERRORS"),
        message: t("QUANTITY_CONTRACTS_MUST_NOT_LESSER_THAN") + this.state.minquantity,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 1500,
          onScreen: true
        }
      });
      return false;
    }
    if (parseFloat(this.state.quantity) > parseFloat(this.state.maxquantity)) {
      store.addNotification({
        title: t("ERRORS"),
        message: t("QUANTITY_CONTRACTS_MUST_NOT_GREATER_THAN") + this.state.maxquantity,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 1500,
          onScreen: true
        }
      });
      return false;
    }
    // if (parseFloat(this.state.order_cost) < parseFloat(this.state.minmargin)) {
    //   store.addNotification({
    //     title: "Error!",
    //     message: "Minimum margin impact is " + this.state.minmargin,
    //     type: "danger",
    //     insert: "top",
    //     container: "top-right",
    //     animationIn: ["animated", "fadeIn"],
    //     animationOut: ["animated", "fadeOut"],
    //     dismiss: {
    //       duration: 1500,
    //       onScreen: true
    //     }
    //   });
    //   return false;
    // }
    if (isNaN(orderprice)) {
      store.addNotification({
        title: t("ERRORS"),
        message: t("PRICE_IS_INVALID"),
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 1500,
          onScreen: true
        }
      });
      return false;
    }
    if (orderprice < 0.001) {
      store.addNotification({
        title: t("ERRORS"),
        message:t("PRICE_CONTRACTS_MUST_NOT_BE_LESSER_THAN"),
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 1500,
          onScreen: true
        }
      });
      return false;
    }
    // else if (this.state.balance < this.state.order_cost) {
    //   store.addNotification({
    //     title: "Warning!",
    //     message: "Due to insuffient balance order cannot be placed",
    //     type: "danger",
    //     insert: "top",
    //     container: "top-right",
    //     animationIn: ["animated", "fadeIn"],
    //     animationOut: ["animated", "fadeOut"],
    //     dismiss: {
    //       duration: 1500,
    //       onScreen: true
    //     }
    //   });
    //   return false;
    // }
    else {
      if (this.state.ordertype == 'Conditional' && this.state.trigger_price < 1) {
        store.addNotification({
          title:t("WARNING") ,
          message: t("TRIGGER_PRICE_CONTRACTS_MUST_NOT_LESSER_THAN"),
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 1500,
            onScreen: true
          }
        });
        return false;
      }

      if (this.state.botstatus == "On" && parseFloat(this.state.quantity) < 1) {
        store.addNotification({
          title: t("ERRORS"),
          message: t("MINIMUM_QUANTITY_1"),
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 1500,
            onScreen: true
          }
        });
        return false;
      }

      if (this.state.firstcurrency == "XRP" && parseFloat(this.state.order_value) < 10) {
        store.addNotification({
          title: t("ERRORS"),
          message: t("MINIMUM_ORDER_VALUE") + this.state.firstcurrency,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 1500,
            onScreen: true
          }
        });
        return false;

      }



      this.setState({ show: true, buyorsell: buyselltype, Liqprice: Liqprice });
    }
  }

  handleClose = () => {
    this.setState({ show: false,tpreadOnly: false, stopreadOnly: false,stopprice: 0, takeprofit: 0,profitpercentage:"0",stoplosspercentage:'0'});
  }

  orderPlacing = (type) => {
    // if (this.state.stopreadOnly == false) {
    //   if (this.state.buyorsell == 'buy' && parseFloat(this.state.markprice) <= parseFloat(this.state.stopprice)) {
    //     this.setState({ alertmessage1: "Stop Loss price you set must be lower than " + this.state.markprice });
    //     return false;
    //   }
    //   else if (this.state.buyorsell == 'buy' && parseFloat(this.state.Liqprice) >= parseFloat(this.state.stopprice)) {
    //     this.setState({ alertmessage1: "Stop Loss price you set must be higher than " + parseFloat(this.state.Liqprice).toFixed(this.state.floating) });
    //     return false;
    //   }
    //   else if (this.state.buyorsell == 'sell' && parseFloat(this.state.markprice) >= parseFloat(this.state.stopprice)) {
    //     this.setState({ alertmessage1: "Stop Loss price you set must be higher than " + this.state.markprice });
    //     return false;
    //   }
    //   else if (this.state.buyorsell == 'sell' && parseFloat(this.state.Liqprice) <= parseFloat(this.state.stopprice)) {
    //     this.setState({ alertmessage1: "Stop Loss price you set must be lower than " + parseFloat(this.state.Liqprice).toFixed(this.state.floating) });
    //     return false;
    //   }
    //   else {
    //     this.setState({ alertmessage1: '' });
    //   }
    // }
    // if (this.state.tpreadOnly == false) {
    //   if (this.state.buyorsell == 'buy' && parseFloat(this.state.markprice) >= parseFloat(this.state.takeprofit)) {
    //     this.setState({ alertmessage2: "Stop Loss price you set must be higher than " + this.state.markprice });
    //     return false;
    //   }
    //   else if (this.state.buyorsell == 'sell' && parseFloat(this.state.markprice) >= parseFloat(this.state.takeprofit)) {
    //     this.setState({ alertmessage2: "Stop Loss price you set must be lower than " + this.state.markprice });
    //     return false;
    //   }
    //   else {
    //     this.setState({ alertmessage2: '' });
    //   }
    // }
    // return false;
    if(this.state.tpreadOnly){
      if(this.state.buyorsell=='buy' && this.state.takeprofit < this.state.markprice){
        store.addNotification({
          title: t("WARNING"),
          message:t("LONG_TAKE_PROFIT_PRICE") ,
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 1500,
            onScreen: true
          }
        });
        return
      }
      else if(this.state.buyorsell=='sell' && this.state.takeprofit > this.state.markprice){
        store.addNotification({
          title: t("WARNING"),
          message: t("SHORT_TAKE_PROFIT_PRICE"),
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 1500,
            onScreen: true
          }
        });
        return
      }
    }
    if(this.state.stopreadOnly){
      if(this.state.buyorsell=='buy' && this.state.stopprice > this.state.markprice){
        store.addNotification({
          title: t("WARNING"),
          message: t("LONG_STOP_LOSS_PRICE"),
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 1500,
            onScreen: true
          }
        });
        return
      }
      else if(this.state.buyorsell=='sell' && this.state.stopprice < this.state.markprice){
        store.addNotification({
          title: t("WARNING"),
          message: t("SHORT_STOP_LOSS_PRICE"),
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 1500,
            onScreen: true
          }
        });
        return
      }
    }
    let userid = this.props.auth.user.id;
    var check1 = type.target.className.includes("btnBuy");
    if (this.state.buyorsell == 'buy') {
      this.setState({ buydisable: false, show: false });
    } else {
      this.setState({ selldisable: false, show: false });
    }
    var orderprice = this.state.ordertype == 'Market' ? this.state.markprice : this.state.price;

    let orderData = {
      "quantity": this.state.quantity,
      "price": orderprice,
      "userid": userid,
      "pair": this.state.firstcurrency + this.state.secondcurrency,
      "pairname": this.state.firstcurrency + this.state.secondcurrency,
      "firstcurrency": this.state.firstcurrency,
      "secondcurrency": this.state.secondcurrency,
      "timeinforcetype": this.state.timeinforcetype.value,
      // "leverage"         : 180,
      "leverage": this.state.leverage,
      "buyorsell": this.state.buyorsell,
      "ordertype": (this.state.ordertype == 'Conditional') ? this.state.triggerordertype : this.state.ordertype,
      "trigger_price": (this.state.ordertype == 'Conditional') ? this.state.trigger_price : 0,
      "trigger_type": (this.state.ordertype == 'Conditional') ? this.state.trigger_type : 'none',
      "tptrigger_type": this.state.tptrigger_type,
      "stoptrigger_type": this.state.stoptrigger_type,
      "post_only": this.state.post_only,
      "reduce_only": this.state.reduce_only,
      "stopcheck": this.state.stopreadOnly,
      "takeprofitcheck": this.state.tpreadOnly,
      "takeprofit": this.state.takeprofit,
      "stopprice": this.state.stopprice,
      newdate: moment()
    }
    this.setState({ disable: false });
    this.setState({ update: false })
    var encryptuserdata = CryptoJS.AES.encrypt(JSON.stringify(orderData), cryptoPass).toString();
    var jsonencryt = {
      token: encryptuserdata
    }
    this.props.orderPlacing(jsonencryt);
    this.setState({ show: false,tpreadOnly: false, stopreadOnly: false,stopprice: 0, takeprofit: 0,profitpercentage:"0",stoplosspercentage:'0'});
  };

  orderHistoryLoadMore =(type) => {
    // this.setState()
    // orderHistoryPage
    let userid = this.props.auth.user.id;
    console.log("userid",userid)
    let reqData = {
      userId : userid,
      page: this.state.orderHistoryPage + 1,
      firstCurrency: this.state.firstcurrency,
      secondCurrency: this.state.secondcurrency,
    }
  //   console.log(this.props.orderHistoryLoadMore(reqData))
  //  this.props.orderHistoryLoadMore(reqData).then((res)=>{
  //   if ( res.length > 0) {
  //     this.setState({
  //       orderHistory: [...this.state.orderHistory, ...result],
  //       orderHistoryPage: this.state.orderHistoryPage + 1
  //     })
  //   }
  //  }).catch((err)=>{
  //   console.log("error",err)
  //  })
   
    axios.post(url + "cryptoapi/getOrderHistory/trade", reqData).then(res => {
      if (res && res.data.length > 0) {
        this.setState({
          orderHistory: [...this.state.orderHistory, ...res.data],
          orderHistoryPage: this.state.orderHistoryPage + 1
        })
      }
    }).catch(err => {
      console.log("error",err)
    });
  }

  historyDetailsLoadMore = () => {
    let userid = this.props.auth.user.id;
    console.log("userid",userid)
    let reqData = {
      userid : userid,
      page: this.state.historyDetailsPage + 1,
      firstCurrency: this.state.firstcurrency,
      secondCurrency: this.state.secondcurrency,
    }

    axios.post(url + "cryptoapi/getHistoryDetails/trade", reqData).then(res => {
      if (res && res.data.length > 0) {
        this.setState({
          Histroydetails: [...this.state.Histroydetails, ...res.data],
          historyDetailsPage: this.state.historyDetailsPage + 1
        })
      }
    })
  }

  filledDetailsLoadMore = () => {
    let userid = this.props.auth.user.id;
    console.log("userid",userid)
    let reqData = {
      userid : userid,
      page: this.state.filledDetailsPage + 1,
      firstCurrency: this.state.firstcurrency,
      secondCurrency: this.state.secondcurrency,
    }
    axios.post(url + "cryptoapi/getFilledDetails/trade", reqData).then(res => {
      if (res && res.data.length > 0) {
        this.setState({
          Filleddetails: [...this.state.Filleddetails, ...res.data],
          filledDetailsPage: this.state.filledDetailsPage + 1
        })
      }
    }).catch(err => {
      console.log("error",err)
    });
  }

  closedPositionLoadMore = () => {
    let userid = this.props.auth.user.id;
    console.log("userid",userid)
    let reqData = {
      userid : userid,
      page: this.state.closedPositionPage + 1,
      firstCurrency: this.state.firstcurrency,
      secondCurrency: this.state.secondcurrency,
    }

    axios.post(url + "cryptoapi/getClosedPosition/trade", reqData).then(res => {
      if (res && res.data.length > 0) {
        this.setState({
          closed_positions: [...this.state.closed_positions, ...res.data],
          closedPositionPage: this.state.closedPositionPage + 1
        })
      }
    })
  }

  orderPlacing1 = (type) => {
    this.setState({ show1: false });
    let userid = this.props.auth.user.id;

    const { positionDetails } = this.state;

    if (isEmpty(positionDetails)) {
      return false
    }

    if (this.state.close_quantity > positionDetails.positionFilled) {
      store.addNotification({
        title: t("WARNING"),
        message: t("CLOSE_QUANTITY_MUST_LOWER_THAN") + positionDetails.positionFilled,
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 1500,
          onScreen: true
        }
      });
      return
    }

    
    var buyselltype = positionDetails.buyorsell;
    var orderprice = (buyselltype == 'buy') ? this.state.selllimit : this.state.buylimit;
    let orderData = {
      "quantity": this.state.close_quantity,
      "price": orderprice,
      "userid": userid,
      "pair": this.state.firstcurrency + this.state.secondcurrency,
      "pairname": this.state.firstcurrency + this.state.secondcurrency,
      "firstcurrency": this.state.firstcurrency,
      "secondcurrency": this.state.secondcurrency,
      "leverage": this.state.leverage,
      "buyorsell": buyselltype,
      "ordertype": this.state.popuptype,
      "trigger_price": 0,
      "trigger_type": 'none',
      "post_only": this.state.post_only,
      "reduce_only": this.state.reduce_only,
      newdate: moment()

    }
    var encryptuserdata = CryptoJS.AES.encrypt(JSON.stringify(orderData), cryptoPass).toString();
    var jsonencryt = {
      token: encryptuserdata
    }
    this.setState({ update: false })
    this.setState({ disable: false });
    this.props.orderPlacing(jsonencryt);
  };


  triggerstoporder = (type) => {

    let userid = this.props.auth.user.id;

    var orderprice = this.state.markprice;
    var buyselltype = (this.state.position_details.length > 0 && this.state.position_details[0].quantity > 0) ? 'sell' : 'buy';
    var close_quantity = (this.state.position_details.length > 0 && this.state.position_details[0].quantity != 0) ? this.state.position_details[0].quantity : 0;
    let orderData = {
      "quantity": close_quantity,
      "price": this.state.instantprice,
      "userid": userid,
      "pair": this.state.firstcurrency + this.state.secondcurrency,
      "pairname": this.state.firstcurrency + this.state.secondcurrency,
      "firstcurrency": this.state.firstcurrency,
      "secondcurrency": this.state.secondcurrency,
      "leverage": this.state.value,
      "buyorsell": buyselltype,
      "ordertype": "Market",
      "stopprice": this.state.stopprice,
      "takeprofit": this.state.takeprofit,
      "trailingstopdistance": this.state.trailingstopdistance,
      "tptrigger_type": this.state.tptrigger_type,
      "stoptrigger_type": this.state.stoptrigger_type,
      "takeprofitcheck": this.state.takeprofitcheck,
      "stopcheck": this.state.stopcheck,
    }
    this.setState({ disable: false, tpshow: false });
    this.props.triggerstop(orderData);
  };


  orderType = (e) => {
    if (e.target.id == 'nav-tradeLimit-tab') {
      this.setState({ limit: true });
    }
    else {
      this.setState({ limit: false });
    }
  };

  cancelFunction = (e) => {
    // alert(1)
    this.setState({ confirmcancel : true });
    this.setState({ id : e.target.id });
    console.log(this.state.id,'this.state.id');
    // if (window.confirm('Are you confirm to cancel this order?.')) {
    //   let userid = this.props.auth.user.id;
    //   var iddetails = { id: e.target.id, userid: userid };
    //   this.props.cancelTrade(iddetails);

    // }
  };

  upvalue = (field) => {
    if (field == 'quantity') {
      var newval = this.state.quantity + 0.5;
      this.setState({ quantity: newval });
    }
  };
  percentagePnL =(e)=>{
    console.log("percentagePnL")
    var type = e.target.id
    var percent = e.target.value;
    if(type == "takeprofit" && this.state.tpreadOnly){
      console.log("this.state.buyorsell",this.state.buyorsell)
      if(this.state.buyorsell == 'buy'){
        this.setState({profitpercentage:percent})
        let profit = parseFloat(this.state.markprice)*(parseFloat(percent)/100);
        let takeprofit = parseFloat(this.state.markprice) + parseFloat(profit);
        console.log("percentagePnL",takeprofit)
        this.setState({takeprofit:takeprofit})
      }
      else{
        this.setState({profitpercentage:percent})
        let profit = parseFloat(this.state.markprice)*(parseFloat(percent)/100);
        let takeprofit = parseFloat(this.state.markprice) - parseFloat(profit);
        console.log("percentagePnL",takeprofit)
        this.setState({takeprofit:takeprofit})
      }
     
    }
    else if(type == "stopprice" && this.state.stopreadOnly){
      if(this.state.buyorsell == 'buy'){
        this.setState({stoplosspercentage : percent})
        let loss = parseFloat(this.state.markprice)*(parseFloat(percent)/100);
        let stopprice =parseFloat(this.state.markprice)  - parseFloat(loss);
        console.log("percentagePnL",stopprice)
        this.setState({stopprice:stopprice})
      }
      else{
        this.setState({stoplosspercentage : percent})
        let loss = parseFloat(this.state.markprice)*(parseFloat(percent)/100);
        let stopprice =parseFloat(this.state.markprice)  + parseFloat(loss);
        console.log("percentagePnL",stopprice)
        this.setState({stopprice:stopprice})
      }
      
    }
  }
  updownvalue = (one, two, three) => {
    console.log("ONE :",one,"TWO :",two);
    var newval = 0;
    if (one == 'quantity') {
      if (two == 'plus') {
        newval = parseFloat(this.state.quantity) + parseFloat(1);
        console.log(newval,"-----------------------------------------------newVaslue-----------------------------------------------")
      } else if (two == 'minus') {
        newval = parseFloat(this.state.quantity) - parseFloat(1);
        console.log(newval,"========Minus+++++++++++")
      }  
      if (newval >= 0) {
        this.setState({ quantity: newval });
      }
      var quantity = newval;
      var orderprice = this.state.ordertype == 'Market' ? this.state.markprice : this.state.price;


      this.setState({ orderQty: quantity, orderPrice: orderprice });
      this.calculateMarginImpact(quantity, orderprice, this.state.value, 'onchange')
    
    }

    if (one == 'price') {
      if (two == 'plus') {
        newval = this.state.price + 0.5;
      } else if (two == 'minus') {
        newval = this.state.price - 0.5;
      }
      if (newval >= 0) {
        this.setState({ price: newval });
      }
    }

    if (one == 'trigger_price') {
      if (two == 'plus') {
        newval = this.state.trigger_price + 0.5;
      } else if (two == 'minus') {
        newval = this.state.trigger_price - 0.5;
      }
      if (newval >= 0) {
        this.setState({ trigger_price: newval });
      }
    }
    if (one == 'takeprofit') {
      if (two == 'plus') {
        newval = this.state.takeprofit + 0.5;
      } else if (two == 'minus') {
        newval = this.state.takeprofit - 0.5;
      }
      if (newval >= 0 && this.state.takeprofitcheck) {
        this.setState({ takeprofit: newval });
      }
    }

    if (one == 'stopprice') {
      if (two == 'plus') {
        newval = this.state.stopprice + 0.5;
      } else if (two == 'minus') {
        newval = this.state.stopprice - 0.5;
      }
      if (newval >= 0 && this.state.stopcheck) {
        this.setState({ stopprice: newval });
      }
    }
    if (one == 'trailingstopdistance') {
      if (two == 'plus') {
        newval = this.state.trailingstopdistance + 0.5;
      } else if (two == 'minus') {
        newval = this.state.trailingstopdistance - 0.5;
      }
      if (newval >= 0 && this.state.trailcheck) {
        this.setState({ trailingstopdistance: newval });
      }
    }

    if (one == 'close_quantity') {
      if (two == 'plus') {
        newval = this.state.close_quantity + 0.5;
      } else if (two == 'minus') {
        newval = this.state.close_quantity - 0.5;
      }
      if (newval >= 0) {
        this.setState({ close_quantity: newval });
      }
    }
  };

  componentWillReceiveProps(nextProps) {
    console.log("nextProps",nextProps)  
    const {t} = this.props;  
    if (this.props.auth.user.blocktime != '' && this.props.auth.user.blockhours) {
      var _date1 = new Date(this.props.auth.user.blocktime);
      this.setState({ blocktime: (new Date(_date1.getTime() + 1000 * 60 * 60 * this.props.auth.user.blockhours)) });
    }

    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
    else {
      this.setState({
        errors: {}
      });
    }
    if (nextProps.auth.trade !== undefined
      && nextProps.auth.trade.data !== undefined
      && nextProps.auth.trade.data.data !== undefined
    ) {
      var pair = this.state.curcontract;
      console.log("next props",this.state.curcontract)
      var res = pair.split("_");
      var temp = pair.replace("-", "");
      var index = nextProps.auth.trade.data.data.findIndex(x => (x.tiker_root) === temp);
      var btcindex = nextProps.auth.trade.data.data.findIndex(x => (x.tiker_root) === 'BTCUSD');
      if (index != -1) {
        var perparray = nextProps.auth.trade.data.data;
        var indexarr = [0, 1, 2, 3, 4];
        var outputarr = indexarr.map(i => perparray[i]);
        // alert(nextProps.auth.trade.data.data[index].markprice);
        this.calculateMarginImpact()
        this.setState({
          records: perparray,
          perpetual: nextProps.auth.trade.data.data[0],
          markprice: parseFloat(nextProps.auth.trade.data.data[index].markprice).toFixed(this.state.floating),
          value: parseFloat(nextProps.auth.trade.data.data[index].leverage).toFixed(),
          maxleverage: parseFloat(nextProps.auth.trade.data.data[index].leverage).toFixed(),
          //price     : parseFloat(nextProps.auth.trade.data.data[index].markprice).toFixed(this.state.floating),
          btcprice: parseFloat(nextProps.auth.trade.data.data[index].markprice).toFixed(2),
          maxquantity: parseFloat(nextProps.auth.trade.data.data[index].maxquantity),
          minquantity: parseFloat(nextProps.auth.trade.data.data[index].minquantity),
          minmargin: parseFloat(nextProps.auth.trade.data.data[index].minmargin),
          botstatus: nextProps.auth.trade.data.data[index].botstatus
        });
      }
    }

    if (nextProps.auth.trade !== undefined && nextProps.auth.trade.data !== undefined && nextProps.auth.trade.data.message == 'tradeTableAll' && nextProps.auth.trade !== undefined
      && nextProps.auth.trade.data !== undefined && nextProps.auth.trade.data.pricedet !== undefined && nextProps.auth.trade.data.pricedet.length > 0) {

      var btcvalue = 0;
      if (nextProps.auth.trade.data.pricedet.length > 0) {
        var usdvalue = parseFloat(this.state.markprice) * parseFloat(nextProps.auth.trade.data.pricedet[0].volume);
        var btcvalue = parseFloat(usdvalue) / parseFloat(this.state.btcprice);
      }
      this.setState({
        //price         : nextProps.auth.trade.data.pricedet[0].last,
        trigger_price: nextProps.auth.trade.data.pricedet[0].last,
        last_price: nextProps.auth.trade.data.pricedet[0].last,
        pricedet: nextProps.auth.trade.data.pricedet,
        // btcvolume     : isNaN(parseFloat(btcvalue))?0:btcvalue,
        btcvolume: usdvalue,
        change: (nextProps.auth.trade.data.pricedet.length > 0) ? nextProps.auth.trade.data.pricedet[0].change : 0,
        instantprice: (nextProps.auth.trade.data.pricedet.length > 0) ? nextProps.auth.trade.data.pricedet[0].last : 0
      });
    }


    if (nextProps.auth.trade !== undefined
      && nextProps.auth.trade.data !== undefined
      && nextProps.auth.trade.data.message !== undefined) {

      var message = nextProps.auth.trade.data.message;

      if (message == 'tradeTableAll' && nextProps.auth.trade.data.buyOrder !== undefined && nextProps.auth.trade.data.sellOrder !== undefined && nextProps.auth.trade.data.assetdetails !== undefined && nextProps.auth.trade.data.contractdetails !== undefined && nextProps.auth.trade.data.Rescentorder !== undefined) {
        var assetdetails = nextProps.auth.trade.data.assetdetails;

        var contractdetails = nextProps.auth.trade.data.contractdetails;
        // var index = assetdetails.findIndex(x => (x.currencySymbol).toLowerCase() === 'btc');
        var index = assetdetails.findIndex(x => (x.currencySymbol).toLowerCase() === this.state.firstcurrency.toLowerCase());
        if (index != -1) {
          var baldet = parseFloat(assetdetails[index].balance);
        }
        else {
          var balance = 0;
        }
        //console.log('nextProps.auth.trade---', nextProps.auth.trade)
        var buyOrder = nextProps.auth.trade.data.buyOrder;
        var sellOrder = nextProps.auth.trade.data.sellOrder;
        var Rescentorder = nextProps.auth.trade.data.Rescentorder;
        var val = (sellOrder.length < 6) ? 0 : ((sellOrder.length >= 6) && (sellOrder.length < 9)) ? 50 : (buyOrder.length < 3) ? 500 : 150
        this.setState({
          buyOrder: buyOrder,
          sellOrder: sellOrder,
          buylimit: (buyOrder.length > 0) ? buyOrder[0]._id : this.state.markprice,
          selllimit: (sellOrder.length > 0) ? sellOrder[sellOrder.length - 1]._id : this.state.markprice,
          Rescentorder: Rescentorder,
          balance: baldet,
          mainmargin: (contractdetails != null ? contractdetails.maint_margin / 100 : 0)
        }, () => {
          // alert(val);
          const { scrollbar } = this.refs;  // scrollbar has access to the internal API
          scrollbar.view.scrollTop = val;
        });

      }

      if (message == 'tradeTableAll' && nextProps.auth.trade.data.orderHistory !== undefined && nextProps.auth.trade.data.assetdetails !== undefined && nextProps.auth.trade.data.Conditional_details !== undefined && nextProps.auth.trade.data.position_details !== undefined && nextProps.auth.trade.data.daily_details !== undefined && nextProps.auth.trade.data.closed_positions !== undefined && nextProps.auth.trade.data.allposition_details !== undefined) {
        console.log('nextProps.auth.trade', nextProps.auth.trade.data.orderHistoryCnt, 
        nextProps.auth.trade.data.closedPositionCnt
        ,nextProps.auth.trade.data.filledDetailsCnt,
        nextProps.auth.trade.data.historyDetailsCnt)
        console.log("orderHistory2",nextProps.auth.trade.data.orderHistory,nextProps.auth.trade.data.position_details,nextProps.auth.trade.data.position_details)
        // console.log("orderHistory22",nextProps.auth)
        var orderHistory = nextProps.auth.trade.data.orderHistory;
        var Histroydetails = nextProps.auth.trade.data.Histroydetails;
        var Filleddetails = nextProps.auth.trade.data.Filleddetails;
        var assetdetails = nextProps.auth.trade.data.assetdetails;
        var Conditional_details = nextProps.auth.trade.data.Conditional_details;
        var position_details = nextProps.auth.trade.data.position_details;
        var daily_details = nextProps.auth.trade.data.daily_details;
        var closed_positions = nextProps.auth.trade.data.closed_positions;
        var allposition_details = nextProps.auth.trade.data.allposition_details;
        var orderHistoryCnt = nextProps.auth.trade.data.orderHistoryCnt;
        var closedPositionCnt = nextProps.auth.trade.data.closedPositionCnt;
        var filledDetailsCnt = nextProps.auth.trade.data.filledDetailsCnt;
        var historyDetailsCnt = nextProps.auth.trade.data.historyDetailsCnt;
        this.setState({
          historyDetailsCnt: historyDetailsCnt,
          filledDetailsCnt: filledDetailsCnt,
          closedPositionCnt: closedPositionCnt,
          orderHistory: orderHistory,
          orderHistoryCnt: orderHistoryCnt,
          Histroydetails: Histroydetails,
          Filleddetails: Filleddetails,
          assetOverview: assetdetails,
          Conditional_details: Conditional_details,
          allposition_details: allposition_details,
          // position_details: position_details,
          daily_details: (daily_details.length > 0) ? daily_details : [],
          value: (position_details.length > 0 && typeof position_details[0].leverage != 'undefined' && this.state.update == true) ? position_details[0].leverage : this.state.value,
          closed_positions: closed_positions,
          update: false
        });
      }

      if (nextProps.auth.trade.data.notify_show !== undefined && nextProps.auth.trade.data.notify_show == 'yes' && nextProps.auth.trade.data.status !== undefined) {
        if (nextProps.auth.trade.data.status) {
          var type = 'success';
          var title = t("SUCCESS");
        } else {
          var type = 'danger';
          var title = t("ERRORS");
        }

        store.addNotification({
          title: title,
          message: message,
          type: type,
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 1500,
            onScreen: true
          }
        });
        this.setState({ disable: true, selldisable: true, buydisable: true });
        this.getTradeData();
        this.getuserTradeData();
      }
      nextProps.auth.trade = "";
    }
  }

  handleClick = (e) => {
    // setAnchorEl(e.currentTarget);
    document.getElementsByTagName("body")[0].classList.toggle("padi_over_body");

  };

  pairChange = (e) => {
    // setAnchorEl(e.currentTarget);
    document.getElementsByTagName("body")[0].classList.add("padi_over_body");
    console.log("pairChange")
    let userid = this.props.auth.user.id;
    console.log("pairChange",e.target.value)
    var pair = e.target.value
    var res = pair.split("-");
    var temp = pair.replace("_", "");
    var loctemp = pair.replace("_", "-");
    var index = this.state.records.findIndex(x => (x.tiker_root) === temp);
    var mainmargin = (index > -1) ? this.state.records[index].maint_margin : 0.5 / 100;
    this.setState({
      curcontract: e.target.value,
      chartpair: temp,
      secondcurrency: res[1],
      chartpair: temp,
      firstcurrency: res[0],
      price: (index > -1) ? this.state.records[index].mark_price : this.state.price,
      perpetual: this.state.records[index],
      mainmargin: mainmargin,
    });
    localStorage.setItem('curpair', loctemp);
    this.props.history.push("/trade/" + loctemp);
    // this.getData();
    var findObj = {
      userid: userid,
      firstCurrency: res[0],
      secondCurrency: res[1]
    };
    this.props.getTradeData(findObj);
    this.props.getuserTradeData(findObj);
    this.fetchPositionData(res[0],res[1])
    this.props.getPricevalue(findObj);
    this.props.getPertual();

  };

  fillprice = (a, b) => {
    a = a.toFixed(8)
    a = parseFloat(a)
    console.log(a,'fillprice-a')
    this.setState({ quantity: a, price: b }, () => {
      this.calculation();
    });
  };

  fillquantity = e => {
    this.setState({ quantity: e.target.innerHTML });
  };
  onChange = e => {
    console.log("eeeeonchabge",e.target.value);
    this.setState({ [e.target.id]: e.target.value });
    // if (e.target.id == 'takeprofit' && e.target.value) {
    //   if (this.state.buyorsell == 'buy' && parseFloat(this.state.markprice) >= parseFloat(e.target.value)) {
    //     this.setState({ alertmessage2: "Stop Loss price you set must be higher than " + this.state.markprice });
    //     return false;
    //   }
    //   else if (this.state.buyorsell == 'sell' && parseFloat(this.state.markprice) >= parseFloat(e.target.value)) {
    //     this.setState({ alertmessage2: "Stop Loss price you set must be lower than " + this.state.markprice });
    //     return false;
    //   }
    //   else {
    //     this.setState({ alertmessage2: '' });
    //     return true;
    //   }
    // }
    if (e.target.id == 'trailcheck' && e.target.value) {
      this.setState({ trailreadOnly: (this.state.trailreadOnly) ? false : true && this.setState({takeprofit: 0})});
    }
    if (e.target.id == 'stopcheck' && e.target.value) {
      this.setState({ stopreadOnly: (this.state.stopreadOnly) ? false : true });
      this.setState({ stopprice: this.state.stopreadOnly == true ? 0 : this.state.stopprice });
    }
    if (e.target.id == 'takeprofitcheck' && e.target.value) {
      this.setState({ tpreadOnly: (this.state.tpreadOnly) ? false : true });
      this.setState({ takeprofit: this.state.tpreadOnly == true ? 0 : this.state.takeprofit });
    }
    // if (e.target.id == 'stopprice' && e.target.value) {
    //   if (this.state.buyorsell == 'buy' && parseFloat(this.state.markprice) <= parseFloat(e.target.value)) {
    //     this.setState({ alertmessage1: "Stop Loss price you set must be lower than " + this.state.markprice });
    //     return false;
    //   }
    //   else if (this.state.buyorsell == 'buy' && parseFloat(this.state.Liqprice) >= parseFloat(e.target.value)) {
    //     this.setState({ alertmessage1: "Stop Loss price you set must be higher than " + parseFloat(this.state.Liqprice).toFixed(this.state.floating) });
    //     return false;
    //   }
    //   else if (this.state.buyorsell == 'sell' && parseFloat(this.state.markprice) >= parseFloat(e.target.value)) {
    //     this.setState({ alertmessage1: "Stop Loss price you set must be higher than " + this.state.markprice });
    //     return false;
    //   }
    //   else if (this.state.buyorsell == 'sell' && parseFloat(this.state.Liqprice) <= parseFloat(e.target.value)) {
    //     this.setState({ alertmessage1: "Stop Loss price you set must be lower than " + parseFloat(this.state.Liqprice).toFixed(this.state.floating) });
    //     return false;
    //   }
    //   else {
    //     this.setState({ alertmessage1: '' });
    //     return true;
    //   }

    // }
    if (e.target.id == 'reduce_only' || e.target.id == 'post_only') {
      var checked = e.target.checked;

      if (e.target.id == 'post_only') {
        this.setState({ post_only: checked });
      } else if (e.target.id == 'reduce_only') {
        this.setState({ reduce_only: checked });
      }
    }
    else if (e.target.id == 'quantity' || e.target.id == 'price') {
      var leverage = parseFloat(100 / this.state.value);
      if (e.target.id == 'quantity') {
        var quantity = e.target.value;
        var orderprice = this.state.ordertype == 'Market' ? this.state.markprice : this.state.price;
        console.log("orderprice",orderprice)
        var order_value = parseFloat(quantity / orderprice).toFixed(8);
        this.setState({ orderQty: quantity, orderPrice: orderprice, order_value });
        // this.calculateMarginImpact(quantity, orderprice, 'onchange')
        this.calculateOrderCost(orderprice, quantity, this.state.leverage)
        return
      }
      if (e.target.id == 'price') {
        var buyselltype = this.state.buyorsell;
        var quantity = this.state.quantity;
        var orderprice = this.state.ordertype == 'Market' ? this.state.markprice : e.target.value;


        var order_value = parseFloat(quantity / orderprice).toFixed(8);
        this.setState({ orderQty: quantity, orderPrice: orderprice, order_value });
        this.calculateOrderCost(orderprice, quantity, this.state.leverage)
        return

        // if(this.state.ordertype=='Limit' && buyselltype=="buy" && parseFloat(e.target.value) > parseFloat(this.state.selllimit))
        // {
        //     this.setState({alertmessage:"Entry price you set must be lower or equal to "+parseFloat(this.state.selllimit)})
        //     return false;
        // }
        // else if(this.state.ordertype=='Limit' && buyselltype=="sell" && parseFloat(e.target.value) < parseFloat(this.state.buylimit))
        // {
        //   this.setState({alertmessage:"Entry price you set must be higher or equal to "+this.state.buylimit})
        //   return false;
        // }
        // else{
        //    this.setState({alertmessage:""})
        // }
      }



      var initial_margin = ((parseFloat(quantity) / parseFloat(orderprice)) * leverage) / 100;

      var feetoopen = (quantity / orderprice) * this.state.taker_fee / 100;

      var Bankruptcy = orderprice * this.state.value / (parseFloat(this.state.value) + 1);

      var feetoclose = (quantity / Bankruptcy) * this.state.taker_fee / 100;

      var order_value = parseFloat(quantity / orderprice).toFixed(8);

      var order_cost = parseFloat(initial_margin) + parseFloat(feetoopen) + parseFloat(feetoclose);


      // var initial_margin = ((parseFloat(quantity)/parseFloat(orderprice))*leverage)/100;

      // var feetoopen = (quantity/orderprice)*this.state.taker_fee/100;

      // var Bankruptcy = orderprice * this.state.value /(parseFloat(this.state.value) + 1);

      // var feetoclose = (quantity/Bankruptcy) * this.state.taker_fee/100;
      // var order_cost = parseFloat(initial_margin) +  parseFloat(feetoopen) +  parseFloat(feetoclose);


    }
  };

  changetimeinforce = timeinforcetype => {
    this.setState({ timeinforcetype });
  };

  orderbookshow = e => {
    this.setState({ orderbookshow: e.target.id })
  };

  calculation = (value = 0) => {
    // alert('tefsdf');
    console.log("value",value,this.state.price,this.state.markprice,this.state.quantity)
    var orderprice = this.state.ordertype == 'Market' ? this.state.markprice : this.state.price;
    var leverage = value != 0 ? value : this.state.value;
    // var leverage = parseFloat(100/this.state.value);

    // var initial_margin = ((parseFloat(this.state.quantity)/parseFloat(orderprice))*leverage)/100;

    // var feetoopen = (this.state.quantity/orderprice)*this.state.taker_fee/100;

    // var Bankruptcy = orderprice * this.state.value /(parseFloat(this.state.value) + 1);

    // var feetoclose = (this.state.quantity/Bankruptcy) * this.state.taker_fee/100;

    // var order_value = (this.state.quantity/orderprice);

    // var order_cost = parseFloat(initial_margin) +  parseFloat(feetoopen) +  parseFloat(feetoclose);

    // var order_value1 = parseFloat(this.state.quantity * orderprice).toFixed(8);
    // console.log("order_value1",order_value1)
    var order_value = parseFloat(this.state.quantity / orderprice).toFixed(8);
    console.log("order_value",order_value)
    this.calculateOrderCost(orderprice, this.state.quantity, this.state.leverage)
    // var required_margin = parseFloat(order_value) / leverage;
    // console.log("required_margin",required_margin)
    // var fee = parseFloat(order_value1) * this.state.taker_fee / 100;
    // console.log("fee",fee)
    // var margininbtc = parseFloat(required_margin) / parseFloat(this.state.btcprice);
    // var feeinbtc = parseFloat(fee) / parseFloat(this.state.btcprice);
    // console.log("margininbtc,feeinbtc",margininbtc,feeinbtc)
    // var order_cost = parseFloat(margininbtc) + parseFloat(feeinbtc);
    // console.log("order_cost",order_cost)
    this.setState({  order_value: parseFloat(order_value).toFixed(8) });
  };

  componentDidMount() {
		window.scrollTo(0, 0);
    // document.getElementsByTagName("body")[0].classList.add("padi_over_body");
    
    this.getData();
    // var fromlocal =localStorage.getItem('curpair')
    // if (localStorage.getItem('curpair') =="null") {
    //   localStorage.setItem('curpair','BTC-USD')
    // }
    if (!localStorage.getItem('curpair') || localStorage.getItem('curpair') == "null") {
      localStorage.setItem('curpair', 'BTC-USD')
    }



  };

  getData() {
    console.log(this.state.firstcurrency , this.state.secondcurrency,"getData")
    this.props.getPertual();
    this.getTradeData();
    this.fetchPositionData(this.state.firstcurrency , this.state.secondcurrency)
    this.getuserTradeData();
    this.socket.emit('CREATEROOM', this.props.auth.user.id);

  }

  changeopenpositions(value) {

    this.props.changeopenpositions({ leverage: value, user_id: this.props.auth.user.id });
  }
  // getPertual() {
  //   this.props.getPertual()
  // }
  getTradeData() {
    let userid = this.props.auth.user.id;
    var findObj = {
      userid: userid,
      firstCurrency: this.state.firstcurrency,
      secondCurrency: this.state.secondcurrency
    };
    this.props.getTradeData(findObj);
  }
  getPricevalue() {
    var findObj = {
      firstCurrency: this.state.firstcurrency,
      secondCurrency: this.state.secondcurrency
    };
    this.props.getPricevalue(findObj);
  }

  getuserTradeData() {
    let userid = this.props.auth.user.id;
    var findObj = {
      userid: userid,
      firstCurrency: this.state.firstcurrency,
      secondCurrency: this.state.secondcurrency
    };
    this.props.getuserTradeData(findObj);
    this.props.getPricevalue(findObj);
  }

  render() {
    const { user } = this.props.auth;
    const { last, change, high, low, volume } = this.state.pairData;
    const pairdata = this.state.records


    const { value, records, assetOverview, timeinforcetype, errors, orderHistory, Histroydetails, Filleddetails, perpetual, Conditional_details, position_details, daily_details, closed_positions, orderHistoryCnt, positionDetails, closedPositionCnt,filledDetailsCnt,historyDetailsCnt } = this.state

    if (Conditional_details.length > 0) {
      var cindex = Conditional_details.findIndex(x => (x.trigger_ordertype) === 'takeprofit');
      var tpprice = (cindex == -1) ? '-' : Conditional_details[cindex].trigger_price;

      var sindex = Conditional_details.findIndex(x => (x.trigger_ordertype) === 'stop');
      var stprice = (sindex == -1) ? '-' : Conditional_details[sindex].trigger_price;
    }



    if (position_details.length > 0) {
      var pos_pairName = (position_details[0].pairName) ? position_details[0].pairName : 0;
      // var pos_quantity = (position_details[0].quantity) ? position_details[0].quantity : 0;
      var pos_quantity = 0;
      if (position_details[0].quantity > 0) {
        pos_quantity = position_details[0].quantity - ((position_details[0].positionFilled) ? position_details[0].positionFilled : 0);
      } else if (position_details[0].quantity < 0) {
        pos_quantity = position_details[0].quantity + ((position_details[0].positionFilled) ? position_details[0].positionFilled : 0);
      }
      var pos_price = (position_details[0].price) ? position_details[0].price : 0;
      var pos_leverage = (position_details[0].leverage) ? position_details[0].leverage : 0;

      //calculate the initial margin

      var order_value1 = parseFloat(pos_quantity * pos_price).toFixed(8);

      var order_value = parseFloat(order_value1 / this.state.btcprice).toFixed(8);

      var required_margin = parseFloat(order_value1) / pos_leverage;

      var fee = parseFloat(order_value1) * this.state.taker_fee / 100;

      var margininbtc = parseFloat(required_margin) / parseFloat(this.state.btcprice);
      var feeinbtc = parseFloat(fee) / parseFloat(this.state.btcprice);

      var pos_initial_margin = parseFloat(margininbtc) + parseFloat(feeinbtc);


      // var pos_initial_margin = (parseFloat(pos_quantity)/parseFloat(pos_price))*((100/parseFloat(pos_leverage))/100);
      var mainmarginwithleverage = parseFloat(this.state.mainmargin) * parseFloat(pos_leverage);

      if (pos_quantity > 0) {
        var difference = parseFloat(this.state.markprice) - parseFloat(pos_price);
        var pos_liqprice = (parseFloat(pos_price) * parseFloat(pos_leverage)) / ((parseFloat(pos_leverage) + 1) - parseFloat(mainmarginwithleverage));
      }
      else {
        var difference = parseFloat(pos_price) - parseFloat(this.state.markprice);
        var pos_liqprice = (parseFloat(pos_price) * parseFloat(pos_leverage)) / ((parseFloat(pos_leverage) - 1) + parseFloat(mainmarginwithleverage));
      }
      var profitnlossusd = parseFloat(difference) * parseFloat(Math.abs(pos_quantity));
      var profitnloss = parseFloat(profitnlossusd) / parseFloat(pos_price);
      var profitnlossbtc = parseFloat(profitnlossusd) / parseFloat(this.state.btcprice);
      var profitnlossper = parseFloat(profitnloss) * 100;
      var roe = parseFloat(profitnlossper) * 10;
      roe = (profitnlossbtc < 0) ? roe : "+" + (roe);


    }
    if (daily_details.length > 0) {
      var daily_quantity = (daily_details[0].quantity) ? daily_details[0].quantity : 0;
      var daily_price = (daily_details[0].price) ? daily_details[0].price : 0;
      var daily_leverage = (daily_details[0].leverage) ? daily_details[0].leverage : 0;
      var daily_fees = (daily_details[0].Fees) ? daily_details[0].Fees : 0;

      //calculate the initial margin
      var daily_initial_margin = (parseFloat(daily_quantity) / parseFloat(daily_price)) * ((100 / parseFloat(daily_leverage)) / 100);
      if (daily_quantity > 0) {
        var difference = parseFloat(this.state.markprice) - parseFloat(daily_price);
      }
      else {
        var difference = parseFloat(daily_price) - parseFloat(this.state.markprice);
      }

      var dailyprofitnlossusd = parseFloat(difference) * parseFloat(Math.abs(daily_quantity));
      var dailyprofitnloss = parseFloat(profitnlossusd) / parseFloat(daily_price);
      var dailyprofitnlossbtc = parseFloat(profitnlossusd) / parseFloat(this.state.btcprice);
      var dailyprofitnlossper = parseFloat(profitnloss) * 100;


      // var dailyprofitnloss        = [ (1/parseFloat(daily_price))  - (1/parseFloat(this.state.last_price)) ] * parseFloat(daily_quantity);
      // dailyprofitnloss            = parseFloat(dailyprofitnloss)-parseFloat(daily_fees)
      // var dailyprofitnlossper     = (parseFloat(dailyprofitnloss)/parseFloat(daily_initial_margin))*100;
      // var dailyprofitnlossusd     = (dailyprofitnloss*parseFloat(this.state.last_price));
      // var dailyprofitnlossbtc =  parseFloat(dailyprofitnlossusd)/parseFloat(this.state.btcprice);

    }
    else {
      var daily_quantity = 0;
      var daily_price = 0;
      var daily_leverage = 0;
      var daily_fees = 0;
      var dailyprofitnlossper = 0;
      var dailyprofitnloss = 0;
      var dailyprofitnlossusd = 0;
      var dailyprofitnlossbtc = 0;
    }
    // Rc Slider - https://react-component.github.io/slider/

    const marks = {
      1: '1x',
      25: '25x',
      50: '50x',
      75: '75x',
      100: '100x',
      125: '125x',
      150: {
        style: {
          color: '#00b5eb',
        },
        label: <strong>150x</strong>,
      },
    };

    const marks1 = {
      1: '1x',
      10: '10x',
      20: '20x',
      30: '30x',
      40: '40x',
      50: {
        style: {
          color: '#00b5eb',
        },
        label: <strong>50x</strong>,
      },
    };
    // const { t } = this.props;
    const { t } = this.props;
    return (
      <div className='headerdrop'> 
        <div className="container-fluid px-0 main_bg_trade_new derivehead">
        <Navtradebar />
        <section className="tradeMain tradePage mb-trade-new-sm">
          
        <div className='row m-0 mb-1  d-sm-flex d-block'>

            {/* <Dropdown classNmae="btn btn-default">
                <Dropdown.Toggle variant="success" id="dropdown-basic"  className='derivebtn  mb-sm-0 mb-2 themebtn'>
                {this.props.match.params.currency}
                </Dropdown.Toggle>
                <Dropdown.Menu className='dd_menu_der_dd'>
                {pairdata.length >0 && pairdata.map((val,index)=>(
                  <Dropdown.Item href="javascript:void(0)" id ={`${val.first_currency}-${val.second_currency}`} value={`${val.first_currency}-${val.second_currency}`} key={index}  onClick={this.pairChange}>{val.first_currency}-{val.second_currency}</Dropdown.Item>
                ))}
                </Dropdown.Menu>
                    
          </Dropdown> */}
        <Select className="border_blue_select_normal_sel bluebtn mb-sm-0 mb-2"
                              width='100%'
                              menuColor='red'
                              value={this.props.match.params.currency}
                              MenuProps={menuProps}
                              onChange={this.pairChange} 
                              // anchorEl={anchorElNoti}
                              onClick={this.handleClick} isSearchable={false}
                      // keepMounted
                      // open={Boolean(anchorElNoti)}
                      // onClose={handleClose}
                      >
        {/* <MenuItem >{this.props.match.params.currency}</MenuItem> */}
          {pairdata.length>0 && pairdata.map((val,index)=>(
            <MenuItem value={`${val.first_currency}-${val.second_currency}`}>{val.first_currency}-{val.second_currency}</MenuItem>
          ))}
        </Select>
        <>
                {/* <button type="button" className="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">BTC-USDT Perpetual</button>
                <ul className="dropdown-menu">
                    <li className="dropdown-header">
                        <ul className="mx-0">
                      <li>BTC-USDT Perpetual</li>
                      <li>BTC-USDT Perpetual</li>

                        </ul>
                    </li>
                </ul> */}
                </>
            
      
                <div className="header-overview derive" style={{flex:1}}>
                <div className="headerOverviewGroup jc-between">
            <div className="headerOverviewStatus">
                <h5>
                <small>{t("SPOT_PRICE")}</small>
                <span className="greenText">
                {(
                  (typeof this.state.markprice != 'undefined') ?
                  parseFloat(this.state.markprice).toFixed(this.state.floating) : 0) + ' '
                }
                </span>
            </h5>
        </div>
        <div className="headerOverviewStatus">
            <h5>
                <small>{t("LAST_PRICE")}</small>0.00
            </h5>
            </div>
            <div className="headerOverviewStatus">
                
              {
                (this.state.change < 0) ?
                    <h5 className="pinkText"><small>{t("24H_CHANGE")}</small>{((this.state.change != '') ? parseFloat(this.state.change).toFixed(2) : 0)}%</h5> :
                    <h5 className="greenText"><small>{t("24H_CHANGE")}</small>{((this.state.change != '') ? parseFloat(this.state.change).toFixed(2) : 0)}%</h5>
              }
              
            </div>
            <div className="headerOverviewStatus">
                <h5>
                    <small>{t("24H_HIGH")}</small>
                    {((typeof this.state.pricedet != 'undefined' && this.state.pricedet.length > 0) ? parseFloat(this.state.pricedet[0].high).toFixed(this.state.floating) : parseFloat(this.state.markprice).toFixed(this.state.floating)) + ' '}
                </h5>
            </div>
            <div className="headerOverviewStatus">
                <h5>
                    <small>{t("24_LOW")}</small>
                    {((typeof this.state.pricedet != 'undefined' && this.state.pricedet.length > 0) ? parseFloat(this.state.pricedet[0].low).toFixed(this.state.floating) : parseFloat(this.state.markprice).toFixed(this.state.floating)) + ' '}
                </h5>
            </div>
            <div className="headerOverviewStatus">
                <h5>
                    <small>{t("24H_TURNOVE")}</small>
                    {(typeof this.state.pricedet != 'undefined' && this.state.pricedet.length > 0) ? parseFloat(Math.abs(this.state.pricedet[0].secvolume)).toFixed(3) : 0} {this.state.secondcurrency}
                </h5>
            </div>
            </div>

        </div>
        </div>
        <div className="rowCustom mx-0">
              <div className="fixedWidth px-0 flexColumn mr-right-trade-new">
                <div className="darkBox tradeLimitMarket border-bottom-one border-left-zero border-right-one">

                  <nav className="pt-2">
                    <div className="nav nav-tabs nav-fill" id="nav-tab-buySell" role="tablist">
                      <a className="nav-item nav-link active" id="nav-tradeLimit-tab" data-toggle="tab" href="#nav-tradeLimit" role="tab" aria-controls="nav-tradeLimit" aria-selected="true" onClick={this.ordertype_changes.bind(this)}>{t("MARKET")}</a>

                      <a className="nav-item nav-link " id="nav-tradeLimit-tab" data-toggle="tab" href="#nav-tradeLimit" role="tab" aria-controls="nav-tradeLimit" aria-selected="true" onClick={this.ordertype_changes.bind(this)}>{t("LIMIT")}</a>


                    </div>
                  </nav>
                  <div className="tab-content" id="nav-tabContent-tradeLimitMarket">
                    <div className="buySellTabButton">
                      {console.log("eeeeeeeeeeeee",this.state.buyorsell)}
                      <div className="btn-group">
                        {(this.state.buyorsell == 'buy') ?  
                          <button className="btn btnGroupBorderBtn btnBuyBlue" onClick={ () => this.handleShow('buy')}>{t("BUY")}</button> :
                          <button className="btn btnGroupBorderBtn btnBuyYellow" onClick={() =>this.handleShow('buy')}>{t("BUY")}</button>
                        }
                        {(this.state.buyorsell == 'sell') ?
                          <button className="btn btnGroupBorderBtn btnSellBlue" onClick={() => this.handleShow('sell')}>{t("SELL")}</button> :
                          <button className="btn btnGroupBorderBtn btnSellYellow" onClick={() => this.handleShow('sell')}>{t("SELL")}</button>
                        }
                      </div>
                    </div>
                    <div className="my-4">
                      {this.state.limit ?
                        ( <>
                        <ReactSelect
                          className="border_blue_select border_blue_select_bg_blue "
                          width='100%'
                          menuColor='red'
                          isSearchable={false}
                          options={options}
                          MenuProps={menuProps}
                          value={timeinforcetype}
                          onChange={this.changetimeinforce}
                // isDisabled={true}

                        
                        />
                       </> )
                        : ''}
                    </div>



                    <div className="tab-pane fade show active" id="nav-tradeLimit" role="tabpanel" aria-labelledby="nav-tradeLimit-tab">
                      {this.state.limit ?
                        <div>
                          <div className="form-group">
                            <div className="d-flex justify-content-between  align-items-center">
                              <div className="">
                                <label><span>{this.state.secondcurrency}</span></label>{t("PRICE")} 
                              </div>
                              <div className="">
                                <div className="def-number-input safari_only colorBlue">
                                  <input className="quantity" min="0" name="price" id="price"  type="text" value={this.state.price} onChange={this.onChange} />

                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="">
                              {/* <div className="col-md-6"> */}
                                <label>{t("CURRENT_PRICE")}
                                <span>{this.state.firstcurrency} in {this.state.secondcurrency}  </span><span>{this.state.markprice + " $"}</span></label>
                               
                              {/* </div> */}
                              {/* <div className="col-md-6">
                                <div className="def-number-input safari_only colorBlue">
                                 

                                </div>
                              </div> */}
                            </div>
                          </div>
                        </div>
                        :
                        <div className="form-group">
                          <div className="">
                            <div className="">
                              <label>{t("CURRENT_PRICE")}
                              <span>{this.state.firstcurrency} in {this.state.secondcurrency}</span> <span>{this.state.markprice + " $"}</span></label>
                            </div>
                            {/* <div className="col-md-6">
                              <div className="def-number-input safari_only colorBlue">
                                {this.state.markprice + " $"}

                              </div>
                            </div> */}
                          </div>
                        </div>
                      }

                      {this.state.conditional ?
                        <div>
                          <div className="form-group">
                            <div className="row">
                              <div className="col-md-5">
                                <div className="btn-group">
                                  <button className="btn btnGroupBorderBtn" onClick={this.conditionalPrice.bind(this)}>{t("LIMIT")}</button>
                                  <button className="btn btnGroupBorderBtn" onClick={this.conditionalPrice.bind(this)}>{t("MARKET")}</button>
                                </div>
                              </div>
                              <div className="col-md-7">
                                <div className="def-number-input number-input safari_only">
                                  <input className="quantity" min="0" readOnly={this.state.readOnly} name="price" id="price"  type="number" value={this.state.price} onChange={this.onChange} />
                                  <button onClick={this.updownvalue.bind('pri', 'price', 'plus')} className="2 price plus"></button>
                                  <button onClick={this.updownvalue.bind('pri', 'price', 'minus')} className="2 price minus"></button>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="row">
                              <div className="col-md-5">
                                <label>t{"TRIGGER_PRICE"}<span>{this.state.second_currency}</span></label>
                              </div>
                              <div className="col-md-7">
                                <div className="def-number-input number-input safari_only">
                                  <input className="quantity" min="0" name="trigger_price" id="trigger_price" type="number" value={this.state.trigger_price} onChange={this.onChange} />
                                  <button onClick={this.updownvalue.bind('pri', 'trigger_price', 'plus')} className="2 price plus"></button>
                                  <button onClick={this.updownvalue.bind('pri', 'trigger_price', 'minus')} className="2 price minus"></button>
                                </div>
                              </div>
                              <div className="col-md-12">
                                <div className="btn-group btn-block mt-3">
                                  <button className="btn btn-borderButton mr-1" onClick={this.triggerPrice.bind(this)}>{t("LAST")}</button>
                                  <button className="btn btn-borderButton mr-1" onClick={this.triggerPrice.bind(this)}>{t("INDEX")}</button>
                                  <button className="btn btn-borderButton mr-1" onClick={this.triggerPrice.bind(this)}>{t("MARK")}</button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        : ''}
                      <div className="form-group">
                        <div className="d-flex justify-content-between align-items-center">
                          <div className="">
                            <label>{t("QTY")}<span>{this.state.secondcurrency}</span></label>
                          </div>
                          <div className="">
                            <div className="def-number-input number-input safari_only">
                              <input className="quantity" min="0" name="quantity" id="quantity" value={this.state.quantity} onChange={this.onChange} type="number" />
                              <button onClick={this.updownvalue.bind('quan', 'quantity', 'plus')} className="2 plus contnet_none">+</button>
                              <button onClick={this.updownvalue.bind('quan', 'quantity', 'minus')} className="2 minus contnet_none">-</button>
                            </div>
                            {/* <div className="input-group mt-2">
                     <button className="btn btn-borderButton mr-1" onClick={this.balanceperc}>25%</button>
                     <button className="btn btn-borderButton mr-1" onClick={this.balanceperc}>50%</button>
                     <button className="btn btn-borderButton mr-1" onClick={this.balanceperc}>75%</button>
                     <button className="btn btn-borderButton" onClick={this.balanceperc}>100%</button>
                  </div>*/}
                          </div>
                        </div>
                      </div>
                      <ul className="list2ColumnJustify">
                        <li>
                          <label>{t("ORDER_VALUE")}</label>
                          <span>{parseFloat(this.state.order_value).toFixed(8) + ' ' + this.state.firstcurrency}</span>
                        </li>
                        <li>
                          <label>{t("BALANCE")}</label>
                          <span>{parseFloat(isNaN(this.state.balance) ? 0 : this.state.balance).toFixed(8) + ' ' + this.state.firstcurrency}</span>
                        </li>
                        <li>
                          <label>{t("ORDER_COST")}</label>
                          <span>{parseFloat(this.state.order_cost).toFixed(8) + ' ' + this.state.firstcurrency}</span>
                        </li>
                        {/* <li>
                          <label>Margin impact</label>
                             <span>{parseFloat(this.state.order_cost).toFixed(8) + ' ' + this.state.firstcurrency}</span>
                        </li> */}
                        {/*{this.state.limit ?
                          <li>
                            <div className="checkbox"><label><input name="post_only" id="post_only" value="1" onChange={this.onChange} type="checkbox" /><span className="cr"><i className="cr-icon fa fa-check"></i></span><span className="listText">Post only</span></label></div>
                            <div className="checkbox"><label><input name="reduce_only" id="reduce_only" value="1" onChange={this.onChange} type="checkbox" /><span className="cr"><i className="cr-icon fa fa-check"></i></span><span className="listText">Reduce only</span></label></div>
                          </li> : ''}*/}
                      </ul>
                      {this.state.alertmessage != '' ?
                        <div className="quadrat">{this.state.alertmessage}</div> : ''
                      }
                      {(typeof user.email != 'undefined') ?
                        <div className="form-group text-center mt-3">
                          {(!this.state.buydisable) ?
                            <button className="btn buttonType2 themebtn dark" onClick={this.handleShow22.bind(this)} disabled={!this.state.disable}>
                              <span className="spinner-border spinner-border-sm"></span> {t("SEND_ORDER")}
                            </button> :
                            <button className="btn buttonType2 themebtn dark" onClick={this.handleShow22.bind(this)} disabled={!this.state.disable}>
                              {t("SEND_ORDER")}
                            </button>
                          }

                        </div>
                        :
                        <div className="form-group input-group buySellBtn">
                          <Link to="/login" className="btn btnBlue hvr-shadow float-right btn-block mb-3 py-2">{t("LOGIN")}</Link>

                        </div>
                      }
                    </div>

                    <div className="tab-pane fade" id="nav-conditional" role="tabpanel" aria-labelledby="nav-conditional-tab">
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-5">
                            <div className="btn-group">
                              <button className="btn btnGroupBorderBtn">{t("LIMIT")}</button>
                              <button className="btn btnGroupBorderBtn">{t("MARKET")}</button>
                            </div>
                          </div>
                          <div className="col-md-7">
                            <div className="def-number-input number-input safari_only">
                              <input className="quantity" min="0" name="quantity" value="1" type="number" />
                              <button onclick="this.parentNode.querySelector('input[type=number]').stepUp()" className="plus"></button>
                              <button onclick="this.parentNode.querySelector('input[type=number]').stepDown()" className="minus"></button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-5">
                            <label>{t("TRIGGER_PRICE")}<span>{t("USD")}</span></label>
                          </div>
                          <div className="col-md-7">
                            <div className="def-number-input number-input safari_only">
                              <input className="quantity" min="0" name="quantity" value="0.25658748" type="number" />
                              <button onclick="this.parentNode.querySelector('input[type=number]').stepUp()" className="plus"></button>
                              <button onclick="this.parentNode.querySelector('input[type=number]').stepDown()" className="minus"></button>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="btn-group btn-block mt-3">
                              <button className="btn btn-borderButton mr-1">{t("LAST")}</button>
                              <button className="btn btn-borderButton mr-1">{t("INDEX")}</button>
                              <button className="btn btn-borderButton mr-1">{t("MARK")}</button>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="row">
                          <div className="col-md-4">
                            <label>{t("QTY")}<span>{t("BTC")}</span></label>
                          </div>
                          <div className="col-md-8">
                            <div className="def-number-input number-input safari_only">
                              <input className="quantity" min="0" name="quantity" value="0.25658748" type="number" />
                              <button onclick="this.parentNode.querySelector('input[type=number]').stepUp()" className="plus"></button>
                              <button onclick="this.parentNode.querySelector('input[type=number]').stepDown()" className="minus"></button>
                            </div>
                            {/*<div className="input-group mt-2">
                    <button className="btn btn-borderButton mr-1">25%</button>
                    <button className="btn btn-borderButton mr-1">50%</button>
                    <button className="btn btn-borderButton mr-1">75%</button>
                    <button className="btn btn-borderButton">100%</button>
                  </div>*/}
                          </div>
                        </div>
                      </div>
                      <ul className="list2ColumnJustify">
                        <li>
                          <label>{t("ORDER_VALUE")}</label>
                          <span>{parseFloat(this.state.order_value).toFixed(8) + ' ' + this.state.firstcurrency}</span>
                        </li>
                        <li>
                          <label>{t("BALANCE")}</label>
                          <span>{parseFloat(this.state.balance).toFixed(8) + ' ' + this.state.firstcurrency}</span>
                        </li>
                        <li>
                          <label>{t("MARGIN_IMPACT")}</label>
                          <span>{parseFloat(this.state.order_cost).toFixed(8) + ' ' + this.state.firstcurrency}</span>
                        </li>
                        {/*{this.state.limit ?
                          <li>
                            <div className="checkbox"><label><input name="post_only" value="1" type="checkbox" /><span className="cr"><i className="cr-icon fa fa-check"></i></span><span className="listText">Post only</span></label></div>
                            <div className="checkbox"><label><input name="reduce_only" value="1" type="checkbox" /><span className="cr"><i className="cr-icon fa fa-check"></i></span><span className="listText">Reduce only</span></label></div>
                          </li> : ''}*/}
                      </ul>
                      <div className="form-group input-group buySellBtn">
                        {(!this.state.buydisable) ?
                          <button className="btn btn-btnBuy mr-2" onClick={this.handleShow.bind(this)} disabled={!this.state.disable}>
                            <span className="spinner-border spinner-border-sm"></span> {t("BUY")}
                          </button> :
                          <button className="btn btn-btnBuy mr-2" onClick={this.handleShow.bind(this)} disabled={!this.state.disable}>
                            {t("BUY")}
                          </button>
                        }
                        {(!this.state.selldisable) ?
                          <button className="btn btn-btnSell mr-2" onClick={this.handleShow.bind(this)} disabled={!this.state.disable}>
                            <span className="spinner-border spinner-border-sm"></span> {t("SELL")}
                          </button> :
                          <button className="btn btn-btnSell mr-2" onClick={this.handleShow.bind(this)} disabled={!this.state.disable}>
                            {t("SELL")}
                          </button>
                        }
                      </div>
                    </div>

                  </div>
                </div>
                <div className="darkBox tradeLeveage border-bottom-one border-left-zero border-right-one">
                  <div class="tableHead border-bottom-one">
                    <h3 className="my-0">{t("LEVERAGE")}</h3>
                  </div>
                  <div class="py-3 px-3 leverageHeight">
                    <div className="form-group">
                      <div className="mt-2 mb-4">
                        <label>{t("RISK_LIMIT")}</label>
                        <Slider
                          min={1}
                          max={this.state.maxleverage}
                          marks={(this.state.firstcurrency == 'BTC' || this.state.firstcurrency == 'ETH') ? marks : marks1}
                          onChange={this.handleChangeComplete}
                          onAfterChange={this.afterchange}
                          included={false}
                          value={this.state.leverage}
                        />
                      </div>
                      <p id="leverageCurrentSliderValLabel" className="text-center text-white">{t("LEVERAGE")}<span id="leverageSliderVal">{this.state.leverage} %</span></p>
                    </div>

                  </div>
                </div>
              </div>
              <div className="liquidWidth px-0 mr-right-new-trade">
                <div className="chartTradeSection mb-md-0 mb-3">
                  <TVChartContainer
                    className={'chartcontainer'}
                    theme={this.state.theme}
                    pair={this.state.chartpair}
                    pairdatachange={this.pairdatachange}
                    datafeedUrl={url + 'chart/perpetual'}
                  />
                </div>
                <div className="row mx-0 mb-md-0 mb-3">
                  <div className="col-md-12 px-0">


                  <div className="darkBox tradeFulltabbedTable tradeFulltabbedTable_h border-left-zero border-bottom-one mt-top-trade-new">
                      <nav className='pt-3'>
                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                          <a className="nav-item nav-link active" id="nav-positions-tab" data-toggle="tab" href="#nav-positions" role="tab" aria-controls="nav-positions" aria-selected="true">{t("POSITION")}{this.state.curcontract.replace("_", "")}</a>
                          <a className="nav-item nav-link" id="nav-closedPL-tab" data-toggle="tab" href="#nav-closedPL" role="tab" aria-controls="nav-closedPL" aria-selected="false">{t("CLOSED_P&L")} {closedPositionCnt}</a>
                          <a className="nav-item nav-link" id="nav-active0-tab" data-toggle="tab" href="#nav-active0" role="tab" aria-controls="nav-active0" aria-selected="false">{t("PENDING")} {orderHistoryCnt}</a>

                          <a className="nav-item nav-link" id="nav-filledOrder-tab" data-toggle="tab" href="#nav-filledOrder" role="tab" aria-controls="nav-filledOrder" aria-selected="false">{t("FILLED_ORDER")} {filledDetailsCnt}</a>
                          <a className="nav-item nav-link" id="nav-tradeHistory-tab" data-toggle="tab" href="#nav-tradeHistory" role="tab" aria-controls="nav-tradeHistory" aria-selected="false">{t("HISTORY")} {historyDetailsCnt}</a>
                          <a className="nav-item nav-link" id="nav-assetsOverview-tab" data-toggle="tab" href="#nav-assetsOverview" role="tab" aria-controls="nav-assetsOverview" aria-selected="false">{t("ASSET_OVERVIEW")}</a>
                        </div>
                      </nav>
                      <div className="tab-content px-0" id="nav-tabContent">
                        <div className="tab-pane fade show active" id="nav-positions" role="tabpanel" aria-labelledby="nav-positions-tab">
                          <div className="table-responsive">
                            <table id="positionsTable" className="table table-striped">
                              <thead>
                                <tr>
                                  <th>{t("CONTRACTS")}</th>
                                  <th>{t("QTY")}</th>
                                  <th>{t("VALUE")}</th>
                                  <th>{t("ENTYRY_PRICE")}</th>
                                  <th>{t("LIQ_PRICE")}</th>
                                  <th>{t("POSITION_MARGIN")}</th>
                                  <th>{t("UNREALIZED")}</th>
                                  <th>{t("DAILY_REALIZED")}</th>
                                  <th>{t("TP_SL")}</th>
                                  <th>{t("CLOSED_BY")}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  !isEmpty(positionDetails) && <tr>
                                  {console.log(positionDetails,'details*******************888')}
                                  {console.log(positionDetails.positionFilled,'positionDetails.positionFilled')}
                                    <td>
                                      <span className="yellowText">{positionDetails.firstCurrency + positionDetails.secondCurrency}</span>
                                    </td>

                                    <td className={
                                      clsx({ "greenText": positionDetails.buyorsell == 'buy' }, { "pinkText": positionDetails.buyorsell == 'sell' })
                                    }>
                                      {positionDetails.positionFilled}
                                    </td>
                                    <td>{toFixed(positionDetails.positionFilled / positionDetails.price, 4)}</td>
                                    <td>{positionDetails.price}</td>
                                    <td>{positionDetails.liquidityPrice}</td>
                                    <td>{inversePositionMargin({
                                      'price': positionDetails.price,
                                      'quantity': positionDetails.positionFilled,
                                      'leverage': positionDetails.leverage,
                                      'takerFee': positionDetails.taker_fees,
                                      'buyorsell': positionDetails.buyorsell
                                    })}</td>

                                    <td>
                                      <UnrealizedProfitLoss
                                        positionDetail={positionDetails}
                                        marketPrice={this.state.markprice}
                                      />
                                    </td>
                                    <td>{'-'}</td>
                                    {
                                      !positionDetails.isProfitLoss && <td>{'-/-'}</td>
                                    }
                                    {
                                      positionDetails.isProfitLoss && <td>{positionDetails.takeProfitPrice + '/' + positionDetails.stopLossPrice}</td>
                                    }
                                    <td>
                                      <button className="btn btnGroupBorderBtn btn-sm px-1 py-0 btn-block fontSM" onClick={this.handleShow1.bind(this)}>{t("MARKET")}</button>
                                    </td>

                                  </tr>
                                }
                              </tbody>
                            </table>
                          </div>
                         
                        </div>
                        <div className="tab-pane fade" id="nav-closedPL" role="tabpanel" aria-labelledby="nav-closedPL-tab">
                          <div className="table-responsive">
                            {/* <Scrollbars ref='scrollbar' style={{ width: '100%', height: 155 }} renderTrackVertical={props => <div className="track-vertical" />}> */}
                              <table id="closedPLTable" className="table table-striped">
                                <thead>
                                  <tr>
                                    <th>{t("CONTRACTS")}</th>
                                    <th>{t("CLOSING_DIRECTION")}</th>
                                    <th>{t("QUANTITY")}</th>
                                    <th>{t("ENTYRY_PRICE")}</th>
                                    <th>{t("EXIT_PRICE")}</th>
                                    <th>{t("CLOSED_P&L(EXCL FEE)")} </th>
                                    {/* <th> 
  <OverlayTrigger
      placement="right"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      <p variant="success"></p>
    </OverlayTrigger>
    </th> */}
                                    <th>{t("EXIT_TYPE")}</th>
                                    <th>{t("CLOSE_POSITION_TIME")}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    closed_positions.map((item, i) => {
                                      var pairName = item.pairname ? item.pairname : '';
                                      var closing_direction = item.closing_direction ? item.closing_direction : 0;
                                      var quantity = item.quantity ? item.quantity : 0;
                                      var entry_price = item.entry_price ? item.entry_price : 0;
                                      var exit_price = item.exit_price ? item.exit_price : 0;
                                      var profitnloss = item.profitnloss ? item.profitnloss : 0;
                                      var exit_type = item.exit_type ? item.exit_type : 0;
                                      var createdDate = item.createdDate ? item.createdDate : 0;
                                      var classs = (profitnloss > 0) ? "greenText" : 'pinkText';

                                      var data1 = new Date(createdDate);
                                      let date12 = data1.getFullYear() + '-' + (data1.getMonth() + 1) + '-' + data1.getDate() + ' ' + data1.getHours() + ':' + data1.getMinutes() + ':' + data1.getSeconds();


                                      // //console.log(date12,'datezxc');
                                      return <tr>
                                        <td className="yellowText">{pairName}</td>
                                        <td>{closing_direction}</td>
                                        <td>{parseFloat(quantity).toFixed(8)}</td>
                                        <td>{parseFloat(entry_price).toFixed(this.state.floating)}</td>
                                        <td>{parseFloat(exit_price).toFixed(this.state.floating)}</td>

                                        <td className={classs}>
                                        <OverlayTrigger
      placement="bottom"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip(profitnloss)}
    >
     <p className='mb-0'>{parseFloat(profitnloss).toFixed(8)}</p> 
    </OverlayTrigger>
                                          
                                          </td>
                                        <td>{exit_type}</td>
                                        <td>{date12}</td>
                                      </tr>
                                    })
                                  }
                                </tbody>
                              </table>
                            {/* </Scrollbars> */}
                          </div>
                          {
                            closed_positions && closed_positions.length < closedPositionCnt &&
                            <div className='load_more_sec_btn'>
                                    
                                    <button className="btn btn-borderButton" onClick={this.closedPositionLoadMore}>{t("LOADMORE")}</button>
                                  
                                    </div>
                          }
                          
                        </div>
                        <div className="tab-pane fade" id="nav-active0" role="tabpanel" aria-labelledby="nav-active0-tab">
                          <div className="table-responsive">
                            {/* <Scrollbars ref='scrollbar' style={{ width: '100%', height: 155 }} renderTrackVertical={props => <div className="track-vertical" />}> */}
                              <table id="active0Table" className="table table-striped">
                                <thead>
                                  <tr>
                                    <th>{t("CONTRACTS")}</th>
                                    <th>{t("QUANTITY")}</th>
                                    <th>{t("PRICE")}</th>
                                    <th>{t("FILLED_REMAINING")}</th>
                                    {/* <th>Order Value</th> */}
                                    <th>{t("TP_SL")}</th>
                                    <th>{t("TYPE")}</th>
                                    <th>{t("STATUS")}</th>
                                    <th>{t("ORDER")}</th>
                                    <th>{t("ORDER_TIME")}</th>
                                    <th>{t("ACTION")}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {  
                                    orderHistory.map((item, i) => {
                                      console.log("orderHistory",orderHistory);
                                      var pairName = item.pairName ? item.pairName : '';
                                      var quantity = item.quantity ? item.quantity : 0;
                                      var price = item.quantity ? item.price : 0;
                                      var filledAmount = item.filledAmount ? item.filledAmount : 0;
                                      var orderValue = item.orderValue ? item.orderValue : 0;
                                      var orderType = item.orderType ? item.orderType : 0;
                                      var orderDate = item.orderDate ? item.orderDate : 0;
                                      var classs = item.buyorsell == 'buy' ? 'greenText' : 'pinkText';
                                      var _id = item._id ? item._id : 0;
                                      var status = item.status == '0' ? 'New' : 'Partial';
                                      var Remaining = parseFloat(quantity) - parseFloat(filledAmount);
                                      // //console.log(orderDate,'orderDate');
                                      var data1 = new Date(orderDate);
                                      let date12 = data1.getFullYear() + '-' + (data1.getMonth() + 1) + '-' + data1.getDate() + ' ' + data1.getHours() + ':' + data1.getMinutes() + ':' + data1.getSeconds();


                                      // //console.log(date12,'datezxc');
                                      return <tr>
                                        <td className="yellowText">{pairName}</td>
                                        <td><span className={classs}>{parseFloat(quantity).toFixed(8)}</span></td>
                                        <td>{parseFloat(price).toFixed(this.state.floating)}</td>
                                        <td>{parseFloat(filledAmount).toFixed(8) + '/' + parseFloat(Remaining).toFixed(8)}</td>
                                        {/* <td>{parseFloat(orderValue).toFixed(8)}</td> */}
                                        {
                                          !item.isProfitLoss && <td>{'-/-'}</td>
                                        }
                                        {
                                          item.isProfitLoss && <td>{item.takeProfitPrice + '/' + item.stopLossPrice}</td>
                                        }

                                        <td>{orderType}</td>
                                        <td>{status}</td>
                                        <td>{_id}</td>
                                        <td>{date12}</td>
                                        <td>
                                          <button className="btn btn-borderButton mr-1" onClick={this.cancelFunction.bind(this)} id={_id}> {t("CANCEL")} </button>
                                        </td>
                                      </tr>
                                    })
                                  }
                                 

                                </tbody>
                              </table>
                            {/* </Scrollbars> */}
                          </div>
                          {
                                    orderHistory && orderHistory.length < orderHistoryCnt && 
                                    <div className='load_more_sec_btn'>
                                    
                                      <button className="btn btn-borderButton" onClick={this.orderHistoryLoadMore}> {t("LOADMORE")} </button>
                                    
                                      </div>
                                  }
                        </div>
                        <div className="tab-pane fade" id="nav-conditional0" role="tabpanel" aria-labelledby="nav-conditional0-tab">
                          <div className="table-responsive">
                            {/* <Scrollbars ref='scrollbar' style={{ width: '100%', height: 155 }} renderTrackVertical={props => <div className="track-vertical" />}> */}
                              <table id="conditional0Table" className="table table-striped">
                                <thead>
                                  <tr>
                                  <th>{t("CONTRACTS")}</th>
                                    <th>{t("QUANTITY")}</th>
                                    <th>{t("PRICE")}</th>
                                    <th>{t("TRIGGER_PRICE")}</th>
                                    <th>{t("ORDER_VALUE")}</th>
                                    <th>{t("PRICE_DISTANCE")}</th>
                                    <th>{t("TYPE")}</th>
                                    <th>{t("STATUS")}</th>
                                    <th>{t("ORDER")}</th>
                                    <th>{t("ORDER_TIME")}</th>
                                    <th>{t("ACTION")}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    Conditional_details.map((item, i) => {
                                      var pairName = item.pairName ? item.pairName : '';
                                      var quantity = item.quantity ? item.quantity : 0;
                                      var price = item.quantity ? item.price : 0;
                                      var filledAmount = item.filledAmount ? item.filledAmount : 0;
                                      var orderValue = item.orderValue ? item.orderValue : 0;
                                      var orderType = item.orderType ? item.orderType : 0;
                                      var trigger_price = item.orderType ? item.trigger_price : 0;
                                      var trigger_type = item.orderType ? item.trigger_type : 0;
                                      var orderDate = item.orderDate ? item.orderDate : 0;
                                      var classs = (quantity > 0) ? 'greenText' : 'pinkText';
                                      var _id = item._id ? item._id : 0;
                                      var status = item.status == '0' ? 'New' : 'Partial';
                                      var Remaining = parseFloat(quantity) - parseFloat(filledAmount);
                                      var price_distance = parseFloat(this.state.last_price) - parseFloat(price);
                                      // //console.log(orderDate,'orderDate');
                                      var data1 = new Date(orderDate);
                                      let date12 = data1.getFullYear() + '-' + (data1.getMonth() + 1) + '-' + data1.getDate() + ' ' + data1.getHours() + ':' + data1.getMinutes() + ':' + data1.getSeconds();
                                      // //console.log(date12,'datezxc');
                                      return <tr>
                                        <td className="yellowText">{pairName}</td>
                                        <td><span className={classs}>{parseFloat(quantity).toFixed(8)}</span></td>
                                        <td>{parseFloat(price).toFixed(this.state.floating)}</td>
                                        <td>{(price_distance > 0) ? '<=' + trigger_price + ' (' + trigger_type + ')' : '>=' + trigger_price + ' (' + trigger_type + ')'}</td>
                                        <td>{parseFloat(orderValue).toFixed(8)}</td>
                                        <td>{this.state.last_price + '(' + price_distance + ')'}</td>
                                        <td>{orderType}</td>
                                        <td>{status}</td>
                                        <td>{_id}</td>
                                        <td>{date12}</td>
                                        <td><button className="btn btn-borderButton mr-1" onClick={this.cancelFunction.bind(this)} id={_id}> {t("CANCEL")} </button></td>
                                      </tr>
                                    })
                                  }
                                </tbody>
                              </table>
                            {/* </Scrollbars> */}
                          </div>
                        </div>
                        <div className="tab-pane fade" id="nav-filledOrder" role="tabpanel" aria-labelledby="nav-filledOrder-tab">
                          <div className="table-responsive">
                            {/* <Scrollbars ref='scrollbar' style={{ width: '100%', height: 155 }} renderTrackVertical={props => <div className="track-vertical" />}> */}
                              <table id="filledOrderTable" className="table table-striped rembordor">
                                <thead>
                                  <tr>
                                  <th>{t("CONTRACTS")}</th>
                                    <th>{t("QUANTITY")}</th>
                                    <th>{t("FILLED_REMAINING")}</th>
                                    <th>{t("EXEC_PRICE")}</th>
                                    <th>{t("PRICE")}</th>
                                    <th>{t("TYPE")}</th>
                                    <th>{t("ORDER")}</th>
                                    <th>{t("TIME")}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                {
                                  Filleddetails.map((item, i) => {
                                    // //console.log(item,'Filleddetails');
                                    var quantity = item.quantity ? item.quantity : 0;
                                    var price = item.price ? item.price : 0;
                                    var _id = item._id ? item._id : 0;
                                    var classs = (quantity > 0) ? 'greenText' : 'pinkText';
                                    var filledAmount = item.filledAmount ? item.filledAmount : 0;
                                    // var Remaining    = parseFloat(Math.abs(quantity)) - parseFloat(filledAmount);
                                   // var Remaining = parseFloat(quantity) - parseFloat(filledAmount);
                                    let Remaining=0
                                    let filledamount = 0

                                    return (
                                    <>
                                      {
                                        item.filled.map((itemdata, i) => {
                                      console.log("filledamount",filledamount)
                                      filledamount = filledamount+itemdata.filledAmount
                                      Remaining  =parseFloat(quantity)- (filledamount == 0 ? parseFloat(itemdata.filledAmount) :parseFloat(filledamount) )

                                        var created_at = itemdata.created_at ? itemdata.created_at : '';
                                        var data1 = new Date(created_at);
                                        let date12 = data1.getFullYear() + '-' + (data1.getMonth() + 1) + '-' + data1.getDate() + ' ' + data1.getHours() + ':' + data1.getMinutes() + ':' + data1.getSeconds();
                                        return (<tr>
                                          <td className="yellowText">{itemdata.pairname}</td>
                                          <td>{parseFloat(quantity).toFixed(8)}</td>
                                          <td>{parseFloat(itemdata.filledAmount).toFixed(8) + '/' + parseFloat(Remaining).toFixed(8)}</td>
                                          <td>{parseFloat(itemdata.Price).toFixed(this.state.floating)}</td>
                                          <td>{parseFloat(price).toFixed(this.state.floating)}</td>
                                          <td>{itemdata.Type}</td>
                                          <td>{_id}</td>
                                          <td>{date12}</td>
                                        </tr>
                                        );
                                      })
                                      }
                                    </>
                                    );
                                  })
                                }
                                </tbody>
                              </table>
                            {/* </Scrollbars> */}
                          </div>
                          {
                            Filleddetails && Filleddetails.length < filledDetailsCnt && 
                            <div className='load_more_sec_btn'>

                              <button className="btn btn-borderButton" onClick={this.filledDetailsLoadMore}> {t("LOADMORE")}  </button>

                            </div>
                          }
                          
                        </div>
                        <div className="tab-pane fade" id="nav-tradeHistory" role="tabpanel" aria-labelledby="nav-tradeHistory-tab">
                          <div className="table-responsive">
                            {/* <Scrollbars ref='scrollbar' style={{ width: '100%', height: 155 }} renderTrackVertical={props => <div className="track-vertical" />}> */}
                              <table id="tradeHistoryTable" className="table table-striped">
                                <thead>
                                  <tr>
                                  <th>{t("CONTRACTS")}</th>
                                    <th>{t("QUANTITY")}</th>
                                    <th>{t("FILLED_REMAINING")}</th>
                                    <th>{t("EXEC_PRICE")}</th>
                                    <th>{t("PRICE")}</th>
                                    <th>{t("TRIGGER_PRICE")}</th>
                                    <th>{t("ORDER_VALUE")}</th>
                                    <th>{t("TYPE")}</th>
                                    <th>{t("STATUS")}</th>
                                    <th>{t("ORDER")}</th>
                                    <th>{t("ORDER_TIME")}</th>
                                  </tr>
                                </thead>
                                <tbody>

                                  {
                                    Histroydetails.map((item, i) => {
                                      var pairName = item.pairName ? item.pairName : '';
                                      var quantity = item.quantity ? item.quantity : 0;
                                      var filledAmount = item.filledAmount ? item.filledAmount : 0;
                                      var e_price = item.filled.length > 0 ? item.filled[0].Price : 0;
                                      var price = item.quantity ? item.price : 0;
                                      var orderValue = item.orderValue ? item.orderValue : 0;
                                      var orderType = item.orderType ? item.orderType : 0;
                                      var orderDate = item.orderDate ? item.orderDate : 0;
                                      var classs = item.buyorsell == 'buy' ? 'greenText' : 'pinkText';
                                      var _id = item._id ? item._id : 0;
                                      var status1 = item.status;
                                      // var Remaining    = parseFloat(quantity) - parseFloat(filledAmount);
                                      var Remaining = parseFloat(Math.abs(quantity)) - parseFloat(Math.abs(filledAmount));

                                      var data = new Date(orderDate);
                                      let date1 = data.getFullYear() + '-' + (data.getMonth() + 1) + '-' + data.getDate() + ' ' + data.getHours() + ':' + data.getMinutes() + ':' + data.getSeconds();
                                      return <tr>
                                        <td>{pairName}</td>
                                        <td><span className={classs}>{parseFloat(quantity).toFixed(8)}</span></td>
                                        <td>{parseFloat(filledAmount).toFixed(8) + '/' + parseFloat(Remaining).toFixed(8)}</td>
                                        <td>{e_price}</td>
                                        <td>{price}</td>
                                        <td>{'-/-'}</td>
                                        <td>{orderValue}</td>
                                        <td>{orderType}</td>
                                        {(status1 == 0) ?
                                          <td >{t("NEW")}</td>
                                          : ''}
                                        {(status1 == 1) ?
                                          <td >{t("COMPLETED")}</td>
                                          : ''}
                                        {(status1 == 2) ?
                                          <td >{t("PARTIAL")}</td>
                                          : ''}
                                        {(status1 == 3) ?
                                          <td >{t("CANCEL")}</td>
                                          : ''}
                                        {(status1 == 4) ?
                                          <td >{t("CONDITIONAL")}l</td>
                                          : ''}
                                        <td>{_id}</td>
                                        <td>{date1}</td>
                                      </tr>
                                    })
                                  }
                                </tbody>

                              </table>
                            {/* </Scrollbars> */}
                          </div>
                          {
                            Histroydetails && Histroydetails.length < historyDetailsCnt && 
                            <div className='load_more_sec_btn'>
                            <button className="btn btn-borderButton" onClick={this.historyDetailsLoadMore}> {t("LOADMORE")} </button>
                          </div>
                          }
                          
                        </div>

                        <div className="tab-pane fade" id="nav-assetsOverview" role="tabpanel" aria-labelledby="nav-assetsOverview-tab">
                          <div className="table-responsive">
                            {/* <Scrollbars ref='scrollbar' style={{ width: '100%', height: 155 }} renderTrackVertical={props => <div className="track-vertical" />}> */}
                              {this.state.assetOverview ?
                                <table id="positionsTable" className="table table-striped">
                                  <thead>
                                    <tr>
                                      <th>{t("COIN")}</th>
                                      <th>{t("EQUITY")}</th>
                                      <th>{t("AVAILABLE_BALANCE")}</th>
                                      <th>{t("POSTION_MARGIN")}</th>
                                      <th>{t("ORDER_MARGIN")}</th>
                                      <th>{t("TOTAL_REALISED_P&L")}</th>
                                      <th>{t("UNREALIZED_P&L")}</th>
                                      <th>{t("RISK_LIMIT")}</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      this.state.assetOverview.map((item, i) => {
                                        var currencySymbol = item.currencySymbol ? item.currencySymbol : '';
                                        var assetbalance = item.balance ? item.balance : '0';
                                        var index = this.state.allposition_details.findIndex(x => (x.firstCurrency) === currencySymbol);

                                        var riskindex = this.state.records.findIndex(x => (x.first_currency) === currencySymbol);

                                        var parposquantity = (index != -1) ? this.state.allposition_details[index].quantity : 0;
                                        var parposprice = (index != -1) ? this.state.allposition_details[index].price : 0;
                                        var parposleverage = (index != -1) ? this.state.allposition_details[index].leverage : 0;

                                        var pos_initial_margin = (parseFloat(parposquantity) / parseFloat(parposprice)) * ((100 / parseFloat(parposleverage)) / 100);
                                        var profitnloss = [(1 / parseFloat(parposprice)) - (1 / parseFloat(this.state.markprice))] * parseFloat(parposquantity);
                                        var unprofitnloss = [(1 / parseFloat(parposprice)) - (1 / parseFloat(this.state.last_price))] * parseFloat(parposquantity);
                                        var profitnlossper = (parseFloat(profitnloss) / parseFloat(pos_initial_margin)) * 100;
                                        var profitnlossusd = (profitnloss * parseFloat(this.state.last_price));

                                        var equitybal = +assetbalance + (+pos_initial_margin);

                                        var risk_limit = (riskindex != -1) ? this.state.records[riskindex].risk_limit : 0;

                                        return <tr>
                                          <td>{currencySymbol}</td>
                                          <td>{parseFloat(isNaN(equitybal) ? 0 : equitybal).toFixed(8)}</td>
                                          <td>{parseFloat(assetbalance).toFixed(8)}</td>
                                          <td>{parseFloat(isNaN(pos_initial_margin) ? 0 : pos_initial_margin).toFixed(8)}</td>
                                          <td>{parseFloat(isNaN(pos_initial_margin) ? 0 : pos_initial_margin).toFixed(8)}</td>
                                          <td>{parseFloat(isNaN(unprofitnloss) ? 0 : profitnloss).toFixed(8)}</td>
                                          <td>{parseFloat(isNaN(profitnloss) ? 0 : profitnloss).toFixed(8)}</td>
                                          <td>{risk_limit}</td>


                                        </tr>
                                      })
                                    }

                                  </tbody>
                                </table> : ''}
                            {/* </Scrollbars> */}
                          </div>
                        </div>

                      </div>

                    </div>

                  </div>
                </div>
              </div>

              <div className="fixedWidth px-0">
              <div className="tradeTableLeftSide  mb-md-0 mb-3 darkBox orderBook tradeTabBoxStyle pb-0 border-bottom-one mb-new-trade-sm">
                  <div class="tableHead border-bottom-one pt-3">
                    <nav>
                      <div class="nav nav-tabs nav-fill flexoverflow" id="nav-tab-book-trades" role="tablist">
                        <a class="nav-item nav-link active" id="nav-orderBook-tab" data-toggle="tab" href="#nav-orderBook" role="tab" aria-controls="nav-orderBook" aria-selected="true">{t("ORDER_BOOK")}</a>
                      </div>
                    </nav>
                    <div class="inputGroup">
                      <button className="btn"><img src={GreenPinkDot} id="greenpink" onClick={this.orderbookshow.bind(this)} /></button>
                      <button className="btn"><img src={PinkDot} id="pink" onClick={this.orderbookshow.bind(this)} /></button>
                      <button className="btn"><img src={GreenDot} id="green" onClick={this.orderbookshow.bind(this)} /></button>
                    </div>
                  </div>
                  <div class="tab-content px-0" id="nav-tabContent">

                    <div class="tab-pane fade show active" id="nav-orderBook" role="tabpanel" aria-labelledby="nav-orderBook-tab">
                      <div className="tradeTableTitle row mx-auto">
                        <span className="col-4">{t("PRICE")}</span>
                        <span className="col-4">{t("QTY")}</span>
                        <span className="col-4">{t("TOTAL")}</span>
                      </div>

                      <Scrollbars ref='scrollbar' style={{ width: '100%', height: 306 }} renderTrackVertical={props => <div className="track-vertical" />}>
                        <div>
                          {
                            (this.state.orderbookshow == 'greenpink' || this.state.orderbookshow == 'pink') ?
                              this.state.sellOrder.map((item, i) => {
                                //console.log("quantityquantity", item.quantity);
                                var toam = this.state.sellOrder[0].total;
                                var precentage = item.total / toam * 100;
                                var classs = (i % 2) ? 'tradeTableBodyRow odd row mx-auto' : 'tradeTableBodyRow even row mx-auto';
                                var ordvalue = parseFloat(item.quantity) / parseFloat(item._id);
                                var index = (this.state.prevsellOrder).findIndex(x => (x._id) === item._id);
                                index = (this.state.prevsellOrder.length > 0) ? index : -2;
                                var prevquantity = (this.state.prevsellOrder.length > 0 && index != -1) ? this.state.prevsellOrder[index].quantity : 0;
                                var highLightcls = (index == -1) ? "highlight" : (prevquantity != 0 && prevquantity != item.quantity) ? "highlight" : '';
                                return <div className={classs + ' ' + highLightcls} >
                                  <span className="col-4 pinkText" onClick={this.fillprice.bind("test", Math.abs(item.quantity), item._id)}>{parseFloat(item._id).toFixed(this.state.floating)}</span>
                                  <span className="col-4" onClick={this.fillprice.bind("test", Math.abs(item.quantity), item._id)}>{parseFloat(Math.abs(item.quantity)).toFixed(8)}</span>
                                  <span className="col-4"> <span className="bgProgress1" style={{ width: precentage + '%' }}></span> <span className="bgProgressText1">{parseFloat(Math.abs(item.total)).toFixed(8)}</span></span>
                                </div>;
                              })
                              : ''
                          }

                          <div className="tradeTableBodyRow even highLight row mx-auto" >
                            <span className={'col-6 pl-5 yellowText'}><i className="fas fa-caret-down "></i>{parseFloat(last).toFixed(this.state.floating)}</span>
                            <span className={'col-6 pl-5 text-center'} data-toggle="tooltip" data-placement="right" data-html="true" title="Spot price">{parseFloat(this.state.markprice).toFixed(this.state.floating)}</span>
                          </div>

                          {
                            (this.state.orderbookshow == 'greenpink' || this.state.orderbookshow == 'green') ?
                              this.state.buyOrder.map((item, i) => {
                                var lastindex = this.state.buyOrder.length;
                                var toam = this.state.buyOrder[lastindex - 1].total;
                                var precentage = item.total / toam * 100;
                                var classs = (i % 2) ? 'tradeTableBodyRow even row mx-auto' : 'tradeTableBodyRow odd row mx-auto';
                                var ordvalue = parseFloat(item.quantity) / parseFloat(item._id);
                                var index = (this.state.prevbuyOrder).findIndex(x => (x._id) === item._id);
                                index = (this.state.prevbuyOrder.length > 0) ? index : -2;
                                var prevquantity = (this.state.prevbuyOrder.length > 0 && index != -1) ? this.state.prevbuyOrder[index].quantity : 0;
                                var highLightcls = (index == -1) ? "highlight" : (prevquantity != 0 && prevquantity != item.quantity) ? "highlight" : '';

                                return <div className={classs + ' ' + highLightcls}>
                                  <span className=" col-4 greenText" onClick={this.fillprice.bind("test", item.quantity, item._id)}>{parseFloat(item._id).toFixed(this.state.floating)}</span>
                                  <span className="col-4" onClick={this.fillprice.bind("test", item.quantity, item._id)}>{parseFloat(item.quantity).toFixed(8)}</span>
                                  <span className="col-4"> <span className="bgProgress" style={{ width: precentage + '%' }}></span> <span className="bgProgressText">{parseFloat(item.total).toFixed(8)}</span></span>
                                </div>;
                              })
                              : ''
                          }
                        </div>
                      </Scrollbars>
                    </div>


                  </div>
                </div>
                <div className="tradeTableLeftSide  mb-md-0 mb-3 darkBox orderBook tradeTabBoxStyle pb-0 border-bottom-one">
                  <div class="tableHead border-bottom-one pt-3">
                    <nav>
                      <div class="nav nav-tabs nav-fill flexoverflow" id="nav-tab-book-trades" role="tablist">
                        <a class="nav-item nav-link active" id="nav-recentTrade-tab" data-toggle="tab" href="#nav-recentTrade" role="tab" aria-controls="nav-recentTrade" aria-selected="true">{t("RECENT_TRADE")}</a>
                      </div>
                    </nav>
                    {/* <div class="inputGroup">
            <button className="btn"><img src={GreenPinkDot} id="greenpink" onClick={this.orderbookshow.bind(this)}/></button>
            <button className="btn"><img src={PinkDot} id="pink" onClick={this.orderbookshow.bind(this)}/></button>
            <button className="btn"><img src={GreenDot} id="green" onClick={this.orderbookshow.bind(this)}/></button>
          </div> */}
                  </div>
                  <div class="tab-content px-0" id="nav-tabContent">


                    <div class="tab-pane fade show active" id="nav-recentTrade" role="tabpanel" aria-labelledby="nav-recentTrade-tab">
                    <div className="tradeTableTitle row mx-auto">
                         <span className="col-4">{t("PRICE")}</span>
                        <span className="col-4">{t("QTY")}</span>
                        <span className="col-4">{t("TOTAL")}</span>
                      </div>
                      <Scrollbars ref='scrollbar' style={{ width: '100%', height: 306 }} renderTrackVertical={props => <div className="track-vertical" />}>
                        <div className="tradeTableBody customScroll">

                          {
                            this.state.Rescentorder.map((item, i) => {
                              var data = new Date(item.created_at);
                              let date1 = data.getHours() + ':' + data.getMinutes() + ':' + data.getSeconds();
                              var classs = (i % 2) ? 'tradeTableBodyRow even row mx-auto' : 'tradeTableBodyRow odd row mx-auto';
                              return <div className={classs} data-toggle="tooltip">
                                <span className="col-4">{parseFloat(item.Price).toFixed(this.state.floating)}</span>
                                <span className="col-4">{parseFloat(Math.abs(item.filledAmount)).toFixed(8)}</span>
                                <span className="col-4">{date1}</span>

                              </div>;

                            })
                          }
                          {(this.state.Rescentorder.length > 20) ?
                            <div className="tradeTableBodyRow odd row mx-auto ">
                              <a onClick={this.loadmoreRescentorder} className="d-block text-center mx-auto"><i className="fas fa-sort-amount-down-alt"></i> {t("LOADMORE")}</a></div> : ''}

                        </div>

                      </Scrollbars>
                    </div>

                  </div>
                </div>
              </div>


            </div>

          </section>


          <Modal show={this.state.show} onHide={this.handleClose} aria-labelledby="contained-modal-title-vcenter" centered className="primary-modal" backdrop="static">
            <Modal.Header closeButton>
              <Modal.Title>{t("ORDER_DETAILS")} <small>{t("LIMIT")} {this.state.buyorsell}</small></Modal.Title>
            </Modal.Header>
            <Modal.Body className=' orderlimit'>
              <div className="form-group">
                <div className="row">
                  <div className="col-md-4">
                    <div className="checkbox pt-2"><label><input name="takeprofitcheck" id="takeprofitcheck" onChange={this.onChange}  type="checkbox" value="1" /><span className="cr"><i className="cr-icon fa fa-check"></i></span><span className="listText checkConfirmationText">{t("TAKE_PROFIT_USD")}</span></label></div>
                  </div>

                  <div className="col-md-8">
                    <div className="def-number-input number-input safari_only input_kax_al">
                      <input className="quantity form-control form-control-qty" min="0" readOnly={!this.state.tpreadOnly} name="takeprofit" id="takeprofit" onChange={this.onChange} value={this.state.takeprofit} type="number" />
                      {/* <button onClick={this.updownvalue.bind('tak', 'takeprofit', 'plus')} className="plus" disabled={this.state.tpreadOnly == false}></button>
                      <button onClick={this.updownvalue.bind('tak', 'takeprofit', 'minus')} className="minus"></button> */}
                    </div>

                  </div>
                </div>
                {this.state.tpreadOnly ? (
                  <div className='btn_div'>
                  <div className="btn-group special mt-2">
                    <button className={this.state.profitpercentage == "25" ? "btn btn-borderButton mr-1 active" : "btn btn-borderButton mr-1"} id={"takeprofit"} value="25" onClick={this.percentagePnL.bind(this)}>25%</button>
                    <button className={this.state.profitpercentage == "50" ? "btn btn-borderButton mr-1 active" : "btn btn-borderButton mr-1"} id={"takeprofit"}  value="50"onClick={this.percentagePnL.bind(this)}>50%</button>
                    <button className={this.state.profitpercentage == "75" ? "btn btn-borderButton mr-1 active" : "btn btn-borderButton mr-1"} id={"takeprofit"}  value="75"onClick={this.percentagePnL.bind(this)}>75%</button>
                    <button className={this.state.profitpercentage == "100" ? "btn btn-borderButton mr-1 active" : "btn btn-borderButton mr-1"} id={"takeprofit"}  value="100" onClick={this.percentagePnL.bind(this)}>100%</button>
                  </div>
                  </div>
                ):(<div></div>)

                }
                {/* <div className='btn_div'>
                <div className="btn-group special mt-2">
                  <button className={this.state.profitpercentage == "25" ? "btn btn-borderButton mr-1 active" : "btn btn-borderButton mr-1"} id={"takeprofit"} value="25" onClick={this.percentagePnL.bind(this)}>25%</button>
                  <button className={this.state.profitpercentage == "50" ? "btn btn-borderButton mr-1 active" : "btn btn-borderButton mr-1"} id={"takeprofit"}  value="50"onClick={this.percentagePnL.bind(this)}>50%</button>
                  <button className={this.state.profitpercentage == "75" ? "btn btn-borderButton mr-1 active" : "btn btn-borderButton mr-1"} id={"takeprofit"}  value="75"onClick={this.percentagePnL.bind(this)}>75%</button>
                  <button className={this.state.profitpercentage == "100" ? "btn btn-borderButton mr-1 active" : "btn btn-borderButton mr-1"} id={"takeprofit"}  value="100" onClick={this.percentagePnL.bind(this)}>100%</button>
                </div>
                </div> */}

              </div>
              <div className="form-group">
                <div className="row">
                  <div className="col-md-4">
                    <div className="checkbox pt-2"><label><input name="stopcheck" id="stopcheck" onChange={this.onChange}  type="checkbox" value="1" /><span className="cr"><i className="cr-icon fa fa-check"></i></span><span className="listText checkConfirmationText">{t("STOP_LOSS_USD")}</span></label></div>
                  </div>
                  <div className="col-md-8">
                    <div className="def-number-input number-input safari_only input_kax_al" >
                      <input className="quantity form-control form-control-qty" min="0" readOnly={!this.state.stopreadOnly} name="stopprice" id="stopprice" onChange={this.onChange} value={this.state.stopprice} type="number" />
                      {/* <button onClick={this.updownvalue.bind('sto', 'stopprice', 'plus')} className="plus" disabled={this.state.stopreadOnly == false}></button>
                      <button onClick={this.updownvalue.bind('sto', 'stopprice', 'minus')} className="minus"></button> */}
                    </div>

                  </div>
                </div>
                {this.state.stopreadOnly ? (
                  <div className='btn_div'>
                  <div className="btn-group special mt-2">
                    <button className={this.state.stoplosspercentage == "25" ? "btn btn-borderButton mr-1 active" : "btn btn-borderButton mr-1"} id={"stopprice"} value={'25'} onClick={this.percentagePnL.bind(this)}>25%</button>
                    <button className={this.state.stoplosspercentage == "50" ? "btn btn-borderButton mr-1 active" : "btn btn-borderButton mr-1"} id={"stopprice"} value={'50'}onClick={this.percentagePnL.bind(this)}>50%</button>
                    <button className={this.state.stoplosspercentage == "75" ? "btn btn-borderButton mr-1 active" : "btn btn-borderButton mr-1"} id={"stopprice"} value={'75'} onClick={this.percentagePnL.bind(this)}>75%</button>
                    <button className={this.state.stoplosspercentage == "100" ? "btn btn-borderButton mr-1 active" : "btn btn-borderButton mr-1"} id={"stopprice"} value={'100'}onClick={this.percentagePnL.bind(this)}>100%</button>
                  </div>
                  </div>
                ):(
                  <div></div>
                )}
                {/* <div className='btn_div'>
                <div className="btn-group special mt-2">
                  <button className={this.state.stoplosspercentage == "25" ? "btn btn-borderButton mr-1 active" : "btn btn-borderButton mr-1"} id={"stopprice"} value={'25'} onClick={this.percentagePnL.bind(this)}>25%</button>
                  <button className={this.state.stoplosspercentage == "50" ? "btn btn-borderButton mr-1 active" : "btn btn-borderButton mr-1"} id={"stopprice"} value={'50'}onClick={this.percentagePnL.bind(this)}>50%</button>
                  <button className={this.state.stoplosspercentage == "75" ? "btn btn-borderButton mr-1 active" : "btn btn-borderButton mr-1"} id={"stopprice"} value={'75'} onClick={this.percentagePnL.bind(this)}>75%</button>
                  <button className={this.state.stoplosspercentage == "100" ? "btn btn-borderButton mr-1 active" : "btn btn-borderButton mr-1"} id={"stopprice"} value={'100'}onClick={this.percentagePnL.bind(this)}>100%</button>
                </div>
                </div> */}

              </div>
              <div className="table-responsive">
                <table className="table table-bordered tradeBuy">
                  <tr>
                    <td align="right" width="50%">{t("ORDER_PRICE")}</td>
                    <td>{(this.state.ordertype == 'Market') ? this.state.markprice + ' ' + this.state.secondcurrency : this.state.price + ' ' + this.state.secondcurrency}</td>
                  </tr>
                  <tr>
                    <td align="right" width="50%">{t("ORDER_VALUE")}</td>
                    <td>{this.state.order_value + ' ' + this.state.firstcurrency}</td>
                  </tr>
                  <tr>
                    <td align="right" width="50%">{t("ORDER_COST")}</td>
                    <td>{this.state.order_cost + ' ' + this.state.firstcurrency}</td>
                  </tr>
                  <tr>
                    <td align="right" width="50%">{t("AVAILABLE_BALANCE")}</td>
                    <td>{parseFloat(this.state.balance).toFixed(8) + ' ' + this.state.firstcurrency}</td>
                  </tr>
                  <tr>
                    <td align="right" width="50%">{t("POSITION_LEVERAGE")}</td>
                    <td>{this.state.leverage + 'x'}</td>
                  </tr>
                  <tr>
                    <td align="right" width="50%">{t("TOTAL_POSITION_QTY_AFTER_EXECUTION")}</td>
                    <td>{this.state.quantity}</td>
                  </tr>
                  <tr>
                    <td align="right" width="50%">{t("TAKE_PROFIT")}</td>
                    <td>{this.state.takeprofit + ' ' + this.state.secondcurrency}</td>
                  </tr>
                  <tr>
                    <td align="right" width="50%">{t("STOP_LOSS")}</td>
                    <td>{this.state.stopprice + ' ' + this.state.secondcurrency}</td>
                  </tr>
                  <tr>
                    <td align="right" width="50%">{t("MARK_PRICE")}</td>
                    <td>{this.state.markprice + ' ' + this.state.secondcurrency}</td>
                  </tr>
                  <tr>
                    <td align="right" width="50%">{t("ESTIMATED_LIQ_PRICE")}</td>
                    <td>{parseFloat(this.state.Liqprice).toFixed(4) + ' ' + this.state.secondcurrency} </td>
                  </tr>
                  <tr>
                    <td align="right" width="50%">{t("MARKPRICE_TO_LIQPRICE_DISTANCE")}</td>
                    <td> {Math.abs((parseFloat(this.state.markprice) - parseFloat(this.state.Liqprice)).toFixed(2)) + ' ' + this.state.secondcurrency}</td>
                  </tr>
                  <tr>
                    <td align="right" width="50%">{t("TIME_IN_FORCE")}</td>
                    <td>{this.state.timeinforcetype.value}</td>
                  </tr>
                </table>
              </div>

              {(this.state.alertmessage1 != '' && this.state.stopreadOnly == false) ?
                <div className="quadrat">{this.state.alertmessage1}</div> : ''
              }
              {(this.state.alertmessage2 != '' && this.state.tpreadOnly == false) ?
                <div className="quadrat">{this.state.alertmessage2}</div> : ''
              }

            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary btnDefaultNewBlue" onClick={this.handleClose}>
              {t("CANCEL")}
              </Button>
              <Button variant="primary btnDefaultNew" onClick={this.orderPlacing}>
              {t("CONFIRM")}
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={this.state.show1} onHide={this.handleClose1} aria-labelledby="contained-modal-title-vcenter" centered className="primary-modal" backdrop="static">
            <Modal.Header closeButton>
              <Modal.Title>{t("CLOSE_BY")} <small>{this.state.popuptype} {(position_details.length > 0 && position_details[0].quantity > 0) ? 'Sell' : 'Buy'}</small></Modal.Title>
            </Modal.Header>
            <Modal.Body className='closeby'>

              {(this.state.popuptype == 'Limit') ?
                <div className="form-group">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="checkbox pt-2"><label>{t("CLOSE_PRICE")} {this.state.firstcurrency}</label></div>
                    </div>
                    <div className="col-md-8">
                      <div className="def-number-input number-input safari_only">
                        <input
                          className="quantity"
                          min="0"
                          name="close_price"
                          id="close_price"
                          value={this.state.close_price}
                          type="number"
                          onChange={this.onChange}
                        />
                        {/* {console.log("this.state.quantity ----------> check : "this.state.quantity)} */}
                        <button onclick="this.parentNode.querySelector('input[type=number]').stepUp()" className="plus"></button>
                        <button onclick="this.parentNode.querySelector('input[type=number]').stepDown()" className="minus"></button>
                      </div>
                    </div>
                  </div>
                </div>
                : ''}

              <div className="form-group">
                <div className="row">
                  <div className="col-md-4">
                    <div className="checkbox pt-2"><label>{t("NUMBER_OF_POSITION_CLOSED")} {this.state.secondßcurrency}</label></div>
                  </div>
                  <div className="col-md-8">
                    <div className="def-number-input number-input safari_only">
                      <input
                        className="quantity"
                        min="0"
                        name="close_quantity"
                        id="close_quantity"
                        value={this.state.close_quantity}
                        type="number"
                        onChange={this.onChange}
                      />
                      <button onClick={this.updownvalue.bind('clo', 'close_quantity', 'plus')} className="plus" disabled={this.state.close_quantity == positionDetails.positionFilled }></button>
                      <button onClick={this.updownvalue.bind('clo', 'close_quantity', 'minus')} className="minus"></button>
                    </div>
                    <div className="input-group mt-2">
                      <button className="btn btn-borderButton mr-1" onClick={this.quantitydivide}>100%</button>
                      <button className="btn btn-borderButton mr-1" onClick={this.quantitydivide}>75%</button>
                      <button className="btn btn-borderButton mr-1" onClick={this.quantitydivide}>50%</button>
                      <button className="btn btn-borderButton" onClick={this.quantitydivide}>25%</button>
                    </div>
                  </div>
                </div>
              </div>

            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary btnDefaultNewBlue" onClick={this.handleClose1}>
              {t("CANCEL")}
              </Button>
              <Button variant="primary btnDefaultNew" onClick={this.orderPlacing1}>
              {t("CONFIRM")}
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={this.state.tpshow} onHide={this.tpClose} aria-labelledby="contained-modal-title-vcenter" centered className="primary-modal" backdrop="static">
            <Modal.Header closeButton>
              <Modal.Title>{t("CLOSE_BY")}<small>{this.state.popuptype} {(position_details.length > 0 && position_details[0].quantity > 0) ? 'Sell' : 'Buy'}</small></Modal.Title>
            </Modal.Header>
            <Modal.Body className='closeby'>


              <div className="form-group ">
                <div className="row">
                  <div className="col-md-4">
                    <div className="checkbox pt-2"><label><input name="takeprofitcheck" id="takeprofitcheck" onChange={this.onChange} type="checkbox" value="1" /><span className="cr"><i className="cr-icon fa fa-check"></i></span><span className="listText checkConfirmationText">{t("TAKE_PROFIT_USD")}</span></label></div>
                  </div>
                  <div className="col-md-8">
                    <div className="def-number-input number-input safari_only">
                      <input className="quantity" min="0" readOnly={!this.state.tpreadOnly} name="takeprofit" id="takeprofit" onChange={this.onChange} value={this.state.takeprofit} type="number" />
                      <button onClick={this.updownvalue.bind('tak', 'takeprofit', 'plus')} className="plus"></button>
                      <button onClick={this.updownvalue.bind('tak', 'takeprofit', 'minus')} className="minus"></button>
                    </div>
                  </div>
                </div>
                {/*<div className="row">
                  <div className="col-md-12 col-offset-2">
                      <div className="input-group mt-2">
                          <button className="btn btn-borderButton mr-1">25%</button>
                          <button className="btn btn-borderButton mr-1">50%</button>
                          <button className="btn btn-borderButton mr-1">75%</button>
                          <button className="btn btn-borderButton">100%</button>
                          <button className="btn btn-borderButton">150%</button>
                          <button className="btn btn-borderButton">200%</button>
                      </div>
                  </div>
              </div>
              <div className="row">
                  <div className="col-md-12">
                      <div className="btn-group btn-block mt-3">
                          <button className="btn btn-borderButton mr-1" onClick={this.triggertp.bind(this)}>Last</button>
                          <button className="btn btn-borderButton mr-1" onClick={this.triggertp.bind(this)}>Index</button>
                          <button className="btn btn-borderButton mr-1" onClick={this.triggertp.bind(this)}>Mark</button>
                      </div>
                  </div>
              </div>*/}
              </div>

              <div className="form-group">
                <div className="row">
                  <div className="col-md-4">
                    <div className="checkbox pt-2"><label><input name="stopcheck" id="stopcheck" onChange={this.onChange}  type="checkbox" value="1" /><span className="cr"><i className="cr-icon fa fa-check"></i></span><span className="listText checkConfirmationText">{t("STOP_LOSS_USD")}</span></label></div>
                  </div>
                  <div className="col-md-8">
                    <div className="def-number-input number-input safari_only">
                      <input className="quantity" min="0" readOnly={!this.state.stopreadOnly} name="stopprice" id="stopprice" onChange={this.onChange} value={this.state.stopprice} type="number" />
                      <button onClick={this.updownvalue.bind('sto', 'stopprice', 'plus')} className="plus"></button>
                      <button onClick={this.updownvalue.bind('sto', 'stopprice', 'plus')} className="minus"></button>
                    </div>
                  </div>
                </div>
                {/*<div className="row">
                  <div className="col-md-12 col-offset-2">
                      <div className="input-group mt-2">
                          <button className="btn btn-borderButton mr-1">25%</button>
                          <button className="btn btn-borderButton mr-1">50%</button>
                          <button className="btn btn-borderButton mr-1">75%</button>
                          <button className="btn btn-borderButton">100%</button>
                          <button className="btn btn-borderButton">150%</button>
                          <button className="btn btn-borderButton">200%</button>
                      </div>
                  </div>
              </div>
               <div className="row">
                  <div className="col-md-12">
                      <div className="btn-group btn-block mt-3">
                          <button className="btn btn-borderButton mr-1" onClick={this.triggerstop.bind(this)}>Last</button>
                          <button className="btn btn-borderButton mr-1" onClick={this.triggerstop.bind(this)}>Index</button>
                          <button className="btn btn-borderButton mr-1" onClick={this.triggerstop.bind(this)}>Mark</button>
                      </div>
                  </div>
              </div>*/}
              </div>


              <div className="form-group">
                <div className="row">
                  <div className="col-md-4">
                    <div className="checkbox pt-2"><label><input name="trailcheck" id="trailcheck" onChange={this.onChange}  type="checkbox" value="1" /><span className="cr"><i className="cr-icon fa fa-check"></i></span><span className="listText checkConfirmationText">{t("TRAILING_STOP_USD")}</span></label></div>
                  </div>
                  <div className="col-md-8">
                    <div className="def-number-input number-input safari_only">
                      <input className="quantity" min="0" readOnly={this.state.trailreadOnly} name="trailingstopdistance" id="trailingstopdistance" onChange={this.onChange} value={this.state.trailingstopdistance} type="number" />
                      <button onClick={this.updownvalue.bind('tri', 'trailingstopdistance', 'plus')} className="plus"></button>
                      <button onClick={this.updownvalue.bind('tri', 'trailingstopdistance', 'minus')} className="minus"></button>
                    </div>
                    {
                      (this.state.trailingstopdistance != '' && this.state.trailingstopdistance != 0) ?
                        <div>{t("IF_OPTIMAL_LAST_TRADED_PRICE_RETRACES_BY")} {this.state.trailingstopdistance} {t("USD_IT_WILL_TRIGGER_STOP_LOSS_WITH_MARKET_ORDER")}</div>
                        : ''
                    }
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary btnDefaultNewBlue" onClick={this.tpClose}>
              {t("CANCEL")}
              </Button>
              <Button variant="primary btnDefaultNew" onClick={this.triggerstoporder}>
              {t("CONFIRM")}
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal show={this.state.confirmcancel} onHide={this.confirmcancelClose} aria-labelledby="contained-modal-title-vcenter" centered className="primary-modal" backdrop="static">
            <Modal.Header closeButton>
              <Modal.Title>{t("CANCEL_ORDER")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>


                    <p className='text-white'>{t("ARE_YOU_CONFIRM_TO_CANCEL_THIS_ORDER")}</p>

          

             
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary btnDefaultNewBlue" onClick={this.confirmcancelClose}>
                {t("CANCEL")}
              </Button>
              <Button variant="primary btnDefaultNew" onClick={
                () => {
                  let userid = this.props.auth.user.id;
                  var iddetails = { id: this.state.id, userid: userid };
                  this.props.cancelTrade(iddetails);
                  this.setState({ confirmcancel: false });
                }
              }>
                {t("CONFIRM")}
              </Button>
            </Modal.Footer>
          </Modal>
            


          <div className="tradeFooterBox">
            <Footer />
          </div>
        </div>
      </div>
    );
  }
}

Trade.propTypes = {
  changeopenpositions: PropTypes.func.isRequired,
  triggerstop: PropTypes.func.isRequired,
  getPricevalue: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  cancelTrade: PropTypes.func.isRequired,
  getPertual: PropTypes.func.isRequired,
  getTradeData: PropTypes.func.isRequired,
  getuserTradeData: PropTypes.func.isRequired,
  orderPlacing: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});
export default connect(
  mapStateToProps,
  {
    changeopenpositions,
    getPertual,
    cancelTrade,
    orderPlacing,
    getTradeData,
    getuserTradeData,
    logoutUser,
    triggerstop,
    getPricevalue
  }
)
(withTranslation()(Trade),withRouter(Trade));