import React, { Component } from 'react'
import BannerX from "../images/xicon.png"
import CaptchaImg from "../images/captchaImg.jpg"
import Navbar from './Navbar'
import Footer from './Footer'
import {Link,withRouter} from 'react-router-dom';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { store } from 'react-notifications-component';
import { resetUser } from "../actions/authActions";
import classnames from "classnames";


class ResetPassword extends Component {

	constructor() {
			super();
			this.state = {
              _id : "",
					password: "",
					password2: "",
					errors: {},
          viewpassword:false,
          viewpasswordconfirm:false

			};
	}

	componentDidMount() {
		window.scrollTo(0, 0);

			if (this.props.auth.isAuthenticated) {
					this.props.history.push("/settings");
			}
	}

	componentWillReceiveProps(nextProps) {
    const {t} = this.props;
			if (nextProps.errors) {
					this.setState({
							errors: nextProps.errors
					});
			}
			if (nextProps.auth.forgotuser !== undefined
					&& nextProps.auth.forgotuser.data !== undefined
					&& nextProps.auth.forgotuser.data.message !== undefined ) {
					store.addNotification({
					  title: t("SUCCESS"),
					  message: nextProps.auth.forgotuser.data.message,
					  type: "success",
					  insert: "top",
					  container: "top-right",
					  animationIn: ["animated", "fadeIn"],
					  animationOut: ["animated", "fadeOut"],
					  dismiss: {
					    duration: 5000,
					    onScreen: true
					  }
					});
					nextProps.auth.forgotuser = "";
					this.props.history.push("/login");
			}
	}

	onChange = e => {
			this.setState({ [e.target.id]: e.target.value });
	};

	onSubmit = e => {
			e.preventDefault();
    	const resetuser = {
              _id : this.props.match.params.id,
					password: this.state.password,
					password2: this.state.password2,
			};
			this.props.resetUser(resetuser);
	};




	render() {
		const { errors } = this.state;
		return <div className='bg_strip'>
      <section class="headerCrediantials  login_banner">
        <Navbar />
        <div className="container">
          <div className="row">
            <div className="col-md-6 mx-auto">
              <div className="formBox mt-5 mb-5" data-aos="fade-up" data-aos-duration="2000" >
              <h2>Reset Password</h2>
              <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
                <div className="tab-pane fade show active" id="nav-emailLogin" role="tabpanel" aria-labelledby="nav-emailLogin-tab">
                  <form id="" name="" className="stoForm landingForm" noValidate onSubmit={this.onSubmit} >
                  <div className="form-group fadeIn second mb-5 mt-3">
                  <label>New Password</label>
                  <div class="input-group input_grp_pwd">
                  <input
                      onChange={this.onChange}
                      value={this.state.password}
                      error={errors.password}
                      id="password"
                      type={this.state.viewpassword? 'text':"password"}
                      className={classnames("form-control", {
                          invalid: errors.password
                      })}
                  />
                   <div class="input-group-append input_grp_pwd_append" onClick={()=>{this.setState({viewpassword:!this.state.viewpassword})}}>
											<span class="input-group-text">
												{this.state.viewpassword?
												<i class="fa fa-eye"></i>
												:
												<i class="fa fa-eye-slash"></i>
												}
												</span>
											</div>
                  </div>
                  <span className="text-danger">{errors.password}</span>
                </div>
                <div className="form-group fadeIn third">
                  <label>Confirm Password</label>
                  <div class="input-group input_grp_pwd">
                  <input
                      onChange={this.onChange}
                      value={this.state.password2}
                      error={errors.password2}
                      id="password2"
                      type={this.state.viewpasswordconfirm ? 'text':"password"}
                      className={classnames("form-control", {
                          invalid: errors.password2
                      })}
                  />
                   <div class="input-group-append input_grp_pwd_append" onClick={()=>{this.setState({viewpasswordconfirm:!this.state.viewpasswordconfirm})}}>
											<span class="input-group-text">
												{this.state.viewpasswordconfirm?
												<i class="fa fa-eye"></i>
												:
												<i class="fa fa-eye-slash"></i>
												}
												</span>
											</div>
                  </div>
                  <span className="text-danger">{errors.password2}</span>
                </div>

                  <div className="form-group mb-4">
                  <input type="submit" className="btn-block fadeIn fourth" value="Submit" />
                </div>
                </form>
                <div id="formFooter" className="fadeIn fourth">
                  <h6 className="text-center mb-4">Not a registered user? <Link to="/register">Register Now!</Link></h6>
                  <h6 className="text-center">If you have lost your 2FA token, please  <Link to="/Contact_us">Contact Us</Link>  with the subject "Lost 2FA Device". Trading support will help you recover your account.</h6>
                </div>
                </div>
                <div className="tab-pane fade" id="nav-mobileLogin" role="tabpanel" aria-labelledby="nav-mobileLogin-tab">
                  <form id="" name="" className="stoForm landingForm" method="">
                  <div className="form-group input-group mobNumber mb-5 mt-3">
                                              <label>Mobile Number</label>
                                              <select className="niceStyleSelect">
                                                  <option selected="">+91</option>
                                                  <option value="1">+92</option>
                                                  <option value="2">+18</option>
                                                  <option value="3">+71</option>
                                              </select>
                                              <input name="" className="form-control" placeholder="" value="" type="text" />
                                          </div>

                  <div className="form-group mb-4">
                  <input type="submit" className="btn-block fadeIn fourth" value="Submit" />
                </div>
                </form>
                <div id="formFooter" className="fadeIn fourth">
                  <h6 className="text-center mb-4">Not a registered user? <Link to="/register">Register Now!</Link></h6>
                  <h6 className="text-center">If you have lost your 2FA token, please  <Link to="/Contact_us">Contact Us</Link> with the subject "Lost 2FA Device". Trading support will help you recover your account.</h6>
                </div>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
		</div>
	}
}





ResetPassword.propTypes = {
    resetUser: PropTypes.func.isRequired,
    auth: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
    errors: state.errors
});

export default connect(
    mapStateToProps,
    { resetUser }
)(withRouter(ResetPassword));
