import React, { Component } from 'react'
import { useHistory } from "react-router-dom";

import Logo from "../images/logo.png"
import LogoNewFixHead from "../images/logo.png"
import LogoLightTheme from "../images/logodark.png";
import { Link, withRouter, NavLink } from 'react-router-dom';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutUser,languageChanger } from "../actions/authActions";
import { store } from 'react-notifications-component';
import { withTranslation } from 'react-i18next';
import { Languagechange } from './i18next/i18n';
import { LANGUAGE_CHANGER } from '../actions/types';
class Navtradebar extends Component {
  
  constructor(props){
    super(props);
    this.state = {
      show:false,
    };
    this.showNavbar = this.showNavbar.bind(this);
  }
  
  
 showNavbar()
 {
   this.setState({show:!this.state.show});
 }

  componentDidMount() {
    let themeData = localStorage.getItem('theme')
    if (themeData) {
      this.themechange(themeData)
    } else {
      this.themechange('darktheme')
    }
  };

 onLogoutClick = e => {
  const {t} = this.props;
    e.preventDefault();
    store.addNotification({
      title: t("LOGOUT_SUCCESS"),
      message: t("COME_JOIN"),
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 5000,
        onScreen: true
      }
    });
    let  data ={
      userId:this.props.auth.user.id
    }
    this.props.logoutUser(data);
  };

  themechange(data) {
    if (data == 'darktheme') {
      document.getElementById("root").classList.add("darktheme")
      document.getElementById("root").classList.remove("lighttheme");
      document.getElementById("modaltheme").classList.add("darkmodal")
      document.getElementById("modaltheme").classList.remove("lightmodal");
      localStorage.setItem('theme', 'darktheme')
      this.setState({ theme: false });
    } else if (data == 'lighttheme') {
      document.getElementById("root").classList.remove("darktheme")
      document.getElementById("root").classList.add("lighttheme")
      document.getElementById("modaltheme").classList.add("lightmodal")
      document.getElementById("modaltheme").classList.remove("darkmodal");
      localStorage.setItem('theme', 'lighttheme')
      console.log('true enter')
      this.setState({ theme: true })
    }
  }
 
  swiftlanguage(data){
  console.log("swiftlanguage_swiftlanguage",data);
  const local = localStorage.getItem('lang');
  this.props.languageChanger(data == ""? local : data)
  };

  render() {

    const themechange = () =>{
      console.log("---------------------------","themeChanges",localStorage.getItem('theme'))
      if(document.getElementById("root").classList.contains("lighttheme")){
        document.getElementById("root").classList.add("darktheme")
        document.getElementById("root").classList.remove("lighttheme");
        document.getElementById("modaltheme").classList.add("darkmodal")
        document.getElementById("modaltheme").classList.remove("lightmodal");
        this.setState({theme:false})
        localStorage.setItem('theme', 'darktheme')
        // this.state.theme === false
      }else{
        document.getElementById("root").classList.remove("darktheme")
        document.getElementById("root").classList.add("lighttheme")
        document.getElementById("modaltheme").classList.add("lightmodal")
        document.getElementById("modaltheme").classList.remove("darkmodal");
        // this.state.theme === true
        localStorage.setItem('theme', 'lighttheme')
        this.setState({theme:true})

      }
     
    }

    const { user,getUser } = this.props.auth;

    let spotUrl = "/spot/BTC-USDT"
    if (localStorage.getItem('curpair1')) {
      spotUrl = "/spot/" + localStorage.getItem('curpair1')
    }
    const { t } = this.props;
    return (
      <div>
     <nav className="navbar navbar-expand-lg fixed-top customNavTrade px-2 px-lg-3">
      <span className="tradeMobile tradeMobile_frlx">
      <Link to="/" className="navbar-brand innerLogo">
        <img className="top_head img-fluid logoDark" src={LogoNewFixHead} />
        <img className="top_head img-fluid logoLight" src={LogoLightTheme} />
        </Link>
      
      <button className="navbar-toggler customNavbarToggler" type="button" onClick={this.showNavbar}>
      <i className="fas fa-bars"></i>
      </button>
      </span>
          <div className={this.state.show?"collapse navbar-collapse show collapse_menu":"collapse navbar-collapse collapse_menu"} id="navbarResponsive">
            {(typeof user.email != 'undefined') ?
              <ul className="navbar-nav ml-auto">
                
                <li className="nav-item dropdown dmenu mr-2">
                  <a className="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">
                  {t("TRADE")}
                  </a>
                  <div className="dropdown-menu sm-menu menu_default_show">
                   <NavLink to={ "/spot/"+localStorage.getItem( 'curpair1')} className="dropdown-item nav-link">{t("SPOT")}</NavLink>
                   <NavLink to={ "/trade/"+localStorage.getItem( 'curpair')} className="dropdown-item nav-link">{t("DERIVATIVES")}</NavLink>
                  </div>
              </li>
                {/* <li className="nav-item">
                  <NavLink to="/copy-trading" className="nav-link">Copy Trading</NavLink>
                  
                </li>  */}
                <li className="nav-item mb-2 mb-lg-0">
                  <NavLink to="/launchpad" className="nav-link">{t("LAUNCHPAD")}</NavLink>
                </li>
                <li className="nav-item">
                  <NavLink to="/MyAssets" className="nav-link">{t("WALLET")}</NavLink>
                </li>

                {/* <li className="nav-item dropdown dmenu mr-2 menu_not_hide_mob">
                    <a className="nav-link dropdown-toggle navbardrop_pos menu_default_hide menu_not_hide" href="#" id="navbardrop1" data-toggle="dropdown">
                     <div className='noti_hader'>
                     <i className='fa fa-bell'></i>
                     <span className='noti_count_bue'>16</span>

                     </div>
                    </a>
                    <div className="dropdown-menu sm-menu menu_default_show dropdown_menu_notif_md">
                    <div className="notificationDropdown noti_child_po" tabindex="0" autofocus="">
    <div className="text-right">
        <button className="mark_read_link">Mark all as read </button>
    </div>
    <ul>
        <li>
            <p>43d ago</p>
            <h5>Login Successfully</h5>
        </li>
        <li>
            <p>43d ago</p>
            <h5>Login Successfully</h5>
        </li>
        <li>
            <p>43d ago</p>
            <h5>Login Successfully</h5>
        </li>
        <li>
            <p>43d ago</p>
            <h5>Login Successfully</h5>
        </li>
        <li>
            <p>43d ago</p>
            <h5>Login Successfully</h5>
        </li>
        <li>
            <p>42d ago</p>
            <h5>Login Successfully</h5>
        </li>
        <li>
            <p>42d ago</p>
            <h5>Login Successfully</h5>
        </li>
      </ul>
      
           

            <p className='mb-0 pb-3 pt-3'>
          <a className="all_noti_link_green" href="/notification">All Notifications</a>
        </p>
    </div>
                                         
                    
                    </div>
                  </li> */}

                  {/* <li className="nav-item px-0 menu_show_mobile_onlye">
                    <Link to="/notification" className="nav-link">Notifications</Link>
                  </li> */}
                <li className="nav-item dropdown dmenu">
                    <a className="nav-link dropdown-toggle menu_default_hide" href="#" id="navbardrop" data-toggle="dropdown">
                      {user.name && user.name.substring(0, 10)}
                      {!user.name && user.email != null ? user.email.split('@')[0].substring(0, 10) : ''}
                    </a>
                    <div className="dropdown-menu sm-menu menu_default_show">
                    <NavLink to="/Settings" className="dropdown-item nav-link">{t("SETTINGS")}</NavLink>
                    {/* <NavLink to="/Kyc" className="dropdown-item nav-link">Kyc</NavLink> */}
                    <NavLink to="/AssetsHistory" className="dropdown-item nav-link">{t("HISTORY")}</NavLink>
                    {/* <NavLink to="/Referral_Program" className="dropdown-item nav-link">Referral Program</NavLink> */}
                    <a className="dropdown-item nav-link" onClick={this.onLogoutClick} href="#">{t("LOGOUT")}</a>
                  </div>
                </li>
                <li className="nav-item dropdown dmenu  mr-2 ml-lg-3">
                  <a className="nav-link dropdown-toggle mobile pl-0" href="#" id="navbardrop" data-toggle="dropdown">
                  {/* {t("LANGUAGE")}  */}
                  {localStorage.getItem('lang') == 'en' || localStorage.getItem('lang') == '' ? 'ENGLISH' : 'español'}
                  </a>
                  <div className="dropdown-menu sm-menu menu_default_show">
                   <a onClick={()=>{Languagechange('en'); this.swiftlanguage("en")}} className="dropdown-item nav-link">English</a>
                   <a onClick={()=>{Languagechange('sp'); this.swiftlanguage("sp")}}className="dropdown-item nav-link">español</a>
                  </div>
              </li>

              
                  <li>
                  <button className='transbtn theme' onClick={() => themechange()}><span className={this.state.theme ? 'fa fa-moon' : 'fa fa-sun'} ></span></button>
                </li>
              </ul> :
              <ul className="navbar-nav ml-auto">
                 <li className="nav-item dropdown dmenu mr-2">
                  <a className="nav-link dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">
                  {t("TRADE")}
                  </a>
                  <div className="dropdown-menu sm-menu menu_default_show">
                   <Link to={ "/spot/"+localStorage.getItem( 'curpair1')} className="dropdown-item nav-link">{t("SPOT")}</Link>
                   <Link to={ "/trade/"+localStorage.getItem( 'curpair')} className="dropdown-item nav-link">{t("DERIVATIVES")}</Link>
                  </div>
              </li>
              <li className="nav-item active  mb-2 mb-lg-0">
                  <Link to={ "/launchpad"} className="nav-link">{t("LAUNCHPAD")}</Link>
                </li>
                {/* <li className="nav-item">
                  <NavLink to="/copy-trading" className="nav-link">Copy Trading</NavLink>
                </li> */}
                <ul className='menu_right navbar-nav'>
                <li className="nav-item ml-2 mb-lg-0 mb-2">
                  <NavLink to="/login" className="nav-link login_btn">{t("SIGN_IN")} <i className="fa fa-user pl-1" aria-hidden="true"></i></NavLink>
                </li>
                {/* <li className="nav-item ml-2 mb-lg-0 mb-2">
                  <NavLink to="/register" className="nav-link register_btn">{t("REGISTER")} <i className="fa fa-user pl-1" aria-hidden="true"></i></NavLink>
                </li> */}
                <li className="nav-item dropdown dmenu mr-2  ml-lg-3">
                  {/* <a className="nav-link dropdown-toggle pl-0 mobile" href="#" id="navbardrop" data-toggle="dropdown">
                  {t("LANGUAGE")}
                  </a> */}
                   <a className="nav-link dropdown-toggle mobile pl-0" href="#" id="navbardrop" data-toggle="dropdown">
                  {/* {t("LANGUAGE")}  */}
                  {localStorage.getItem('lang') == 'en' || localStorage.getItem('lang') == '' ? 'ENGLISH' : 'español'}
                  </a>
                  <div className="dropdown-menu sm-menu menu_default_show">
                   {/* <Link to={ "/spot/"+localStorage.getItem( 'curpair1')} className="dropdown-item nav-link">English</Link>
                   <Link to={ "/trade/"+localStorage.getItem( 'curpair')} className="dropdown-item nav-link">Spanish</Link> */}
                   <a onClick={()=>{Languagechange('en'); this.swiftlanguage("en")}} className="dropdown-item nav-link">English</a>
                   <a onClick={()=>{Languagechange('sp'); this.swiftlanguage("sp")}} className="dropdown-item nav-link">español</a>
                  </div>
              </li>
                <li>
                  <button className='transbtn theme' onClick={() => themechange()}><span className={this.state.theme ? 'fa fa-moon' : 'fa fa-sun'} ></span></button>
                </li>
                </ul>
              
              </ul>
            }
          </div>
        </nav>

      </div>
    )
  }
}
Navtradebar.propTypes = {
  logoutUser: PropTypes.object.isRequired,
  languageChanger: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
    auth: state.auth,
  });
export default connect(
    mapStateToProps, { logoutUser,languageChanger }
  )(withTranslation()(Navtradebar),withRouter(Navtradebar));