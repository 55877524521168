import React, { Fragment, Component } from 'react';
// import { DefaultRoute } from 'react-router';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/hover.css';
import './css/animate.css';
import './css/responsive-table.css';
import './css/customScroll.css';
import './css/nice-select.css';
import './css/style.css';
import 'react-notifications-component/dist/theme.css';
import DatatablePage from './components/Table'
import Landing from './components/Landing'
import Maintanance from './components/Maintanance'
import Login from './components/Login'
import Register from './components/Register'
import PaymentCode from './components/PaymentCode'
import ForgotPassword from './components/ForgotPassword'
import Resetpassword from './components/ResetPassword'
import Footer from './components/Footer'
import Settings from './components/Settings'
import Referral from './components/Referral';
import About from './components/About'
import AffiliateProgram from './components/AffiliateProgram'
import PresidentMessage from './components/PresidentMessage'
import Leverage from './components/Leverage'
import Fee from './components/Fee'
import Contact from './components/Contact'
import Support from './components/SupportTicket'
import Bonus from './components/Bonus'
import Terms from './components/Terms'
import Spot from './components/Spot'
import Privacy from './components/Privacy'
import Faq from './components/Faq'
import SupportReply from './components/SupportReply'
import MyAssets from './components/MyAssets'
import AssetsExchange from "./components/AssetsExchange.jsx"
import ManageAddress from "./components/ManageAddress.js"
import ClosedPandL from "./components/ClosedPandL.js"
import Trade from './components/Trade'
import Blog from './components/Blog'
import OnlyCategories from "./components/BlogOnlyCategorys"
import BlogDetaile from './components/BlogDetaile'
import Orderhistory from './components/Orderhistory'
import TradeHistory from './components/TradeHistory'
import UserPendingOrder from './components/UserPendingOrder';
import BonusHistory from './components/BonusHistory'
import AssetsHistory from './components/AssetsHistory'
import Beginnerguide from './components/BeginnersGuide'
import ReactNotification from 'react-notifications-component'
import WithdrawalHistory from './components/WithdrawHistory'
import Helpmain from './components/HelpMain.jsx'
import HelpDetail from './components/HelpDetailPage'
import { Redirect, BrowserRouter, Route, Switch } from 'react-router-dom';
import { Provider } from "react-redux";
import ConditionRoute from "./components/conditional-route/ConditionRoute";
import store from "./store";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, getUserData, logoutUser } from "./actions/authActions";
import axios from "axios";
import keys from "./actions/config";
import IdleTimer from 'react-idle-timer'
import kyc from "./components/kyc"
import PendingHistory from './components/PendingHistory';
import StackingHome from './components/StackingHome';
import Stacking from './components/Stacking';
import LaunchpadDetails from './components/LaunchpadDetails';
import LaunchpadHome from './components/LaunchpadHome';
import LaunchpadToken from './components/LaunchpadToken';
import StakingHistory from './components/StakingHistory';
import io from "socket.io-client"
import CopyTrading from './components/copy-trading';
import CopyTradingSetting from './components/copy-trading-setting';
import CopyTraderInfo from './components/copy-trader-info';
import CopyTradingMyAccount from './components/copy-trade-myaccount';

import { I18nextProvider } from 'react-i18next';
import i18n from './components/i18next/i18n';

// Static Page
import Risk from './components/Risk';
import AmlCft from './components/AmlCft';
import CookiePolicy from './components/CookiePolicy';
import AntiSpamPolicy from './components/AntiSpamPolicy';

// import Notification from './components/Notification';

const url = keys.baseUrl;

if (localStorage.jwtToken) {
  const token = localStorage.jwtToken;
  setAuthToken(token);

  const decoded = jwt_decode(token);
  store.dispatch(setCurrentUser(decoded));
  const currentTime = Date.now() / 1000;
  getUserData(decoded);
  console.log("reredned................",decoded.exp);
  if (new Date().getTime() < currentTime) {
    console.log("reredned................");
    store.dispatch(logoutUser());
    window.location.href = "./login";
  }


}


class App extends Component {
  constructor(props) {
    super(props);
    this.idleTimer = null
    this.onAction = this._onAction.bind(this)
    this.onActive = this._onActive.bind(this)
    this.onIdle = this._onIdle.bind(this)
    this.state = {
      maintanancecheck: false
    }
    // this.socket = io(keys.socketUrl, { secure: true, transports: ['polling'], jsonp: false, rejectUnauthorized: false }); //live
    this.socket = io(keys.socketUrl);


    this.socket.on('DeActivated', function (data) {
      let datas = { userId: data?.userId?._id };
      console.log('asdfvagsdnfsjhd', datas)
      store.dispatch(logoutUser(datas));

    })


    this.socket.on('Deleted', function (data) {
      console.log('lfakfgsbjsdgffj',data)
     let datas = {userId : data?.pdata?._id};
     console.log('asdfhvgjsdgs',datas)
     store.dispatch(logoutUser(datas));

    })


  }

  componentDidMount() {
    this.getSingleUser()
  }



  getSingleUser = async () => {
    // if (localStorage.jwtToken) {
    //   const token = localStorage.jwtToken;
    //   setAuthToken(token);

    //   const decoded = jwt_decode(token);

    //  const id=decoded.id
    //   const userData=await axios.get(url+"cryptoapi/getSingleUser/"+id)

    //     store.dispatch(getUserData(userData.data.userData))

    // }


  }
  // this.socket.on('DeActivated', function (data) {
  //   console.log('Deactivadlaskdflsdhfglk',data);
  //   // priceappenddata(data);
  // });
  renderRedirect = () => {
    if (this.state.maintanancecheck) {
      return <Redirect to='/maintanance' />
    }
    // return <Redirect to='/' />
  }
  _onAction(e) {
    ////console.log('user did something', e)
  }

  _onActive(e) {
    //console.log('user is active', e)
    //console.log('time remaining', this.idleTimer.getRemainingTime())
  }

  _onIdle(e) {
    //console.log('user is idle', e)
    //console.log('last active', this.idleTimer.getLastActiveTime())
    if (this.idleTimer.getRemainingTime() == 0) {
      store.dispatch(logoutUser());
      window.location.href = "/login";
    }
  }




  render() {
    return (
      <div>
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          element={document}
          onActive={this.onActive}
          onIdle={this.onIdle}
          onAction={this.onAction}
          debounce={250}
          timeout={1000 * 60 * 60} />
        <Provider store={store}>
         <I18nextProvider i18n={i18n}>
          <BrowserRouter basename="/">
            <Fragment>
              <ReactNotification />
              <section className='container-fluid px-0'>
                <Switch>

                  <Route exact path='/copy-trading-setting' component={CopyTradingSetting} />
                  <Route exact path='/copy-trading' component={CopyTrading} />
                  <Route exact path='/copy-trader-info' component={CopyTraderInfo} />
                  <Route exact path='/copy-trade-myaccount' component={CopyTradingMyAccount} />

                  <Route exact path='/' component={Landing} />
                  <Route exact path='/Launchpad' component={LaunchpadHome} />
                  <Route exact path='/LaunchpadToken' component={LaunchpadToken} />

                  <Route exact path='/LaunchpadDetails/:id' component={LaunchpadDetails} />
                  {/*  <Route exact path='/Staking' component={StackingHome} />
                  <Route exact path='/StakingList' component={Stacking} /> */}

                  <Route exact path='/maintanance' component={Maintanance} />
                  <Route exact path='/Payment' component={PaymentCode} />
                  <Route exact path='/register' component={Register} />
                  <Route exact path='/login' component={Login} />
                  <Route exact path='/Activate/:id' component={Login} />
                  <Route exact path='/ForgotPassword' component={ForgotPassword} />
                  <Route exact path='/resetpassword/:id' component={Resetpassword} />
                  <Route exact path='/Withdraw/:id' component={Landing} />

                  <Route exact path='/trade' component={Trade} />
                  <Route exact path='/trade/:currency' component={Trade} />
                  <Route exact path='/spot/:currency' component={Spot} />
                  <Redirect from='/spot' exact to='/spot/BTC-USDT' />

                  <Route exact path='/about' component={About} />
                  {/* <Route exact path='/AffiliateProgram' component={AffiliateProgram} /> */}
                  <Route exact path='/PresidentMessage' component={PresidentMessage} />
                  <Route exact path='/Bonus' component={Bonus} />
                  <Route exact path='/Contact_us' component={Contact} />
                  <Route exact path='/Terms' component={Terms} />
                  <Route exact path='/risk' component={Risk} />
                  <Route exact path='/aml-cft' component={AmlCft} />
                  <Route exact path='/cookie-policy' component={CookiePolicy} />
                  <Route exact path='/anti-spam-policy' component={AntiSpamPolicy} />
                  <Route exact path='/Privacy' component={Privacy} />
                  <Route exact path='/Faq' component={Faq} />
                  <Route exact path='/helpcenter' component={Helpmain} />



                  <Route exact path='/Fee' component={Fee} />
                  <Route exact path='/beginnersguide' component={Beginnerguide} />
                  <Route exact path='/helpdetails/:aid' component={HelpDetail} />
                  {/* <Route exact path='/blog' component={Blog} />
                  <Route exact path='/BlogCategorys/:id' component={OnlyCategories} />
                  <Route exact path='/BlogDetaile/:aid' component={BlogDetaile} /> */}

                  <Switch>
                    <ConditionRoute exact path='/settings' type="private" component={Settings} />
                    <ConditionRoute exact path='/ClosedPandL' type="private" component={ClosedPandL} />
                    <ConditionRoute exact path='/MyAssets' type="private" component={MyAssets} />
                    {/* <ConditionRoute exact path='/Referral_Program' type= "private" component={Referral} /> */}
                    <ConditionRoute exact path='/support' type="private" component={Support} />
                    <ConditionRoute exact path='/supportreply/:id' type="private" component={SupportReply} />
                    <ConditionRoute exact path='/WithdrawalHistory' type="private" component={WithdrawalHistory} />
                    <ConditionRoute exact path='/Trade' type="private" component={Trade} />
                    <ConditionRoute exact path='/Orderhistory' type="private" component={Orderhistory} />
                    <ConditionRoute exact path='/BonusHistory' type="private" component={BonusHistory} />
                    <ConditionRoute exact path='/TradeHistory' type="private" component={TradeHistory} />
                    <ConditionRoute exact path='/StakingHistory' type="private" component={StakingHistory} />

                    <ConditionRoute exact path='/AssetsHistory' type="private" component={AssetsHistory} />
                    <ConditionRoute exact path='/Trade/:currency' type="private" component={Trade} />
                    <ConditionRoute exact path='/AssetsExchange' type="private" component={AssetsExchange} />
                    <ConditionRoute exact path='/ManageAddress' type="private" component={ManageAddress} />
                    <ConditionRoute exact path="/Kyc" type="private" component={kyc} />
                    {/* <ConditionRoute exact path="/notification" type= "private" component={Notification} /> */}

                  </Switch>
                  <Redirect to='/' />
                </Switch>
              </section>
            </Fragment>
            {this.renderRedirect()}
          </BrowserRouter>
          </I18nextProvider>
        </Provider>
      </div>
    );
  }
}

export default App;
