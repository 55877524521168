export default {
    "REGISTER_TITLE_1": "Registre su cuenta fácilmente y comience a operar.",
    "REGISTER": "Registro",
    "ALREADY_HAVE_ACCOUNT": "¿Ya tienes una cuenta?",
    "ACCEPT_TERMS_MESSAGE": "Por favor acepte los Términos y Condiciones de Uso",
    "RECOVERY_PASSWORD_TITLE": "Escriba su contraseña registrada y le enviaremos un correo electrónico de recuperación de contraseña.",
    "EMAIL_NOT_EXISTS": "Cuenta de usuario no encontrada",
    "SIGN_IN_BUTTON": "Iniciar sesión",
    "EMAIL_PLACEHOLDER": "Dirección de correo electrónico",
    "EMAIL_CHANGE_PLACEHOLDER": "Cambiar dirección de correo electrónico",
    "ENTER_PASSWORD": "Introducir la contraseña",
    "KEEP_SIGN_COMPUTER": "Mantenerme conectado en esta computadora",
    "DON'T_HAVE_ACCOUNT": "Don't have an account",
    "EMAIL_REQUIRED": "El campo de correo electrónico es obligatorio",
    "EMAIL_INVALID": "Dirección de correo electrónico no válida",
    "INVALID_PASSWORD": "contraseña invalida",
    "ONLY_NUMERIC": "Permitir solo valores numéricos",
    "INVALID_OTP": "OTP no válido",
    "FORGOT_PASSWORD": "Has olvidado tu contraseña",
    // "SIGN_IN": "Registrate gratis",
    "PASSWORD_REQUIRED": "El campo de contraseña es obligatorio",
    "PASSWORD_INCORRECT": "Contraseña incorrecta",
    "CHECK_OPTION": "Por favor marque la opción",
    "HAVE_BACKUP": "tengo una copia de seguridad del codigo",
    "PASSWORD_MIN_MAX": "mínimo 6 y máximo 18",
    "CONFIRM_PASSWORD_MISMATCH": "Las contraseñas deben coincidir",
    "REGEX_PASSWORD": "La contraseña debe contener al menos una mayúscula, al menos una minúscula, al menos un número, al menos un carácter especial",
    "PASSWORD_CHANGE_SUCCESS": "Restablecimiento de contraseña con éxito",
    "PASSSWORD_RESET": "Password Reset",
    "CURRENT_PASSWORD_REQUIRED": "El campo Contraseña actual es obligatorio",
    "CONFIRM_PASSWORD_REQUIRED": "El campo de confirmación de contraseña es obligatorio",
    "CONFIRM_PASSWORD": "confirmar Contraseña",
    "UPDATE_PASSWORD": "Actualizar contraseña de inicio de sesión",
    "LOGIN": "Acceso",
    "ORDER_HISTORY": "Historial de pedidos",
    "DEFAULT_FIAT_CURRENCY": "Moneda fiduciaria predeterminada",
    "PAGE_AFTER_LOGIN": "Página predeterminada después de iniciar sesión",
    "DEFAULT_LANGUAGE": "Mi idioma predeterminado",
    "REF_CODE_OPTION": "Código de Referencia (Opcional)",
    "CURRENT_PASSWORD": "Contraseña actual",
    "NEW_PASSWORD": "Nueva contraseña",
    "CONFIRM_PASSWORD": "Confirmar nueva contraseña",
    "REFERRAL_CODE": "Código de referencia",
    "I_AGREE": "Acepto y estoy de acuerdo con el",
    "REFERRAL": "Remisión",
    "YOUR_REFERRAL_ID": "Su identificación de referencia",
    "INVITE_FRIENDS": "Invitar a amigos",
    "YOUR_TOTAL_REFERRAL": "Su ingreso total por referencias",
    "TERMS": "Términos y condiciones",
    "AND": "y",
    "PRIVACY": "política de privacidad",
    "PASSWORD": "Contraseña",
    "OTP": "Fiscalía",
    "DEFINITION": "Definiciones",
    "TERMS": "términos",
    "OUR_TERMS": "Nuestros Términos",
    "TERMS_CONTENT_1": "Estos Términos de uso de Bellexchange se celebran entre usted (en lo sucesivo, usted o su) y los operadores de Bellexchange (como se define a continuación). Al acceder, descargar, usar o hacer clic en Acepto para aceptar cualquier Servicio de Bellexchange (como se define a continuación) proporcionado por Bellexchange (como se define a continuación), usted acepta que ha leído, entendido y aceptado todos los términos y condiciones estipulados en estos Términos de uso (en lo sucesivo, estos Términos), así como nuestra Política de privacidad en https://www.Bellexchange.com/en/privacy. Además, al usar algunas funciones de los Servicios, puede estar sujeto a términos y condiciones adicionales específicos aplicables a esas funciones.",
    "TERMS_CONTENT_2": "Al acceder, usar o intentar usar los Servicios de Bellexchange en cualquier capacidad, usted reconoce que acepta y está de acuerdo en estar sujeto a estos Términos. Si no está de acuerdo, no acceda a Bellexchange ni utilice los servicios de Bellexchange.",
    "TERMS_CONTENT_3": "Bellexchange se refiere a un ecosistema que comprende los sitios web de Bellexchange (cuyos nombres de dominio incluyen, entre otros, https://www.Bellexchange.com), aplicaciones móviles, clientes, subprogramas y otras aplicaciones que se desarrollan para ofrecer los Servicios de Bellexchange e incluye de forma independiente: plataformas, sitios web y clientes operados.",
    "TERMS_CONTENT_4": "Bellexchange Operadores de Bellexchange se refieren a todas las partes que ejecutan Bellexchange, incluidas, entre otras, las personas jurídicas, las organizaciones no incorporadas y los equipos que brindan los Servicios de Bellexchange y son responsables de dichos servicios. Por conveniencia, a menos que se indique lo contrario, las referencias a Bellexchange y nosotros en estos Términos significan específicamente Bellexchange",
    "TERMS_CONTENT_5": "Los Servicios de Bellexchange se refieren a varios servicios que le brinda Bellexchange que se basan en tecnologías de Internet y/o blockchain y se ofrecen a través de los sitios web, aplicaciones móviles, clientes y otras formas de Bellexchange (incluidos los nuevos habilitados por el desarrollo tecnológico futuro). Los servicios de Bellexchange incluyen, entre otros, componentes del ecosistema de Bellexchange como plataformas de comercio de activos digitales, el sector financiero, Bellexchange Labs, Bellexchange Academy, Bellexchange Charity, Bellexchange Info, Bellexchange Launchpad, Bellexchange Research, Bellexchange Chain, Bellexchange X, Bellexchange Fiat Gateway, servicios existentes ofrecidos por Trust Wallet y nuevos servicios que proporcionará Bellexchange",
    "SEND_CODE": "Enviar código",
    "RE_SEND_CODE": "Reenviar codigo",
    "VERIFY_RECAPTCHA": "Verificar recaptcha",
    "MOBILE_NO": "Número de teléfono móvil",
    "ENTER_MOBILE_NO": "Introduce el número de móvil",
    "VERIFY_CODE": "Código de verificación",
    "REQUIRED": "Requerido",
    "PRICE_ONLY_NUMERIC": "Precio solo valor numérico",
    "FIRST_NAME_ALPHABET": "FirstName Debe ser completado con Alfabetos",
    "LAST_NAME_ALPHABET": "LastName Debe ser llenado con Alfabetos",
    "BLOCKNO_NAME_ALPHABET": "BlockNo Debe ser llenado con Alfabetos",
    "STATE_NAME_ALPHABET": "Estado Debe ser llenado con Alfabetos",
    "CITY_NAME_ALPHABET": "Ciudad Debe ser llenada con Alfabetos",
    "POSTAL_CODE_MUST_NUMBER": "Código Postal Debe ser llenado con Números",
    "INVALID_EMAIL": "Email inválido",
    "FILLED": "completado",
    "ONLY_NUMERIC": "Solo valores numéricos permitidos",
    "ONLY_NUMERIC_POSITIVE": "Solo se permiten valores numéricos positivos",
    "QUANTITY_REQUIRED": "El campo de cantidad es obligatorio",
    "MAX_LIMIT_REQUIRED": "El campo de límite máximo es obligatorio",
    "MAX_LIMIT_4": "Límite máximo 4",
    "INVALID_CODE": "Codigo invalido",
    "TERMS_REQUIRED": "El campo de términos es obligatorio",
    "PERSONAL_DETAILS": "Detalles personales",
    "FULL_NAME": "Nombre completo",
    "RESIDENTIAL_ADDRESS": "Dirección residencial",
    "BUILDING_BLOCK": "Nombre del edificio/bloque",
    "ADDRESS": "DIRECCIÓN",
    "STATE_PROVISION": "Provincia del estado",
    "CITY": "Ciudad",
    "POSTAL_CODE": "Código Postal",
    "COUNTRY": "País",
    "EDIT_PROFILE": "Editar mi perfil",
    "SUBMIT": "entregar",
    "FIRST_NAME": "Nombre de pila",
    "LAST_NAME": "Apellido",
    "NAME": "Nombre",
    "PROFILE_EDIT_SUCCESS": "¡Gracias, tu perfil ha sido actualizado!",
    "SOMETHING_WRONG": "Algo salió mal",
    "NO_DATA": "No hay datos",
    "NO_ORDER": "no hay orden",
    "ORDER_CANCEL": "Su pedido cancelado con éxito",
    "ORDER_ALREADY_COMPLETED": "Tu pedido ya completado",
    "ORDER_ALREADY_CANCEL": "Tu pedido ya cancelado",
    "REGISTER_BANK_ACCOUNT": "Aquí puede registrar su cuenta bancaria dentro del sistema que le permitirá retirar fondos de coin goldx",
    "ADD_BANK_ACCOUNT": "Agregar cuenta bancaria",
    "ADD_BANK": "Agregar banco",
    "READ": "Leer",
    "MY_BANK_ACCOUNT": "Mis cuentas bancarias",
    "DEFAULT": "Por defecto",
    "BANK_ACCOUNT": "Cuenta bancaria",
    "CHANGE": "cambiar",
    "BANK_NAME": "Nombre del banco",
    "ACCOUNT_NO": "Número de cuenta",
    "ACCOUNT_NUMBER": "Número de cuenta",
    "ACCOUNT_HOLDER_NAME": "nombre del titular de la cuenta",
    "IBAN_SWIF": "Código IBAN/Swift",
    "IBAN_CODE": "Código IFSC",
    "BANK": "Banco",
    "STATUS": "Estado",
    "CREATED_ON": "Crear en",
    "TWO_FA": "Autenticación de 2 factores",
    "EDIT": "Editar",
    "DELETE": "Borrar",
    "TWO_FA_MSG": "Debe habilitar 2FA para retirar fondos fiduciarios.",
    "BANK_EDIT_SUCCESS": "Datos bancarios actualizados correctamente",
    "BANK_ADD_SUCCESS": "Datos bancarios añadidos con éxito",
    "BANK_DELETE_SUCCESS": "Datos bancarios eliminados con éxito",
    "BANK_SET_PRIMARY_SUCCESS": "Datos bancarios predeterminados actualizados con éxito",
    "UPDATE": "Actualizar",
    "NOTES": "notas",
    "MIN_DEPOSIT_LIMIT": "Límite mínimo de depósito",
    "DEPOSIT_TIME": "El tiempo de depósito tomará de 4 a 24 horas debido a la red bancaria",
    "CHAT_ONLY_TRADE": "Chat solo información de detalles comerciales",
    "WITHDRAW_FIAT_DEPOSIT": "Retirar Depósito Fiat",
    "WITHDRAW_FIAT": "retirar fiat",
    "AVOID_WORNG_WORDS": "Evita palabras innecesarias",
    "TRANSFER_PAYMENT_BUTTON": " Haga clic en el botón Transferir pago para completar el pago al vendedor",
    "NEED_SUPPORT": "¿Necesita ayuda?",
    "CREAT_SUPPORT_TICKET": "Crear ticket de soporte",
    "SUPPORT_DETAILS": "Detalles de soporte",
    "2FA_VERIFID": "Verificación de código 2FA",
    "PASSWORD_TITLE": "Por medidas de seguridad, su contraseña debe contener:",
    "PASSWORD_DESCRIPTION1": "un mínimo de 6 caracteres de largo",
    "PASSWORD_DESCRIPTION2": "al menos una letra MAYÚSCULAS (A, B, X, Y...)",
    "PASSWORD_DESCRIPTION3": "al menos un dígito (0,1,2,9...)",
    "PASSWORD_DESCRIPTION4": "al menos un carácter especial (! @ # $ ^ *...)",
    "PASSWORD_DESCRIPTION5": "un máximo de 18 caracteres de largo",
    "PASSWORD_DESCRIPTION6": "al menos una letra MINUSCULAS (a,b,x,y...)",
    "TWO_FA_DESCRIPTION1": `Habilite la autenticación de dos factores como medida de seguridad adicional para proteger sus fondos y poder retirarlos. Utilice el autenticador de Google
    que puedes`,
    "TWO_FA_DESCRIPTION2": `Descargue el autenticador de Google haciendo clic en "Descargar aquí" y escanee el código QR. Recibirá un código de 6 dígitos, que debe ingresar en el cuadro 2FA.`,
    "TWO_FA_DESCRIPTION3": "Abajo el código de 32 dígitos en un libro blanco para su seguridad si olvidó el código 2FA para retirar fondos",
    "DOWNLOAD_HERE": "Descarga aquí",
    "HOW_ENABLE": "Cómo habilitar",
    "ENABLE": "Habilitar 2FA",
    "DISABLE": "Deshabilitar 2FA",
    "ENTER_TWO_FA_CODE": "Ingrese el código 2FA de 6 dígitos",
    "TWO_FA_CODE": "Su código de seguridad de 32 dígitos",
    "SCAN_QR_CODE": "Escanea el código QR",
    "DISABLED": "Desactivado",
    "ENABLED": "Activado",
    "TWO_FA_ENABLE_SUCCESS": "2FA habilitado con éxito",
    "TWO_FA_DISABLE_SUCCESS": "2FA deshabilitado exitosamente",
    "EDIT_SETTING_SUCCESS": "Configuración actualizada con éxito",
    "IDENTITY_DOCUMENT": "Documento de identidad",
    "IDENTIFICATION_DOCUMENT": "Documento de identificación",
    "SELECTED_ID_NUMBER": "Ingrese el número de identificación del documento seleccionado",
    "FRONT_SIDE": "Lado delantero",
    "PICTURE_SCAN": "Imagen o escaneo que muestra las 4 esquinas",
    "MAX_1MB_IMG": "Máx. 1 MB en jpg/png/pdf",
    "BACK_SIDE": "Parte trasera",
    "SIDE": "Lado",
    "EXECTED_PRICE": "Precio Ejecutado",
    "EXECUTED": "Ejecutado",
    "ADA": "ADA",
    "SELFIE_SELECTED_ID": "Selfie con ID seleccionado",
    "IDENTITY_DOCUMENT_TITLE1": "Aquí puede verificar su identidad cargando uno de los documentos de identidad aprobados",
    "IDENTITY_DOCUMENT_DESCRIPTION1": "Todas las imágenes deben ser claramente visibles y el texto también claramente visible",
    "IDENTITY_DOCUMENT_DESCRIPTION2": "Si el texto no es legible, el rostro no está claro o la imagen borrosa debe descalificarse y NO se verificará el estado.",
    "IDENTITY_DOCUMENT_DESCRIPTION3": "La selfie o imagen con su identificación seleccionada debe mostrar una cara clara sosteniendo la identificación seleccionada. Por favor vea un ejemplo",
    "IDENTITY_DOCUMENT_DESCRIPTION4": "Si desea cambiar su documento de identidad póngase en contacto con nuestro",
    "IDENTITY_HINT1": "máx. Tamaño de 9 MB, jpg, png, pdf permitido",
    "IDENTITY_HINT2": "Máx. 9 MB en jpg/png/pdf",
    "HERE": "Aquí",
    "IDENTITY_DOCUMENT_VERIFIED": "Tu documento de identidad ha sido subido correctamente",
    "SUPPORT_TEAM": "equipo de apoyo",
    "TICKET_FOR": "boleto para",
    "MSG_TO_SUPPORT_TEAM": "Mensaje al equipo de soporte",
    "ADDRESS_DOCUMENT_TITLE1": "Comprobante de domicilio residencial",
    "ADDRESS_DOCUMENT_TITLE2": "Documento de prueba de dirección",
    "ADDRESS_DOCUMENT_TITLE3": "Sube el documento",
    "ADDRESS_DOCUMENT_DESCRIPTION1": "El documento debe indicar claramente su nombre completo y dirección completa.",
    "ADDRESS_DOCUMENT_DESCRIPTION2": "El documento NO debe tener más de 3 meses",
    "ADDRESS_DOCUMENT_DESCRIPTION3": "Factura de servicios públicos aprobada factura de luz, factura de gas, teléfono fijo, factura de internet. Factura de teléfono móvil NO aceptada.",
    "ADDRESS_DOCUMENT_DESCRIPTION4": "Factura de servicios (Facturas de servicios aprobadas: factura de luz, factura de gas, teléfono fijo, factura de internet). Teléfono móvil no aceptado.",
    "ADDRESS_DOCUMENT_DESCRIPTION5": "Si desea cambiar su documento de dirección, comuníquese con nuestro",
    "ADDRESS_DOCUMENT_VERIFIED": "Su comprobante de domicilio ha sido enviado",
    "DRIVING_LICENSE": "Licencia de conducir",
    "UTILITY_BILL": "Factura de servicios públicos",
    "BANK_STATEMENT": "Extracto de cuenta",
    "CREDIT_CARD_STATEMENT": "extracto de tarjeta de crédito",
    "MUNICIPALITY_REGISTRATION": "Registro Municipal",
    "GOVERNMENT_ISSUED_ID": "Identificacion dada por el gobierno",
    "VOTER_ID": "ID de votante",
    "CITIZEN_SHIP": "Ciudadanía",
    "PAN_CARD": "Imagen de la tarjeta panorámica",
    "PASSPORT": "Pasaporte",
    "PANCARD": "Tarjeta de la cacerola",
    "GAS_BILL": "Factura del gas",
    //"CITIZEN_SHIP": "Aadhar Card",
    "TOO_LARGE": "Demasiado grande",
    "INVALID_IMAGE": "Seleccione un formato válido",
    "IDENTITY_DOC_UPLOAD_SUCCESS": "Documento de identidad actualizado con éxito",
    "ADDRESS_DOC_UPLOAD_SUCCESS": "Documento residencial actualizado con éxito",
    "NOT_APPLY": "No aplicar",
    "VERIFIED": "Verificado",
    "NOT_VERIFIED": "No verificado",
    "REJECTED": "Rechazado",
    "BASIC": "Básico",
    "ADVANCED": "Avanzado",
    "PRO": "Pro",
    "BASIC_PENDING": "Básico pendiente",
    "BASIC_SUBMITTED": "Básico enviado",
    "SUBMITTED": "Enviado",
    "BASIC_VERIFIED": "Básico Verificado",
    "ADVANCED_Pending": "Pendiente avanzada",
    "PRO_PENDING": "pendiente profesional",
    "ADVANCED_Pending": "Pendiente avanzadag",
    "PRO_PENDING": "pendiente profesional",
    "BASIC_USER": "Usuario básico",
    "ADVANCED_USER": "Usuario avanzado",
    "PRO_USER": "Usuario profesional",
    "TYPE_BASIC_DESCRIPTION1": "Deposite criptomonedas hasta 10,00 € de valor",
    "TYPE_BASIC_DESCRIPTION2": "Opere en mercados criptográficos y fiduciarios",
    "TYPE_BASIC_DESCRIPTION3": "Retira cripto y fiat hasta 10,00 € de valor por un máximo de $2500",
    "ID_ADDRESS_PROOF": "Prueba de identificación y dirección",
    "DEFAULT_BANK_ACCOUNT_DETAIL": "Comprobante de cuenta bancaria (solo banco de retiro predeterminado)",
    "USER_ID": "Identificación de usuario",
    "MEMBER_SINCE": "Miembro desde",
    "VERIFICATION_TYPE": "Tipo de verificación",
    "EMAIL_PHONE": "Dirección de correo electrónico y teléfono",
    "VERIFY_NOW": "verifica ahora",
    "PENDING": "Pendiente",
    "PHONE": "Teléfono",
    "TRANS_WALLET_AMOUNT": "Importe de la billetera de transferencia",
    "WITHDRAW_ACCOUNT": "Retirar cuenta",
    "FIAT_WITHDRAW_DESCRIPTION1": "Retiro mínimo",
    "FIAT_WITHDRAW_DESCRIPTION2": "El retiro demorará entre 24 y 48 horas debido a la red bancaria / días festivos",
    "FINAL_WITHDRAW_AMOUNT": "Cantidad final de retiro",
    "FINAL_WITHDRAW_AMOUNT_WITH_FEE": "Retirar monto con cargo",
    "WALLET_BALANCE": "Saldo de la cartera",
    "ENTER2FA_CODE": "Ingrese el código 2FA",
    "WALLET_TRANSFER": "Transferencia de billetera",
    "MIN_WITHDRAW_LIMIT": "Límite mínimo de retiro",
    "WITHDRAW_TIME_TAKE": "El tiempo de retiro tomará de 4 a 24 horas debido a la red bancaria",
    "WITHDRAW_TIME": "Su retiro Válido solo por 2 minutos",
    "WITHDRAW_PROCESS": "Envíe su retiro, su correo recibido puede aprobar (o) cancelar",
    "WITHDRAW_PROCESS2": "después de 2 minutos, su retiro no se completó automáticamente cancelado y luego se actualizará su cantidad",
    "WITHDRAW_AMOUNT": "Retirar cantidad",
    "FUND_TRANSFER": "Transferencia de fondos",
    "RECEIVER_EMAIL": "Correo electrónico del receptor",
    "WITHDRAW_ADDRESS": "Retirar dirección",
    "ALLOW_NUMERIC": "Solo numérico permitido",
    "QUANTITY_ONLY_NUMERIC": "Cantidad solo valor numérico",
    "SIDE_REQUIRED": "El campo lateral es OBLIGATORIO",
    "INVALID_SIDE": "Lado inválido",
    "MIN_LIMIT_REQUIRED": "El campo de límite mínimo es OBLIGATORIO",
    "MAX_LIMIT_REQUIRED": "El campo de límite máximo es OBLIGATORIO",
    "SELECT_DATE": "Seleccione la fecha",
    "GREATER_THEN_CURRENT": "Seleccione una fecha mayor que la fecha actual",
    "LESS_LIMIT_PURCHASE": "Desde el precio límite debe ser menor que hasta el precio límite",
    "ACCEPT_TERMS": "Por favor acepte los términos y la política",
    "BALANCE": "Balance",
    "DISTANCE": "Distancia",
    "INSUFFICIENT_BALANCE": "Saldo insuficiente",
    "KYC_SUBMIT_ALERT": "Envíe sus detalles de kyc",
    "EMAIL_SUMBIT": "Por favor envíe los detalles de su correo electrónico",
    "INVALID_BANK_ACCOUNT": "Cuenta bancaria inválidat",
    "VERIFICATION_LINK": "Enlace de verificación enviado a la dirección de correo electrónico",
    "INVALID_TOKEN": "simbolo no valido",
    "EXPIRY_TOKEN": "token de caducidad",
    "DEPOSIT_QR_CODE": "Para depositar usa este QR",
    "COIN_ADDRESS": "Su dirección de moneda",
    "COPY_ADDRESS": "Copiar dirección",
    "COIN_DEPOSIT_DESCRIPTION1": "Envíe solo LTC a esta dirección de depósito.",
    "COIN_DEPOSIT_DESCRIPTION2": "Depósito mínimo: 0.001 LTC",
    "COIN_DEPOSIT_DESCRIPTION3": "El envío de monedas o tokens que no sean LTC a esta dirección puede resultar en la pérdida de su depósito.",
    "WITHDRAW_TOO_LOW": "Importe final demasiado bajo",
    "FINAL_AMOUNT": "Cantidad final",
    "FIAT_DEPOSIT_DESCRIPTION1": "Depósito mínimo: 10€",
    "FIAT_DEPOSIT_DESCRIPTION2": "Debe subir comprobante de depósito en pdf/jpg e ingresar número de referencia/DNI",
    "ADMIN_ACCOUNT": "Cuenta de administrador",
    "DEPOSIT": "Depósito",
    "DEPOSIT_AMOUNT": "Cantidad del depósito",
    "UPLOAD_PROOF": "Cargar prueba",
    "STAKE_AMOUNT": "Monto de la apuesta",
    "T_HAVE_READ": "He leído el",
    "DEPOSIT_REQUEST_SUCCESS": "Solicitud de depósito enviada con éxito",
    "DEPOSIT_TOO_LOW": "Cantidad de depósito demasiado baja",
    "SELECT_CURRENCY": "Seleccione el tipo de moneda",
    "INVALID_WALLET_TYPE": "Tipo de billetera no válido",
    "WALLET_MIS_MATCH": "De billetera y a billetera deben ser diferentes",
    "TO_WALLET": "A la billetera",
    "FROM_WALLET": "De la billetera",
    "WALLET_TRANSFER_SUCCESS": "El proceso de conversión de billetera se completó con éxito",
    "OPEN_ORDER": "Ordenes abiertas",
    "CANCEL": "Cancelar",
    "ORDERS": "Pedidos",
    "SUBSCRIBE_ETH": "Suscríbete ETH",
    "LOT_AMOUNT": "cantidad de lote",
    "AVAILABLE_LOTS": "Disponibles 10 Lotes",
    "STAKE_LIMIT": "Límite de apuesta",
    "MINIMUM_LOT": "Mínimo 1 Lote",
    "LOT_SIZE": "Tamaño del lote",
    "INTEREST_PER_LOT": "interés por lote",
    "INDIVIDUAL_MAX": "máximo individual",
    "VALUE_DATE": "Fecha valor",
    "INTEREST_RATE": "Tasa de interés",
    "INT_DURATION_TIME": "Fecha de distribución de intereses",
    "REDEMPTION_DATE": "Fecha de redención",
    "EXPECT_INTEREST": "interés esperado",
    "HAVE_READ_AND_AGREE": "He leído y acepto las",
    "USER": "Usuario",
    "CURRENT_SUBSCRIPTION": "Current Subscription",
    "TYPE": "Tipo",
    "RELEASE": "Liberar",
    "ENTER_CODE": "Introduzca el código",
    "PRICE": "Precio",
    "LATEST_PRICE": "Último precio",
    "FLEXIBLE": "Flexible",
    "LOCKED": "Bloqueado",
    "SUBSCRIBE": "Suscribir",
    "STOP_LIMIT": "Límite de parada",
    "STOP_MARKET": "Stop-mercado",
    "TRAILING_STOP": "Trailing-stop",
    "DEFAULT_THEME": "Tema predeterminado para operar",
    "LIGHT_THEME": "Tema de luz",
    "DARK_THEME": "Tema oscuro",
    "GENERAL_SETTINGS": "Configuración general",
    "GENERAL": "General",
    "HIGH_YIELD": "Alto rendimiento",
    "CRYPTOS": "criptos",
    "VACATION": "Vacation",
    "DISABLE_ALL_ADVERTISMENT_SELL": "Deshabilite toda su publicidad para vender temporalmente",
    "DISABLE_ALL_ADVERTISMENT_BUY": "Deshabilite toda su publicidad para comprar temporalmente",
    "YOUR_BALANCE": "Tu balance",
    "ESTIMATED_VALUE": "Valor estimado:",
    "BUSD": "BUSD",
    "BNB": "BNB",
    "TRX": "TRX",
    "XRP": "XRP",
    "OTHERS": "Otro",
    "TOTAL_BALANCE": "Saldo total de la cuenta",
    "TRANSFER_FUND": "Transferir fondos",
    "AMOUNT": "Cantidad",
    "WELCOME_TO": "Bienvenido a",
    "BANNER_DESCRIPTION_1": `Ponemos el poder en tus manos para`,
    "BANNER_DESCRIPTION_2": "comprar, vender y comerciar moneda digital.",
    "GET_STARTED": "Empezar",
    "EXPLORE_MARKETS": "Explora nuestros mercados",
    "EXPLORE_MARKETS_DESCRIPTION_1": "El cambio de divisas de Bellexchange líder en el mundo",
    "EXPLORE_MARKETS_DESCRIPTION_2": "Opere Bitcoin, ETH y cientos de otras monedas de Bellexchange en minutos.",
    "BUY": "Comprar",
    "DASHBOARD": "Panel",
    "MARKET": "Mercado",
    "SPOT_ORDERS": "Pedidos",
    "ADMIN_ANNOUNCEMENT": "Anuncio de administración",
    "LAST_LOGIN": "Último acceso",
    "ADMIN": "Administrador",
    "ACCOUNT": "Cuenta",
    "KYC_VERIFICATION": "KYC Verification",
    "EMAIL": "Correo electrónico",
    "MOBILE_PHONE": "Teléfono móvil",
    "2FA_TOOL_TIP1": "Para retirar fondos,\nhabilite la autenticación de 2 factores",
    "ENABLE_NOW": "Habilitado ahora",
    "DISABLE_NOW": "Deshabilitar ahora",
    "LOGIN_PASSWORD": "Contraseña de inicio de sesión",
    "ALERT": "Alerta",
    "WHEN_ENABLE_DISABLE_2FA": "Cuando habilitar / deshabilitar 2FA",
    "WHEN_LOGIN_PWD_CHG": "Cuando cambie la contraseña de inicio de sesión",
    "MARKTING_PROMOTION_EMAIL": "Correo electrónico de marketing y promoción",
    "WALLET_BAL": "Saldo de la cartera",
    "AMOUNT_TO_STAKE": "Cantidad interesada a apostar",
    "SUBSCRIPTION_AMOUNT": "monto de la suscripción",
    "SUBSCRIPTION_DATE": "Fecha de suscripción",
    "WITHDRAW": "Retirar",
    "TRANSFER": "Transferir",
    "BTCUSDT": "BTCUSDT",
    "FIAT_HISTORY": "Fiat History",
    "CRYPTO_HISTORY": "Historial de criptomonedas",
    "P2P_HISTORY": "Historial de pedidos P2P",
    "ADV_FIAT_DEPOSIT": "Depósito Fiat Avanzado hasta",
    "ADV_FIAT_WITHDRAW": " Fiat avanzado retirarse hasta",
    "ALL_ADVANCE_FEATURE": "Todas las funciones avanzadas",
    "PLUS": "MÁS",
    "FIAT_DEPOSIT": "Depósito fiduciario",
    "DEPOSIT_BANK_INFO": "Información del banco de depósito",
    "SPOT_BALANCE": "Saldo al contado",
    "ACCOUNT_FEATURES": "Características completas de las cuentas",
    "UNLIMITED_FIAT_DEP_WITH": "Depósito y Retiro ILIMITADO de Fiat",
    "DERIVATIVE_BAL": "Saldo de derivados",
    "P2P_BAL": "Saldo P2P",
    "STATISTICS": "Estadísticas",
    "TOTAL_SPOT_TRADE": "Comercio al contado total completado",
    "TOTAL_DERIVATIVE_TRADE": "Total Derivados Colocados",
    "P2P_TRADE": "Comercio P2P",
    "OPEN": "Abierto",
    "SPOT_TRADE": "comercio al contado",
    "DERIVATIVE_TRADE": "comercio de derivados",
    "2FA_PASS_UPDATE": "Actualización de dos factores y contraseña",
    "ESCROW_STATUS": "Estado de depósito en garantía",
    "COMPLETED": "Terminado",
    "RECENT_TRANSACTION": "Transacciones Recientes",
    "LOGIN_HISTORY": "Historial de inicio de sesión",
    "TRADE_HISTORY": "Historia comercial",
    "NOTIFICATION_HISTORY": "Historial de notificaciones",
    "HISTORY": "Historia",
    "DATE": "Fecha",
    "TYPE": "Tipo",
    "SYMBOL": "Símbolo",
    "TRANSACTION_REF": "Ref. de transacción",
    "NO_RECORD": "Sin registro",
    "POST_AD": "Publicar anuncio",
    "MINUTES": "Minutos",
    "PLEASE_MAKE_PAYMENT": "Realice un pago dentro de los 30:00 minutos, de lo contrario, el pedido se cancelará automáticamente.",
    "TRADE_LIMIT": "Límite comercial",
    "TRANSFER_PAYMENT": "Pago transferido",
    "RELEASE_ASSET": "Liberar activo",
    "DISPUTE_TRADE": "Comercio de disputas",
    "GENERAL": "General",
    "RLY_TO_ADMIN": "Responder al administrador",
    "CLOSE_THE_TICKET": "Satisfecho, Cerrar este ticket",
    "WANT_PAY": "Quiero pagar",
    "WANT_SELL": "quiero vender",
    "CANCEL_ORDER": "Cancelar orden",
    "WILL RECEIVE": "voy a recibir",
    "CALNEL_AD": "¿Está seguro de que desea cancelar el anuncio?",
    "IP_ADDRESS": "Dirección IP",
    "LOCATION": "Ubicación",
    "DEVICE": "Dispositivo",
    "FIAT_CRYPTO": "fiat/cripto",
    "TOTAL": "Total",
    "FIAT_WALLET": "Monedero Fiat",
    "CRYPTO_WALLET": "Monedero criptográfico",
    "SPOT": "LUGAR",
    "DERIVATIVE": "DERIVADO",
    "P2P": "P2P",
    "TRIGGER_CONDITION": "Condiciones de activación",
    "SELL_BITCOIN": "vender bitcoins",
    "BUY_BITCOIN": "comprar bitcoins",
    "BITCOIN": "Bitcoin",
    "ETHEREUM": "Etéreo",
    "TOTAL_STAKING": "Participación total",
    "RIPPLE": "Onda",
    "LITE_COION": "Litecoin",
    "TETHER_COIN": "TetherCoin",
    "FIND_COIN": "encontrar moneda",
    "WALLET": "Billetera",
    "FUND": "Fondo",
    "HOME": "Hogar",
    "FEES": "Honorarios",
    "FAQ": "FAQ",
    "TERMS_CONDITION": "Términos y condiciones",
    "TRANSFER_CONFIRM": "Transferir Confirmar",
    "STAKING_DELAILS": "Staking Details",
    "DAY_APY": "Día APY",
    "YESTERDAY_APY": "APY de Flexbile de ayer",
    "FLEXIBLE_INTERESTED": "Interés Flexible Por Mil",
    "PRIVACY_POLICY": "política de privacidad",
    "REG_EMAIL_MOBILE": "Su dirección de correo electrónico registrada/número de teléfono móvil",
    "DOWNLOAD_APP": "Descarga la aplicación desde",
    "MARKETS": "Mercado",
    "SPOT_MARKET": "Mercado al contado",
    "DERIVATIVE_MARKET": "Mercado derivado",
    "POLICY": "política",
    "SUBMIT_POST": "Enviar mensaje",
    "POST_ID": "ID del mensaje",
    "POSTED_ON": "Publicado en",
    "MARKET": "Mercado",
    "SUPPORT": "Apoyo",
    "CONTACT_US": "Contacta con nosotros",
    "CONTACT_PARAGRAPH_1": "Lorem Ipsum es simplemente un texto ficticio de la industria de la impresión y la composición tipográfica. Lorem Ipsum ha sido el texto ficticio estándar de la industria desde el siglo XVI.",
    "SUPPORT_TICKET": "Ticket de soporte",
    "NEWS_UPDATE_TITLE": "Recibe las últimas noticias y actualizaciones",
    "COPY_RIGHT": "©Copy right ©{{YEAR}} Todos los derechos reservados",
    "CONNECT_WITH_US": "Conecta con nosotros",
    "CONTACT": "Contacto",
    "PROFILE": "Perfil",
    "PERSONAL": "Personal",
    "MY_ACCOUNT": "Mi cuenta",
    "LOGOUT": "Cerrar sesión",
    "KYC": "KYC",
    "CONTACT_DETAILS": "Detalles de contacto",
    "BANK_ACCOUNT_DETAIL": "Detalles de cuenta bancaria",
    "PROFILE_DESCRIPTION1": "Sus datos personales deben introducirse estrictamente <b>como se indica en sus documentos oficiales de identificación y comprobante de domicilio</b> su dirección debe ser la indicada en el comprobante de domicilio que se requiere para verificar la cuenta. Si tiene alguna duda, escríbanos a <a>{{SUPPORT_MAIL}}</a>",
    "PROFILE_IMAGE": "Imagen de perfil",
    "FILE_FORMAT1": "jpg/jpeg/png máximo {{TAMAÑO}}",
    "CHANGE_EMAIL": "Cambiar e-mail",
    "PHONE_NUMBER": "Número de teléfono",
    "SEND_OTP": "Enviar OTP",
    "RESEND_OTP": "Reenviar OTP",
    "ENTER_OTP": "Ingresar OTP",
    "SEC": "Segundo",
    "UPDATE_PHONE_NO": "Actualizar número de teléfono",
    "ADD_ANOTHER_ACCOUNT": "Agregar otra cuenta",
    "WHY_COIN": "¿Por qué moneda?",
    "PARAGRAPH_1": "Lorem Ipsum es simplemente un texto ficticio de la industria de la impresión y la composición tipográfica. Lorem Ipsum ha sido el texto ficticio estándar de la industria desde el siglo XVI.",
    "GOLD": "Oro",
    "SPOT_TRADE": "Comercio al contado",
    "PARAGRAPH_2": "Lorem Ipsum es simplemente un texto ficticio de la industria de la impresión y la composición tipográfica. Lorem Ipsum ha sido el texto ficticio estándar de la industria desde el siglo XVI. Lorem Ipsum es simplemente texto ficticio de la industria de la impresión y la composición tipográfica.",
    "START_TRADE": "Comience a operar al contado",
    "DERIVATIVE_TRADE": "Comercio de derivados",
    "PARAGRAPH_3": "Lorem Ipsum es simplemente un texto ficticio de la industria de la impresión y la composición tipográfica. Lorem Ipsum ha sido el texto ficticio estándar de la industria desde el siglo XVI. Lorem Ipsum es simplemente texto ficticio de la industria de la impresión y la composición tipográfica.",
    "START_DERIVATIVE": "Comience a operar con derivados",
    "LIGHT": "Ligero",
    "MODE": "Modo",
    "DARK": "Oscuro",
    "COIN": "Moneda",
    "FEATURE": "Características",
    "FREE": "Gratis",
    "INSTANT": "Instantáneo",
    "TRADING": "Comercio",
    "FIAT_EASILY": "Fiat fácilmente",
    "PROMPT": "Inmediato",
    "KYC_VERIFY": "Verificar KYC",
    "SECURE_PLATFORM": "Nuestra plataforma segura",
    "2FA_PROTECT": "2FA Protegido",
    "PARAGRAPH_4": "Lorem Ipsum es simplemente un texto ficticio de la industria de la impresión y la composición tipográfica.",
    "PARAGRAPH_5": "Lorem Ipsum es simplemente un texto ficticio de la industria de la impresión y la composición tipográfica.",
    "PARAGRAPH_6": "Lorem Ipsum es simplemente texto ficticio de la industria de la impresión y la composición tipográfica.",
    "256-BIT": "Cifrado de 256 bits",
    "SECURE_WALLET": "Cartera asegurada",
    "JOIN_REFERRAL_HEADING": "Únase a nuestro programa de referencia",
    "EARN_MONEY": "Únase a nuestro programa de referencia",
    "JOIN_REFERRAL": "Únase a nuestra referencia",
    "GET_STARTED_HEADING": "Comience en unos minutos",
    "SIGN_UP": "Inscribirse",
    "ACCOUNT_ACCESS": "Acceso instantáneo a la cuenta en 2 minutos",
    "VERIFY": "Verificar",
    "PASSWORD_ADDRESS": "con pasaporte y comprobante de dirección",
    "TRADE": "Comercio",
    "PAIR_PROFIT": "con múltiples pares y beneficios contables",
    "TRADE_WITH": "Comercio con la aplicación móvil",
    "FOOTER_P": "Compatible con múltiples dispositivos, comience a operar con seguridad y comodidad.",
    "P2P_TRADING": "Comercio P2P",
    "PARAGRAPH_7": "Lorem Ipsum es simplemente un texto ficticio de la industria de la impresión y la composición tipográfica. Lorem Ipsum ha sido el estándar de la industria.",
    "I_WANT_TO": "Yo quiero",
    "ACCOUNT_FROZEN": "Los clics maliciosos conducirán a la congelación de la cuenta",
    "SELL": "Vender",
    "BUY": "Comprar",
    "PAY_WITH_TIME_LIMIT": "Pague dentro del límite de tiempo, de lo contrario, el pedido se cancelará automáticamente 1",
    "PAY_TRADE_TERMS": "Solo pague según los términos comerciales",
    "CONFIRM_PAYMENT": "Confirmar pago",
    "PAYMENT_MADE_SELLER": "Por favor, confirme que el pago se ha realizado al vendedor.",
    "SELECT_CRYPTO": "Seleccione Cripto",
    "AMOUNT": "Cantidad",
    "PAYMENT_TYPE": "Tipo de pago",
    "START_p2p": "Iniciar P2P",
    "EMAIL": "Correo electrónico",
    "NOT_VERIFIED": "No verificado",
    "ID_DOCUMENT": "Documento de identidad:",
    "ADDRESS_PROOF": "Verificación de domicilio:",
    "2FA_HEADING": "Autenticación en 2 pasos",
    "DIGITAL_CODE": "Ingrese el código de seis dígitos 2FA",
    "PASTE_CODE": "pegar código",
    "CONFIRM": "Confirmar",
    "SECURITY_SETTINGS": "Configuraciones de seguridad",
    "POST_NEW_AD": "Publicar nuevo anuncio",
    "P2P_STATISTICS": "Estadísticas P2P",
    "TOTAL_TRADES": "Operaciones totales",
    "FIRST_TRADE": "primer comercio",
    "TRADES": "vientos alisios",
    "COMPLETION": "terminación",
    "AVL": "Promedio",
    "CREATED_TIME": "Tiempo creado",
    "ORDER_ID": "Solicitar ID",
    "ATTACHMENT": "Adjunto",
    "MORE_ABOUT_SUPPORT": "Más sobre soporte",
    "LOGIN_ISSUE": "Problema de inicio de sesión",
    "DEPOSIT_ISSUE": "Emisión de depósito",
    "WITHDRAW_ISSUE": "Retirar problema",
    "ADMIN_RLY": "Respuesta del administrador en",
    "CONFIRM_MY_POST": "Confirmar mi publicación",
    "FILTER_BY": "Filtrado por",
    "REPLAY": "Responder",
    "BACK": "Atrás",
    "YOUR_API_KEY": "Tus claves API",
    "ID": "Identificación",
    "CREATED": "Creado",
    "PERMISSION": "permisos",
    "ACTION": "Acción",
    "STAKE_NOW": "Apuesta ahora",
    "LOADING": "Cargando...",
    "INVALID_URL": "URL invalida",
    "TRANSFER_BITCOIN": "Transferir bitcoins",
    "TICKET_ID": "Identificación de entradas",
    "POST_HISTORY": "Historial de publicaciones",
    "REF_KEY_LATER": "Nombre opcional para esta clave. Esto le ayudará a hacer referencia a esta clave más adelante.",
    "IP_RESTRICTION": "Restricciones de acceso IP:",
    "SECURITY_REASONS": "Por razones de seguridad, no revele su(s) clave(s) a terceros que no sean de confianza. Recomendamos que los usuarios agreguen sus direcciones IP a cada una de las claves API. Se puede agregar un máximo de cuatro (4) direcciones IP a cada clave. Para agregar varias direcciones IP, introdúzcalas por separado con comas entre ellas. por ejemplo, 192.168.1.1,192.168.1.2,192.168.1.3",
    "CREATE_API_KEY": " Crear clave API",
    "WRITE_SECRET_KEY": "Por favor, escriba la clave secreta.",
    "SOMEWHERE_SAFE": "¡Escríbelo en algún lugar seguro! La clave secreta ya no estará disponible una vez que abandone esta página.",
    "SECRET": "Secreto",
    "USD": "USD",
    "EURO": "Euro",
    "ADD_ANOTHER_ACCOUNT": "Agregar otra cuenta",
    "CRYPTO": "Cripto",
    "LAST_PRICE": "Ultimo precio",
    "FIAT": "Fíat",
    "YOUR_FULL_NAME": "Tu nombre completo",
    "SUBJECT": "Sujeto",
    "MESSAGE": "Mensaje",
    "CONTRACTS": "Contratos",
    "FILLED_TOTAL": "Lleno/Total",
    "FILLED_PRICE": "Precio completo",
    "FILLED": "Completado",
    "TRADE_HISTORY": "Historia comercial",
    "ORDER_PRICE": "Precio del pedido",
    "TRADE_TYPE": "Tipo de comercio",
    "ORDER_TYPE": "Tipo de orden",
    "ORDER_NO": "N º de pedido.",
    "Download_PDF": "Descargar PDF",
    "CANCEL_POST": "Cancelar mi publicación",
    "ORDER_TIME": "Tiempo de la orden",
    "NO_DATA": "Datos no disponibles",
    "SIZE": "Tamaño",
    "TAKER_PRICE": "Taker Profit",
    "STOP_LOSS": "Detener la pérdida de",
    "GTC": "GTC",
    "IOC": "IOC",
    "FOK": "FOK",
    "LONG": "Largo",
    "SHORT": "Corto",
    "VALUE": "Valor",
    "COST": "Costo",
    "TP_SL": "TP/SL",
    "24H_CHANGE": "Cambio 24h",
    "24_HIGH": "24h alta",
    "24_LOW": "Bajo 24h",
    "24H_VOLUME": "Volumen 24h",
    "QTY": "Cant.",
    "ORDER_BOOK": "Libro de pedidos",
    "PLACE_ORDER": "Realizar pedido",
    "LIMIT": "Límite",
    "PERPETUAL": "Perpetuo",
    "BTC_PAIRS": "Pares BTC",
    "ETH_PAIRS": "Pares ETH",
    "PAIR": "Par",
    "MARKET_CLOSE": "Cierre del mercado",
    "CLOSE_QTY": "Cerrar Cantidad USD",
    "5CONTRACT_CLOSE": "5 Contrato(s) se cerrarán al último precio negociado y su ganancia esperada será ",
    "INCLUSIVE": "incluidos los costos de colsing estimados",
    "FEES": "Tarifa",
    "FEE_CONTENT": "Lorem Ipsum es simplemente un texto ficticio de la industria de la impresión y la composición tipográfica. Lorem Ipsum ha sido el texto ficticio estándar de la industria desde el 1500s",
    "ENTYRY_PRICE": "Precio de entrada",
    "IDENTITY_VERIFICATION": "Verificación de identidad",
    "LIQ_PRICE": "líquido Precio",
    "POSITION_MARGIN": "Margen de posición",
    "UNREALIZED": "PyG no realizado (%)",
    "DAILY_REALIZED": "Pérdidas y ganancias diarias realizadas",
    "TRAILING_STOP": "Parada final",
    "CLOSED_BY": "Cerca a",
    "RECENT_TRADE": "Operaciones recientes",
    "TIME": "Tiempo",
    "STOP_PRICE": "Precio de parada",
    "SPOT_PRICE": "Precio de contado",
    "LAST_PRICE": "Ultimo precio",
    "LIMIT_PRICE": "Precio límite",
    "POSITION": "Posición",
    "EDIT_MY_POST": "Editar mi publicación",
    "CRYPTO_CURRENCY": "criptomoneda",
    "SELECT_COIN": "Seleccionar moneda",
    "QUANTITY": "Cantidad",
    "PRICE_CURRENCY": "Precio Moneda",
    "CURRENCY_MARKET_PRICE": "Precio de mercado actual",
    "PRICE_LIMIT_MARKET": "Límite de precio desde",
    "SHOW_MY_POST": "Mostrar mi publicación hasta",
    "PERFERRED_PAYMENTS": "Pagos Preferidos",
    "PAYMENT_TO_MADE": "Pago a realizar",
    "PRICE_LIMIT_FROM": "Límite de precio desde",
    "PRICE_LIMIT_TO": "Límite de precio hasta",
    "SELECT_PAYMENT": "Seleccione Pago",
    "DURATION_DAYS": "Duración (Días)",
    "I_AGREE_ESCROW": "Acepto depositar en custodia mis fondos criptográficos y acepto el",
    "DURATION": "Duración",
    "ALL": "Todo",
    "ETH": "ETH",
    "APY": "APY",
    "XRB": "XRB",
    "SPOT_WALLET": "Monedero al contado",
    "DERIVATIVE_WALLET": "Cartera de derivados",
    "P2P_WALLET": "Monedero P2P",
    "FORM": "De",
    "QUANTITY": "Cantidad",
    "FLEX_STAKING": "Replanteo flexible",
    "LOCKED_STAKING": "Replanteo bloqueado",
    "MIN_SUBSCRIPTION": "Suscripción mínima",
    "SUBSCRIPTION": "suscripción",
    "MAX_SUBSCRIPTION": "Suscripción máxima",
    "STAKING_HISTORY": "Historial de apuestas",
    "REDEMPTION_PERIOD": "Período de redención",
    "REDEMPTION": "Redención",
    "READ_AND_AGREE": "He leído y acepto las",
    "STAKING_TERMS": "Staking Terms",
    "INTEREST": "Interés",
    "STAKING": "replanteo",
    "BTC/USD": "BTC/USD",
    "DIGITAL_ASSETS": "Activos digitales líderes",
    "ONE_PLATFORM": "Bajo una plataforma",
    "MATKET_PRICE": "Precio de mercado",
    "TRADE": "Comercio",
    "SPOT_ORDERS": "Órdenes al contado",
    "DERIVATIVE_ORDERS": "Órdenes Derivadas",
    "P2P_ORDERS": "Pedidos P2P",
    "STAKING_ORDERS": "Órdenes de replanteo",
    "MANA": "MANA",
    "BTC": "BTC",
    "DAYS": "Días",
    "TOTAL_VOLUME": "Volumen total",
    "TOTAL_TRADE_COMPLETE": "Comercio total completo",
    "ENTER_AMOUNT": "Ingrese la cantidad",
    "VIEW_CHAT": "Ver/Chatear",
    "PAY_BY": "Pagado por",
    "TOTAL": "Total",
    "ALL_PAYMENT": "todo el pago",
    "AVAILABLE": "Disponible",
    "SELLER_PAYMENT_TYPE": "Tipo de pago del vendedor",
    "PAYMENT_TIME_LIMIT": "Límite de tiempo de pago",
    "TYPE": "Tipo",
    "RECENT_POST": "Publicación reciente",
    "TO": "To",
    "DEPOSIT_CURRENCY": "Moneda de depósito",
    "YOUR": "Su",
    "WALLET_ADDRESS": "Dirección de la billetera",
    "SCAN_QR_CODE": "Escanear código QR",
    "MEMO_TAG": "Etiqueta de nota",
    "TOKEN_PURCHASE_EXPIRED": "Compra de token caducada",
    "MINIMUM_QUANTITY": "La cantidad es mayor que la cantidad mínima",
    "TOKEN_PURCHASE_SUCCESS": "Éxito en la compra de tokens",
    "INSUFFICIENT_BALANCE": "Saldo insuficiente",
    "NOT_AVAILABLE_BALANCE": "Saldo no disponible",
    "SOMETHING_WRONG": "Ocurre algo",
    "INVALID_COIN": "Moneda inválida",
    "PRICE_REQUIRED": "el precio es requerido",
    "24H_CHANGE": "Cambio 24H",
    "24H_HIGH": "24H alto",
    "24H_TURNOVE": "Rotación 24H",
    "24H_TURNOVE": "24H Turnove",
    "CURRENT_PRICE": "Precio actual",
    "TRIGGER_PRICE": "Precio de activación",
    "LAST": "Último",
    "INDEX": "Índice",
    "MARK": "Marca",
    "ORDER_VALUE": "Valor del pedido",
    "ORDER_COST": "Costo de la orden",
    "SEND_ORDER": "Enviar orden",
    "MARGIN_IMPACT": "Impacto de margen",
    "LEVERAGE": "aprovechar",
    "RISK_LIMIT": "Límite de riesgo",
    "CLOSED_P&L": "PyG cerrado",
    "FILLED_ORDER": "Pedido completado",
    "ASSET_OVERVIEW": "Resumen de activos",
    "CLOSING_DIRECTION": "dirección de cierre",
    "EXIT_PRICE": "Precio de salida",
    "CLOSED_P&L(EXCL FEE)": "Pérdidas y ganancias cerradas (sin cargo)",
    "EXIT_TYPE": "Tipo de salida",
    "CLOSE_POSITION_TIME": "Tiempo de posición de cierre",
    "LOADMORE": "Carga más",
    "FILLED_REMAINING": "Lleno/Restante",
    "PRICE_DISTANCE": "Precio (Distancia)",
    "EXEC_PRICE": "Precio ejecutivo",
    "NEW": "Nuevo",
    "PARTIAL": "Parcial",
    "CONDITIONAL": "Condicional",
    "EQUITY": "Equidad",
    "AVAILABLE_BALANCE": "Saldo disponible",
    "ORDER_MARGIN": "Margen de pedido",
    "TOTAL_REALIZED_P&L": "Pérdidas y ganancias totales realizadas",
    "UNREALIZED_P&L": "Pérdidas y ganancias no realizadas",
    "ORDER_DETAILS": "Detalles del pedido",
    "TAKE_PROFIT_USD": "Tomar ganancias USD",
    "STOP_LOSS_USD": "Detener la pérdida USD",
    "POSITION_LEVERAGE": "Apalancamiento de posición",
    "TAKE_PROFIT": "Saca provecho",
    "STOP_LOSS": "Detener la pérdida de",
    "MARK_PRICE": "precio_marca",
    "ESTIMATED_LIQ_PRICE": "licuado estimado Precio",
    "MARKPRICE_TO_LIQPRICE_DISTANCE": "Precio de marca a liq. Distancia de precio",
    "TIME_IN_FORCE": "Tiempo en vigor",
    "NUMBER_OF_POSITION_CLOSED": "Número de Posiciones Cerradas",
    "CLOSE_PRICE": "Precio de cierre",
    "TRAILING_STOP_USD": "Stop dinámico USD",
    "IF_OPTIMAL_LAST_TRADED_PRICE_RETRACES_BY": "Si el último precio negociado óptimo retrocede por",
    "USD_IT_WILL_TRIGGER_STOP_LOSS_WITH_MARKET_ORDER": "USD, activará Stop-Loss con Market Order",
    "ARE_YOU_CONFIRM_TO_CANCEL_THIS_ORDER": "¿Confirmas cancelar este pedido?",
    "ORDER": "orden",
    "APPLY_TO_BECOME_A_TRADER": "Aplicar para convertirse en un comerciante",
    "CONDITIONS": "Condiciones",
    "CUMULATIVE_DEPOSIT": "Depósito acumulado ≥",
    "USDT": "USDT",
    "OK": "Ok",
    "REASON_AFFILIATE_PROGRAM": "Razones para unirse al programa de afiliados de Bell Exchnage",
    "ATTRACTIVE_COMMISSION_CRYPTOCURRENCIES": "Atractivas comisiones en criptomonedas",
    "YOU_WILL_REFFERRAL_COMMISSION": "Obtendrá una comisión de referencia del 10 % por cada venta (importe neto de la venta, sin IVA ni gastos de envío). Pagos mensuales en criptomonedas.",
    "DEDICATED_SUPPORT": "Soporte dedicado",
    "OUR_AFFILIATE_TEAM": "Nuestro equipo de afiliados está aquí para ayudarlo de manera proactiva a desarrollar su campaña con éxito y brindarle los recursos y las respuestas que necesita.",
    "REAL_TIME_REPORTS": "Informes en tiempo real",
    "GET_ONLINE": "Obtenga acceso en línea las 24 horas, los 7 días de la semana a su cuenta de afiliado para administrar sus campañas. Opción de seguimiento para ayudarlo a optimizar las fuentes de tráfico y las conversiones.",
    "HIGH_IMPACT_PROMO_MATERAILS": "Materiales promocionales de alto impacto",
    "OUR_AFFILIATE_PROGRAM": "Nuestro programa de afiliados le otorga acceso a banners creativos, enlaces, videos. Si necesita algún activo adicional, ¡no dude en preguntar!",
    "BELL_EXCHANGES_AFFILIATE_PROGRAM": "El programa de afiliados de Bell Exchnage se encuentra entre los mejores programas de afiliados de criptomonedas. Publique su enlace de afiliado y obtenga recompensas en monedas criptográficas por sus referencias.",
    "THIS_AFFILIATE_PROGRAM": "Este programa de afiliados está destinado a bloggers, YouTubers, criptoempresas... o cualquier persona que tenga una red y una voz en el criptomundo y tenga como objetivo educar sobre la importancia de la seguridad.",
    "REGISTER_NOW_AND_GET": "Regístrese ahora y obtenga un enlace de afiliado para su sitio web, canal, boletín informativo... ¡y gane una comisión del 10 % en criptomonedas en todas sus referencias!",
    "BY_SIGNING_AFFILIATE_PROGRAM": "Al registrarse en nuestro Programa de afiliados, acepta los Términos de uso y acepta que podemos procesar sus datos personales de acuerdo con nuestra Política de privacidad y Política de cookies.",
    "RISK_DISCLOSURE_STATEMENT": "Risk Disclosure Statement",
    "DELIVER_OUR_SERVICE": "Usamos la información recopilada para brindar nuestros servicios y verificar la identidad del usuario. Usamos la dirección IP y los identificadores únicos almacenados en las cookies de su dispositivo para ayudarnos a autenticar su identidad y brindar nuestro servicio. Dadas nuestras obligaciones legales y los requisitos del sistema, no podemos brindarle servicios sin datos como identificación, información de contacto e información relacionada con transacciones.",
    "TO_PROTECT_OUR_USERS": "Para proteger a nuestros usuarios",
    "PROTECT_OUR_PLANS": "Usamos la información recopilada para proteger nuestra plataforma, las cuentas de los usuarios y los archivos. Usamos direcciones IP y datos de cookies para protegernos contra el abuso automatizado, como spam, phishing y ataques de denegación de servicio distribuido (DDoS). Analizamos la actividad comercial con el objetivo de detectar comportamientos sospechosos de manera temprana para evitar posibles fraudes y pérdidas de fondos a manos de malos actores.",
    "LEGAL_AND_REGULATORY": "Para cumplir con los requisitos legales y reglamentarios",
    "PRIVACY_AND_SECURITY": "El respeto por la privacidad y la seguridad de los datos que almacena con Bell Exchnage informa nuestro enfoque para cumplir con las regulaciones, las solicitudes gubernamentales y las consultas generadas por los usuarios. No divulgaremos ni proporcionaremos ninguna información personal a fuentes de terceros sin la revisión de nuestro equipo de casos legales y/o el consentimiento previo del usuario.",
    "TO_MEASURE_SITE_PERFORMANCE": "Para medir el rendimiento del sitio",
    "MEASURE_AND_ANALYSE": "Medimos y analizamos activamente los datos para comprender cómo se utilizan nuestros servicios. Nuestro equipo de operaciones lleva a cabo esta actividad de revisión para mejorar continuamente el rendimiento de nuestra plataforma y resolver problemas con la experiencia del usuario.",
    "TO_COMMUNICATE_WITH_YOU": "Para comunicarme contigo",
    "PERSONAL_INFORMATION_COLLECTED": "Usamos la información personal recopilada, como una dirección de correo electrónico para interactuar con los usuarios directamente cuando brindamos atención al cliente en un ticket o para mantenerlo informado sobre inicios de sesión, transacciones y seguridad. Sin procesar su información personal para confirmar cada comunicación, no podremos responder a sus solicitudes, preguntas y consultas enviadas. Todas las comunicaciones directas se mantienen confidenciales y se revisan internamente para verificar su precisión.",
    "ENFORCE_OUR_TERMS": "Para hacer cumplir nuestros Términos de uso y otros acuerdoss",
    "CUSTOMER": "Es muy importante para nosotros y nuestros clientes que revisemos, investiguemos y prevengamos continuamente cualquier actividad potencialmente prohibida o ilegal que viole nuestros Términos de servicio. Para el beneficio de toda nuestra base de usuarios, hacemos cumplir cuidadosamente nuestros acuerdos con terceros e investigamos activamente las violaciones de nuestros Términos de uso publicados. Bell Exchnage se reserva el derecho de cancelar la prestación del servicio a cualquier usuario que se encuentre participando en actividades que violen nuestros Términos de uso.",
    "PROTECT_USER_DATA": "CÓMO PROTEGE Bell Exchnage LOS DATOS DEL USUARIO",
    "SECURITY_BELLEXCHANGE": "Bell Exchnage ha implementado una serie de medidas de seguridad para garantizar que su información no se pierda, abuse o altere. Nuestras medidas de seguridad de datos incluyen, entre otras: Escaneo PCI, tecnología de encriptación en capas de sockets seguros, seudonimización, restricciones de acceso a datos internos y estrictos controles de acceso físico a edificios y archivos. Tenga en cuenta que es imposible garantizar una transmisión de datos 100% segura a través de Internet ni un método de almacenamiento electrónico. Como tal, le solicitamos que comprenda la responsabilidad de tomar precauciones de seguridad de forma independiente para proteger su propia información personal.",
    "FROM_COIN": "De la moneda",
    "SA": "Sá.",
    "TO_COIN": "Acuñar",
    "AMOUNT": "Cantidad",
    "ALL_AMOUNT": "Todo el monto",
    "SINGLE_LIMIT": "Límite único",
    "24_HOURS_LIMIT": "Límite de 24 horas",
    "TRADE_FEES": "Tarifas comerciales",
    "RECEIVING_AMOUNT": "Cantidad de recepción",
    "INCLUDING_FEE": "incluida la tarifa 0.5%",
    "WALLET_BALANCE": "Saldo de la cartera",
    "EXCHANGE_HISTORY": "Historial de intercambio",
    "AMOUNT_INPUTED": "Importe de entrada",
    "TRANSACTION_ID": "ID de transacción",
    "AMOUNT_RECEIVED": "Cantidad recibida",
    "BTC_DEPOSIT": "Depósito BTC",
    "BTC_WITHDRAW": "Retiro de BTC",
    "NOTES1": "No deposite ningún activo que no sea BTC en la dirección anterior. De lo contrario, los activos se perderán de forma permanente.",
    "NOTES2": "Proporcione la dirección correcta de la billetera comercial para garantizar un depósito.",
    "NOTES3":  "Su depósito se reflejará dentro de su cuenta después de recibir 1 confirmación en la cadena de bloques.",
    "RECEIVER_WALLET_ADDRESS": "Dirección de la billetera del destinatario",
    "ADD_WALLET": "Agregar billetera",
    "BTC_AMOUNT": "Cantidad de BTC",
    "SEND_ALL": "Envia todo",
    "FINAL_AMOUNT": "Cantidad final",
    "ENTER_AUTHENTICATION_CODE": "Ingrese el código de autenticación 2FA",
    "MINUMUM_WITHDRAW": "Retiro mínimo",
    "INSIDE_YOUR_ACCOUNT": "Su retiro se reflejará dentro de su cuenta después de recibir 1 confirmación en la cadena de bloques.",
    "DEPOSIT_HISTORY": "Historial de depósitos",
    "START_DATE": "Fecha de inicio",
    "END_DATE": "Fecha final",
    "CSV": "Descargar.csv",
    "NO_RECORDS": "No hay registros",
    "BEGINNERS_GUIDE": "Guía para principiantes",
    "ACCOUNT_REGISTRATION": "Registro de cuenta",
    "REGISTER_REAL_ACCOUNT": "Registrar una cuenta real",
    "REGISTER_TESNET_ACCOUNT": "Registrar una cuenta de red de prueba",
    "FORGOT_PASSWORD_RETRIEVE_PASSWORD": "Olvidé mi contraseña/Cómo recuperar la contraseña",
    "ACCOUNT_AND_SECURITY": "Cuenta y Seguridad",
    "BYBIT_INSTALL": "¿Cómo instalar la APP Bybit en tu Smartphone?",
    "ACCOUNT_OVERVIEW": "Descripción de cuenta",
    "ACCOUNT_DETAILS": "Detalle de la cuentas",
    "SECURITY": "Seguridad",
    "SET_CHANGE_EMAIL": "Establecer/Cambiar correo electrónico",
    "SET_CHANGE_GOOGLE_AITHENTICATION": "Establecer/cambiar la autenticación de Google",
    "SET_CHANGE_MOBILE_PHONE": "Configurar/Cambiar Teléfono Móvil",
    "API_MANAGEMENT": "Gestión de API",
    "ADD_NEW_API_KEY": "Agregar nueva clave API",
    "SETTINGS": "Ajustes",
    "DEPOSIT_AND_WITHDRAWAL": "Depósito y retiro",
    "BYBIT_DEPOSIT_OR_WITHDRAW": "¿Cómo hacer un depósito o retiro en Bybit?",
    "WITHDRAWAL_REQUEST": "¿Cómo enviar una solicitud de retiro?",
    "ADD_YOUR_WALLET_ADDRESS": "¿Cómo agregar la dirección de su billetera?",
    "PLACING_ORDER": "Ordenando",
    "DIFFERENT_RERERENCE_PRICE": "¿Cómo establecer un precio de referencia diferente para activar Take Profit/Stop Loss?",
    "ORDER_PLACING_OVERVIEW": "Descripción general de la colocación de pedidos",
    "MARKET_ORDER": "orden de mercado",
    "LIMIT_ORDER": "orden de límite",
    "CONDITIONAL_ORDER": "orden condicional",
    "PARTIAL_CLOSE": "¿Cómo cerrar parcialmente su posición abierta?",
    "TAKE_PROFIT_STOP_LOSS": "Establecer Take Profit/Stop Loss",
    "ACTIVE_ORDER": "orden activa",
    "POSITION_CLOSING": "Cierre de posición",
    "ADJUST_TAKE_PROFIT": "Establecer o ajustar Take Profit/Stop Loss",
    "ADJUST_TRAILING_STOP": "Set or Adjust Trailing Stop",
    "MORE": "Más",
    "RECENT_BLOGS": "Blogs recientes",
    "CATEGORY": "Categoría",
    "BLOG": "Blog",
    "CATEGORIES": "Categorías",
    "QUESTIONNAIRE": "Cuestionario",
    "CRYPTO_DERIVATES_TRADING": "1. ¿Cuándo comenzó a operar con derivados de criptomonedas?",
    "LAST_3_MONTH": "últimos 3 meses",
    "LAST_6_MONTH": "últimos 6 meses",
    "MORE_THAN_1_YEAR": "Más de 1 año",
    "FEWS_DAYS": "Pocos dias",
    "CRYPTO_DERIVATIVES": "2. ¿Cuál es su objetivo principal al operar con derivados de criptomonedas?",
    "LONG_TERM": "A largo plazo",
    "SHORT_TERM": "Corto plazo",
    "SWING_TRADING": "comercio oscilante",
    "INTRADAY": "intradía",
    "BONUS_REWARDS": "Recompensas de bonificación",
    "RISK_FREE_TRADING": "Negociación sin riesgos con bonificación",
    "FACEBOOK": "Facebook",
    "TWITTER": "Gorjeo",
    "TELEGRAM": "Telegrama",
    "CLAIM_NOW": "Reclama ahora",
    "BONUS": "Primo",
    "DEPOSIT_AMOUNT": "Cantidad del depósito",
    "REFERREDUSER": "Usuarioreferido",
    "CLOSE_TIME": "cerrar tim",
    "UNREALIZED_P&l_MARK_PRICE": "P&L no realizado (precio de marca)",
    "UNREALIZED_P&L_LTP": "Pérdidas y ganancias no realizadas (LTP)",
    "COOKIES_POLICY": "Política de cookies",
    "AUTOMATICALLY_UNFOLLOW": "Dejar de seguir automáticamente",
    "WHEN_TRADER_DOES_NOT_OPEN": "Cuando el comerciante no abre posiciones para",
    "CONSECUTIVE_HOURS": "horas consecutivas, la copia comercial se cancelará automáticamente",
    "COPY_TRADE_USDT": "Copiar capital comercial (USDT)",
    "COPY_TRADE_DATA": "Copiar datos comerciales",
    "COPY_TRADE_NET_PROFIT": "Copie el beneficio neto comercial (USDT)",
    "COPY_TRADE": "Copiar comercio",
    "MY_TRADER": "mi comerciante",
    "FOLLOW_INFORMATION": "Seguir Información",
    "DETAILS": "Detalles",
    "SUMMARY": "Resumen",
    "TRADER": "Comerciante",
    "FUTURES": "Futuros",
    "MAX_AMOUNT_BALANCE": "máx. / Importe del saldo (USDT)",
    "EARNING_MONEY_NOW": "Ganar dinero ahora",
    "CURRENTLY_FOLLOWING": "Actualmente siguiendo",
    "PEOPLE": "gente",
    "FOLLOW": "Seguir",
    "TRADER_DETAILS": "Detalles del comerciante",
    "PL_RATIO": "Relación P/G",
    "TRADING_ORDER": "Orden comercial",
    "ACCUM_FOLLOWERS": "Seguidor de acumulacións",
    "PROFIT_USDT": "Beneficio (USDT)",
    "GAIN": "Ganar",
    "LOSS": "Pérdida",
    "COPY_TRADE_ORDER": "Copiar orden comercial",
    "FOLLOWERS": "Seguidores",
    "FULL": "Lleno",
    "COPY_TRADE": "Copiar comercio",
    "LEVERAGE_MODE": "Modo de apalancamiento",
    "FUTURE_SETTING": "Configuración futura",
    "COPY_TRADING_AMOUNT": "Copiar monto comercial",
    "FIXED_AMOUNT": "Cantidad fija",
    "FIXED_RATIO": "Proporción fija",
    "STOP_LOSS_RATIO_OPTIONAL": "Relación de stop loss (opcional)",
    "TAKE_PROFIT_RATIO_OPTIONAL": "Tasa de Take Profit (opcional)",
    "MAXIMUM_POSITION": "Posición máxima",
    "CONT": "cont",
    "ADVANCED_SETTING": "Configuración avanzada",
    "STOP_LOSS_RATIO": "Ratio de stop-loss",
    "FOLLOW_THE_TRADER": "Sigue al comerciante",
    "PROFIT_SHARE_RATIO": "Relación de participación en las ganancias 10%",
    "UNFOLLOW": "Dejar de seguir",
    "ONLY_SELECTED_PAIRS": "Notas: Solo se copiarán los pares seleccionados",
    "UNIFIED_SETTING": "Configuración unificada",
    "RESPECTIVE_SETTING": "Configuración respectiva",
    "NEXT": "Próximo",
    "FOLLOWING_THIS_TRADER": "Si cancela el segumiento de este comerciante, las órdenes que se han colocado para seguir no se cerrarán de inmediato y aún seguirán las acciones de cierre del comerciante. También puede cerrar la posición manualmente",
    "THINK_AGAIN": "Piensa otra vez",
    "LTC": "LTC",
    "BCH": "BCH",
    "TRANSFER_AMOUNT": "Monto de la transferencia",
    "MAX": "máx.",
    "CONFIRM_COPY_TRADE_INFORMATION": "Confirmar copia de información comercial",
    "FOLLOW_MODE": "Modo de seguimiento",
    "FIXED": "Fijo",
    "TAKE_PROFIT_RATIO": "Relación de toma de ganancias",
    "CRYPTO_COPY_TRADING": "Comercio de copias criptográficas",
    "TRADER_LIST": "Lista de comerciantes",
    "HOURLY_DATA_UPDATE": "Actualización de datos por hora",
    "BECOME_TRADER": "Conviértase en comerciante",
    "TRADER_PROFILE": "Perfil de comerciante",
    "RANKING": "Clasificación",
    "TOTAL_PL": "Pérdidas/ganancias totales",
    "CUMULATIVE_RETURN": "Retorno Acumulativo",
    "ACCUM_TRANSACTIONS": "Transacciones acumuladas",
    "TOTAL_PL_RATIO": "Relación total de pérdidas y ganancias",
    "WIN_RATE": "Ratio de victorias",
    "TOTAL_PL_USDT": "Pérdidas por ganancias totales (USDT)",
    "ACCUM_PL_RATIO": "Ratio P/L acumulado",
    "THERE_IS_NO_TRADERS": "No hay comerciantes",
    "VIEW_MORE": "Ver más",
    "FAQ": "FAQ",
    "BELL EXCHANGE FEE SCHEDULE": "LISTA DE TARIFAS DE INTERCAMBIO DE BELL",
    "BUY_OR_SELL_POPULAR_DIGITAL": "Compre y venda monedas digitales populares, realice un seguimiento de ellas en un solo lugar. Invierta en criptomoneda mensualmente.",
    "LOREM_IPSUM": "lorem ipsum",
    "HELPLOREM": "ayuda@lorem.com",
    "RELATED_LINKS": "enlaces relacionados",
    "ABOUT_US": "Sobre nosotros",
    "SIGNUP_TO_OUR_NEWSLETTER": "Suscríbase a nuestro boletín de noticias",
    "COPY_RIGHT": "Derechos de autor",
    "GUIDES": "Guías",
    "RISK": "Riesgo",
    "ANTI_SPAM_POLICY": "Política antispam",
    "SUBSCRIBE_FOR_LATEST_UPDATES": "Suscríbete para las últimas actualizaciones",
    "FOLLOW_US": "Follow Us",
    "LOCAL_COIN_ALL_RIGTHS_RESERVED": "2020 © Lokal Coin All Rights Reserved",
    "DISCORD": "Discordia",
    "TEAM": "Equipo",
    "AML": "AML",
    "CFT": "CFT",
    "KYC_REGISTRATION": "Registro KYC",
    "REGISTER_ON": "Registrado en",
    "NOT_REGISTER_USER": "¿No eres usuario registrado?",
    "REGISTER_NOW": "¡Regístrate ahora!",
    "LOST_YOUR_TOKEN_PLEASE": "Si ha perdido su token 2FA, por favor",
    "LOST_2FA_DEVICE": "con el asunto Dispositivo 2FA perdido. El soporte comercial lo ayudará a recuperar su cuenta.",
    "OTP_CODE": "Código OTP",
    "NO_SMS_RECEIVED": "¿No ha recibido ningún SMS?",
    "RESEND_OTP": "reenviar otp",
    "HELLO_HAFIZ": "Hola hafiz",
    "NOT_YET_REGISTERED": "Aún no registrado",
    "PROOF_IDENTITY": "Prueba de identidad",
    "DOCUMENT_TYPE": "Tipo de Documento",
    "ID_NUMBER": "Número de identificación",
    "UPLOAD_NEW_IMAGE": "Sube una nueva imagen.",
    "PLEASE_UPLOAD_CLEAR": "Cargue una copia clara y legible de cualquiera de los siguientes",
    "MAX_3MB_IMG": "Las imágenes deben ser /.jpg/.png/.jpeg max 3MB",
    "SELECT_DOCUMENT_TYPE": "Seleccionar tipo de documento",
    "PROOF_OF_ADDRESS": "Comprobante de domicilio",
    "FRONT_SIDE_NAME_AND_ADDRESS": "Anverso (Nombre y Dirección)",
    "SELF_PHOTO": "Autofoto",
    "WAITING_FOR_APPROVAL": "A la espera de la aprobación",
    "NOTE": "Nota",
    "PLEASE_UPLOAD_CLEAR_PHOTO_YOURSELF": "Sube una foto tuya clara",
    "WORLD_CLASS_TRADING": "Plataforma de comercio de clase mundial",
    "LOREM_PARAGRAPH": "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Rerum a corporis veniam necessitatibus dolores. Quibusdam ea, possimus aliquid impedit dolorum dicta pariatur odit. Mollitia repellendus saepe commodi iusto porro quia.",
    "ETHBTC": "ETHBTC",
    "TOP_CRYPTO_ASSETS": "Principales criptoactivos",
    "LOREM_PARAGRAPH2": "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Rerum a corporis veniam necessitatibus dolores. Quibusdam ea, possimus aliquid impedit dolorum dicta pariatur odit. Mollitia repellendus saepe commodi i",
    "SIMPLE KYC REQUIRED": "SE REQUIERE KYC SENCILLO",
    "START_EARNING_TODAY": "Comience a ganar hoy",
    "EXECUTED_ORDERSPE_SECOND": "Segundo orden ejecutado",
    "INTEGRATED_LIQUIDITY_PROVIDERS": "Proveedores de liquidez integrados",
    "AVERAGE_ORDER_EXECUTION_SPEED": "Velocidad media de ejecución de órdenes",
    "CLASS_TRADING_PLATFORM": "Nuestra plataforma de comercio de clase mundial",
    "LOREM_PARAGRAPH3": "rem ipsum, dolor sit amet consectetur adipisicing elit. Rerum a corporis veniam necessitatibus dolores. Quibusda",
    "EASY_ACCESS": "Fácil acceso",
    "QUICK_BALANCE_CHECK": "Consulta rápida de saldo",
    "PROFIT_BOOKING": "Reserva de ganancias",
    "MULTI_WINDOW_PLATFORM": "Plataforma de ventanas múltiples",
    "LOREM_IPSUM": "lorem ipsum",
    "FAST_AND_STABLE": "Rápido y estable",
    "LOREUM_PARAGRAPH4": "dolor sit amet consectetur adipisicing elit. Rerum a corporis veniam necessitatibus dolore",
    "DYNAMIC_CRYPTOCURRENCY": "Comercio dinámico de criptomonedas que lo coloca en el asiento del conductor",
    "TRADING_WITH": "Comerciar con",
    "BELL_EXCHANGE": "Bell Exchnage",
    "LOREM_PARAGRAPH5": "Ejecute transacciones en microsegundos sin comprometer la confiabilidad o la seguridad. Nuestra plataforma de negociación de latencia tiene todas las herramientas que necesita para aprovechar al máximo las oportunidades del mercado, cuando y donde",
    "OPEN_FREE_ACCOUNT": "Abra una cuenta gratis",
    "BEST_IN_CLASS": "La mejor plataforma de negociación de su clase",
    "LOREM_OUR_FAST": "Nuestra plataforma de negociación rápida, segura y confiable es adecuada tanto para operadores principiantes como profesionales",
    "LOW_TRADING_FEE": "Tarifa de negociación baja",
    "ENJOY_TIGHT": "Disfrute de márgenes ajustados y tasas de comisión bajas al operar con BTC, ETH, LTC, XRP y BCH con un apalancamiento de 1:100",
    "FAST_AND_STABLE": "Rápido y estable",
    "MULTIPLE_CHART_TYPES": "Múltiples tipos de gráficos, variedad de indicadores y herramientas de dibujo, así como la capacidad de operar directamente desde el gráfico",
    "RELIABILITY": "Fiabilidad",
    "BLOCKCHAIN_PLATFORM": "Plataforma de cadena de bloques",
    "PROJECT_INTRODUCTION": "Introducción al proyecto",
    "INDUSTRY": "Industria",
    "WEBSITE": "Sitio web",
    "TOKEN_DETAILS": "Detalles del token",
    "TOKEN_SALE_START_DATE": "Fecha de inicio de la venta de tokens",
    "TOKEN_LAUNCH_PRICE": "Precio de lanzamiento del token",
    "MINIMUM_PURCHASE_AMOUNT": "Importe mínimo de compra",
    "ACCEPTED_CURRENCIES": "Monedas Aceptadas",
    "DISCOUNT": "Descuento",
    "TAKE_AVAILABLE_TO_SALE": "Token disponible para la venta",
    "TOKEN_MAX_SUPPLY": "Suministro máximo de fichas",
    "DOCUMENTS": "Documents",
    "I_AM_NOT_CITIZEN": "No soy ciudadano y/o residente de países de la lista negra del GAFI y/o países no reconocidos por Estonia/UE.",
    "COMPLETE_KYC": "KYC completo",
    "MY_TRADES": "Mis operaciones",
    "TOTAL_AMOUNT": "Cantidad total",
    "SOCIAL_MEDIA": "Medios de comunicación social",
    "BRIJESH": "brijesh gadiali",
    "CEO": "CEO",
    "WHITE_PAPER": "Papel blanco",
    "PRESENTATION": "Presentación",
    "EXECUTIVE_SUMMARY": "Resumen ejecutivo",
    "YOU_PAY": "Tu pagas",
    "BELL EXCHANGE LAUNCH PROGRAM": "PROGRAMA DE LANZAMIENTO DE BELL EXCHANGE",
    "TOKEN_LAUNCH_PLATFORM": "Una plataforma de lanzamiento de tokens para proyectos transformadores",
    "BUY_TOKEN": "Comprar token",
    "HOW_WE_SUPPORT": "Cómo apoyamos",
    "TOKEN_LAUNCH": "Lanzamiento de fichas",
    "LONG_ESTABLISHED": "Es un hecho establecido desde hace mucho tiempo que un lector se distraerá con el contenido legible de una página cuando mire su diseño. El punto de usar Lorem Ipsum es que tiene una distribución de letras más o menos normal, a diferencia de usar.",
    "SET_UP": "Configuración",
    "PLATFORM_INTEGRATION": "Integración de plataforma",
    "LAUNCH": "Lanzamiento",
    "PROJECT_DEVELOPMENT": "Desarrollo del proyecto",
    "SOCIAL_MEDIA_SETUP": "Configuración de redes sociales",
    "FILLING_INFORMATION_ABOUT_PROJECT": "Relleno de información sobre el proyecto.",
    "ESTABLISHMENT1": "Establecimiento de una plataforma de juegos en línea completamente desarrollada y lista para funcionar",
    "ESTABLISHMENT2": "Establecimiento de un casino en línea completamente desarrollado y listo para funcionar",
    "ESTABLISHMENT3": "Abastecimiento y obtención de las licencias necesarias",
    "ESTABLISHMENT4": "Desarrollo de programas de caridad y relaciones públicas para todos los continentes.",
    "ESTABLISHMENT5": "Establecimiento de portales y canales de redes sociales.",
    "ESTABLISHMENT6": "Invitación de un grupo exclusivo de accionistas fundadores a la empresa",
    "ESTABLISHMENT7": "Building insurance solutions with established companies for the portal",
    "ESTABLISHMENT8": "Establecimiento de soluciones de pago globales",
    "SOCIAL_MEDIA_SETUP_FULFILLMENT": "Configuración y cumplimiento de redes sociales",
    "ESTABLISHED_LAYOUT": "Es un hecho establecido desde hace mucho tiempo que un lector se distraerá con el contenido legible de una página cuando mire su diseño.",
    "EXTERNAL_PROMOTION": "Promoción externa en Social Media",
    "LAUNCHPAD_STAGE": "Etapa de plataforma de lanzamiento",
    "CHECK_LAUNCHED_TOKENS": "VER TOKENS LANZADOS",
    "BELL_EXCHANGE_LAUNCH": "Lanzamiento de Bell Exchange",
    "APPLY_FOR": "Solicitar",
    "PROGRAM": "Programa",
    "AADHYA": "Adhya",
    "LISTING_MANAGER": "Gerente de listado",
    "GET_IN_TOUCH_WITH_US": "Ponte en contacto con nosotros",
    "YOUR_TELEGRAM_NAME": "Tu nombre de telegrama",
    "YOUR_PROJECT_NAME": "Su nombre de proyecto",
    "YOUR_EMAIL_ADDRESS": "Su dirección de correo electrónico",
    "FREE_CONSULTATION": "Consulta gratis",
    "HOURS": "Horas",
    "MINS": "minutos",
    "SECS": "Segundos",
    "LAUNCHPAD_DETAILS": "Detalles de la plataforma de lanzamiento",
    "TOKEN_NAME": "nombre de token",
    "TOKEN_SYMBOL": "símbolo de ficha",
    "TOKEN_ICON": "ICONO de ficha",
    "AVAILABLE_CURRENCY": "Moneda disponible",
    "MINIMUM_PURCHASE_AMOUNT": "Importe mínimo de compra",
    "CONTENT": "contenido",
    "ADD_TOKEN_DETAILS": "Agregar detalles del token",
    "BELL_EXCHANGE_LAUNCHPAD": "Plataforma de lanzamiento de Bell Exchange",
    "APPLY": "Aplicar",
    "ACTIVE_TOKENS": "Fichas activas",
    "COMPLETED_TOKENS": "Fichas completadas",
    "KS_COIN": "Moneda KS",
    "GAMING_STAGE": "Juegos | Nivel 1",
    "SESSION_SUPPLY": "Suministro de sesión",
    "START": "Comenzar",
    "END": "Fin",
    "CLOSE": "Cerca",
    "VIEW": "Vista",
    "LEVERAGE_TRADING" : "Comercio de apalancamiento",
    "LEVERAGE_ENABLES" : "El apalancamiento le permite obtener una exposición mucho mayor al mercado en el que está operando que la cantidad que depositó para abrir la operación.",
    "LEVERAGE_CALCULATOR" : "Calculadora de apalancamiento",
    "LEVERAGE" : "Aprovechar",
    "RESULT" : "Resultado",
    "LEVERAGE_CALCULATOR_ALLOWS" : "La calculadora de apalancamiento le permite calcular la cantidad total de poder adquisitivo que obtendrá en la plataforma de negociación en función de su capital",
    "INITIAL_MARGIN" : "Margen inicial",
    "PNL" : "PNL",
    "ROE" : "ROE",
    "START_TRADING" : "Comienza a negociar",
    "SIGN_IN" : "Iniciar sesión",
    // "FORGOT_PASSWORD" : "Has olvidado tu contraseña",
    "REGISTER_HERE" : "Registrar aquí",
    "2FA_CODE" : "Código 2FA",
    "EMAIL_CONFIRMATION_CODE" : "Código de confirmación de email",
    "CHECK_YOUR_EMAIL_CONFIRMATION_CODE" : "Revise su correo electrónico para obtener un código de confirmación",
    "ALREADY_REGISTERED_USER" : "¿Ya eres usuario registrado?",
    "LOGIN_HERE" : "Entre aquí",
    "PLEASE_ENTER_THE_VERIFICATION_CODE" : "Por favor ingrese el código de verificación que ha sido enviado a",
    "SMS_VERIFICATION_CODE" : "Código de verificación de SMS",
    "NO_SMS_RECEIVED" : "¿No has recibido ningún SMS?",
    "LAUNCHPAD" : "Plataforma de lanzamiento",
    "LANGUAGE" : "Idioma",
    "ENGLISH" : "English",
    "SPANISH" : "español",
    "SPOT" : "Lugar",
    "DERIVATIVES" : "Derivados",
    "CHANGE" : "Cambiar",
    "24H_LOW" : "Bajo 24H",
    "24H_TURNOVER" : "Rotación 24H",
    "STOP_LIMIT" : "Límite de parada",
    "MAKER_FEE" : "Tarifa del fabricante",
    "TAKER_FEE" : "Tarifa del tomador",
    "STOP" : "Detener",
    "BUYORSELL" : "Compra o Venta",
    "SEARCH_PAIRS" : "Buscar pares",
    "DEPOSIT_RECEIVE" : "Depositar / Recibir",
    "COPIED" : "Copiado",
    "AMOUNT_IN" : "Cantidad en",
    "NOTES_ONCE_THE_TRANSACTION" : "Una vez completada la transacción, el monto depositado se verá reflejado",
    "RAISE_A_TICKET" : "¡Si tiene alguna otra consulta, puede generar un boleto!",
    "BTCUSD_CONVERSION" : "Conversión BTC-USD",
    "BTC_BALANCE" : "Saldo BTC",
    "USD_BALANCE" : "Saldo en USD",
    "FROM_CURRENCY" : "De moneda",
    "TO_CURRENCY" : "A moneda",
    "REGISTER_EMAIL" : "Correo electrónico registrado",
    "GOOGLE_AUNTHENTICATION" : "Autenticación de Google",
    "MY_ASSETS" : "Mis activos",
    "WITHDRAWAL_HISTORY" : "Historial de retiros",
    "YOU_WILL_GET" : "Conseguirás",
    "MANAGE_ADDRESS" : "Administrar dirección",
    "DERIVATIVES_BALANCE" : "Saldo de derivados",
    "WALLET_CONVERSION" : "Conversión de billetera",
    "ADD_ADDRESS" : "Añadir dirección",
    "CURRENCY" : "Divisa",
    "TAG_OPTIONAL" : "Etiqueta (Opcional)",
    "ADD" : "Agregar",
    "MEMO_TAG" : "Memo/Etiqueta",
    "NO_RECORDS_FOUND" : "No se encontraron registros",
    "SEARCH" : "Buscar",
    "TRADING_FESS" : "Tarifas comerciales",
    "PAIRS" : "Pares",
    "FILLED_TYPE": "Tipo relleno",
    "FILLED_QTY": "Cantidad completa",
    "FEE_RATE": "Tasa de tarifa",
    "FEES_PAID": "Tarifas pagadas",
    "UNFILLED_QTY": "Cantidad sin llenar",
    "TRANSACTION_FEE" : "Tarifa de transacción",
    "WITHDRAWAL_REMARKS" : "Comentarios de retiro",
    "PROFILE_DETAILS" : "detalles del perfil",
    "ATTACHMENT_IF_ANY" : "Adjuntos si los hay *",
    "IMAGE_SHOULD_ALLOWED_ONLY_PNG" : "La imagen debe permitirse solo png/jpeg/jpg",
    "RESIDENTIAL_DETAILS" : "Detalles Residenciales",
    "OPEN_THIS_SELECT_MENU" : "Abrir este menú de selección",
    "ONE" : "Uno",
    "TWO" : "Dos",
    "THREE" : "Tres",
    "ADD_ACCOUNT_DETAILS" : "Agregar detalles de la cuenta",
    "BANK_COUNTRY" : "país del banco",
    "BANK_ADDRESS" : "Dirección del banco",
    "BANK_CITY" : "ciudad bancaria",
    "LABEL_SPACE" : "Espacio de etiqueta",
    "CREATE_TICKET" : "Crear Ticket",
    "2FA_CODE_WITHDRAW_CRYPTO" : "Escriba el código 2FA para retirar criptografía",
    "I_HAVE_BACKED_UP_SECRET_CODE" : "He respaldado el código secreto",
    "ENTER_DIGIT_GOOGLE_AUTHENTICATION_CODE" : "Ingrese el código de autenticación de Google de 6 dígitos",
    "CHANGE_PASSWORD" : "Cambiar la contraseña",
    "ALERT_AND_NOTIFICATION" : "Alertas y notificaciones",
    "SHOWS_CONFIRMATION_WINDOW_ORDERS" : "Muestra la ventana de confirmación de pedidos",
    "SHOW_CONFIRMATION_WINDOW_ORDERS_MOBILE_SETS" : "Mostrar ventana de confirmación para pedidos en el sitio móvil",
    "TURN_ON" : "Encender",
    "ORDERBOOK_ANIMATION" : "Animación del libro de pedidos",
    "S_NO" : "S.No",
    "OPERATING_SYSTEM_BROWSER" : "Sistema operativo/navegador",
    "COUNTRY_REGION" : "País/Región",
    "ISMOBILE" :  "esmóvil",
    "EMAIL_AUTHENTICATION" : "Autenticación de correo electrónicon",
    "COPY" : "Copiar",
    "TELEGRAM_NAME_FIELD_REQUIRED" : "Su campo de Nombre de Telegram es obligatorio",
    "TELEGRAM_PROJECT_FIELD_REQUIRED" : "El campo Nombre de tu proyecto es obligatorio",
    "TELEGRAM_EMAIL_FIELD_REQUIRED" : "Su campo de nombre de correo electrónico es obligatorio",
    "INPUT_AMOUNT_IS_BELOW": "La cantidad de entrada está por debajo o excede el límite único.",
    "ERRORS": "¡Errores!",
    "SUCCESS": "¡Éxito!",
    "YOUR_EXCHANGE_HAS_BEEN_COMPLETED": "Su intercambio ha sido completado.",
    "LOGOUT_SUCCESS": "Cerrar sesión ¡Éxito!",
    "COME_JOIN": "Ven y únete a nosotros pronto.",
    "SUBSCRIBED_SUCCESSFULLY": "Suscrito con éxito",
    "EMAIL_ALREADY_EXISTS": "¡El ID de correo electrónico ya existe!",
    "RESET_PASSWORD_LINK_SET": "Enlace de restablecimiento de contraseña enviado a ID de correo registrado",
    "IMAGE_SIZE_IS_TOO_LARGE": "El tamaño de la imagen es demasiado grande.",
    "WARNING": "¡Advertencia!",
    "LAUNCHPAD_CONTACTUS": "Launchpad Contactus añadido con éxito",
    "LOGIN_SUCCESSFULLY": "¡Inicia sesión con éxito!",
    "UNRECOGNIZED_IP": "Dirección IP no reconocida",
    "WELCOME_TO_BELL_EXCHANGE": "Bienvenido a Bell Exchange. ¡Comienza a operar y disfruta!",
    "PLEASE_ENTER_2FA_CODE": "Ingrese el código 2FA.",
    "PLEASE_ENTER_YOUR_CONFIRMATION": "Por favor, introduzca su código de confirmación.",
    "PLEASE_CHECK_YOUR_EMAIL_VERIFICATION_CODE": "Por favor revise su correo electrónico para un código de verificación.",
    "DELETED_SUCCESSFULLY": "Borrado exitosamente",
    "CURRENCY_NAME_SHOULD_NOT_EMPTY": "El nombre de la moneda no debe estar vacío",
    "ADDRESS_SHOULD_NOT_EMPTY": "La dirección no debe estar vacía",
    "NAME_SHOULD_NOT_EMPTY": "El nombre no debe estar vacío",
    "FROM_TO_WALLET": "Las carteras de origen y destino deben ser diferentes.",
    "AMOUNT_ENTERED_IS_INVALID": "El monto ingresado no es válido.",
    "FROM_AND_TO_CURRENCY": "Desde y hasta la moneda debe ser diferente.",
    "AUTHENTICATION_CODE_MISSING": "Falta el código de autenticación",
    "REGISTRATION_SUCCESS": "¡Registración exitosa!",
    "ACTIVATION_MAIL_SENT": "Correo de activación enviado. Por favor revise su correo electrónico y haga clic en el enlace de activación.",
    "CURRENCY_UPDATED": "Moneda actualizada.",
    "PASSWORD_UPDATED": "Contraseña actualiza",
    "OTP_IS_MISSING": "Falta OTP",
    "PHONE_NUMBER_IS_EMPTY": "El número de teléfono está vacío",
    "NOTIFICATION_PREFERENCES_UPDATED": "Preferencias de notificación actualizadas",
    "MESSAGE_IS_EMPTY": "El mensaje está vacío",
    "QUANTITY_MUST_NOT_LESS_THAN": "La cantidad no debe ser inferior a",
    "PLEASE_ENTER_QUANTITY": "Ingrese la cantidad",
    "QUANTITY_MUST_NOT_MORE_THAN": "La cantidad no debe ser mayor a",
    "PRICE_IS_INVALID": "El precio no es válido.",
    "PRICE_LESS_THAN": "El precio no debe ser inferior a 0,00000001",
    "TRIGGER_PRICE_MUST_NOT_BE_LESS_THAN": "El precio de activación no debe ser inferior a 1",
    "PLEASE_LOGIN": "¡Por favor Iniciar sesión!",
    "CHECK_YOUR_STAKE_CURRENCY": "Verifique la moneda de su apuesta",
    "YOU_CANNOT_POST_EMPTY_MESSAGE": "No puedes publicar un mensaje vacío",
    "QUANTITY_CONTRACTS_MUST_NOT_LESSER_THAN": "La cantidad del contrato no debe ser inferior a",
    "QUANTITY_CONTRACTS_MUST_NOT_GREATER_THAN": "La cantidad del contrato no debe ser superior a",
    "PRICE_CONTRACTS_MUST_NOT_BE_LESSER_THAN": "El precio del contrato no debe ser inferior a 0,001",
    "TRIGGER_PRICE_CONTRACTS_MUST_NOT_LESSER_THAN": "El precio de activación del contrato no debe ser inferior a 1",
    "MINIMUM_QUANTITY_1": "La cantidad mínima es 1",
    "MINIMUM_ORDER_VALUE": "El valor mínimo de pedido es 10 ",
    "LONG_TAKE_PROFIT_PRICE": "Largo: el precio Take Profit debe ser mayor que el precio de mercado",
    "SHORT_TAKE_PROFIT_PRICE": "Corto: el precio Take Profit debe ser menor que el precio de mercado",
    "LONG_STOP_LOSS_PRICE": "Largo: el precio de Stop Loss debe ser menor que el precio de mercado",
    "SHORT_STOP_LOSS_PRICE": "Corto: el precio de stop loss debe ser mayor que el precio de mercado",
    "CLOSE_QUANTITY_MUST_LOWER_THAN": "La cantidad cercana que establezca debe ser inferior o igual",
    "TICKET_FOR": "boleto para",
    "ATTACHMENT": "Adjunto",
    "TICKET_SUCCESS" : "¡Crear Ticket!",
    "TICKET_RAISED" : "¡Boleto recaudado con éxito!"
}