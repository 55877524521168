import React, { Component } from 'react'
import TradeHeader from './TradeHeader'
import Footer from './Footer'
// import Factor2AImg from "../images/scanCopy.png"
import { Link, withRouter } from 'react-router-dom';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import axios from "axios";
import 'react-phone-number-input/style.css'
import { store } from 'react-notifications-component';
import { profileUser, tfaFormsubmit, createApiKey, deleteApiKey, getUserData } from "../actions/authActions";
// import PhoneInput from 'react-phone-number-input'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import classnames from "classnames";
import keys from "../actions/config";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Popup from "reactjs-popup";
import { Modal, Button, Accordion, Card } from 'react-bootstrap/';
import Select from 'react-select';
import { Scrollbars } from 'react-custom-scrollbars';
import LoaderSmall from './LoaderSmall';
import prof from '../../src/images/contact_img.png'
import Navtradebar from './Navtradebar';
import { withTranslation } from 'react-i18next';
import { CountryDropdown } from 'react-country-region-selector'
const url = keys.baseUrl;

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px dotted pink',
    color: state.isSelected ? 'red' : 'blue',
    padding: 20,
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';
    return { ...provided, opacity, transition };
  }
}


const options = [
  { value: 'Active Order and Positions', label: 'Active Order and Positions' },
  { value: 'Positions Only', label: 'Positions Only' },
  { value: 'Active Order Only', label: 'Active Order Only' },
];

// const options1 = [
//   { value: 'Beshare', label: 'Beshare' },
//   { value: '3Commas', label: '3Commas' },
//   { value: 'AIcoin', label: 'AIcoin' },
//   { value: 'alpha-algo', label: 'alpha-algo' },
//   { value: 'Cornix', label: 'Cornix' },
//   { value: '合约帝', label: '合约帝' },
// ];

const options1 = [
  { label: "Passport", value: "Passport" },
  { label: "Aadhar_card", value: "Aadhar_card" },
  { label: "Driving_license", value: "Driving_license" }
]



class Settings extends Component {

  constructor(props) {
    super(props);
    console.log(props?.location?.state?.data, "PROPS DATA", props);
    this.state = {
      currentTab: 0,
      userid: "",
      fields: "",
      name: "",
      id: "",
      currency: "",
      oldpassword: "",
      password: "",
      password2: "",
      loginpassword: "",
      secretcode: "",
      googleauth: "",
      onecode: "",
      remarkname: "",
      ipaddress: "",
      keypermission: "",
      applicationName: "",
      readOnly: "",
      twofactorkey: "",
      notifications: {},
      errors: {
        idproof: "",
        idproofno: "",
        addressproof: "",
        frontsidefile: "",
        backsidefile: "",
        selfiefile: "",
        addressfile: "",
        pancardfile: ""

      },
      windoworder: "false",
      mobilesite: "false",
      position: "false",
      animation: "false",
      phone: "",
      secretcode: "",
      accept: '',
      show: false,
      type1: true,
      selectedOption: null,
      type2: false,
      otp: "",
      sms: "Not Verified",
      APItype: 1,
      loginhistory: {},
      apiKeydetails: {},
      profileurl: null,
      changePasswordLoading: false,
      viewpassword: false,
      viewconfirmpassword: false,
      viewnewpassword: false,
      viewoldpassword: false,
      CategoryList: [],
      message: "",
      attachments: "",
      categoryId: "",
      idproof: "",
      addressproof: "",
      idproofno: "",
      Categoryissue: [],
      Addresstatus: "",
      Addressphotostatus: "",
      Photostatus: "",
      verifiedstatus: "",
      IDstatus: "",
      firstName: "",
      lastName: "",
      buildingblock: "",
      address: "",
      country: "",
      State: "",
      city: "",
      postalcode: "",
      phonenumber: "",
      email: "",
      newEmail: ""



    };
    // this.setState({currentTab :props?.location?.state?.data})

  }
  onChange = e => {
    console.log(e.target.value, "Target")
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleChangeProfile = (e) => {
    console.log('eakjhsdfjasdjfasvgdjh', e.target.name, e.target.value)
    let regex = /^[0-9]+$/;
    if (e.target.name == 'firstName') {
      this.setState({ 'firstName': e.target.value })
    }

    if (e.target.name == 'lastName') {
      this.setState({ 'lastName': e.target.value })
    }
    if (e.target.name == 'buildingblock') {
      this.setState({ 'buildingblock': e.target.value })
    }
    if (e.target.name == 'address') {
      this.setState({ 'address': e.target.value })
    }
    if (e.target.name == 'State') {
      this.setState({ 'State': e.target.value })
    }
    if (e.target.name == 'city') {
      this.setState({ 'city': e.target.value })
    }
    if (e.target.name == 'postalcode') {
      console.log('regex.test(e.target.value)', regex.test(e.target.value))
      // this.setState({'postalcode' : e.target.value})
      if (e.target.value == "" || regex.test(e.target.value)) {
        this.setState({ 'postalcode': e.target.value })
      }
    }
    if (e.target.name == 'phonenumber') {
      console.log('e.adsfaksdfjalksdfjlasd', e.target.name, e.target.value)
      if (e.target.value == "" || regex.test(e.target.value)) {
        this.setState({ 'phonenumber': e.target.value })
      }

    }


  }


  handleFileProfile = (e) => {
    console.log('e.target.galydsioasfa', e.target.name, e.target.files[0])
    this.setState({ [e.target.name]: e.target.files[0] })
  }


  handleCountry = (data) => {
    console.log('shldfasdfabhsdbfkashdk', data)
    this.setState({ country: data })
  }


  handleChangeEmail = (e) => {

    if (e.target.name == 'newEmail') {
      this.setState({ 'newEmail': e.target.value })
    }
  }
  EmailValidation() {
    let errors = {};
    let emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([A-Za-zA-Z\-0-9]+\.)+[A-Za-zA-Z]{2,}))$/;
    if (this.state.newEmail == "") {
      errors.changeemail = "Email is required"
    }
    else if (!emailRegex.test(this.state.newEmail)) {
      errors.changeemail = "Invalid Email Address"
    }
    this.setState({ errors: errors })
    return errors;
  }

  ChangeEmailAddress = async () => {
    const { t } = this.props;
    let validation = await this.EmailValidation();
    let Objlen = Object.keys(validation).length
    console.log('daskfbhasjdbfjasdfbhj', validation)
    let userId = this.props.auth.user.id;
    let newEmail = this.state.newEmail;
    let data = {
      'userId': userId,
      'newEmail': newEmail
    }
    if (Objlen == 0) {
      axios.post(url + "api/ChangeEmailAddress", data).then(res => {
        console.log('dfjhbfbvhdhdh', res)
        if (res.data.status == true) {
          store.addNotification({
            title: t("SUCCESS"),
            message: t("VERIFICATION_LINK_OLD_EMAIL"),
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true
            }
          })
        }
        else {
          store.addNotification({
            title: t("WARNING"),
            message: t("EMAIL_ALREADY_EXISTS"),
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: [
              "animated", "fadeIn"
            ],
            animationOut: [
              "animated", "fadeOut"
            ],
            dismiss: {
              duration: 1500,
              onScreen: true
            }
          });
        }
      })
    }

  }

  ProfileValidation() {
    let errors = {};
    var imageFormat = /\.(jpg|JPG|jpeg|JPEG|png|PNG)$/;
    const { firstName, lastName, buildingblock, address, country, profileImage, city, State, postalcode, phonenumber } = this.state;
    { console.log('asdkfbasjhfasdfjavgsdfhvags', country, country == "") }
    if (firstName == "") {
      errors.firstName = "FirstName is required"
    }

    if (lastName == "") {
      errors.lastName = "LastName is required"
    }

    if (buildingblock == "") {
      errors.buildingblock = "Buildingblock is required"
    }

    if (address == "") {
      errors.address = "Address is required"
    }

    if (country == "") {
      errors.country = "Country is required"
    }

    if (State == "") {
      errors.State = "State is required"
    }

    if (city == "") {
      errors.city = "city is required"
    }

    if (postalcode == "") {
      errors.postalcode = "Postalcode is required"
    }

    if (phonenumber == "") {
      errors.phonenumber = "Phonenumber is required"
    }
    console.log('imagggggggggggggggggggggg', this.state.Image == '')
    if (!profileImage && this.state.Image == '') {
      errors.profileImage = "Image is required"
    }

    if (profileImage && profileImage?.name) {
      if (profileImage && profileImage.size > 100000) {
        errors.profileImage = "Too_large"
      }
      else if (!imageFormat.test(profileImage?.name)) {
        errors.profileImage = "Invalid Image"
      }
    }
    this.setState({ errors: errors })
    return errors;

  }


  UpdateProfile = async (e) => {
    const { t } = this.props;
    let validation = await this.ProfileValidation()
    console.log('validataiondhaslkfdhalkshdfkashd', validation, Object.keys(validation).length)
    let userId = this.props.auth.user.id
    const { firstName, lastName, buildingblock, address, country, profileImage, city, State, postalcode, phonenumber } = this.state;
    console.log(';lksdhfkashdfjkasgdkhf', profileImage)
    let formData = new FormData()
    formData.append("firstname", firstName)
    formData.append("lastName", lastName)
    formData.append("buildingblock", buildingblock)
    formData.append("address", address)
    formData.append("country", country)
    formData.append("profileImage", profileImage)
    formData.append("city", city)
    formData.append("State", State)
    formData.append("postalcode", postalcode)
    formData.append("phonenumber", phonenumber)
    formData.append("userId", userId)
    let Objlen = Object.keys(validation).length;
    if (Objlen == 0) {
      axios.post(url + "api/editUserProfile", formData).then(res => {
        if (res.data.status == true) {
          store.addNotification({
            title: t("SUCCESS"),
            message: t("PROFILE_EDIT_SUCCESS"),
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true
            }
          })
        }
        else {
          store.addNotification({
            title: t("WARNING"),
            message: t("SOMETHING_WRONG"),
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true
            }
          })
        }
      })
    }

  }

  handleChange = (event) => {
    console.log('event', event)
    this.setState({ attachment: event.target.files[0] })
  }
  handleSupport = (e) => {
    console.log('vatyseasdasdfa', e.value)
    this.setState({ categoryId: e.value })
  }

  handleFile = (e) => {
    const { t } = this.props;
    let errors = {};
    const { name, files } = e.target;
    var imageFormat = /\.(jpg|JPG|jpeg|JPEG|png|PNG)$/;
    console.log('enter this >>>>>>>>>>>>>>>', files[0])
    if (files[0] && files[0].name) {
      if (files[0].size > 100000) {
        errors.Image = t("TOO_LARGE")
        console.log('enter this111', files)
      }

      else if (!imageFormat.test(files[0].name)) {
        errors.Image = t("INVALID_IMAGE")
        console.log('enter this222', files)
      }
    }
    console.log('errors12321342', errors, Object.keys(errors).length)
    if (Object.keys(errors).length == 0) {
      console.log('filesdetialsl', files)
      this.setState({ attachments: files[0] })
      this.setState({ errors: errors })
    }
    else {
      console.log('errors2141234', errors)
      this.setState({ errors: errors })
    }


  }



  handleselectKyc = (data) => {
    console.log('datasgal', data)
    this.setState({ idproof: data.value })
  }

  handleselectAddressKyc = (data) => {
    this.setState({ addressproof: data.value })
  }

  handleKyc = (e) => {
    let regex = /^[0-9]+$/;
    console.log('e.target.name', e.target.name, e.target.value,regex.test(e.target.value),e.target.value == '')
    if (e.target.name == 'idproofno') {
      if (e.target.value == '' || regex.test(e.target.value)) {
        this.setState({ [e.target.name]: e.target.value })
      }
    }
  }

  handleFilekyc = (e) => {
    console.log('e.target.filename', e.target.name, e.target.files[0])
    this.setState({ [e.target.name]: e.target.files[0] })
  }


  handleChange1 = applicationName => {
    this.setState({ applicationName });
  };

  handleClose = () => {
    this.setState({
      show: false,
      remarkname: "",
      ipaddress: "",
      keypermission: "",
      applicationName: "",
      readOnly: "",
      twofactorkey: ""
    });
  }

  handleShow = () => {
    this.setState({ show: true });
  }

  handleOnChange = (e) => {
    this.setState({ APItype: e.target.value });
    if (e.target.value == 1) {
      this.setState({ type1: true, type2: false });
    } else {
      this.setState({ type2: true, type1: false });
      this.setState({ type2: true, type1: false });
    }
  }

  handleSelect = (e) => {
    this.setState({ APItype: e.target.value });
  }

  deleteKey = (e) => {
    let userid = this.props.auth.user.id;
    if (window.confirm('Are you sure you want to delete this key?')) {
      e.preventDefault();
      const apiData = {
        userid: userid,
        id: e.target.id
      };
      this.props.deleteApiKey(apiData);
    }
  }

  getCategoryList = () => {
    console.log('enter this one first')
    axios.get(url + "api/getcategorylist/").then(res => {
      let arr1 = [];
      console.log('res.details', res.data, res.data.status == true)
      if (res.data.status == true) {
        let category = res.data.result;

        {
          category && category.length > 0 && category.map((item) => {
            if (item.status == 'Active') {
              arr1.push({ 'label': item.categoryName, 'value': item.categoryName })
            }

          })
        }
      }
      else {

      }
      console.log('skdfasbkjdfasjkdfgajsd', arr1)
      this.setState({ CategoryList: arr1 })
    }).catch()
  }


  getCategory = () => {
    let id = this.props.auth.user.id
    axios.get(url + "api/getcategory/" + id).then(res => {
      if (res.data.status == true) {
        console.log('res.data', res.data)
        let category = res.data.result;
        this.setState({ Categoryissue: category })
      }
    })
  }

  styles = {
    option: (provided, state) => ({
      ...provided,
      color: "ccc",
      backgroundColor: "#000",
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      height: '52px',
      padding: '0 6px',
      backgroundColor: "#03081f",
      borderColor: '#81c8f6',
      borderRadius: 10,
      borderStyle: 'solid',
      borderWidth: '1px'

    }),
    control: (provided, state) => ({
      ...provided,
      height: '52px',
      borderRadius: 10,
      backgroundColor: "#03081f",
      border: 'none'

    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: '52px',
      position: 'absolute',
      right: 0,
      top: 0,
      color: '#fff'
    }),
    singleValue: (provided, state) => ({
      ...provided,
      color: "#fff"
    })
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    this.getData()
    this.getCategoryList()
    this.getCategory()
  }

  CreateNewTicket = () => {
    try {
      const { t } = this.props;
      let userId = this.props.auth.user.id
      console.log('enter thisssssssss', userId)
      const formData = new FormData();
      formData.append("category", this.state.categoryId);
      formData.append("message", this.state.message);
      formData.append("attachment", this.state.attachments)
      formData.append("userId", userId)
      axios.post(url + "api/createTicket/", formData).then(res => {
        console.log('res.data.stastus', res.data)
        if (res.data.status == true) {
          store.addNotification({
            title: t("TICKET_SUCCESS"),
            message: t("TICKET_RAISED"),
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 5000,
              onScreen: true
            }
          })
          this.getCategory()
        }
        else {
          console.log('res.data', res.data)
          let error = res.data;
          this.setState({ errors: error })
        }
      })
    } catch (err) {
      console.log('errors', err)
    }
  }


  Close = (ticketId) => {
    try {
      console.log('datsgal', ticketId)
      let id = this.props.auth.user.id
      let reqData = {
        'id': id,
        'ticketId': ticketId

      }
      axios.post(url + "api/supportstatus_update/", reqData).then(res => {
        if (res.data.status == true) {
          this.getCategory()
        }
      })
    }
    catch (err) {
      console.log('err', err)
    }
  }

  KycValidation() {
    console.log('enter this--------------')
    let errors = {};
    console.log('errorsdatasgalaasdfas', errors)
    let imageFormat = /\.(jpg|JPG|jpeg|JPEG|png|PNG)$/
    console.log('idproofdata', this.state.idproof == "", !this.state.frontsidefile)
    console.log('this.state.IDstatusthis.state.IDstatus', this.state.IDstatus == 'Not Verified', (this.state.IDstatus == 'Not verified' || this.state.IDstatus == 'Rejected'), (this.state.Addresstatus == 'Not verified' || this.state.Addresstatus == 'Rejected'))
    if ((this.state.IDstatus == 'Not verified' || this.state.IDstatus == 'Rejected') || (this.state.Addresstatus == 'Not verified' || this.state.Addresstatus == 'Rejected')) {

      if (this.state.idproof == "") {
        errors.idproof = "IDproof is required"
      }
      if (this.state.idproofno == "") {
        errors.idproofno = "IDproofno is required"
      }
      if (this.state.addressproof == "") {
        errors.addressproof = "Addressproof is required"
      }
      if (!this.state.frontsidefile) {
        errors.frontsidefile = "Image field is required"
      }
      if (!this.state.backsidefile) {
        errors.backsidefile = "Image field is required"
      }
      if (!this.state.selfiefile) {
        errors.selfiefile = "Image field is required"
      }
      if (!this.state.addressfile) {
        errors.addressfile = "Image field is required"
      }
      if (!this.state.pancardfile) {
        errors.pancardfile = "Image field is required"
      }



      if (this?.state?.frontsidefile?.name || this?.state?.backsidefile?.name || this?.state?.selfiefile?.name || this?.state?.pancardfile?.name || this?.state?.addressproof?.name) {
        if (this?.state?.frontsidefile && this?.state?.frontsidefile?.name) {
          if (this?.state?.frontsidefile?.size > 1000000) {
            errors.frontsidefile = "Too_large"
          }
          else if (!imageFormat.test(this?.state?.frontsidefile?.name)) {
            errors.frontsidefile = "Please choose valid Image"
          }
        }


        if (this?.state?.backsidefile && this?.state?.backsidefile?.name) {
          if (this?.state?.backsidefile?.size > 1000000) {
            errors.backsidefile = "Too_large"
          }
          else if (!imageFormat.test(this?.state?.backsidefile?.name)) {
            errors.backsidefile = "Please choose valid Image"
          }
        }

        if (this?.state?.selfiefile && this?.state?.selfiefile?.name) {
          if (this?.state?.selfiefile?.size > 1000000) {
            errors.selfiefile = "Too_large"
          }
          else if (!imageFormat.test(this?.state?.selfiefile?.name)) {
            errors.selfiefile = "Please choose valid Image"
          }
        }

        if (this?.state?.pancardfile && this?.state?.pancardfile?.name) {
          if (this?.state?.pancardfile?.size > 1000000) {
            errors.pancardfile = "Too_large"
          }
          else if (!imageFormat.test(this?.state?.pancardfile?.name)) {
            errors.pancardfile = "Please choose valid Image"
          }
        }
        if (this?.state?.addressfile && this?.state?.addressfile?.name) {
          if (this?.state?.addressfile?.size > 1000000) {
            errors.addressfile = "Too_large"
          }
          else if (!imageFormat.test(this?.state?.addressfile?.name)) {
            errors.addressfile = "Please choose valid Image"
          }
        }
      }
      return errors
    }

  }

  UpdateKyc = async () => {
    try {
      const { t } = this.props;
      let validation = await this.KycValidation()
      console.log('validation dats', validation)
      this.setState({ errors: validation })
      console.log('vlidation')
      let reqData = {
        "idproof": this.state.idproof,
        "idproofno": this.state.idproofno,
        "addressproof": this.state.addressproof,
        "frontsidefile": this.state.frontsidefile,
        "backsidefile": this.state.backsidefile,
        "selfiefile": this.state.selfiefile,
        "pancardfile": this.state.pancardfile,
        "addressfile": this.state.addressfile
      }
      let userId = this.props.auth.user.id
      let formData = new FormData();
      formData.append("idproof", this.state.idproof)
      formData.append("idproofno", this.state.idproofno)
      formData.append("addressproof", this.state.addressproof)
      formData.append("frontsidefile", this.state.frontsidefile)
      formData.append("backsidefile", this.state.backsidefile)
      formData.append("selfiefile", this.state.selfiefile)
      formData.append("pancardfile", this.state.pancardfile)
      formData.append("addressfile", this.state.addressfile)
      formData.append("userId", userId)

      let objlen = Object.keys(validation).length;
      console.log('datsgalssss', objlen)
      if (objlen == 0) {
        console.log('enter this objlen')
        axios.post(url + "api/user_kycUpdate", formData).
          then(res => {
            if (res.data.status == true) {
              store.addNotification({
                title: t("SUCCESS"),
                message: "Kyc details Update Successfully !",
                type: "success",
                insert: "top",
                container: "top-right",
                animationIn: [
                  "animated", "fadeIn"
                ],
                animationOut: [
                  "animated", "fadeOut"
                ],
                dismiss: {
                  duration: 1500,
                  onScreen: true
                }
              });
            }
            this.getData()
          })
      }

    }
    catch (err) {
      console.log('err', err)
    }
  }


  getData() {
    let userid = this.props.auth.user.id;
    axios.get(url + "cryptoapi/userget/" + userid).then(res => {
      console.log("GETDATE", res.data);
      this.props.auth.user.name = res.data.name;
      this.setState({
        userid: res.data.userid,
        name: res.data.name,
        currency: res.data.currency,
        mobilesite: res.data.mobilesite,
        windoworder: res.data.windoworder,
        position: res.data.position,
        animation: res.data.animation,
        loginhistory: res.data.loginhistory,
        apiKeydetails: res.data.apiKeydetails,
        sms: res.data.sms,
        secretcode: res.data.newSecret.secret,
        phone: res.data.phonenumber,
        Factor2AImg: "https://chart.googleapis.com/chart?chs=130x130&chld=L|0&cht=qr&chl=otpauth://totp/Bell Exchange%3Fsecret=" + res.data.newSecret.secret,
        profileurl: res.data.profile,
        Addresstatus: res.data.Addresstatus,
        Addressphotostatus: res.data.Addressphotostatus,
        Photostatus: res.data.Photostatus,
        verifiedstatus: res.data.verifiedstatus,
        IDstatus: res.data.IDstatus,
        // idproof : res.data.IDtype,
        // idproofno : res.data.IDproofno,
        // frontsidefile : res.data.IDprooffront,
        // backsidefile : res.data.IDproofback,
        firstName: res.data.firstName,
        lastName: res.data.lastName,
        buildingblock: res.data.BuildingblockName,
        address: res.data.Address,
        country: res.data.countryName,
        State: res.data.state,
        postalcode: res.data.Postalcode,
        city: res.data.city,
        phonenumber: res.data.phonenumber,
        newEmail: res.data.email,
        email: res.data.email,
        Image: res.data.profileImage

      })
      console.log('Imageeeeeeeeeeee', res.data.profileImage)
      if (res.data.google != 'Disabled') {
        this.setState({ googleauth: 'Verified' });
      } else {
        this.setState({ googleauth: '' });

      }

      this.loginHistory();
    }).catch()
  }

  componentWillReceiveProps(nextProps) {
    const { t } = this.props;
    console.log(nextProps.errors, "NEXT PROPS.....................................");
    if (nextProps?.location?.state?.data != "" && typeof (nextProps?.location?.state?.data) != "undefined") {
      console.log("NEXT PROPS IN .....................................", typeof (nextProps?.location?.state?.data) == "undefined", nextProps?.location?.state?.data != "");
      this.setState({ currentTab: nextProps?.location?.state?.data })
    }
    if (nextProps.errors) {
      this.setState({ errors: nextProps.errors });
    } else {
      this.setState({ errors: {} });
    }

    if (nextProps.auth && nextProps.auth.update2fa != undefined && nextProps.auth.update2fa.message != undefined) {
      store.addNotification({
        title: t("SUCCESS"),
        message: nextProps.auth.update2fa.message,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: [
          "animated", "fadeIn"
        ],
        animationOut: [
          "animated", "fadeOut"
        ],
        dismiss: {
          duration: 1500,
          onScreen: true
        }
      });
      this.setState({
        loginpassword: '',
        onecode: ''
      });
      this.getData();
      nextProps.auth.update2fa = "";

    }

    if (nextProps.auth.updateprofile != undefined && nextProps.auth.updateprofile.data !== undefined && nextProps.auth.updateprofile.data.message !== undefined) {

      store.addNotification({
        title: t("SUCCESS"),
        message: nextProps.auth.updateprofile.data.message,
        type: "success",
        insert: "top",
        container: "top-right",
        animationIn: [
          "animated", "fadeIn"
        ],
        animationOut: [
          "animated", "fadeOut"
        ],
        dismiss: {
          duration: 1500,
          onScreen: true
        }
      });
      const userid = {
        id: this.props.auth.user.id
      }

      //this function used for change name in trade header  --> this function from action file--> load in app.js
      getUserData(userid)
      this.getData();
      nextProps.auth.updateprofile = "";

    }

  }

  setCurrency(event) {
    this.setState({ currency: event.target.value })
  }

  onProfileSubmit = e => {
    e.preventDefault();
    console.log("attachement", this.state.attachment);
    this.setState({ errors: {} });
    if (this.validateForm()) {
      console.log('enter vliduate')
      let fields = {};
      fields["email_add"] = "";
      fields["subject"] = "";
      fields["description"] = "";
      fields["attachment"] = "";
      this.setState({ validation: fields });
      const userData = {
        userid: this.state.userid,
        name: this.state.name,
        id: this.props.auth.user.id,
      };
      const data = new FormData();
      data.append('userid', this.state.userid)
      data.append('name', this.state.name)
      data.append('id', this.props.auth.user.id)
      data.append('file', this.state.attachment)
      data.append('profileURL', this.state.profileurl)
      this.setState({ errors: {} });
      console.log("userData", this.state.userid, this.state.name, this.props.auth.user.i);

      this.props.profileUser(data);
    }

  };
  validateForm() {
    this.setState({ errors: {} });
    let fields = this.state.validation;
    let errors = {};
    let imageFormat = /\.(jpg|JPG|png|PNG|jpeg|JPEG)$/;
    var MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
    errors["email_add"] = '';
    errors["subject"] = '';
    errors["description"] = '';
    errors["attachment"] = '';
    console.log('errors', errors)
    let formIsValid = true;

    // if (this.state.email_add=='') {
    //   formIsValid = false;
    //   errors["email_add"] = "*Please enter your email-ID.";
    // } else if (typeof fields["email_add"] !== "undefined") {
    //   var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    //   if (!pattern.test(fields["email_add"])) {
    //     formIsValid = false;
    //     errors["email_add"] = "*Please enter valid email-ID.";
    //   }
    // }
    if (this.state.attachment != 'undefined') {
      { console.log('attachemnt', this.state.attachment) }
      if (this?.state?.attachment?.size > MAX_FILE_SIZE) {
        formIsValid = false;
        errors["attachment"] = "*Image is Above 5MB";
      }



      if (!imageFormat.test(this?.state?.attachment?.name)) {
        formIsValid = false;
        errors["attachment"] = "*Image Should Allowed only png/jpeg/jpg";
      }
      if (this.state.subject == '') {
        formIsValid = false;
        errors["subject"] = "*Please enter your Subject";
      }
      if (this.state.description == '') {
        formIsValid = false;
        errors["description"] = "*Please enter your Description";
      }
    }

    this.setState({
      errors: errors
    });
    console.log('errors--', errors)
    return formIsValid;

  }
  createApiKey = e => {
    const { t } = this.props;
    let userid = this.props.auth.user.id;
    e.preventDefault();
    const apiData = {
      userid: userid,
      remarkname: this.state.remarkname,
      APItype: this.state.APItype,
      ipaddress: this.state.ipaddress,
      keypermission: (this.state.keypermission != '')
        ? this.state.keypermission.value
        : '',
      readOnly: this.state.readOnly,
      twofactorkey: this.state.twofactorkey,
      applicationName: (this.state.applicationName != '')
        ? this.state.applicationName.value
        : ''
    };
    //console.log(apiData);
    // this.props.createApiKey(apiData);
    axios.post(url + "cryptoapi/CreateApiKey", apiData).then(res => {
      if (res.data.success) {
        store.addNotification({
          title: t("SUCCESS"),
          message: res.data.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: [
            "animated", "fadeIn"
          ],
          animationOut: [
            "animated", "fadeOut"
          ],
          dismiss: {
            duration: 1500,
            onScreen: true
          }
        });
        this.setState({ errors: "" });
        this.handleClose();
        this.getData();
      } else {
        if (res.data.message != undefined) {
          store.addNotification({
            title: t("ERRORS"),
            message: res.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: [
              "animated", "fadeIn"
            ],
            animationOut: [
              "animated", "fadeOut"
            ],
            dismiss: {
              duration: 1500,
              onScreen: true
            }
          });
        }
        if (res.data.errors != undefined)
          this.setState({ errors: res.data.errors });
      }
    }).catch();
  };

  tfaFormsubmit = e => {
    e.preventDefault();
    const userData = {
      userid: this.state.userid,
      loginpassword: this.state.loginpassword,
      secretcode: this.state.secretcode,
      onecode: this.state.onecode,
      id: this.props.auth.user.id,
      accept: this.state.accept,
    };
    this.props.tfaFormsubmit(userData);
    console.log("currettab", this.state.currentTab)
    // this.setState({
    //   loginpassword: '',
    //   onecode: ''
    // });
  };

  onCurrencySubmit = e => {
    const { t } = this.props;
    let userid = this.props.auth.user.id;
    e.preventDefault();
    const currencyData = {
      currency: this.state.currency,
      id: this.props.auth.user.id
    };
    axios.post(url + "cryptoapi/updatecurrency", currencyData).then(res => {
      if (res.data.success) {
        store.addNotification({
          title: t("SUCCESS"),
          message: t("CURRENCY_UPDATED"),
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: [
            "animated", "fadeIn"
          ],
          animationOut: [
            "animated", "fadeOut"
          ],
          dismiss: {
            duration: 1500,
            onScreen: true
          }
        });
        this.setState({ errors: "" })
      } else {
        this.setState({ errors: res.data.errors })
      }
    }).catch();
  };

  checkBoxClicked(event) {
    const { id } = event.target

    /*this if condition is used for clear particular error state*/
    if (id == "accept") {
      if (this.state.errors && this.state.errors.accept) {
        delete this.state.errors["accept"];

      }
    }
    this.setState({
      [event.target.id]: event.target.checked.toString()
    });
  }

  apiKeyDetails = () => {
    let table = [];
    if (this.state.apiKeydetails.length > 0) {
      for (var i = 0; i < this.state.apiKeydetails.length; i++) {
        var createdDate = this.state.apiKeydetails[i].createdDate.split;
        table.push(<tr>
          <td>{i + 1}</td>
          <td>{this.state.apiKeydetails[i].createdDate}</td>
          <td>{
            this.state.apiKeydetails[i].expiredDate
              ? this.state.apiKeydetails[i].expiredDate
              : 'permanent'
          }</td>
          <td>{this.state.apiKeydetails[i].apikey}</td>
          <td>{
            this.state.apiKeydetails[i].remarkname
              ? this.state.apiKeydetails[i].remarkname
              : this.state.apiKeydetails[i].applicationName
          }</td>
          <td>{this.state.apiKeydetails[i].secretkey}</td>
          <td>{this.state.apiKeydetails[i].keypermission}</td>
          <td>{this.state.apiKeydetails[i].ipaddress}</td>
          <td>
            <a onClick={this.deleteKey} id={this.state.apiKeydetails[i]._id}>delete</a>
          </td>
        </tr>)
      }
    } else {
      table.push(<tr>
        <td colspan="9">
          <center>No data Found</center>
        </td>
      </tr>)
    }
    return table;
  }

  loginHistory = () => {

    let table = [];
    console.log('tabletable', table)
    for (var i = 0; i < this.state.loginhistory.length; i++) {

      var data1 = new Date(this.state.loginhistory[i].createdDate);
      let date = data1.getFullYear() + '-' + (
        data1.getMonth() + 1) + '-' + data1.getDate() + ' ' + data1.getHours() + ':' + data1.getMinutes() + ':' + data1.getSeconds();
      date = (data1.getFullYear() != 1970)
        ? date
        : '';

      table.push(<tr>
        <td>{i + 1}</td>
        <td>{this.state.loginhistory[i].ipaddress}</td>
        <td>{this.state.loginhistory[i].os}/{this.state.loginhistory[i].broswername}</td>
        <td>{this.state.loginhistory[i].countryName}/{this.state.loginhistory[i].regionName}</td>
        <td>{this.state.loginhistory[i].ismobile}</td>
        <td>{this.state.loginhistory[i].status}</td>
        <td>{date}</td>
      </tr>)
    }
    return table.reverse();
  }
  onChangePasswordSubmit = (e) => {
    const { t } = this.props;
    e.preventDefault();

    this.setState({ changePasswordLoading: true });

    const params = {
      _id: this.props.auth.user.id,
      oldpassword: this.state.oldpassword,
      password: this.state.password,
      password2: this.state.password2
    };

    axios.post(url + "cryptoapi/updatepassword", params).then(res => {
      if (res.data.success) {
        store.addNotification({
          title: t("SUCCESS"),
          message: t("PASSWORD_UPDATED"),
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: [
            "animated", "fadeIn"
          ],
          animationOut: [
            "animated", "fadeOut"
          ],
          dismiss: {
            duration: 1500,
            onScreen: true
          }
        });
        this.setState({
          errors: "",
          oldpassword: "",
          password: "",
          password2: "",
          changePasswordLoading: false
        });
      } else {
        this.setState({
          errors: res.data.errors,
          changePasswordLoading: false
        });
      }
    }).catch(response => {
      console.error(response);
      this.setState({ changePasswordLoading: false });
    });
  }

  checkOTP = e => {
    const { t } = this.props;
    e.preventDefault();
    //console.log(e);
    if (typeof this.state.otp != "undefined" && this.state.otp != "") {
      const otpdata = {
        _id: this.props.auth.user.id,
        otp: this.state.otp
      };
      axios.post(url + "cryptoapi/checkotp", otpdata).then(res => {
        if (res.data.success) {
          this.setState({ sms: 'Verified' })
          store.addNotification({
            title: t("SUCCESS"),
            message: res.data.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: [
              "animated", "fadeIn"
            ],
            animationOut: [
              "animated", "fadeOut"
            ],
            dismiss: {
              duration: 1500,
              onScreen: true
            }
          });
          this.setState({ errors: "" })
        } else {
          store.addNotification({
            title: t("ERRORS"),
            message: res.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: [
              "animated", "fadeIn"
            ],
            animationOut: [
              "animated", "fadeOut"
            ],
            dismiss: {
              duration: 1500,
              onScreen: true
            }
          });
        }
      }).catch();

    } else {
      store.addNotification({
        title: t("ERRORS"),
        message: t("OTP_IS_MISSING"),
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: [
          "animated", "fadeIn"
        ],
        animationOut: [
          "animated", "fadeOut"
        ],
        dismiss: {
          duration: 1500,
          onScreen: true
        }
      });
    }
  }

  onSendOTP = e => {
    const { t } = this.props;
    e.preventDefault();
    //console.log(e);
    if (typeof this.state.phone != "undefined" && this.state.phone != "") {
      const phonenumberdata = {
        _id: this.props.auth.user.id,
        phone: this.state.phone
      };
      axios.post(url + "cryptoapi/sendotp", phonenumberdata).then(res => {
        if (res.data.success) {
          store.addNotification({
            title: t("SUCCESS"),
            message: res.data.message,
            type: "success",
            insert: "top",
            container: "top-right",
            animationIn: [
              "animated", "fadeIn"
            ],
            animationOut: [
              "animated", "fadeOut"
            ],
            dismiss: {
              duration: 1500,
              onScreen: true
            }
          });
          this.setState({ errors: "" })
        } else {
          store.addNotification({
            title: t("ERRORS"),
            message: res.data.message,
            type: "danger",
            insert: "top",
            container: "top-right",
            animationIn: [
              "animated", "fadeIn"
            ],
            animationOut: [
              "animated", "fadeOut"
            ],
            dismiss: {
              duration: 1500,
              onScreen: true
            }
          });
        }
      }).catch();

    } else {
      store.addNotification({
        title: t("ERRORS"),
        message: t("PHONE_NUMBER_IS_EMPTY"),
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: [
          "animated", "fadeIn"
        ],
        animationOut: [
          "animated", "fadeOut"
        ],
        dismiss: {
          duration: 1500,
          onScreen: true
        }
      });
    }
  }
  onChangeNotificationSubmit = e => {
    const { t } = this.props;
    e.preventDefault();
    const updatenotification = {
      _id: this.props.auth.user.id,
      windoworder: this.state.windoworder.toString(),
      mobilesite: this.state.mobilesite.toString(),
      position: this.state.position.toString(),
      animation: this.state.animation.toString()
    };

    axios.post(url + "cryptoapi/updatenotification", updatenotification).then(res => {
      if (res.data.success) {
        store.addNotification({
          title: t("SUCCESS"),
          message: t("NOTIFICATION_PREFERENCES_UPDATED"),
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: [
            "animated", "fadeIn"
          ],
          animationOut: [
            "animated", "fadeOut"
          ],
          dismiss: {
            duration: 1500,
            onScreen: true
          }
        });
        this.setState({ errors: "" })
      } else {
        this.setState({ errors: res.data.errors })
      }
    }).catch();

  };

  copyText = e => {
    const { t } = this.props;
    console.log(this.state.currentTab, "currentTab")
    store.addNotification({
      title: t("SUCCESS"),
      message: t("COPIED"),
      type: "success",
      insert: "top",
      container: "top-right",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 1500,
        onScreen: true
      }
    });
    console.log(this.state.currentTab, "currentTab")
  }

  renderProfile() {
    const { errors } = this.state;
    { console.log('errrrrrrrrrrrrrrrrrrrr', errors) }
    const { t } = this.props;
    return (
      <div className="card-body">
        {console.log('names', this.state.name)}
        {/* <h3 className="assetTitle mb-5">Profile</h3> */}
        <h3 className="assetTitle mb-5">{t("PROFILE_DETAILS")}</h3>
        <hr />
        <div className='row'>
          <div className="col-xl-5 col-lg-5 col-md-6 col-sm-6">
            <div className="form-group">
              <label>{t("FIRST_NAME")}<span className='required text-danger'>*</span></label>
              <input value={this.state.firstName} onChange={this.handleChangeProfile} name='firstName' type="text" />
              {/* {readOnly className={classnames("form-control", { invalid: errors.userid })} } */}
              <span className="text-danger">{errors.firstName}</span>
            </div>

          </div>
          <div className="col-xl-5 col-lg-5 col-md-6 col-sm-6">
            <div className="form-group">
              <label>{t("LAST_NAME")}<span className='required text-danger'>*</span></label>
              <input onChange={this.handleChangeProfile} value={this.state.lastName} name='lastName' type="text" />
              <span className="text-danger">{errors.lastName}</span>
            </div>
          </div>

          <div className="col-xl-5 col-lg-5 col-md-6 col-sm-6">
            {/* <div className="form-group">
                                   <label>Attachments if any</label>
                                   <label className="custom-file form-control custom_file_posit_c" id="customFile">
                                      <input type="file"  onChange={this.handleChange} name="file1" className="custom-file-input" id="exampleInputFile" aria-describedby="fileHelp" />
                                      <span className="custom-file-control form-control-file"></span>
                                  </label>
                                  <span style={{color:"red"}}>{errors.query_image}</span>
                                </div> */}

            <div className="form-group">
              <label className='blue_lable_cont'>{t("ATTACHMENT_IF_ANY")}  <span style={{ color: "gray" }}>{t("IMAGE_SHOULD_ALLOWED_ONLY_PNG")}</span></label>
              <div className="custom-file">
                {/* {this.state.attachment !== '' ? <img src={window.URL.createObjectURL(this.state.attachment.name)} /> : <img src={`http://localhost:3528/images/${this.state.attachment.name}`}/>} */}
                {/* {formValue.frontImage !== '' ? <img className="profileimg" src={window.URL.createObjectURL(formValue?.frontImage)}  /> :
                                        <img className="profileimg" src={`http://localhost:2053/images/kyc/${Image}`}  /> } */}
                <input
                  type="file"
                  onChange={this.handleFileProfile}
                  name="profileImage" className="custom-file-input" id="exampleInputFile" aria-describedby="fileHelp" />

                <label className="custom-file-label" for="exampleInputFile">
                  {this.state.profileImage != null &&
                    this.state.profileImage.name
                  }
                  {this.state.profileImage == null &&
                    "Select File"
                  }
                  {/* {console.log('select files',this.state.profileurl)} */}
                </label>

              </div>
              <span style={{ color: "red" }}>{errors.profileImage}</span>
            </div>
            {console.log("this.state.profileurl", this.state.profileurl,this.state.Image)}
            <img
              className="img-fluid proofThumb"
              // src={this.state.profileImage ? URL.createObjectURL(this.state.profileImage) : `${keys.imageUrl}profile_images/${this.state.profileurl}` || ""}
              src={this.state.profileImage ? (URL.createObjectURL(this.state.profileImage)) : (this?.state?.Image ? `${keys.imageUrl}profile_images/${this.state.Image}` : '')}
            />

          </div>

        </div>

        <h3 className="assetTitle mb-5 mt-4">{t("RESIDENTIAL_DETAILS")}</h3>
        <hr />
        <div className='row'>
          <div className="col-xl-5 col-lg-5 col-md-6 col-sm-6">
            <div className="form-group">
              <label>{t("BUILDING_BLOCK")}<span className='required text-danger'>*</span></label>
              <input onChange={this.handleChangeProfile} type="text" value={this.state.buildingblock} name="buildingblock" />
              <span className="text-danger">{errors.buildingblock}</span>
            </div>
          </div>
          <div className="col-xl-5 col-lg-5 col-md-6 col-sm-6">
            <div className="form-group">
              <label>{t("ADDRESS")}<span className='required text-danger'>*</span></label>
              <input onChange={this.handleChangeProfile} name="address" value={this.state.address} type="text" />
              <span className="text-danger">{errors.address}</span>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className="col-xl-5 col-lg-5 col-md-6 col-sm-6">
            <div className="form-group">
              <label>{t("COUNTRY")}</label>
              {/* <Select isSearchable={false} name='country' onChange={this.handleChangeProfile}
                styles={this.styles} className="border_blue_select"
                width='100%'
                menuColor='red'
                options={options1}
              /> */}
              <CountryDropdown className="country_dd_cus"
                onChange={this.handleCountry} value={this.state.country} />
              <span className="text-danger">{errors.country}</span>
            </div>
          </div>
          <div className="col-xl-5 col-lg-5 col-md-6 col-sm-6">
            <div className="form-group">
              <label>{t("STATE_PROVISION")}<span className='required text-danger'>*</span></label>
              <input onChange={this.handleChangeProfile} name="State" type="text" value={this.state.State} />
              <span className="text-danger">{errors.State}</span>
            </div>
            {/* <div className="col-xl-5 col-lg-5 col-md-6 col-sm-6">
              <div className="form-group">
                <label>{t("STATE_PROVISION")}<span className='required text-danger'>*</span></label>
                <input onChange={this.onChange} value={this.state.userid} error={errors.userid} id="userid" type="text" readOnly className={classnames("form-control", { invalid: errors.userid })} />
                <span className="text-danger">{errors.userid}</span>
              </div>
            </div> */}




          </div>
          <div className="col-xl-5 col-lg-5 col-md-6 col-sm-6">
            <div className="form-group">
              <label>{t("CITY")}<span className='required text-danger'>*</span></label>
              <input onChange={this.handleChangeProfile} type="text" name='city' value={this.state.city} />
              <span className="text-danger">{errors.city}</span>
            </div>
          </div>
          <div className="col-xl-5 col-lg-5 col-md-6 col-sm-6">
            <div className="form-group">
              <label>{t("POSTAL_CODE")}<span className='required text-danger'>*</span></label>
              <input onChange={this.handleChangeProfile} value={this.state.postalcode} name="postalcode" type="text" />
              <span className="text-danger">{errors.postalcode}</span>
            </div>
          </div>
        </div>

        <h3 className="assetTitle mb-5 mt-4">{t("CONTACT_DETAILS")}</h3>
        <hr />
        <div className='row'>
          <div className="col-xl-5 col-lg-5 col-md-6 col-sm-6">
            <div className="form-group">
              <label>{t("EMAIL_CHANGE_PLACEHOLDER")}<span className='required text-danger'>*</span></label>
              <div className="input-group mb-3">
                <input type="email" onChange={this.handleChangeEmail} name="newEmail" value={this.state.newEmail} aria-label="Recipient's username" aria-describedby="button-addon2" />
                <button className="btn btn-outline-secondary" type="button" id="button-addon2" onClick={this.ChangeEmailAddress}>{t("UPDATE")}</button>
              </div>
              <span className="text-danger">{errors.changeemail}</span>
            </div>
          </div>
          <div className="col-xl-5 col-lg-5 col-md-6 col-sm-6">
            <div className="form-group">
              <label>{t("PHONE_NUMBER")}<span className='required text-danger'>*</span></label>
              <div className="input-group mb-3">
                <input type="text" value={this.state.phonenumber} onChange={this.handleChangeProfile} name="phonenumber" aria-label="Recipient's username" aria-describedby="button-addon2" />
                {/* <button className="btn btn-outline-secondary" type="button" id="button-addon2">{t("UPDATE")}</button> */}
              </div>
              <span className="text-danger">{errors.phonenumber}</span>
            </div>
          </div>




        </div>
        <div className='row'>
          <div className="col-lg-2 col-md-2 col-sm-12 mt-2 mt-lg-0">
            <div className="form-group">
              <label className="d-none d-md-block invisible label_height_upload">{t("LABEL_SPACE")}</label>
              <button onClick={this.UpdateProfile} className="btn btnDefaultNew px-4 mt-2">{t("UPDATE")}</button>
            </div>
          </div>
        </div>



      </div>
    )
  }
  renderBank() {
    const { errors } = this.state;

    return (
      <div className="card-body">
        {console.log('names', this.state.name)}
        {/* {/ <h3 className="assetTitle mb-5">Profile</h3> /} */}
        <form className="stoForm" noValidate onSubmit={this.onProfileSubmit}>
          <h3 className="assetTitle mb-5">Bank Account Details</h3>
          <hr />
          <h5 className="text-light mb-5">Add Account Details</h5>

          <div className='row'>
            <div className="col-xl-5 col-lg-5 col-md-6 col-sm-6">
              <div className="form-group">
                <label>Select Currency</label>
                <Select isSearchable={false}
                  styles={this.styles} className="border_blue_select"
                  width='100%'
                  menuColor='red'
                  options={options1}
                />
                <span className="text-danger">{errors.userid}</span>
              </div>

            </div>
            <div className="col-xl-5 col-lg-5 col-md-6 col-sm-6">
              <div className="form-group">
                <label>Account Holder Name</label>
                <input onChange={this.onChange} value={this.state.userid} error={errors.userid} id="userid" type="text" readOnly className={classnames("form-control", { invalid: errors.userid })} />
                <span className="text-danger">{errors.userid}</span>
              </div>

            </div>
            <div className="col-xl-5 col-lg-5 col-md-6 col-sm-6">
              <div className="form-group">
                <label>Bank Name</label>
                <input onChange={this.onChange} value={this.state.name} error={errors.name} id="name" type="text" className={classnames("form-control", { invalid: errors.name })} />
                <span className="text-danger">{this.state.name == '' ? errors.name : ''}{console.log('errors.name', errors.name)}</span>
              </div>
            </div>
            <div className="col-xl-5 col-lg-5 col-md-6 col-sm-6">
              <div className="form-group">
                <label>Account Number</label>
                <input onChange={this.onChange} value={this.state.userid} error={errors.userid} id="userid" type="text" readOnly className={classnames("form-control", { invalid: errors.userid })} />
                <span className="text-danger">{errors.userid}</span>
              </div>
            </div>
            <div className="col-xl-5 col-lg-5 col-md-6 col-sm-6">
              <div className="form-group">
                <label>IFSC Code</label>
                <input onChange={this.onChange} value={this.state.name} error={errors.name} id="name" type="text" className={classnames("form-control", { invalid: errors.name })} />
                <span className="text-danger">{this.state.name == '' ? errors.name : ''}{console.log('errors.name', errors.name)}</span>
              </div>
            </div>
            <div className="col-xl-5 col-lg-5 col-md-6 col-sm-6">
              <div className="form-group">
                <label>Bank Country</label>
                <Select isSearchable={false}
                  styles={this.styles} className="border_blue_select"
                  width='100%'
                  menuColor='red'
                  options={options1}
                />
                <span className="text-danger">{errors.userid}</span>
              </div>

            </div>
            <div className="col-xl-5 col-lg-5 col-md-6 col-sm-6">
              <div className="form-group">
                <label>Bank Address</label>
                <input onChange={this.onChange} value={this.state.name} error={errors.name} id="name" type="text" className={classnames("form-control", { invalid: errors.name })} />
                <span className="text-danger">{this.state.name == '' ? errors.name : ''}{console.log('errors.name', errors.name)}</span>
              </div>
            </div>
            <div className="col-xl-5 col-lg-5 col-md-6 col-sm-6">
              <div className="form-group">
                <label>Bank City</label>
                <input onChange={this.onChange} value={this.state.name} error={errors.name} id="name" type="text" className={classnames("form-control", { invalid: errors.name })} />
                <span className="text-danger">{this.state.name == '' ? errors.name : ''}{console.log('errors.name', errors.name)}</span>
              </div>
            </div>

          </div>


          <div className='row'>
            <div className="col-lg-2 col-md-2 col-sm-12 mt-2 mt-lg-0">
              <div className="form-group">
                <label className="d-none d-md-block invisible label_height_upload">Label Space</label>
                <button className="btn btnDefaultNew px-4 mt-2">Update</button>
              </div>
            </div>
          </div>
          <div>
            <div className='mt-4'>
              <h6 className='assetTitle'>My Bank Accounts</h6>

              <div className="table-responsive assetsTable table_radius">
                {/* <Scrollbars style={{ width: '100%', height: 300 }} renderTrackVertical={props=>
            <div className="track-vertical" />}> */}
                <table id="assetsTable" className="table">
                  <thead>
                    <tr>
                      <th>Default</th>
                      <th>Bank Name</th>
                      <th>Account No</th>
                      <th>Status</th>
                      <th>2 Factor Authentication</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>hjkhjk</td>
                      <td>hjkhjk</td>
                      <td>hjkhjk</td>
                      <td>hjkhjk</td>
                      <td>hjkhjk</td>
                    </tr>
                  </tbody>
                </table>
                {/* {/ </Scrollbars> /} */}
              </div>
            </div>
          </div>

        </form>
      </div>
    )
  }



  renderKyc() {
    const { errors } = this.state;
    { console.log('errorsdatsthis', this.state.errors) }
    console.log('errors.photo', errors)
    return (
      <div className="card-body">
        {console.log('names', this.state.name)}
        <h3 className="assetTitle mb-5">Identity Document</h3>
        <hr />
        <div className='row'>
          <div className="col-xl-5 col-lg-5 col-md-6 col-sm-6">
            <div className="form-group">
              <label>Identification Document {this?.state?.IDstatus == 'Verified' ? <span className='text-success f-12'>{this?.state?.IDstatus} </span> : <span className='text-danger f-12'>{this?.state?.IDstatus} </span>} </label>
              <Select isSearchable={false}
                styles={this.styles} className="border_blue_select"
                width='100%'
                menuColor='red'
                name='idproof'
                options={options1}
                isDisabled={(this?.state?.IDstatus == 'Verified' || this?.state?.IDstatus == 'Pending') ? true : false}
                onChange={this.handleselectKyc}
              />




              <span className="text-danger">{errors?.idproof}</span>
            </div>
          </div>
          <div className="col-xl-5 col-lg-5 col-md-6 col-sm-6">
            <div className="form-group">
              <label>Enter the selected document ID number {this?.state?.IDstatus == 'Verified' ? <span className='text-success f-12'>{this?.state?.IDstatus} </span> : <span className='text-danger f-12'>{this?.state?.IDstatus} </span>} </label>
              {console.log('this?.state?.IDstatus == ', this?.state?.IDstatus, this?.state?.IDstatus == 'Verified' || 'Pending')}
              <input onChange={this.handleKyc} value={this.state.idproofno} name='idproofno' type="text" disabled={(this?.state?.IDstatus == 'Verified' || this?.state?.IDstatus == 'Pending') ? true : false} />
              {/* {readOnly className={classnames( "form-control", { invalid: errors.userid })}} */}
              <span className="text-danger">{errors?.idproofno}</span>
            </div>
          </div>
          <div className="col-xl-5 col-lg-5 col-md-6 col-sm-6">
            <div className="form-group">
              <label className='blue_lable_cont'>Front Side {this?.state?.IDstatus == 'Verified' ? <span className='text-success f-12'>{this?.state?.IDstatus} </span> : <span className='text-danger f-12'>{this?.state?.IDstatus} </span>}</label>
              <div className="custom-file">
                <input
                  type="file"
                  onChange={this.handleFilekyc}
                  disabled={this?.state?.IDstatus == 'Verified' || this?.state?.IDstatus == 'Pending' ? true : false}
                  name="frontsidefile" className="custom-file-input" id="exampleInputFile" aria-describedby="fileHelp" />

                <label className="custom-file-label" for="exampleInputFile">
                  {this?.state?.frontsidefile ? this?.state?.frontsidefile?.name : "Select File"}
                </label>

              </div>
              <span className='text-danger'>{errors?.frontsidefile ? errors?.frontsidefile : <span style={{ color: "gray" }}>Max. 9MB size, jpg, png, pdf allowed</span>}</span>
              {/* <span style={{ color: "gray" }}>Max. 9MB size, jpg, png, pdf allowed</span> */}
            </div>
          </div>
          <div className="col-xl-5 col-lg-5 col-md-6 col-sm-6">
            <div className="form-group">
              <label className='blue_lable_cont'>Back Side {this?.state?.IDstatus == 'Verified' ? <span className='text-success f-12'>{this?.state?.IDstatus} </span> : <span className='text-danger f-12'>{this?.state?.IDstatus} </span>}</label>
              <div className="custom-file">
                <input
                  type="file"
                  onChange={this.handleFilekyc}
                  disabled={this?.state?.IDstatus == 'Verified' || this?.state?.IDstatus == 'Pending' ? true : false}
                  name="backsidefile" className="custom-file-input" id="exampleInputFile" aria-describedby="fileHelp" />

                <label className="custom-file-label" for="exampleInputFile">
                  {this?.state?.backsidefile ? this?.state?.backsidefile?.name : "Select File"}
                </label>

              </div>
              <span className='text-danger'>{errors?.backsidefile ? errors?.backsidefile : <span style={{ color: "gray" }}>Max. 9MB size, jpg, png, pdf allowed</span>}</span>
              {/* <span style={{ color: "gray" }}>Max. 9MB size, jpg, png, pdf allowed</span> */}
            </div>
          </div>

          <div className="col-xl-5 col-lg-5 col-md-6 col-sm-6">
            <div className="form-group">
              <label className='blue_lable_cont'>Selfie with Selected ID {this?.state?.IDstatus == 'Verified' ? <span className='text-success f-12'>{this?.state?.IDstatus} </span> : <span className='text-danger f-12'>{this?.state?.IDstatus} </span>}</label>
              <div className="custom-file">
                <input
                  type="file"
                  onChange={this.handleFilekyc}
                  disabled={this?.state?.IDstatus == 'Verified' || this?.state?.IDstatus == 'Pending' ? true : false}
                  name="selfiefile" className="custom-file-input" id="exampleInputFile" aria-describedby="fileHelp" />

                <label className="custom-file-label" for="exampleInputFile">
                  {this?.state?.selfiefile ? this?.state?.selfiefile?.name : "Select File"}
                </label>

              </div>
              <span className='text-danger'>{errors?.selfiefile ? errors?.selfiefile : <span style={{ color: "gray" }}>Max. 9MB size, jpg, png, pdf allowed</span>}</span>
              {/* <span style={{ color: "gray" }}>Max. 9MB size, jpg, png, pdf allowed</span> */}
            </div>
          </div>
          <div className="col-xl-5 col-lg-5 col-md-6 col-sm-6">
            <div className="form-group">
              <label className='blue_lable_cont'>Pan Card Image {this?.state?.IDstatus == 'Verified' ? <span className='text-success f-12'>{this?.state?.IDstatus} </span> : <span className='text-danger f-12'>{this?.state?.IDstatus} </span>} </label>
              <div className="custom-file">
                <input
                  type="file"
                  onChange={this.handleFilekyc}
                  disabled={this?.state?.IDstatus == 'Verified' || this?.state?.IDstatus == 'Pending' ? true : false}
                  name="pancardfile" className="custom-file-input" id="exampleInputFile" aria-describedby="fileHelp" />

                <label className="custom-file-label" for="exampleInputFile">
                  {this?.state?.pancardfile ? this?.state?.pancardfile?.name : "Select File"}
                </label>

              </div>
              <span className='text-danger'>{errors?.pancardfile ? errors?.pancardfile : <span style={{ color: "gray" }}>Max. 9MB size, jpg, png, pdf allowed</span>}</span>
              {/* <span style={{ color: "gray" }}>Max. 9MB size, jpg, png, pdf allowed</span> */}
            </div>
          </div>


        </div>

        <h3 className="assetTitle mb-0 mt-4">Proof of Residential Address</h3>
        <hr />
        <div className='row'>
          <div className="col-xl-5 col-lg-5 col-md-6 col-sm-6">
            <div className="form-group">
              {console.log('Addressstausta', (this?.state?.Addresstatus == 'Verified' || this?.state?.Addresstatus == 'Pending'), this?.state?.Addresstatus)}
              <label>Address Proof Document {this?.state?.Addresstatus == 'Verified' ? <span className='text-success f-12'>{this?.state?.Addresstatus} </span> : <span className='text-danger f-12'>{this?.state?.Addresstatus} </span>}</label>
              <Select isSearchable={false}
                styles={this.styles} className="border_blue_select"
                width='100%'
                menuColor='red'
                options={options1}
                onChange={this.handleselectAddressKyc}
                isDisabled={(this?.state?.Addresstatus == 'Verified' || this?.state?.Addresstatus == 'Pending') ? true : false}
              />
              <span className="text-danger">{errors?.addressproof}</span>
            </div>

          </div>
          <div className="col-xl-5 col-lg-5 col-md-6 col-sm-6">
            <div className="form-group">
              <label className='blue_lable_cont'>Upload the document {this?.state?.Addresstatus == 'Verified' ? <span className='text-success f-12'>{this?.state?.Addresstatus} </span> : <span className='text-danger f-12'>{this?.state?.Addresstatus} </span>} </label>
              <div className="custom-file">
                <input
                  type="file"
                  onChange={this.handleFilekyc}
                  disabled={this?.state?.Addresstatus == 'Verified' || this?.state?.Addresstatus == 'Pending' ? true : false}
                  name="addressfile" className="custom-file-input" id="exampleInputFile" aria-describedby="fileHelp" />

                <label className="custom-file-label" for="exampleInputFile">
                  {this?.state?.addressfile ? this?.state?.addressfile?.name : "Select File"}
                </label>

              </div>
              {/* <span style={{ color: "gray" }}>Max. 9MB size, jpg, png, pdf allowed</span> */}
              <span className='text-danger'>{errors?.addressfile ? errors?.addressfile : <span style={{ color: "gray" }}>Max. 9MB size, jpg, png, pdf allowed</span>}</span>
            </div>
          </div>


        </div>


        <div className='row'>
          <div className="col-lg-2 col-md-2 col-sm-12 mt-2 mt-lg-0">
            <div className="form-group">
              <label className="d-none d-md-block invisible label_height_upload">Label Space</label>
              <button className="btn btnDefaultNew px-4 mt-2" onClick={this.UpdateKyc}>Update</button>
            </div>
          </div>
        </div>
        <div>
        </div>

      </div>
    )
  }

  renderSupport() {
    const { errors } = this.state;
    const { CategoryList, attachments, Categoryissue } = this.state;
    const { t } = this.props
    return (
      <div className="card-body">
        {console.log('namesList', this.state.CategoryList)}
        {/* <h3 className="assetTitle mb-5">Profile</h3> */}
        <form className="stoForm" noValidate onSubmit={this.onProfileSubmit}>
          <h3 className="assetTitle mb-5">{t("SUPPORT")}</h3>
          <hr />
          <div className='row'>
            <div className="col-xl-5 col-lg-5 col-md-6 col-sm-6">
              <div className="form-group">
                <label>{t("TICKET_FOR")}</label>
                {/* <select class="form-select" aria-label="Default select example">
                  <option selected>{t("OPEN_THIS_SELECT_MENU")}</option>
                  <option value="1">{t("ONE")}</option>
                  <option value="2">{t("TWO")}</option>
                  <option value="3">{t("THREE")}</option>
                </select> */}
                <Select isSearchable={false}
                  styles={this.styles} className="border_blue_select"
                  width='100%'
                  menuColor='red'
                  onChange={this.handleSupport}
                  options={CategoryList}
                />
                <span className="text-danger">{errors.category}</span>
                {/* <label>Identifi cation Document</label> */}

                {/* <Select isSearchable={false}
                  styles={this.styles} className="border_blue_select"
                  width='100%'
                  menuColor='red'
                  options={options1}
                />
                <span className="text-danger">{errors.userid}</span> */}
              </div>
            </div>
            <div className="col-xl-5 col-lg-5 col-md-6 col-sm-6">
              <div className="form-group">
                <label>{t('MSG_TO_SUPPORT_TEAM')}</label>
                <input name="message" type="text" onChange={(e) => { this.setState({ message: e.target.value }) }} />
                <span className="text-danger">{errors.message}</span>
                {/* readOnly className={classnames("form-control", { invalid: errors.userid })} */}
              </div>
            </div>
            <div className="col-xl-5 col-lg-5 col-md-6 col-sm-6">
              <div className="form-group">
                <label className='blue_lable_cont'>{t('ATTACHMENT')}</label>
                <div className="custom-file">
                  <input
                    type="file"
                    onChange={this.handleFile}
                    name="attachments" className="custom-file-input" id="file" aria-describedby="fileHelp" />

                  <label className="custom-file-label" for="exampleInputFile">
                    {/* {this.state.attachment != null &&
                      this.state.attachment.name
                    }
                    {this.state.attachment == null &&
                      "Select File"
                    } */}
                    {attachments && attachments.name ? <small>{attachments.name}</small> : "Select File"}
                  </label>
                </div>
                {console.log('errors.Images', errors.Image)}
                <span className="text-danger">{errors.Image ? errors.Image : (errors.file ? errors.file : "")}</span>
                <img
                  className="img-fluid proofThumb"
                  // src={this.state.profileImage ? URL.createObjectURL(this.state.profileImage) : `${keys.imageUrl}profile_images/${this.state.profileurl}` || ""}
                  src={this.state.attachments && URL.createObjectURL(this?.state?.attachments)}
                />
                {errors.Image ? "" : <span style={{ color: "gray" }}>{t("IDENTITY_HINT1")}</span>}
              </div>
            </div>

            <div className=" col-12 mt-2 mt-lg-0">
              <div className="form-group">
                <label className="d-none d-md-block invisible label_height_upload">{t("LABEL_SPACE")}</label>
                <button onClick={this.CreateNewTicket} className="btn btnDefaultNew px-4 mt-2">{t("CREATE_TICKET")}</button>
              </div>
            </div>
          </div>

          {/* <h3 className="assetTitle mb-0 mt-4">{t("SUPPORT_DETAILS")}</h3>
        <hr/>
        {/* <div id="accordion" className='replytoadmin'>
  <div class="card">
    <div class="card-header" id="headingOne">
      <h5 class="mb-0">
        
        <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
        <h5 >{t("SUBJECT")} <span>{t("DEPOSIT_ISSUE")}</span></h5>   
        <h5 className='hidein767'>{t("TICKET_ID")} <span>#154875</span></h5>  
        <h5 className='hidein767'>{t("STATUS")} <span>{t("OPEN")}</span></h5>    
        <h5><span>+</span></h5>
        </button>
      </h5>
    </div>

    <div id="collapseOne" class="collapse" aria-labelledby="headingOne" data-parent="#accordion">
      <div class="card-body">
         <p>{t("CREATED_ON")}:2023-03-22 14:58</p> 
         <div className='d-flex'>
            <div>
                <img src={prof} className="prof"/>
                <p>{t("USER")}</p>
              </div> 
              <p>nbvghf hjfdugh ijfdgjhd fguhg</p>
         </div>
         <div className='showin767'>  
          <p className='mb-1'><span className='text-gray'>{t("TICKET_ID")}</span> #154875</p>  
          <p className='mb-3'><span className='text-gray'>{t("STATUS")} </span>{t("OPEN")} </p> 
         </div>
         <div className=''>
            <p className='mb-0'>{t("RLY_TO_ADMIN")}</p>
            <textarea className='form-control'></textarea>
         </div>
         <div className='d-sm-flex align-items-center justify-content-between'>
             <div><button className="btn btnDefaultNew px-4 mt-2">{t("REPLAY")}</button></div>
             <div><button className='btn btn-link'>{t("CLOSE_THE_TICKET")}</button></div>
         </div>
      </div>
    </div>


  </div>

  <div class="card">
    <div class="card-header" id="headingTwo">
    <h5 class="mb-0">
        
        <button class="btn btn-link" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
        <h5 >{t("SUBJECT")} <span>{t("DEPOSIT_ISSUE")}</span></h5>   
        <h5 className='hidein767'>{t("TICKET_ID")} <span>#154875</span></h5>  
        <h5 className='hidein767'>{t("STATUS")} <span>{t("OPEN")}</span></h5>    
        <h5><span>+</span></h5>
        </button>
      </h5>
    </div>

    <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
      <div class="card-body">
         <p>{t("CREATED_ON")}:2023-03-22 14:58</p> 
         <div className='d-flex'>
            <div>
                <img src={prof} className="prof"/>
                <p>{t("USER")}</p>
              </div> 
              <p>nbvghf hjfdugh ijfdgjhd fguhg</p>
         </div>
         <div className=''>
            <p className='mb-0'>{t("RLY_TO_ADMIN")}</p>
            <textarea className='form-control'></textarea>
         </div>
         <div className='d-sm-flex align-items-center justify-content-between'>
             <div><button className="btn btnDefaultNew px-4 mt-2 justify-content-center">{t("REPLAY")}</button></div>
             <div><button className='btn btn-link'>{t("CLOSE_THE_TICKET")}</button></div>
         </div>
      </div>
    </div>

    
  </div>
  
  
</div> */}


          {console.log('categoryissue', Categoryissue)}
          {Categoryissue && Categoryissue.length > 0 && Categoryissue.map((item, key) => {
            return (
              (item.replystatus == 'Open') &&
              <div className='replytoadmin '>
                <div class="w-full card-header  justify-content-between">
                  <>
                    <h5>Subject <span>{item.categoryName}</span></h5>
                    <h5 className=''>Ticket ID <span>#{item.tickerId}</span></h5>
                    <h5 className=''>Status <span>{item.replystatus}</span></h5>
                    <button className='themebtn' onClick={() => { this.Close(item.tickerId) }} >Close</button>
                  </>
                </div>
              </div>)
          })}
          <div>
          </div>

        </form>
      </div>
    )
  }
  checkbox(e) {
    const { id, value } = e.target;
    // let formData = { ...formValue, ...{ [id]: e.target.checked } }
    // setFormValue(formData)
    this.setState({ [id]: e.target.checked })

  }
  renderSecurity() {
    const { errors } = this.state;
    const { user } = this.props.auth;
    const { t } = this.props
    return (
      <div className="card-body" id="child1">
        <h3 className="assetTitle mb-5">{t("SECURITY")}</h3>
        <div className="row">
          <div className="col-md-12">
            <div className="accordianSubGroup">
              <h3 className="groupTitle">1. {t("EMAIL_AUTHENTICATION")} : <small className="userMailId">{user.email}</small> <small className="textVerifyGreen"><i className="far fa-check-circle"></i> {t("VERIFIED")}</small></h3>
            </div>

            {/* <div className="accordianSubGroup d-none">
              <h3 className = "groupTitle" > 2. SMS Authentication: {
                this.state.sms == "Not Verified" && (
                  <small className="textUnVerifyRed">
                      <i className="far fa-times-circle pr-1"></i>{this.state.sms}
                  </small>
                )
              } {
                this.state.sms == "Verified" && (
                  <span>
                    <small className="userMailId"></small>
                    <small className="textVerifyGreen">
                      <i className="far fa-check-circle"></i>{this.state.sms}
                    </small>
                  </span>
                )
              }
              </h3>
              <div className="row mt-3">
                <div className="col-xl-4 col-lg-5 col-md-6 col-sm-6">
                  <form noValidate onSubmit={this.onSendOTP} id="otp-form">
                    <div className="form-group input-group mobNumber">
                      <label>Mobile Number</label> 
                      {
                        this.state.sms != "Verified" ?
                        <PhoneInput placeholder="Enter phone number" country={ ''} value={this.state.phone} onChange={phone=> this.setState({ phone }) } className="disabled" 
                      // inputProps={{   
                      //   disabled : true
                      // }}
                      /> : 
                      <PhoneInput placeholder={this.state.phone} country={ ''} className="disabled" disabled={true} />
                      }
                      
                        <div className="input-group-append securityphone bot_10_px">
                          {this.state.sms != "Verified" ?
                          <button className="btn btnDefault no-hover" >Send OTP</button> : ''}
                        </div>
                    </div>
                  </form>
                </div>
                { this.state.sms != "Verified"?
                <form className="col-xl-8 col-lg-7 col-md-6 col-sm-6" noValidate onSubmit={this.checkOTP} id="otp-form">
                  <div className="row">
                    <div className="col-xl-6 col-lg-9 col-md-9 col-sm-12">
                      <div className="form-group">
                        <label>Enter 6 Digit OTP</label>
                        <input type="number" id="otp"  max="6" className="form-control"  onChange={(e) => {if(e.target.value.length>6) return false ; this.onChange(e); }} value={this.state.otp} />
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-3 col-md-2 col-sm-12">
                      <div className="form-group">
                        <label className="d-none d-md-block invisible">L</label>
                        <button className="btn btnDefault px-4 no-hover" type="submit">Save</button>
                      </div>
                    </div>
                  </div>
                </form>:'' }
              </div>
            </div> */}
            <div className="accordianSubGroup">
              <h3 className="groupTitle">2. {t("GOOGLE_AUNTHENTICATION")}: {
                (this.state.googleauth == '') ? (

                  <small className="textUnVerifyRed">
                    <i className="far fa-times-circle pr-1"></i>{t("DISABLED")}
                  </small>
                ) : (
                  <span>
                    <small className="userMailId"></small>
                    <small className="textVerifyGreen">
                      <i className="far fa-check-circle"></i>{t("VERIFIED")}
                    </small>
                  </span>
                )
              }</h3>
              <div className="row mt-3">
                <div className="col-xl-4 col-lg-4 col-md-6 col-10">
                  <div className="form-group">
                    <label>{t("2FA_CODE_WITHDRAW_CRYPTO")}</label>

                    <CopyToClipboard text={this.state.secretcode} onCopy={() => this.copyText('invite link', this.state.secretcode)} className="copy_clip_width">
                      <span>
                        <label>
                          <i class="fa fa-clipboard" aria-hidden="true"></i> <a href='javascript:void(0)'> {t("COPY")} </a>
                        </label>
                      </span>
                    </CopyToClipboard>

                  </div>
                  <div className="checkbox">

                    <label>
                      <input type="checkbox" id="accept" onChange={this.checkBoxClicked.bind(this)}
                        //  value={this.state.accept}
                        checked={this.state.accept === "true"}
                      />
                      <span className="cr"><i className="cr-icon fa fa-check"></i></span>
                      <span className="crcheckbox d-flex">{t("I_HAVE_BACKED_UP_SECRET_CODE")}</span>
                    </label>
                    <div>
                      <span className="text-danger">{errors.accept}</span>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-sm-2">
                  <div className="form-group">
                    <span className="scanCode"><img src={this.state.Factor2AImg} className="img-fluid" /></span>
                  </div>
                </div>

              </div>
              <div className="row">
                <div className="col-xl-4 col-lg-5 col-md-6 col-sm-6">
                  <div className="form-group">
                    <label>{t("LOGIN_PASSWORD")}</label>
                    <div class="input-group input_grp_pwd">
                      <input onChange={this.onChange} value={this.state.loginpassword} id="loginpassword" type={this.state.viewpassword ? "text" : "password"} error={errors.loginpassword} className={classnames("form-control", { invalid: errors.loginpassword })} />

                      <div class="input-group-append input_grp_pwd_append" onClick={() => { this.setState({ viewpassword: !this.state.viewpassword }) }}>
                        <span class="input-group-text">
                          {this.state.viewpassword ?
                            <i class="fa fa-eye"></i>
                            :
                            <i class="fa fa-eye-slash"></i>
                          }
                        </span>
                      </div>
                    </div>
                    <span className="text-danger">{errors.loginpassword}</span>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-5 col-md-6 col-sm-6">
                  <div className="form-group">
                    <label>{t("ENTER_DIGIT_GOOGLE_AUTHENTICATION_CODE")}</label>
                    <input onChange={this.onChange} value={this.state.onecode} id="onecode" type="number" error={errors.onecode} className={classnames("form-control", { invalid: errors.onecode })} />
                    <span className="text-danger">{errors.onecode}</span>
                  </div>
                </div>
                <div className="col-xl-4 col-lg-2 col-md-2 col-sm-12">
                  <div className="form-group">
                    <label className="d-none d-lg-block invisible">{t("LABEL_SPACE")}</label>
                    <button className="btn btnDefaultNew px-4 mt-2" onClick={this.tfaFormsubmit}>{this.state.googleauth != '' ? 'Disable' : 'Enable'}</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }


  renderChangePassword() {
    const { errors } = this.state;
    const { t } = this.props
    return (
      <div className="card-body" id="child1">
        <h3 className="assetTitle mb-5">{t("CHANGE_PASSWORD")}</h3>
        <form noValidate onSubmit={this.onChangePasswordSubmit} id="update-Changepassword">
          <div className='row'>
            <div className='col-12 col-md-12 col-lg-12 col-xl-9'>
              <div className='row'>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                  <div className="form-group">
                    <label>{t("CURRENT_PASSWORD")}</label>
                    <div class="input-group input_grp_pwd">
                      <input onChange={this.onChange} value={this.state.oldpassword} id="oldpassword" type={this.state.viewoldpassword ? "text" : "password"} error={errors.oldpassword} className={classnames("form-control", { invalid: errors.oldpassword })} />
                      <div class="input-group-append input_grp_pwd_append" onClick={() => { this.setState({ viewoldpassword: !this.state.viewoldpassword }) }}>

                        <span class="input-group-text">
                          {this.state.viewoldpassword ?
                            <i class="fa fa-eye"></i>
                            :
                            <i class="fa fa-eye-slash"></i>
                          }
                        </span>
                      </div>
                    </div>
                    <span className="text-danger">{errors.oldpassword}</span>
                  </div>
                </div>

              </div>
            </div>

          </div>
          <div className='row'>
            <div className='col-12 col-md-12 col-lg-12 col-xl-9'>
              <div className='row'>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                  <div className="form-group">
                    <label>{t("NEW_PASSWORD")}</label>
                    <div class="input-group input_grp_pwd">

                      <input onChange={this.onChange} value={this.state.password} id="password" type={this.state.viewnewpassword ? "text" : "password"} error={errors.password} className={classnames("form-control", { invalid: errors.password })} />
                      <div class="input-group-append input_grp_pwd_append" onClick={() => { this.setState({ viewnewpassword: !this.state.viewnewpassword }) }}>

                        <span class="input-group-text">
                          {this.state.viewnewpassword ?
                            <i class="fa fa-eye"></i>
                            :
                            <i class="fa fa-eye-slash"></i>
                          }
                        </span>
                      </div>
                    </div>
                    <span className="text-danger">{errors.password}</span>
                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
                  <div className="form-group">
                    <label>{t("CONFIRM_PASSWORD")}</label>
                    <div class="input-group input_grp_pwd">


                      <input onChange={this.onChange} value={this.state.password2} id="password2" type={this.state.viewconfirmpassword ? "text" : "password"} error={errors.password2} className={classnames("form-control", { invalid: errors.password2 })} />
                      <div class="input-group-append input_grp_pwd_append" onClick={() => { this.setState({ viewconfirmpassword: !this.state.viewconfirmpassword }) }}>
                        <span class="input-group-text">
                          {this.state.viewconfirmpassword ?
                            <i class="fa fa-eye"></i>
                            :
                            <i class="fa fa-eye-slash"></i>
                          }
                        </span>
                      </div>
                    </div>
                    <span className="text-danger">{errors.password2}</span>
                  </div>
                </div>
                <div className="col-lg-3 col-md-2 col-sm-12">
                  <div className="form-group">
                    <label className="d-none d-md-block invisible">{t("LABEL_SPACE")}</label>
                    <button className="btn btnDefaultNew px-4 mt-2 mt-md-0">{t("UPDATE")}</button>
                    {this.state.changePasswordLoading && <LoaderSmall />}
                  </div>
                </div>
              </div>
            </div>
          </div>

        </form>
      </div>
    )
  }


  renderAlertsNotifications() {
    const { t } = this.props;
    return (
      <div className="card-body" id="child1">
        <h3 className="assetTitle mb-5">{t("ALERT_AND_NOTIFICATION")}</h3>
        <div className="row">
          <form noValidate onSubmit={this.onChangeNotificationSubmit} id="update-notification">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="form-group settingsCheckList">
                <div className="checkbox">
                  <label>
                    <input type="checkbox" id="windoworder" onChange={this.checkBoxClicked.bind(this)} checked={this.state.windoworder === "true"} />
                    <span className="cr"><i className="cr-icon fa fa-check"></i></span>
                    <span className="listText">{t("SHOWS_CONFIRMATION_WINDOW_ORDERS")}</span>
                  </label>
                </div>
                <div className="checkbox">
                  <label>
                    <input type="checkbox" id="mobilesite" onChange={this.checkBoxClicked.bind(this)} checked={this.state.mobilesite === "true"} />
                    <span className="cr"><i className="cr-icon fa fa-check"></i></span>
                    <span className="listText">{t("SHOW_CONFIRMATION_WINDOW_ORDERS_MOBILE_SETS")}</span>
                  </label>
                </div>
                {/*
                <div className="checkbox">
                  <label>
                    <input type="checkbox" id="position" onChange={this.checkBoxClicked.bind(this)} checked={this.state.position==="true" } />
                    <span className="cr"><i className="cr-icon fa fa-check"></i></span>
                    <span className="listText">Shows Confirmation Window for changing leverage while having open position</span>
                  </label>
                </div> */}
                <div className="checkbox">
                  <label>
                    <input type="checkbox" id="animation" onChange={this.checkBoxClicked.bind(this)} checked={this.state.animation === "true"} />
                    <span className="cr"><i className="cr-icon fa fa-check"></i></span>
                    <span className="listText">{t("TURN_ON")}: {t("ORDERBOOK_ANIMATION")}</span>
                  </label>
                </div>
              </div>
              <div className="form-group">
                <label className="d-none d-lg-block invisible">{t("LABEL_SPACE")}</label>
                <button className="btn btnDefaultNew px-4">{t("UPDATE")}</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }


  renderLoginHistory() {
    const { t } = this.props;
    return (
      <div className="card-body" id="child1">
        <h3 className="assetTitle mb-3">{t("")}Login History</h3>
        <div className="table-responsive assetsTable table_radius">
          {/* <Scrollbars style={{ width: '100%', height: 300 }} renderTrackVertical={props=>
            <div className="track-vertical" />}> */}
          <table id="assetsTable" className="table">
            <thead>
              <tr className="wow flipInX" data-wow-delay=".5s;">
                <th>{t("S_NO")}</th>
                <th>{t("IP_ADDRESS")}</th>
                <th>{t("OPERATING_SYSTEM_BROWSER")}</th>
                <th>{t("COUNTRY_REGION")}</th>
                <th>{t("ISMOBILE")}?</th>
                <th>{t("STATUS")}</th>
                <th>{t("DATE")}</th>
              </tr>
            </thead>
            <tbody>
              {this.loginHistory()}
            </tbody>
          </table>
          {/* </Scrollbars> */}
        </div>
      </div>
    )
  }


  renderTab(tabIndex) {
    switch (tabIndex) {
      case 1:
        return this.renderSecurity()
      case 2:
        return this.renderChangePassword()
      case 3:
        // return this.renderAlertsNotifications()
        return ''
      case 4:
        return this.renderLoginHistory()
      case 5:
        return this.renderBank()
      case 6:
        return this.renderKyc()
      case 7:
        return this.renderSupport()

      default:
        return this.renderProfile()
    }
  }


  render() {
    const { errors } = this.state;
    const { user } = this.props.auth;
    const { t } = this.props;
    const { keypermission, applicationName } = this.state;
    // this.setState({currentTab :props?.location?.state?.data})
    return (
      <div className='bg_strip_none'>
        {/* <TradeHeader /> */}
        <Navtradebar />
        <div>
          <section>
            <div className="row mx-0">
              <div className="col-md-12 px-0">

                {/* <div id="accordionPersonal" className="accordianComman"> */}

                <section className="wallet-container settingsec">

                  <nav className="wallet-side-menu more_menus">
                    <div className="nav nav-tabs" id="navLink-tab" role="tablist">
                      <a className={this.state.currentTab == 0 ? "nav-item active" : "nav-item"} onClick={() => this.setState({ currentTab: 0 })}>{t("PROFILE")}</a>
                      <a className={this.state.currentTab == 5 ? "nav-item active" : "nav-item"} onClick={() => this.setState({ currentTab: 5 })}>{t("BANK")}</a>
                      <a className={this.state.currentTab == 6 ? "nav-item active" : "nav-item"} onClick={() => this.setState({ currentTab: 6 })}>{t("KYC")}</a>
                      <a className={this.state.currentTab == 7 ? "nav-item active" : "nav-item"} onClick={() => this.setState({ currentTab: 7 })}>{t("SUPPORT")}</a>
                      <a className={this.state.currentTab == 1 ? "nav-item active" : "nav-item"} onClick={() => this.setState({ currentTab: 1 })}>{t("SECURITY")}</a>
                      <a className={this.state.currentTab == 2 ? "nav-item active" : "nav-item"} onClick={() => this.setState({ currentTab: 2 })}>{t("CHANGE_PASSWORD")}</a>
                      {/* <a className="nav-item" onClick={() => this.setState({ currentTab: 3 })}>Alerts & Notifications</a> */}
                      <a className={this.state.currentTab == 4 ? "nav-item active" : "nav-item"} onClick={() => this.setState({ currentTab: 4 })}>{t("LOGIN_HISTORY")}</a>
                    </div>
                  </nav>

                  <div className="wallet-tab">
                    {this.renderTab(this.state.currentTab)}
                  </div>

                </section>

              </div>
            </div>
          </section>
        </div>
        <Footer />
      </div>
    );
  }
}


Settings.propTypes = {
  profileUser: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});
export default connect(
  mapStateToProps,
  { profileUser, tfaFormsubmit, createApiKey, deleteApiKey }
)(withTranslation()(Settings), withRouter(Settings));
