
/* eslint-disable */

import React, { Component } from 'react'
import '../css/style-trade.css';
// import '../js/main.js';

import TradeHeader from './TradeHeader'
import Footer from './Footer'
import Navtradebar from './Navtradebar'
import LogoNewFixHead from "../images/logo.png"
import LogoLightTheme from "../images/logo.png"
import Selicon1 from "../images/selicon1.png"
import Selicon2 from "../images/selicon2.png"
import Selicon4 from "../images/selicon4.png"
import Selicon5 from "../images/selicon5.png"
import Selicon6 from "../images/selicon6.png"
import Selicon7 from "../images/Icon.png"
import dcntrimage from "../images/dcntr.png"

import { getspotPertual, spotorderPlacing, displayingDigits, getspotTradeData, getspotuserTradeData, spotcancelTrade, logoutUser, getspotPricevalue, triggerstop, changeopenpositions } from "../actions/authActions";

import GreenPinkDot from "../images/btn-green-pink-dot.png"
import PinkDot from "../images/btn-pink-dot.png"
import GreenDot from "../images/btn-green-dot.png"
import TradeChart from "../images/tradeChart.jpg"
import Slider, { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';
import { withTranslation } from 'react-i18next';
import axios from "axios";
import { Link, withRouter } from 'react-router-dom';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { store } from 'react-notifications-component';
import { Modal, Button } from 'react-bootstrap/';
import Select from 'react-select';
import io from "socket.io-client";
import { Scrollbars } from 'react-custom-scrollbars';
import { TVChartContainer } from './TVChartContainer';
import keys from "../actions/config";
import CryptoJS from "crypto-js"
import * as moment from "moment";
const cryptoPass = keys.cryptoPass
const url = keys.baseUrl;

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    borderBottom: '1px dotted pink',
    color: state.isSelected ? 'red' : 'blue',
    padding: 20,
  }),
  control: () => ({
    // none of react-select's styles are passed to <Control />
  }),
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  }
}

const options1 = [
  { value: 'Beshare', label: 'Beshare' },
  { value: '3Commas', label: '3Commas' },
  { value: 'AIcoin', label: 'AIcoin' },
  { value: 'alpha-algo', label: 'alpha-algo' },
  { value: 'Cornix', label: 'Cornix' },
  { value: '合约帝', label: '合约帝' },
];

const options = [
  { value: 'GoodTillCancelled', label: 'GoodTillCancelled' },
  { value: 'ImmediateOrCancel', label: 'ImmediateOrCancel' },
  { value: 'FillOrKill', label: 'FillOrKill' },
];


class Trade extends Component {

  constructor(props) {
    console.log("prpos", props)

    super(props);
    ////console.log('typeof this.props.match.params.currency');
    // //console.log(typeof this.props.match.params.currency);
    if (typeof this.props.match.params.currency == 'undefined' || localStorage.getItem('curpair1') == "null" || localStorage.getItem('curpair1') == null) {
      var currency = 'BTC-USDT';
      localStorage.setItem('curpair1', 'BTC-USDT')
    } else {
      var currency = this.props.match.params.currency == 'login' ? 'BTC-USDT' : this.props.match.params.currency;
      this.props.history.push("/spot/" + currency)
      console.log("currency", currency)
      localStorage.setItem('curpair1', currency)
    }
    currency = currency.toUpperCase();

    var date = new Date();
    var curhour = date.getHours();
    var timeuntillfunding = (curhour > 0 && curhour < 8) ? (8 - curhour) : (curhour > 8 && curhour < 16) ? (16 - curhour) : (curhour > 16 && curhour < 24) ? (24 - curhour) : 0;

    this.state = {
      value: (currency == 'BTC-USDT') ? 195 : 50,
      dummyleverage: 10,
      curcontract: currency,
      chartpair: currency.replace("-", ""),
      secondcurrency: currency.split('-')[1],
      firstcurrency: currency.split('-')[0],
      chartpair: currency.replace("-", ""),
      price: 7800,
      instantprice: 7800,
      index_price: 7800,
      rescentcount: 50,
      markprice: 0,
      quantity: 0.00000000,
      order_value: 0.00000000,
      order_cost: 0.00000000,
      curarray: ["BTC-USDT", "ETH-USDT", "ETH-BTC", "XRP-USDT", "XRP-BTC"],
      btcprice: 0,
      balance: 0,
      firstbalance: 0,
      secondbalance: 0,
      searchpair: "",
      allrecords: [],
      last_price: 1,
      maxquantity: 0,
      minquantity: 0,
      minmargin: 0,
      close_quantity: 0,
      btcvolume: 0,
      close_price: 0,
      prevoiusprice: 0,
      selllimit: 0,
      buylimit: 0,
      buysumvalue: 0,
      sellsumvalue: 0,
      maxleverage: 0,
      floating: (currency == 'BTC-USDT' || currency == 'ETH-USDT') ? 2 : 6,
      spot_floating: '',
      taker_fee: 0.075,
      maker_fee: 0.075,
      disable: true,
      buydisable: true,
      selldisable: true,
      readOnly: false,
      records: [],
      temprecordsforsorting: [],
      favrecords: [],
      assetOverview: [],
      tradeTableAll: [],
      buyOrder: [],
      prevbuyOrder: [],
      sellOrder: [],
      prevsellOrder: [],
      orderHistory: [],
      Rescentorder: [],
      Histroydetails: [],
      position_details: [],
      allposition_details: [],
      closed_positions: [],
      Conditional_details: [],
      Filleddetails: [],
      lastpricedet: "",
      daily_details: [],
      errors: {},
      spotpricecolor: 'greenText',
      orderbookshow: 'greenpink',
      ordertype: 'Limit',
      triggerordertype: 'limit',
      theme: (document.getElementById("root").classList.contains(localStorage.getItem('theme')) ? "Dark" : "White"),
      limit: false,
      conditional: false,
      reduce_only: false,
      post_only: false,
      show: false,
      tpshow: false,
      show1: false,
      tpreadOnly: true,
      trailreadOnly: true,
      stopreadOnly: true,
      readOnly1: true,
      update: true,
      timeinforcetype: { value: 'GoodTillCancelled', label: 'GoodTillCancelled' },
      perpetual: '',
      username: '',
      message: '',
      trailingstopdistance: 0,
      alertmessage: '',
      TradingView: '',
      messages: [],
      Liqprice: 0,
      takeprofit: 0,
      stopprice: 0,
      buytotal: 0,
      selltotal: 0,
      stopper: 0,
      tptrigger_type: null,
      stoptrigger_type: null,
      blocktime: '',
      change: 0,
      timeuntillfunding: timeuntillfunding,
      buyorsell: 'buy',
      alertmessage1: '',
      alertmessage2: '',
      limitbuypercentage: "",
      limitsellpercentage: "",
      marketbuypercentage: "",
      marketsellpercentage: "",
      buyprice: 0,
      sellprice: 0,
      sortpair: "Normal",
      sortprice: "Normal",
      sortchange: "Normal",
      takerFees: 0,
      marketPrice: 0,
      showTable: 'order',
      orderPrice: 0,
      orderHistoryPage: 1,
      orderHistoryCnt: 0,
      tempstopprice: "",
      stoplimitsellstopprice: "",
      stoplimitbuylimitprice: "",
      stoplimitselllimitprice: "",
      marketpairValue: currency.split('-')[1],
      ordervalue: 0,
      confirmcancel: false,
      id: '',
      buyquantity: '',
      orderprice: '',
      ordersellprice: '',
      orderbuyprice: '',
      histroyDetailsCnt: 0,
      filledDetailsCnt: 0,
      histroydetailsPage: 1,
      filledDetailsPage: 1
    };
    console.log('orderprice----', this.state.ordersellprice, this.state.orderbuyprice)

    console.log('this.state', this.state)
    this.socket = io(keys.socketUrl, { secure: true, transports: ['polling'], jsonp: false, rejectUnauthorized: false }); //live
    // this.socket = io(keys.socketUrl,{transports:['polling']});

    this.socket.on('RECEIVE_MESSAGE', function (data) {
      ////console.log(data);
      addMessage(data);
    });

    this.socket.on('recentTrade', function (data) {
      ////console.log(data);
      appendRecentData(data);
    });

    this.socket.on('TRADE1', function (data) {
      console.log("spotorderPlacingspotorderPlacing", data);
      getPricevalue();
      // if( data.contractdetails !== undefined && data.contractdetails.first_currency !== undefined &&  data.contractdetails.first_currency==this.state.first_currency)
      // {
      appendData(data);
      // }
    });

    this.socket.on('TRADEBIN', function (data) {
      ////console.log(data, 'tradebindata');
      // getPricevalue();
      // if( data.contractdetails !== undefined && data.contractdetails.first_currency !== undefined &&  data.contractdetails.first_currency==this.state.first_currency)
      // {
      appendBin(data);
      // }
    });

    this.socket.on('USERTRADE', function (data) {
      console.log(data, 'userddata');
      // let userid = this.props.auth.user.id;
      userAppendData(data);
    });



    this.socket.on('PRICEDETAILS', function (data) {
      // console.log(data, 'PRICEDETAILS');
      priceAppendData(data);
      // ShowfavouritesFilter(data)
    });

    this.socket.on('NOTIFICATION', function (data) {
      store.addNotification({
        title: t("SUCCESS"),
        message: data,
        type: "success",
        insert: "top",
        container: "top-right",
        pauseOnHover: true,
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 2000,
          onScreen: true
        }
      });
    });

    this.socket.on('adminliquidity-' + this.props.auth.user.id, function (data) {
      // console.log("-----data", data)
      // secondcurrency: currency.split('-')[1],
      // firstcurrency: currency.split('-')[0],


      appendBalance(data)

    });

    const appendBalance = data => {
      //console.log('~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~',data)
      try {
        if (data.Currency == this.state.firstcurrency) {

          this.setState({ firstbalance: data.latestbalance })

        } else if (data.Currency == this.state.secondcurrency) {
          this.setState({ secondbalance: data.latestbalance })
        }
      }
      catch (err) { }
    }

    const appendBin = data => {

      // console.log("datadatadata",data.pair)
      if (data.message == 'tradeTableAll' && data.buyOrder !== undefined && data.sellOrder !== undefined) {
        for (var i = 0; i < this.state.records.length; i++) {
          var pairCheck = this.state.records[i].first_currency + "-" + this.state.records[i].second_currency
          // if (data.firstCurrency == this.state.firstcurrency && data.secondCurrency == this.state.secondcurrency) {
          if (this.props.match.params.currency == pairCheck) {
            if (this.state.records[i].tiker_root == data.pair) {
              var buyOrder = data.buyOrder;
              var sellOrder = data.sellOrder;
              this.setState({
                buyOrder: buyOrder,
                sellOrder: sellOrder,
              });
            }
          }
        }
      }
    }

    const appendRecentData = data => {
      for (var i = 0; i < this.state.records.length; i++) {
        var pairCheck = this.state.records[i].first_currency + "-" + this.state.records[i].second_currency
        if (this.props.match.params.currency == pairCheck) {
          if (this.state.records[i].tiker_root == data.pair) {
            let recentTrade = this.state.Rescentorder
            recentTrade.pop()
            this.setState({
              Rescentorder: [...data.data, ...recentTrade],
            });
          }
        }
      }
    }

    const appendData = data => {

      this.setState({ prevbuyOrder: this.state.buyOrder, prevsellOrder: this.state.sellOrder });
      if (data.message == 'tradeTableAll' && data.buyOrder !== undefined && data.sellOrder !== undefined && data.contractdetails !== undefined && data.Rescentorder !== undefined) {
        // console.log('insideifffffffff',data)

        //console.log(data.contractdetails.first_currency)
        //console.log(this.state.first_currency)
        if (data.contractdetails.first_currency == this.state.firstcurrency) {
          //console.log('insideif')
          var assetdetails = data.assetdetails;
          var contractdetails = data.contractdetails;

          var buyOrder = data.buyOrder;
          var sellOrder = data.sellOrder;
          var Rescentorder = data.Rescentorder;
          // console.log(sellOrder,'///////////sellOrder')
          this.setState({
            buyOrder: buyOrder,
            sellOrder: sellOrder,
            Rescentorder: Rescentorder,
            mainmargin: contractdetails.maint_margin / 100,
          });
        }
      }
    };


    const priceAppendData = data => {

      // console.log("dataaaaaaaaaaaaaaaa",data);
      const { records, pricedet } = this.state;
      // console.log("recordsrecordsrecords",records)
      if (data) {
        var searcharr = [];
        records.map((item, key) => {
          if (item.tiker_root == data.tiker_root) {
            searcharr.push({
              ...item,
              ...{
                last: data.markprice,
                low: data.low,
                high: data.high,
                change: data.change,
                volume: data.secvolume,
              }
            })
          } else {
            searcharr.push(item)
          }
        })

        this.setState({ records: searcharr })
      }
      if (data && (data.tiker_root == this.state.chartpair.replace("USDT", "USD") || data.tiker_root == this.state.chartpair)) {

        // if (data && data.tiker_root == this.state.chartpair) {
        // console.log("data.data.last", data);
        // first_currency,second_currency

        // var floating = (this.state.chartpair == 'BTCUSDT' || this.state.chartpair == 'ETHUSDT') ? 2 : 8;
        var floating = ((data.first_currency == 'BTC' || data.first_currency == 'ETH') && data.second_currency == 'USDT') ? 2 : (data.second_currency == 'BTC' || data.second_currency == 'ETH') ? 8 : 5;

        // let floating = data.floatingDigits ? data.floatingDigits : 8
        this.setState({ markprice: parseFloat(data.markprice).toFixed(floating), last_price: data.last != null ? data.last : 1 });
        if (this.state.prevoiusprice != 0 && this.state.prevoiusprice < data.markprice) {
          this.setState({ spotpricecolor: "greenText" });
        }
        else {
          this.setState({ spotpricecolor: "pinkText" });
        }
        this.setState({ prevoiusprice: this.state.markprice, floating: floating });


        let pricedetData = pricedet;
        console.log("pricedetpricedetpricedet", pricedet)
        if (typeof pricedetData != 'undefined') {
          pricedetData[0]['high'] = data.high;
          pricedetData[0]['low'] = data.low;
          pricedetData[0]['secvolume'] = data.secvolume;
        } else {
          pricedetData = [{
            high: data.high,
            low: data.low,
            secvolume: data.secvolume,
          }];
        }

        console.log("pricedetDatapricedetData", pricedetData)
        this.setState({ change: data.change, pricedet: pricedetData })
      }
    }

    const getPricevalue = () => {
      var findObj = {
        firstCurrency: this.state.firstcurrency,
        secondCurrency: this.state.secondcurrency
      };
      this.props.getspotPricevalue(findObj);
    }


    const userAppendData = data => {
      if (data.message == 'tradeTableAll' && data.orderHistory !== undefined && data.assetdetails !== undefined && data.Conditional_details !== undefined) {
        //console.log("-----data0", data)
        var orderHistory = data.orderHistory;
        var Histroydetails = data.Histroydetails;
        var Filleddetails = data.Filleddetails;
        var lastpricedet = data.lastpricedet;
        var assetdetails = data.assetdetails;
        var Conditional_details = data.Conditional_details;
        var orderHistoryCnt = data.orderHistoryCnt;


        this.setState({
          orderHistory: orderHistory,
          orderHistoryCnt,
          Histroydetails: Histroydetails,
          // lastpricedet        : lastpricedet,
          Filleddetails: Filleddetails,
          assetOverview: assetdetails,
          Conditional_details: Conditional_details,
          // instantprice        : (lastpricedet.length?lastpricedet.price:0,
        });
      }
    };


    //get message
    axios
      .get(url + "cryptoapi/chat-data")
      .then(res => {
        if (res.status === 200) {
          // //console.log("RESRESRESR");
          // //console.log(res.data);
          let filterrecords = [];
          for (var i = 0; i < res.data.length; i++) {
            var chatdata = {};
            chatdata.message = res.data[i].message;
            if (res.data[i].userId != null && res.data[i].userId.name != '') {
              chatdata.author = res.data[i].userId.name;
            } else {
              if (res.data[i].userId != null) {
                var split_name = res.data[i].userId.email.split("@");
                chatdata.author = split_name[0];
              }
            }
            chatdata.moderator = (typeof res.data[i].userId.moderator != 'undefined') ? res.data[i].userId.moderator : 0;
            filterrecords.push(chatdata);
          }
          this.setState({ messages: filterrecords })
          // //console.log(this.state.messages);
        }
      })
      .catch();
    //get message

    const addMessage = data => {
      this.setState({ messages: [...this.state.messages, data] });
    };
    const userTradeFun = data => {

      this.setState({ userTrade: [...this.state.userTrade, data] });

    };

    this.sendMessage = ev => {
      ev.preventDefault();
      //save Chat Message to database
      if (this.state.message != '') {
        var chatdata = {};
        chatdata.message = this.state.message;
        chatdata.userId = this.props.auth.user.id;
        axios
          .post(url + "cryptoapi/chat-add", chatdata)
          .then(res => {
            if (res.status === 200) {
              //console.log(res.data.message);
            }
          })
          .catch();
        var name = "";
        if (this.props.auth.user.name != "") {
          this.state.username = this.props.auth.user.name;
        } else {
          var split_name = this.props.auth.user.email.split("@");
          this.state.username = split_name[0];
        }
        var fmoderator = (typeof this.props.auth.user.moderator != 'undefined') ? this.props.auth.user.moderator : 0;
        this.socket.emit('SEND_MESSAGE', {
          author: this.state.username,
          message: this.state.message,
          moderator: fmoderator,
        })
        this.setState({ message: '' });
      }
      else {
        store.addNotification({
          title: t("ERRORS"),
          message: t("MESSAGE_IS_EMPTY"),
          type: "danger",
          insert: "top",
          container: "top-right",
          pauseOnHover: true,
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 1500,
            onScreen: true
          }
        });
      }
    }
    this.sendTradeMessage = ev => {
      ev.preventDefault();
      this.socket.emit('username', this.props.auth.user.id);
      this.socket.emit('SEND_TRADE', {
        toid: this.props.auth.user.id,
        id: this.props.auth.user.id,
        message: this.state.userTradedata
      })
      this.socket.emit('disconnect', {
        id: this.props.auth.user.id,
      })
    }
    this.ordertype_changes = this.ordertype_changes.bind(this);
    this.orderHistoryLoadMore = this.orderHistoryLoadMore.bind(this);
    this.spotHistoryDetailsLoadMore = this.spotHistoryDetailsLoadMore.bind(this)
    this.spotFilledDetailsLoadMore = this.spotFilledDetailsLoadMore.bind(this)
    this.searchPairFilter = this.searchPairFilter.bind(this);
  }

  toggleMode = e => {

    if (document.body.classList == "") {
      document.body.classList.add('themeLight');
      this.setState({ theme: "White" })
    }
    else {
      document.body.classList.remove('themeLight');
      this.setState({ theme: "Dark" })
    }
  }
  onLogoutClick = e => {
    e.preventDefault();
    store.addNotification({
      title: t("LOGOUT_SUCCESS"),
      message: t("COME_JOIN"),
      type: "success",
      insert: "top",
      container: "top-right",
      pauseOnHover: true,
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: 2000,
        onScreen: true
      }
    });
    let data = { userId: this.props.auth.user.id }
    this.props.logoutUser(data);
    window.location.href = "";
  };

  searchpair = e => {
    this.setState({ [e.target.id]: e.target.value });
    var searcharr = [];
    for (var i = 0; i < this.state.allrecords.length; i++) {
      if (this.state.allrecords[i].tiker_root.indexOf(e.target.value.toUpperCase()) !== -1) {
        searcharr.push(this.state.allrecords[i]);
      }
      if (i == this.state.allrecords.length - 1) {
        this.setState({ records: searcharr })
      }
    }
  }
  Showfavourites = market => {
    // console.log("inside");
    var fromlocal = JSON.parse(localStorage.getItem("favouritepairs"));
    var searcharr = [];
    if (fromlocal != null) {
      this.state.allrecords.map((itemallrecords, i) => {
        fromlocal.map((itemfromlocal, i) => {

          if (itemallrecords.tiker_root == itemfromlocal) {
            //console.log("fromlocalfromlocal", itemfromlocal);
            searcharr.push(itemallrecords)
            this.setState({ favrecords: searcharr })
          }
        })
      })
      // this.state.dashboardlistingarray.map((listarray,i)=>{})
    }
  }
  searchPairFilter = (data) => {
    const { records, firstcurrency, secondcurrency } = this.state;
    if (data && (data.pairname == this.state.chartpair.replace("USDT", "USD") || data.pairname == this.state.chartpair)) {
      var searcharr = [];

      records.map((item, key) => {
        // console.log("----item.tiker_root = data.pairname",item.tiker_root = data.pairname)
        if (item.tiker_root == data.pairname) {
          searcharr.push({
            ...item,
            ...{
              last: data.last,
              low: data.low,
              high: data.high,
              change: data.change,
              volume: data.volume,
            }
          })
        } else {
          searcharr.push(item)
        }
      })
      this.setState({ records: searcharr })
    }

    if (data && (data.pairname == this.state.chartpair.replace("USDT", "USD") || data.pairname == this.state.chartpair)) {
      let floating = data.floatingDigits ? data.floatingDigits : 8
      this.setState({ markprice: parseFloat(data.last).toFixed(floating), last_price: !isNaN(data.last) ? data.last : 0, floating: floating });

      // this.setState({ prevoiusprice: this.state.markprice, floating: floating });
    }

  }

  marketpair = market => {
    //console.log(market.target.innerHTML, 'market');
    // this.setState({ [e.target.id]: e.target.value });
    var searcharr = [];
    for (var i = 0; i < this.state.allrecords.length; i++) {
      if (this.state.allrecords[i].second_currency.toUpperCase() == market.target.innerHTML.toUpperCase() || market.target.innerHTML == 'All') {
        searcharr.push(this.state.allrecords[i]);
      }
      if (i == this.state.allrecords.length - 1) {
        this.setState({ records: searcharr })
        //console.log(searcharr, 'searcharr')
      }
    }
    this.setState({ marketpairValue: market.target.innerHTML.toUpperCase() })
  }
  loadmoreRescentorder = () => {
    axios
      .post(url + "cryptoapi/spotloadmoreRescentorder", { pair: this.state.firstcurrency + this.state.secondcurrency, rescentcount: this.state.rescentcount + 50 })
      .then(res => {
        this.setState({ Rescentorder: res.data.data, rescentcount: (this.state.rescentcount + 50) })
      });
  }
  handleChangeStart = () => {
    //console.log('Change event started')
  };

  handleChange = value => {

    this.setState({ dummyleverage: value });
  };


  percentagequantity = (e) => {

    var typee = e.target.id;
    var typeoftrade = typee.split("-")[1]
    var percetage = typee.split("-")[0]
    // var Nordertypee = typee.split("-")[2]
    // console.log("New",Nordertypee)
    var ordertypee = this.state.ordertype
    var buyquanpercent = 0
    var convert = parseFloat(this.state.secondbalance) / parseFloat(this.state.markprice)
    // console.log("----typeoftrade", typeoftrade, ordertypee)
    // console.log("convertconvertconvertconvert",convert);
    if (typeoftrade == "buy") {
      // this.state.secondbalance
      if (ordertypee == "Limit") {
        buyquanpercent = (parseFloat(percetage) * parseFloat(convert)) / 100
        buyquanpercent = convert > 0 ? parseFloat(buyquanpercent).toFixed(8) : 0
        var buyorder_value = parseFloat(buyquanpercent * this.state.buyprice).toFixed(8);

        // if (parseFloat(buyquanpercent) > 0) {
        //   this.setState({ buytotal: parseFloat(buyorder_value).toFixed(8) });
        //   this.setState({ buyquantity: buyquanpercent, limitbuypercentage: percetage })
        // } else {
        //   this.setState({ buyquantity: 0 })
        // }

        let buyquantity = 0, buyquantityPice = 0
        buyquantityPice = (parseFloat(this.state.secondbalance) * (percetage / 100));
        // buyquantity = buyquantityPice / parseFloat(this.state.markprice)
        buyquantity = buyquantityPice / parseFloat(this.state.buyprice)
        console.log('buyquantitiy hihi', buyquantity)
        this.setState({ buytotal: parseFloat(buyquantityPice).toFixed(8), buyquantity: buyquantity, limitbuypercentage: percetage })

      }
      if (ordertypee == "Market") {
        //console.log("insident  buy marlket");
        // buyquanpercent=(parseFloat(percetage)* parseFloat(this.state.secondbalance))/100
        buyquanpercent = (parseFloat(percetage) * parseFloat(convert)) / 100
        buyquanpercent = convert > 0 ? buyquanpercent : 0
        var buyorder_value = parseFloat(buyquanpercent * this.state.buyprice).toFixed(8);

        if (parseFloat(buyquanpercent) > 0) {
          console.log('buyorder_value hihi', buyorder_value, parseFloat(buyorder_value).toFixed(8))
          this.setState({ buytotal: parseFloat(buyorder_value).toFixed(8) });
          this.setState({ buyquantity: buyquanpercent, marketbuypercentage: percetage })
        } else {
          this.setState({ buyquantity: 0 })
        }
      }
    } else if (typeoftrade == "sell") {
      if (ordertypee == "Limit") {
        console.log("---------------------------------------- insident  buy Limit -----------------------------------------");

        buyquanpercent = (parseFloat(percetage) * parseFloat(this.state.firstbalance)) / 100
        var sellorder_value = parseFloat(buyquanpercent * this.state.sellprice).toFixed(8);
        // if (parseFloat(buyquanpercent) > 0) {
        //   this.setState({ selltotal: sellorder_value });

        //   this.setState({ sellquantity: buyquanpercent, limitsellpercentage: percetage })

        // } else {
        //   this.setState({ sellquantity: 0 })
        // }

        let sellquantity = 0, sellquantityPice = 0
        sellquantity = (parseFloat(this.state.firstbalance) * (percetage / 100));
        // let markprice = this.state.orderPrice == 0? this.state.markprice : this.state.orderPrice
        // sellquantityPice = sellquantity * parseFloat(this.state.markprice)
        sellquantityPice = sellquantity * parseFloat(this.state.sellprice)

        //selltotal
        this.setState({ selltotal: parseFloat(sellquantityPice).toFixed(8), sellquantity: sellquantity, limitsellpercentage: percetage })

      }

      if (ordertypee == "Market") {
        // //console.log("insident  buy marlket");
        buyquanpercent = (parseFloat(percetage) * parseFloat(this.state.firstbalance)) / 100
        var sellorder_value = parseFloat(buyquanpercent * this.state.sellprice).toFixed(8);

        if (parseFloat(buyquanpercent) > 0) {
          this.setState({ selltotal: parseFloat(sellorder_value).toFixed(8) });
          this.setState({ sellquantity: buyquanpercent, marketsellpercentage: percetage })
        } else {
          this.setState({ sellquantity: 0 })
        }
      }
    }


  }


  balanceperc = e => {

    var orderprice = this.state.markprice;
    var balanceperc = e.target.innerHTML.replace("%", "");

    var initial_balance = parseFloat(this.state.balance) * parseFloat(balanceperc) / 100;

    var leveragebal = parseFloat(this.state.value) * parseFloat(this.state.balance);

    var openfee = parseFloat(leveragebal) * parseFloat(this.state.taker_fee) / 100;

    var closefee = parseFloat(openfee) * parseFloat(this.state.value) / parseFloat(this.state.value + 1);

    var btcval = parseFloat(initial_balance) - (parseFloat(openfee) + parseFloat(closefee));
    var quanti = parseFloat(btcval) * parseFloat(orderprice);

    this.setState({ quantity: parseFloat(quanti).toFixed() });
    this.calculation();

  };


  triggerPrice = (param) => {
    var type = param.target.innerHTML;
    // //console.log(type,'params');
    var trigger_price = (type == 'Last') ? this.state.last_price : (type == 'Index') ? this.state.index_price : this.state.markprice;
    // //console.log(trigger_price);
    this.setState({ trigger_price: trigger_price, trigger_type: type });
  }
  triggertp = (param) => {
    var type = param.target.innerHTML;
    // //console.log(type,'params');
    var trigger_price = (type == 'Last') ? this.state.last_price : (type == 'Index') ? this.state.index_price : this.state.markprice;
    // //console.log(trigger_price);
    this.setState({ takeprofit: trigger_price, tptrigger_type: type });
  }
  triggerstop = (param) => {
    var type = param.target.innerHTML;
    // //console.log(type,'params');
    var trigger_price = (type == 'Last') ? this.state.last_price : (type == 'Index') ? this.state.index_price : this.state.markprice;
    // //console.log(trigger_price);
    this.setState({ stopprice: trigger_price, stoptrigger_type: type });
  }

  conditionalPrice = (param) => {
    var type = param.target.innerHTML;
    (type == 'Limit') ? this.setState({ readOnly: false, triggerordertype: type }) : this.setState({ readOnly: true, triggerordertype: type });
  }
  ordertype_changes = (type) => {
    //console.log('type------->', type)
    if (type == 'Limit') {
      if (this.state.ordertype != 'Limit') {
        this.setState({ buyquantity: '', buytotal: '', sellquantity: '', selltotal: '' });
      }
      this.setState({ limit: true, conditional: false, ordertype: type, price: '' });
    }
    else if (type == 'Conditional') {
      this.setState({ conditional: true, ordertype: type, limit: false });
    } else if (type == 'Stop Limit') {
      this.setState({ limit: false, market: false, buyStopLimittype: true, ordertype: type, price: '' });
    }
    else {
      if (this.state.ordertype != 'Market') {
        this.setState({ buyquantity: '', buytotal: '', sellquantity: '', selltotal: '' });
      }
      this.setState({ limit: false, market: true, conditional: false, ordertype: type });
    }


  }
  tpClose = (param) => {
    this.setState({ tpshow: false });
  }
  handleClose1 = (param) => {
    this.setState({ show1: false });
  }


  tpshow = (param) => {
    var type = param.target.innerHTML;
    this.setState({ tpshow: true });
  }

  handleShow = (e) => {
    var type = e.target.innerHTML;
    this.setState({ buyorsell: type.toLowerCase(), price: this.state.markprice, alertmessage: "" });
  }
  // handleShow22 = (type) => {
  //   var curarray = ["BTC-USDT", "ETH-USDT", "ETH-BTC", "XRP-USDT", "XRP-BTC"]
  //   if (curarray.includes(this.state.curcontract)) {
  //     var orderprice = this.state.ordertype == 'Market' ? this.state.markprice : this.state.price;
  //   }
  //   else {
  //     var orderprice = this.state.ordertype == 'Market' ? (this.state.buyorsell == 'buy') ? this.state.selllimit : this.state.buylimit : this.state.price;
  //   }
  //   var balance = (this.state.buyorsell == 'buy') ? this.state.secondbalance : this.state.firstbalance;
  //   var order_value = (this.state.buyorsell == 'buy') ? this.state.order_value : this.state.quantity;
  //   // if (this.state.ordertype == 'Limit' && this.state.buyorsell == "buy" && parseFloat(this.state.price) > parseFloat(this.state.markprice) && curarray.includes(this.state.curcontract)) {
  //   //   this.setState({ alertmessage: "Entry price you set must be lower or equal to " + this.state.markprice })
  //   //   return false;
  //   // }
  //   // else if (this.state.ordertype == 'Limit' && this.state.buyorsell == "sell" && parseFloat(this.state.price) < parseFloat(this.state.markprice) && curarray.includes(this.state.curcontract)) {
  //   //   this.setState({ alertmessage: "Entry price you set must be higher or equal to " + this.state.markprice })
  //   //   return false;
  //   // }
  //   // else {
  //   //   this.setState({ alertmessage: "" })
  //   // }
  //   if (parseFloat(this.state.quantity) < parseFloat(this.state.minquantity)) {
  //     store.addNotification({
  //       title: "Success!",
  //       message: "Quantity must not be lesser than " + this.state.minquantity,
  //       type: "danger",
  //       insert: "top",
  //       container: "top-right",
  //       animationIn: ["animated", "fadeIn"],
  //       animationOut: ["animated", "fadeOut"],
  //       dismiss: {
  //         duration: 1500,
  //         onScreen: true
  //       }
  //     });
  //     return false;
  //   }
  //   if (parseFloat(this.state.quantity) > parseFloat(this.state.maxquantity)) {
  //     store.addNotification({
  //       title: "Success!",
  //       message: "Quantity must not be higher than " + this.state.maxquantity,
  //       type: "danger",
  //       insert: "top",
  //       container: "top-right",
  //       animationIn: ["animated", "fadeIn"],
  //       animationOut: ["animated", "fadeOut"],
  //       dismiss: {
  //         duration: 1500,
  //         onScreen: true
  //       }
  //     });
  //     return false;
  //   }
  //
  //   if (isNaN(orderprice)) {
  //     store.addNotification({
  //       title: "Success!",
  //       message: "Price is invalid",
  //       type: "danger",
  //       insert: "top",
  //       container: "top-right",
  //       animationIn: ["animated", "fadeIn"],
  //       animationOut: ["animated", "fadeOut"],
  //       dismiss: {
  //         duration: 1500,
  //         onScreen: true
  //       }
  //     });
  //     return false;
  //   }
  //
  //   if (orderprice < 0.00000001) {
  //     store.addNotification({
  //       title: "Success!",
  //       message: "Price of contract must not be lesser than 0.00000001",
  //       type: "danger",
  //       insert: "top",
  //       container: "top-right",
  //       animationIn: ["animated", "fadeIn"],
  //       animationOut: ["animated", "fadeOut"],
  //       dismiss: {
  //         duration: 1500,
  //         onScreen: true
  //       }
  //     });
  //     return false;
  //   }
  //   else if (parseFloat(balance) < parseFloat(order_value)) {
  //     store.addNotification({
  //       title: "Error!",
  //       message: "Due to insuffient balance order cannot be placed",
  //       type: "danger",
  //       insert: "top",
  //       container: "top-right",
  //       animationIn: ["animated", "fadeIn"],
  //       animationOut: ["animated", "fadeOut"],
  //       dismiss: {
  //         duration: 1500,
  //         onScreen: true
  //       }
  //     });
  //     return false;
  //   }
  //   else {
  //     if (this.state.ordertype == 'Conditional' && this.state.trigger_price < 1) {
  //       store.addNotification({
  //         title: "Error!",
  //         message: "Trigger Price of contract must not be lesser than 1",
  //         type: "danger",
  //         insert: "top",
  //         container: "top-right",
  //         animationIn: ["animated", "fadeIn"],
  //         animationOut: ["animated", "fadeOut"],
  //         dismiss: {
  //           duration: 1500,
  //           onScreen: true
  //         }
  //       });
  //       return false;
  //     }
  //this.state.buytotal
  //
  //
  //     this.setState({ show: true });
  //   }
  // }
  handleShow22 = (e) => {

    // var type = e.target.innerHTML; // e.target.data-
    var type = e.target.id
    console.log('typesgal', type)
    var res = type.split(" ");
    var temptype = res[0].toLowerCase()
    console.log("buyorseeel type temptype", temptype)
    this.setState({ buyorsell: temptype });
    var curarray = ["BTC-USDT", "ETH-USDT", "ETH-BTC", "XRP-USDT", "XRP-BTC"]
    if (curarray.includes(this.state.curcontract)) {
      var orderprice = this.state.ordertype == 'Market' ? this.state.markprice : this.state[this.state.buyorsell + "price"];
    }
    else {
      var orderprice = this.state.ordertype == 'Market' ? (this.state.buyorsell == 'buy') ? this.state.selllimit : this.state.buylimit : this.state[this.state.buyorsell + "price"];
    }
    this.setState({ orderprice: orderprice })

    var buyorsellquantity = temptype == "buy" ? this.state.buyquantity : this.state.sellquantity;
    var balance = (temptype == 'buy') ? this.state.secondbalance : this.state.firstbalance;
    var order_value = (this.state.buyorsell == 'buy') ? this.state.buytotal : this.state.sellquantity;
    var pricetwo = this.state.buyorsell == "buy" ? this.state.buyprice : this.state.sellprice
    console.log("balance", temptype, this.state.secondbalance, this.state.firstbalance, balance)
    console.log('typeuseit', [type + "quantity"])
    if (parseFloat(this.state[type + "quantity"]) < parseFloat(this.state.minquantity)) {
      store.addNotification({
        title: t("ERRORS"),
        message: t("QUANTITY_MUST_NOT_LESS_THAN") + this.state.minquantity + '.',
        type: "danger",
        insert: "top",
        container: "top-right",
        pauseOnHover: true,
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 1500,
          onScreen: true
        }
      });
      return false;
    }
    if (buyorsellquantity == "" || parseFloat(buyorsellquantity) < 0 ||
      isNaN(buyorsellquantity) || buyorsellquantity == undefined) {
      store.addNotification({
        title: t("ERRORS"),
        message: t("PLEASE_ENTER_QUANTITY"),
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 1500,
          onScreen: true,
          pauseOnHover: true
        }
      });
      return false;
    }
    if (parseFloat(this.state[type + "quantity"]) > parseFloat(this.state.maxquantity)) {
      store.addNotification({
        title: t("ERRORS"),
        message: t("QUANTITY_MUST_NOT_MORE_THAN") + this.state.maxquantity + '.',
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 1500,
          onScreen: true,
          pauseOnHover: true
        }
      });
      return false;
    }

    if (isNaN(orderprice)) {
      store.addNotification({
        title: t("ERRORS"),
        message: t("PRICE_IS_INVALID"),
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 1500,
          onScreen: true,
          pauseOnHover: true
        }
      });
      return false;
    }

    if (orderprice < 0.00000001 && this.state.ordertype != 'Market') {
      store.addNotification({
        title: t("ERRORS"),
        message: t("PRICE_LESS_THAN"),
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 1500,
          onScreen: true,
          pauseOnHover: true
        }
      });
      return false;
    }
    else if (parseFloat(balance) < parseFloat(order_value)) {
      console.log("hi i worked", parseFloat(balance), parseFloat(order_value))
      store.addNotification({
        title: t("ERRORS"),
        message: t("INSUFFICIENT_BALANCE"),
        type: "danger",
        insert: "top",
        container: "top-right",
        animationIn: ["animated", "fadeIn"],
        animationOut: ["animated", "fadeOut"],
        dismiss: {
          duration: 1500,
          onScreen: true,
          pauseOnHover: true
        }
      });
      return false;
    }
    else {
      if (this.state.ordertype == 'Stop Limit') {


        // if (this.state.ordertype == 'Stop Limit' && this.state.buyorsell == "buy") {

        // if (parseFloat(pricetwo) > parseFloat(this.state.tempstopprice) && parseFloat(this.state.tempstopprice) > parseFloat(this.state.markprice)) {
        // this.setState({ show: true });

        // }
        // else {
        //   // this.setState({show:true});
        //   store.addNotification({
        //     title: "Error!",
        //     message: "Limit Price should be Greater than that of Spot Price and Stop Price",
        //     type: "danger",
        //     insert: "top",
        //     container: "top-right",
        //     animationIn: ["animated", "fadeIn"],
        //     animationOut: ["animated", "fadeOut"],
        //     dismiss: {
        //       duration: 1500,
        //       onScreen: true
        //     }
        //   });
        //   return false
        // }
        // }
        // if (this.state.ordertype == 'Stop Limit' && this.state.buyorsell == "sell") {
        // if (parseFloat(this.state.markprice) > parseFloat(this.state.tempstopprice) && parseFloat(this.state.tempstopprice) > parseFloat(pricetwo)) {
        // this.setState({ show: true });
        // }
        // else {
        //   // this.setState({show:true});
        //   store.addNotification({
        //     title: "Error!",
        //     message: "Market Price should be Greater than that of Limit Price and Stop Price",
        //     type: "danger",
        //     insert: "top",
        //     container: "top-right",
        //     animationIn: ["animated", "fadeIn"],
        //     animationOut: ["animated", "fadeOut"],
        //     dismiss: {
        //       duration: 1500,
        //       onScreen: true
        //     }
        //   });
        //   return false
        // }

        // }

      } else if (this.state.ordertype == 'Conditional' && this.state.trigger_price < 1) {
        store.addNotification({
          title: t("ERRORS"),
          message: t("TRIGGER_PRICE_MUST_NOT_BE_LESS_THAN"),
          type: "danger",
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 1500,
            onScreen: true,
            pauseOnHover: true
          }
        });
        return false;
      }



      this.setState({ show: true });
    }
  }

  handleClose = () => {
    this.setState({ show: false });
  }

  // orderPlacing = (type) => {
  //   if (this.state.stopreadOnly == false) {
  //     if (this.state.buyorsell == 'buy' && parseFloat(this.state.markprice) <= parseFloat(this.state.stopprice)) {
  //       this.setState({ alertmessage1: "Stop Loss price you set must be lower than " + this.state.markprice });
  //       return false;
  //     }
  //     else if (this.state.buyorsell == 'buy' && parseFloat(this.state.Liqprice) >= parseFloat(this.state.stopprice)) {
  //       this.setState({ alertmessage1: "Stop Loss price you set must be higher than " + parseFloat(this.state.Liqprice).toFixed(this.state.floating) });
  //       return false;
  //     }
  //     else if (this.state.buyorsell == 'sell' && parseFloat(this.state.markprice) >= parseFloat(this.state.stopprice)) {
  //       this.setState({ alertmessage1: "Stop Loss price you set must be higher than " + this.state.markprice });
  //       return false;
  //     }
  //     else if (this.state.buyorsell == 'sell' && parseFloat(this.state.Liqprice) <= parseFloat(this.state.stopprice)) {
  //       this.setState({ alertmessage1: "Stop Loss price you set must be lower than " + parseFloat(this.state.Liqprice).toFixed(this.state.floating) });
  //       return false;
  //     }
  //     else {
  //       this.setState({ alertmessage1: '' });
  //     }
  //   }
  //   if (this.state.tpreadOnly == false) {
  //     if (this.state.buyorsell == 'buy' && parseFloat(this.state.markprice) >= parseFloat(this.state.takeprofit)) {
  //       this.setState({ alertmessage2: "Stop Loss price you set must be higher than " + this.state.markprice });
  //       return false;
  //     }
  //     else if (this.state.buyorsell == 'sell' && parseFloat(this.state.markprice) >= parseFloat(this.state.takeprofit)) {
  //       this.setState({ alertmessage2: "Stop Loss price you set must be lower than " + this.state.markprice });
  //       return false;
  //     }
  //     else {
  //       this.setState({ alertmessage2: '' });
  //     }
  //   }
  //   // return false;
  //   let userid = this.props.auth.user.id;
  //   var check1 = type.target.className.includes("btnBuy");
  //   if (this.state.buyorsell == 'buy') {
  //     this.setState({ buydisable: false, show: false });
  //   } else {
  //     this.setState({ selldisable: false, show: false });
  //   }
  //   var curarray = ["BTC-USDT", "ETH-USDT", "ETH-BTC", "XRP-USDT", "XRP-BTC"]
  //   // alert(this.state.curcontract)
  //   // alert(curarray.includes(this.state.curcontract))
  //   if (curarray.includes(this.state.curcontract)) {
  //     var orderprice = this.state.ordertype == 'Market' ? this.state.markprice : this.state.price;
  //   }
  //   else {
  //     var orderprice = this.state.ordertype == 'Market' ? (this.state.buyorsell == 'buy') ? this.state.selllimit : this.state.buylimit : this.state.price;
  //   }
  //
  //   let orderData = {
  //     "quantity": this.state.quantity,
  //     "price": orderprice,
  //     "userid": userid,
  //     "pair": this.state.firstcurrency + this.state.secondcurrency,
  //     "pairname": this.state.firstcurrency + this.state.secondcurrency,
  //     "firstcurrency": this.state.firstcurrency,
  //     "secondcurrency": this.state.secondcurrency,
  //     "timeinforcetype": this.state.timeinforcetype.value,
  //     "buyorsell": this.state.buyorsell,
  //     "ordertype": (this.state.ordertype == 'Conditional') ? this.state.triggerordertype : this.state.ordertype,
  //     "trigger_price": (this.state.ordertype == 'Conditional') ? this.state.trigger_price : 0,
  //     "trigger_type": (this.state.ordertype == 'Conditional') ? this.state.trigger_type : null,
  //     "tptrigger_type": this.state.tptrigger_type,
  //     "stoptrigger_type": this.state.stoptrigger_type,
  //     "post_only": this.state.post_only,
  //     "reduce_only": this.state.reduce_only,
  //     "stopcheck": this.state.stopreadOnly,
  //     "takeprofitcheck": this.state.tpreadOnly,
  //     "takeprofit": this.state.takeprofit,
  //     "stopprice": this.state.stopprice,
  //     newdate: moment()
  //   }
  //   this.setState({ disable: false });
  //   this.setState({ update: false })
  //   var encryptuserdata = CryptoJS.AES.encrypt(JSON.stringify(orderData), cryptoPass).toString();
  //   var jsonencryt = {
  //     token: encryptuserdata
  //   }
  //
  //   this.props.spotorderPlacing(jsonencryt);
  // };

  orderPlacing = (type) => {
    if (this.state.stopreadOnly == false) {
      if (this.state.buyorsell == 'buy' && parseFloat(this.state.markprice) <= parseFloat(this.state.stopprice)) {
        this.setState({ alertmessage1: "Stop Loss price you set must be lower than " + this.state.markprice });
        return false;
      }
      else if (this.state.buyorsell == 'buy' && parseFloat(this.state.Liqprice) >= parseFloat(this.state.stopprice)) {
        this.setState({ alertmessage1: "Stop Loss price you set must be higher than " + parseFloat(this.state.Liqprice).toFixed(this.state.floating) });
        return false;
      }
      else if (this.state.buyorsell == 'sell' && parseFloat(this.state.markprice) >= parseFloat(this.state.stopprice)) {
        this.setState({ alertmessage1: "Stop Loss price you set must be higher than " + this.state.markprice });
        return false;
      }
      else if (this.state.buyorsell == 'sell' && parseFloat(this.state.Liqprice) <= parseFloat(this.state.stopprice)) {
        this.setState({ alertmessage1: "Stop Loss price you set must be lower than " + parseFloat(this.state.Liqprice).toFixed(this.state.floating) });
        return false;
      }
      else {
        this.setState({ alertmessage1: '' });
      }
    }
    if (this.state.tpreadOnly == false) {
      if (this.state.buyorsell == 'buy' && parseFloat(this.state.markprice) >= parseFloat(this.state.takeprofit)) {
        this.setState({ alertmessage2: "Stop Loss price you set must be higher than " + this.state.markprice });
        return false;
      }
      else if (this.state.buyorsell == 'sell' && parseFloat(this.state.markprice) >= parseFloat(this.state.takeprofit)) {
        this.setState({ alertmessage2: "Stop Loss price you set must be lower than " + this.state.markprice });
        return false;
      }
      else {
        this.setState({ alertmessage2: '' });
      }
    }
    // return false;
    let userid = this.props.auth.user.id;
    var check1 = type.target.className.includes("btnBuy");
    if (this.state.buyorsell == 'buy') {
      this.setState({ buydisable: false, show: false });
    } else {
      this.setState({ selldisable: false, show: false });
    }
    var curarray = ["BTC-USDT", "ETH-USDT", "ETH-BTC", "XRP-USDT", "XRP-BTC"]
    // alert(this.state.curcontract)
    // alert(curarray.includes(this.state.curcontract))
    if (curarray.includes(this.state.curcontract)) {
      var orderprice = this.state.ordertype == 'Market' ? this.state.markprice : this.state[this.state.buyorsell + "price"];
    }
    else {
      var orderprice = this.state.ordertype == 'Market' ? (this.state.buyorsell == 'buy') ? this.state.selllimit : this.state.buylimit : this.state[this.state.buyorsell + "price"];
    }
    let orderData = {
      "quantity": this.state[this.state.buyorsell + "quantity"],
      "market_value_order": this.state.ordervalue,
      "price": orderprice,
      "userid": userid,
      "pair": this.state.firstcurrency + this.state.secondcurrency,
      "pairname": this.state.firstcurrency + this.state.secondcurrency,
      "firstcurrency": this.state.firstcurrency,
      "secondcurrency": this.state.secondcurrency,
      "timeinforcetype": this.state.timeinforcetype.value,
      "buyorsell": this.state.buyorsell,
      "ordertype": (this.state.ordertype == 'Conditional') ? this.state.triggerordertype : this.state.ordertype,
      "trigger_price": (this.state.ordertype == 'Stop Limit') ? this.state.tempstopprice : 0,
      "trigger_type": (this.state.ordertype == 'Conditional') ? this.state.trigger_type : (this.state.ordertype == 'Stop Limit') ? "Last" : null,
      "tptrigger_type": this.state.tptrigger_type,
      "stoptrigger_type": this.state.stoptrigger_type,
      "post_only": this.state.post_only,
      "reduce_only": this.state.reduce_only,
      "stopcheck": this.state.stopreadOnly,
      "takeprofitcheck": this.state.tpreadOnly,
      "takeprofit": this.state.takeprofit,
      "stopprice": this.state.stopprice,
      newdate: moment()
    }
    console.log('orderData', orderData);
    //return false;
    this.setState({ disable: false });
    this.setState({ update: false })
    var encryptuserdata = CryptoJS.AES.encrypt(JSON.stringify(orderData), cryptoPass).toString();
    var jsonencryt = {
      token: encryptuserdata
    }

    this.props.spotorderPlacing(jsonencryt);
    this.setState({ buyquantity: 0 })
  };


  orderType = (e) => {
    if (e.target.id == 'nav-tradeLimit-tab') {
      this.setState({ limit: true });
    }
    else {
      this.setState({ limit: false });
    }
  };

  cancelFunction = (e) => {
    console.log("====== IN")
    this.setState({ confirmcancel: true });
    this.setState({ id: e.target.id });
    console.log(this.state.id, 'this.state.id');
  };

  confirmcancelClose = (param) => {
    this.setState({ confirmcancel: false });
  };

  upvalue = (field) => {
    if (field == 'quantity') {
      var newval = this.state.quantity + 0.5;
      this.setState({ quantity: newval });
    }
  };

  updownvalue = (one, two, three) => {
    //console.log('one,two,three', one, two, three)
    var newval = 0;
    if (one == 'quantity') {
      if (two == 'plus') {
        newval = parseFloat(this.state.quantity ? this.state.quantity : 0) + 1;
      } else if (two == 'minus') {
        newval = parseFloat(this.state.quantity ? this.state.quantity : 0) - 1;
      }
      if (newval >= 0) {
        this.setState({ quantity: newval });
        var orderprice = this.state.ordertype == 'Market' ? this.state.markprice : this.state.price;
        var order_value = parseFloat(newval * orderprice).toFixed(8);
        this.setState({ order_value: parseFloat(order_value).toFixed(8) });
      }
    }

    if (one == 'price') {
      if (two == 'plus') {
        newval = this.state.price + 0.5;
      } else if (two == 'minus') {
        newval = this.state.price - 0.5;
      }
      if (newval >= 0) {
        this.setState({ price: newval });
      }
    }

    if (one == 'trigger_price') {
      if (two == 'plus') {
        newval = this.state.trigger_price + 0.5;
      } else if (two == 'minus') {
        newval = this.state.trigger_price - 0.5;
      }
      if (newval >= 0) {
        this.setState({ trigger_price: newval });
      }
    }
    if (one == 'takeprofit') {
      if (two == 'plus') {
        newval = this.state.takeprofit + 0.5;
      } else if (two == 'minus') {
        newval = this.state.takeprofit - 0.5;
      }
      if (newval >= 0 && this.state.takeprofitcheck) {
        this.setState({ takeprofit: newval });
      }
    }

    if (one == 'stopprice') {
      if (two == 'plus') {
        newval = this.state.stopprice + 0.5;
      } else if (two == 'minus') {
        newval = this.state.stopprice - 0.5;
      }
      if (newval >= 0 && this.state.stopcheck) {
        this.setState({ stopprice: newval });
      }
    }
    if (one == 'trailingstopdistance') {
      if (two == 'plus') {
        newval = this.state.trailingstopdistance + 0.5;
      } else if (two == 'minus') {
        newval = this.state.trailingstopdistance - 0.5;
      }
      if (newval >= 0 && this.state.trailcheck) {
        this.setState({ trailingstopdistance: newval });
      }
    }

    if (one == 'close_quantity') {
      if (two == 'plus') {
        newval = this.state.close_quantity + 0.5;
      } else if (two == 'minus') {
        newval = this.state.close_quantity - 0.5;
      }
      if (newval >= 0) {
        this.setState({ close_quantity: newval });
      }
    }
  };

  componentWillReceiveProps(nextProps) {
    // console.log('---------nextProps', nextProps.auth);
    if (this.props.auth.user.blocktime != '' && this.props.auth.user.blockhours) {
      var _date1 = new Date(this.props.auth.user.blocktime);
      this.setState({ blocktime: (new Date(_date1.getTime() + 1000 * 60 * 60 * this.props.auth.user.blockhours)) });
    }

    if (nextProps.errors) {
      this.setState({
        errors: nextProps.errors
      });
    }
    else {
      this.setState({
        errors: {}
      });
    }
    // console.log(nextProps.auth.trade,'nextProps');
    if (nextProps.auth.trade !== undefined
      && nextProps.auth.trade.data !== undefined
      && nextProps.auth.trade.data.data !== undefined
    ) {
      console.log("nextProps", nextProps)
      var pair = this.state.curcontract;
      console.log("next Props", this.state.curcontract)
      var res = pair.split("_");
      var temp = pair.replace("-", "").replace("_", "");

      var index = nextProps.auth.trade.data.data.findIndex(x => (x.tiker_root?.toUpperCase()) === temp);
      var btcindex = nextProps.auth.trade.data.data.findIndex(x => (x.tiker_root) === 'BTCUSD');
      // console.log("nextProps.auth.trade", nextProps.auth.trade);
      if (index != -1) {
        var perparray = nextProps.auth.trade.data.data;
        // var indexarr = [3,5,0,2,1,4];
        if (perparray.length)
          var marketlist = perparray.reduce((unique, o) => {
            if (!unique.some(obj => obj.second_currency === o.second_currency)) {
              unique.push(o);
            }
            return unique;
          }, []);
        // var outputarr = indexarr.map(i => perparray[i]);
        if (perparray && perparray.length > 0) {
          var searcharr = [];
          for (var i = 0; i < perparray.length; i++) {
            if (this.state.marketpairValue == 'ALL') {
              searcharr.push(perparray[i]);
            } else if (perparray[i].second_currency.toUpperCase() == this.state.marketpairValue) {
              searcharr.push(perparray[i]);
            }
            if (i == perparray.length - 1) {
              this.setState({ records: searcharr })
            }
          }
        }
        console.log("nextProps.auth.trade.data.data[index].markprice", nextProps.auth.trade.data)
        this.setState({
          // records: perparray,
          temprecordsforsorting: perparray,
          allrecords: perparray,
          marketlist: marketlist,
          perpetual: nextProps.auth.trade.data.data[0],
          // markprice: (nextProps.auth.trade.data.data[index].markprice) ? parseFloat(nextProps.auth.trade.data.data[index].markprice) : (nextProps.auth.trade.data.data[index].last == null) ? 0 : nextProps.auth.trade.data.data[index].last,

          markprice: (nextProps.auth.trade.data.data[index].mark_price) ? parseFloat(nextProps.auth.trade.data.data[index].mark_price) : (nextProps.auth.trade.data.data[index].last == null) ? parseFloat(nextProps.auth.trade.data.data[index].mark_price) : nextProps.auth.trade.data.data[index].last,

          value: parseFloat(nextProps.auth.trade.data.data[index].leverage).toFixed(),
          maxleverage: parseFloat(nextProps.auth.trade.data.data[index].leverage).toFixed(),
          price: parseFloat(nextProps.auth.trade.data.data[index].mark_price).toFixed(this.state.floating),
          btcprice: 7800,
          maxquantity: parseFloat(nextProps.auth.trade.data.data[index].maxquantity),
          minquantity: parseFloat(nextProps.auth.trade.data.data[index].minquantity),
          minmargin: parseFloat(nextProps.auth.trade.data.data[index].minmargin),
          // sellprice: parseFloat(nextProps.auth.trade.data.data[index].markprice).toFixed(5),
          // buyprice: parseFloat(nextProps.auth.trade.data.data[index].markprice).toFixed(5),

          sellprice: (nextProps.auth.trade.data.data[index].mark_price) ? parseFloat(nextProps.auth.trade.data.data[index].mark_price) : (nextProps.auth.trade.data.data[index].last == null) ? parseFloat(nextProps.auth.trade.data.data[index].mark_price) : nextProps.auth.trade.data.data[index].last,

          buyprice: (nextProps.auth.trade.data.data[index].mark_price) ? parseFloat(nextProps.auth.trade.data.data[index].mark_price) : (nextProps.auth.trade.data.data[index].last == null) ? parseFloat(nextProps.auth.trade.data.data[index].mark_price) : nextProps.auth.trade.data.data[index].last,
          maker_fee: nextProps.auth.trade.data.data[index].maker_rebate,
          taker_fee: nextProps.auth.trade.data.data[index].taker_fees

        });
      }
    }

    if (nextProps.auth.trade !== undefined && nextProps.auth.trade.data !== undefined && nextProps.auth.trade.data.message == 'tradeTableAll' && nextProps.auth.trade !== undefined
      && nextProps.auth.trade.data !== undefined) {
      // console.log("----nextProps.auth.trade.data-----", nextProps.auth.trade)
      if (nextProps.auth.trade.data.lastpricedet !== undefined && !isNaN(nextProps.auth.trade.data.lastpricedet)) {
        this.setState({
          last_price: nextProps.auth.trade.data.lastpricedet,
        });
      }

      if (nextProps.auth.trade.data.pricedet !== undefined && nextProps.auth.trade.data.pricedet.length > 0) {
        //console.log(nextProps.auth.trade.data.pricedet, 'tradeTableAlltradeTableAll');
        var btcvalue = 0;
        if (nextProps.auth.trade.data.pricedet.length > 0) {
          var usdvalue = parseFloat(this.state.markprice) * parseFloat(nextProps.auth.trade.data.pricedet[0].volume);
          var btcvalue = parseFloat(usdvalue) / parseFloat(this.state.btcprice);
        }
        this.searchPairFilter(nextProps.auth.trade.data.pricedet[0])
        this.setState({
          price: nextProps.auth.trade.data.pricedet[0].last,
          trigger_price: nextProps.auth.trade.data.pricedet[0].last,
          last_price: !isNaN(nextProps.auth.trade.data.pricedet[0].last) ? nextProps.auth.trade.data.pricedet[0].last : 0,
          pricedet: typeof nextProps.auth.trade.data.pricedet != "undefined" && nextProps.auth.trade.data.pricedet.length != 0 && nextProps.auth.trade.data.pricedet,
          btcvolume: isNaN(parseFloat(nextProps.auth.trade.data.pricedet[0].volume)) ? 0 : nextProps.auth.trade.data.pricedet[0].volume,
          change: (nextProps.auth.trade.data.pricedet.length > 0) ? nextProps.auth.trade.data.pricedet[0].change : 0,
          instantprice: (nextProps.auth.trade.data.pricedet.length > 0) ? nextProps.auth.trade.data.pricedet[0].last : 0
        });
      } else {
        // let priceDetail = [];
        // priceDetail[0] = {
        //   high: nextProps.auth.trade.data.lastpricedet ? nextProps.auth.trade.data.lastpricedet : 0
        // }
        this.setState({
          // price: nextProps.auth.trade.data.pricedet[0].last,
          // trigger_price: nextProps.auth.trade.data.pricedet[0].last,
          // last_price: !isNaN(nextProps.auth.trade.data.pricedet[0].last) ? nextProps.auth.trade.data.pricedet[0].last : 0,
          pricedet: undefined,
          // btcvolume: isNaN(parseFloat(nextProps.auth.trade.data.pricedet[0].volume)) ? 0 : nextProps.auth.trade.data.pricedet[0].volume,
          // change: 0,
          // instantprice: (nextProps.auth.trade.data.pricedet.length > 0) ? nextProps.auth.trade.data.pricedet[0].last : 0
        });
      }
    }

    if (nextProps.auth.trade !== undefined
      && nextProps.auth.trade.data !== undefined
      && nextProps.auth.trade.data.message !== undefined) {
      const { t } = this.props
      var message = nextProps.auth.trade.data.message;
      console.log(nextProps, 'tradedetails');
      if (message == 'tradeTableAll' && nextProps.auth.trade.data.buyOrder !== undefined && nextProps.auth.trade.data.sellOrder !== undefined && nextProps.auth.trade.data.assetdetails !== undefined && nextProps.auth.trade.data.contractdetails !== undefined && nextProps.auth.trade.data.Rescentorder !== undefined && nextProps.auth.trade.data.sellsumvalue !== undefined && nextProps.auth.trade.data.buysumvalue !== undefined) {
        var assetdetails = nextProps.auth.trade.data.assetdetails;
        //console.log(assetdetails, 'assetdetails');
        var contractdetails = nextProps.auth.trade.data.contractdetails;
        var index = assetdetails.findIndex(x => (x.currencySymbol).toLowerCase() === this.state.firstcurrency.toLowerCase());
        var index1 = assetdetails.findIndex(x => (x.currencySymbol).toLowerCase() === this.state.secondcurrency.toLowerCase());

        var firstbalance = (index != -1) ? parseFloat(assetdetails[index].spotwallet).toFixed(8) : 0;
        var secondbalance = (index1 != -1) ? parseFloat(assetdetails[index1].spotwallet).toFixed(8) : 0;
        var buyOrder = nextProps.auth.trade.data.buyOrder;
        var sellOrder = nextProps.auth.trade.data.sellOrder;
        var spotFloating = nextProps.auth.trade.data.flaotDigits;
        var Rescentorder = nextProps.auth.trade.data.Rescentorder;
        var buysumvalue = nextProps.auth.trade.data.buysumvalue.length > 0 ? nextProps.auth.trade.data.buysumvalue[0].orderValue : 0;
        var sellsumvalue = nextProps.auth.trade.data.sellsumvalue.length > 0 ? nextProps.auth.trade.data.sellsumvalue[0].quantity : 0;
        var val = (sellOrder.length < 6) ? 0 : ((sellOrder.length >= 6) && (sellOrder.length < 9)) ? 50 : (buyOrder.length < 3) ? 500 : 150
        this.setState({
          buyOrder: buyOrder,
          sellOrder: sellOrder,
          spot_floating: spotFloating,
          Rescentorder: Rescentorder,
          firstbalance: firstbalance,
          secondbalance: secondbalance,
          buylimit: (buyOrder.length > 0) ? buyOrder[0]._id : 0,
          selllimit: (sellOrder.length > 0) ? sellOrder[sellOrder.length - 1]._id : 0,
          buysumvalue: buysumvalue,
          sellsumvalue: sellsumvalue,
        }, () => {
          try {
            // alert(val);
            const { scrollbar } = this.refs;  // scrollbar has access to the internal API
            scrollbar.view.scrollTop = val;
          } catch (err) { }
        });





      }

      if (message == 'tradeTableAll' && nextProps.auth.trade.data.orderHistory !== undefined && nextProps.auth.trade.data.assetdetails !== undefined && nextProps.auth.trade.data.Conditional_details !== undefined) {
        console.log('nextProps.auth.trade', nextProps.auth.trade.data.orderHistoryCnt, nextProps.auth.trade.data.HistroyDetailsCnt, nextProps.auth.trade.data.FilledDetailsCnt)
        var orderHistory = nextProps.auth.trade.data.orderHistory;
        var Histroydetails = nextProps.auth.trade.data.Histroydetails;
        var Filleddetails = nextProps.auth.trade.data.Filleddetails;
        var assetdetails = nextProps.auth.trade.data.assetdetails;
        var Conditional_details = nextProps.auth.trade.data.Conditional_details;
        var orderHistoryCnt = nextProps.auth.trade.data.orderHistoryCnt;
        var histroyDetailsCnt = nextProps.auth.trade.data.HistroyDetailsCnt;
        var filledDetailsCnt = nextProps.auth.trade.data.FilledDetailsCnt;
        //console.log(Conditional_details, 'Conditional_details');
        this.setState({
          orderHistory: orderHistory,
          Histroydetails: Histroydetails,
          Filleddetails: Filleddetails,
          assetOverview: assetdetails,
          Conditional_details: Conditional_details,
          orderHistoryCnt: orderHistoryCnt,
          histroyDetailsCnt: histroyDetailsCnt,
          filledDetailsCnt: filledDetailsCnt
        });
      }

      if (nextProps.auth.trade.data.notify_show !== undefined && nextProps.auth.trade.data.notify_show == 'yes' && nextProps.auth.trade.data.status !== undefined) {
        if (nextProps.auth.trade.data.status) {
          var type = 'success';
          var title = t("SUCCESS");
        } else {
          var type = 'danger';
          var title = t("ERRORS");
        }

        store.addNotification({
          title: title,
          message: message,
          type: type,
          insert: "top",
          container: "top-right",
          animationIn: ["animated", "fadeIn"],
          animationOut: ["animated", "fadeOut"],
          dismiss: {
            duration: 1500,
            onScreen: true,
            pauseOnHover: true
          }
        });
        this.setState({ disable: true, selldisable: true, buydisable: true });
        this.getTradeData();
        this.getuserTradeData();
      }
      nextProps.auth.trade = "";
    }
  }

  orderHistoryLoadMore() {
    // this.setState()
    // orderHistoryPage

    let reqData = {
      userId: this.props.auth.user.id,
      page: this.state.orderHistoryPage + 1,
      firstCurrency: this.state.firstcurrency,
      secondCurrency: this.state.secondcurrency,
    }
    console.log("reqData", reqData);
    axios.post(url + "cryptoapi/getOrderHistory", reqData).then(res => {
      if (res && res.data.length > 0) {
        this.setState({
          orderHistory: [...this.state.orderHistory, ...res.data],
          orderHistoryPage: this.state.orderHistoryPage + 1
        })
      }
    }).catch(err => {
    });
  }

  spotFilledDetailsLoadMore() {
    let reqData = {
      userId: this.props.auth.user.id,
      page: this.state.filledDetailsPage + 1,
      firstCurrency: this.state.firstcurrency,
      secondCurrency: this.state.secondcurrency,
    }
    console.log("reqData", reqData)
    axios.post(url + "cryptoapi/getSpotFilledDetails", reqData).then(res => {
      if (res && res.data.length > 0) {
        this.setState({
          Filleddetails: [...this.state.Filleddetails, ...res.data],
          filledDetailsPage: this.state.filledDetailsPage + 1
        })
      }
    }).catch(err => {
    });
  }

  spotHistoryDetailsLoadMore() {

    let userID = this.props.auth.user.id
    let reqData = {
      userId: userID ? userID : this.props.auth.user.id,
      page: this.state.histroydetailsPage + 1,
      firstCurrency: this.state.firstcurrency,
      secondCurrency: this.state.secondcurrency,
    }
    console.log("reqData", reqData)

    console.log("reqData", reqData, userID)
    axios.post(url + "cryptoapi/getSpotHistoryDetails", reqData).then(res => {
      if (res && res.data.length > 0) {
        this.setState({
          Histroydetails: [...this.state.Histroydetails, ...res.data],
          histroydetailsPage: this.state.histroydetailsPage + 1
        })
      }
    }).catch(err => {
    });
  }

  Marketsorting = (e) => {
    var type = e.target.id;
    //console.log("Marketsorting type", type)
    var fieldtosort = type.split("-")[0]
    var sortingtype = type.split("-")[1]
    var temprefrecords = this.state.records
    // console.log("Marketsorting",temprefrecords,"Market PAir Records",this.state.records);
    if (fieldtosort == "Pair") {
      // //console.log("Marketsorting inside pair");
      if (sortingtype == "Normal") {
        this.setState({
          records: temprefrecords,
          sortpair: "Ascending"
        })
      } else if (sortingtype == "Ascending") {
        let sortedrecords;
        function compare(a, b) {
          // Use toUpperCase() to ignore character casing
          const bandA = a.tiker_root.toUpperCase();
          const bandB = b.tiker_root.toUpperCase();

          let comparison = 0;
          if (bandA > bandB) {
            comparison = 1;
          } else if (bandA < bandB) {
            comparison = -1;
          }
          return comparison;
        }

        sortedrecords = temprefrecords.sort(compare);
        this.setState({
          records: sortedrecords,
          sortpair: "Descending"
        })
      } else {
        // var sortedrecords= temprefrecords.sort((a, b) => b.first_currency - a.first_currency)
        let sortedrecords;
        function compare(a, b) {
          // Use toUpperCase() to ignore character casing
          const bandA = a.tiker_root.toUpperCase();
          const bandB = b.tiker_root.toUpperCase();

          let comparison = 0;
          if (bandA > bandB) {
            comparison = 1;
          } else if (bandA < bandB) {
            comparison = -1;
          }
          return comparison * -1;
        }
        sortedrecords = temprefrecords.sort(compare);
        this.setState({
          records: sortedrecords,
          sortpair: "Normal"
        })

      }
    } else if (fieldtosort == "Price") {
      // //console.log("Marketsorting inside Price");

      if (sortingtype == "Normal") {
        this.setState({
          records: temprefrecords,
          sortprice: "Ascending"
        })
      } else if (sortingtype == "Ascending") {
        var sortedrecords = temprefrecords.sort((a, b) => parseFloat(a.last) - parseFloat(b.last))
        this.setState({
          records: sortedrecords,
          sortprice: "Descending"
        })

      } else {
        var sortedrecords = temprefrecords.sort((a, b) => parseFloat(b.last) - parseFloat(a.last))
        this.setState({
          records: sortedrecords,
          sortprice: "Normal"
        })

      }

    } else if (fieldtosort == "Change") {
      if (sortingtype == "Normal") {
        this.setState({
          records: temprefrecords,
          sortchange: "Ascending"
        })
      } else if (sortingtype == "Ascending") {
        var sortedrecords = temprefrecords.sort((a, b) => parseFloat(a.change) - parseFloat(b.change))
        this.setState({
          records: sortedrecords,
          sortchange: "Descending"
        })
      } else {
        var sortedrecords = temprefrecords.sort((a, b) => parseFloat(b.change) - parseFloat(a.change))
        this.setState({
          records: sortedrecords,
          sortchange: "Normal"
        })

      }
    } else {

    }
  }
  Addtofavourites = (e) => {
    var fromlocal = []
    fromlocal = JSON.parse(localStorage.getItem("favouritepairs"));
    var pair = e.target.id;


    if (fromlocal != null) {
      if (fromlocal.includes(pair)) {
        // //console.log("Already added");
        var getLocalStorage = JSON.parse(localStorage.getItem("favouritepairs"));

        // //console.log("indexof localstorageitemss",getLocalStorage)
        for (var i = 0; i < getLocalStorage.length; i++) {
          var Val = getLocalStorage[i];
          var newStorage = getLocalStorage.filter(function (r) {
            return r != pair;
          });
          localStorage.setItem("favouritepairs", JSON.stringify(newStorage)); //Assign it back to LocalStorage.
        }
        var temprecords = this.state.records
        var newrecords = temprecords.filter(function (r) {
          return r.tiker_root != pair;
        });
        this.setState({ records: temprecords })

      } else {
        fromlocal.push(pair)

        localStorage.setItem("favouritepairs", JSON.stringify(fromlocal));
      }
    }
    else {
      var newarray = []
      newarray.push(pair)
      localStorage.setItem("favouritepairs", JSON.stringify(newarray));
    }
  }

  pairChange = (e) => {
    console.log(e.target, "element lcick");
    console.log(e.target.closest("tr"), "event");
    var allDivTd = document.getElementsByClassName("all_pair_tr");
    console.log(allDivTd, "allDivTd");

    for (var i = 0; i < allDivTd.length; i++) {
      var td = allDivTd[i];
      td.classList.remove("active_pair")

      // var alltd = td.getElementsByTagName("tr");
      //   for(var j = 0; j < alltd.length; j++){
      //     var tdnew = alltd[j];
      //     console.log(tdnew,"tdnew");

      //     tdnew.classList.remove("active_pair")
      // }

    }

    e.target.closest("tr").classList.add("active_pair");
    let userid = this.props.auth.user.id;

    var pair = e.target.id;
    var res = pair.split("_");
    var temp = pair.replace("_", "");
    var loctemp = pair.replace("_", "-");
    var index = this.state.records.findIndex(x => (x.tiker_root) === temp);
    var mainmargin = (index > -1) ? this.state.records[index].maint_margin : 0.5 / 100;
    ////console.log(index)
    console.log(res, 'resres')
    this.setState({
      curcontract: e.target.id,
      chartpair: temp,
      secondcurrency: res[1],
      chartpair: temp,
      firstcurrency: res[0],
      price: (index > -1) ? this.state.records[index].mark_price : this.state.price,
      perpetual: this.state.records[index],
      mainmargin: mainmargin,
      buytotal: 0,
      selltotal: 0,
      buyquantity: '',
      sellquantity: ''
    });
    localStorage.setItem('curpair1', loctemp);
    this.props.history.push("/spot/" + loctemp);
    console.log(this.props.history, "this.props.history")
    // window.location.href = "/spot/" + loctemp;
    // this.getData();

    var findObj = {
      userid: userid,
      firstCurrency: res[0],
      secondCurrency: res[1]
    };
    this.props.getspotTradeData(findObj);
    this.props.getspotuserTradeData(findObj);

    this.props.getspotPricevalue(findObj);
    this.props.getspotPertual();
  };

  fillpriceforbuy = e => {
    console.log('clickprice', e.target.innerHTML)
    this.setState({ sellprice: e.target.innerHTML });
  };

  fillpriceforsell = e => {
    console.log('clickprice', e.target.innerHTML)
    this.setState({ buyprice: e.target.innerHTML });
  };

  fillquantityforbuy = e => {
    this.setState({ sellquantity: e.target.innerHTML });
    var orderprice = parseFloat(this?.state?.buyprice * e.target.innerHTML).toFixed(8);
    this.setState({ selltotal: orderprice })
    this.setState({ ordersellprice: orderprice })
  };
  fillquantityforsell = e => {
    this.setState({ buyquantity: e.target.innerHTML });
    var orderprice = parseFloat(this?.state?.sellprice * e.target.innerHTML).toFixed(8);
    this.setState({ buytotal: orderprice })
    this.setState({ orderbuyprice: orderprice })
  };

  onChange = (e) => {
    console.log(e.target.id, "INPUT EVENT")
    // e.preventDefault();
    if (!/^\d*\.?\d*$/.test(e.target.value) || /^[a-zA-z]/.test(e.target.value)) {
      return
    }
    // buyquantity
    this.setState({ [e.target.id]: e.target.value });

    if (e.target.id == 'takeprofit' && e.target.value) {
      if (this.state.buyorsell == 'buy' && parseFloat(this.state.markprice) >= parseFloat(e.target.value)) {
        this.setState({ alertmessage2: "Stop Loss price you set must be higher than " + this.state.markprice });
        return false;
      }
      else if (this.state.buyorsell == 'sell' && parseFloat(this.state.markprice) >= parseFloat(e.target.value)) {
        this.setState({ alertmessage2: "Stop Loss price you set must be lower than " + this.state.markprice });
        return false;
      }
      else {
        this.setState({ alertmessage2: '' });
        return true;
      }
    }
    if (e.target.id == 'trailcheck' && e.target.value) {
      this.setState({ trailreadOnly: (this.state.trailreadOnly) ? false : true });
    }
    if (e.target.id == 'stopcheck' && e.target.value) {
      this.setState({ stopreadOnly: (this.state.stopreadOnly) ? false : true });
    }
    if (e.target.id == 'takeprofitcheck' && e.target.value) {
      this.setState({ tpreadOnly: (this.state.tpreadOnly) ? false : true });
    }
    if (e.target.id == 'stopprice' && e.target.value) {
      if (this.state.buyorsell == 'buy' && parseFloat(this.state.markprice) <= parseFloat(e.target.value)) {
        this.setState({ alertmessage1: "Stop Loss price you set must be lower than " + this.state.markprice });
        return false;
      }
      else if (this.state.buyorsell == 'buy' && parseFloat(this.state.Liqprice) >= parseFloat(e.target.value)) {

        this.setState({ alertmessage1: "Stop Loss price you set must be higher than " + parseFloat(this.state.Liqprice).toFixed(this.state.floating) });
        return false;
      }
      else if (this.state.buyorsell == 'sell' && parseFloat(this.state.markprice) >= parseFloat(e.target.value)) {
        this.setState({ alertmessage1: "Stop Loss price you set must be higher than " + this.state.markprice });
        return false;
      }
      else if (this.state.buyorsell == 'sell' && parseFloat(this.state.Liqprice) <= parseFloat(e.target.value)) {
        this.setState({ alertmessage1: "Stop Loss price you set must be lower than " + parseFloat(this.state.Liqprice).toFixed(this.state.floating) });
        return false;
      }
      else {
        this.setState({ alertmessage1: '' });
        return true;
      }

    }
    if (e.target.id == 'reduce_only' || e.target.id == 'post_only') {
      var checked = e.target.checked;

      if (e.target.id == 'post_only') {
        this.setState({ post_only: checked });
      } else if (e.target.id == 'reduce_only') {
        this.setState({ reduce_only: checked });
      }
    }
    else if (e.target.id == 'buyquantity' || e.target.id == 'buyprice') {
      //console.log("here")
      var curarray = ["BTC-USDT", "ETH-USDT", "XRP-USDT", "LTC-USDT", "BCH-USDT", "ETH-BTC", "XRP-BTC", "LTC-BTC", "BCH-BTC", "LTC-ETH", "XRP-ETH", "BCH-ETH", "BTC-BUSD", "ETH-BUSD", "LTC-BUSD", "XRP-BUSD", "BCH-BUSD"]

      if (e.target.id == 'buyquantity') {
        this.setState({ limitbuypercentage: "" })
        var quantity = e.target.value;
        if (curarray.includes(this.state.curcontract)) {
          var orderprice = this.state.ordertype == 'Market' ? this.state.markprice : this.state.buyprice;
        }
        else {
          var orderprice = this.state.ordertype == 'Market' ? (this.state.buyorsell == 'buy') ? this.state.selllimit : this.state.buylimit : this.state.buyprice;
        }

      }
      if (e.target.id == 'buyprice') {
        var buyselltype = this.state.buyorsell;
        var quantity = this.state.buyquantity;
        var orderprice = e.target.value;
      }
      console.log('orderprice hiji', orderprice)
      this.setState({ orderprice: orderprice })
      console.log('buyquantities', quantity)
      var order_value = parseFloat(quantity * orderprice).toFixed(8);
      console.log('ordervalue hihihihi', order_value)
      if (quantity != '' && orderprice != '') {
        if (isNaN(order_value)) {
          this.setState({ buytotal: 0 });
        } else {
          this.setState({ buytotal: order_value });

        }
      }
    }
    else if (e.target.id == 'sellquantity' || e.target.id == 'sellprice') {

      var curarray = ["BTC-USDT", "ETH-USDT", "XRP-USDT", "LTC-USDT", "BCH-USDT", "ETH-BTC", "XRP-BTC", "LTC-BTC", "BCH-BTC", "LTC-ETH", "XRP-ETH", "BCH-ETH", "BTC-BUSD", "ETH-BUSD", "LTC-BUSD", "XRP-BUSD", "BCH-BUSD"]

      if (e.target.id == 'sellquantity') {
        this.setState({ limitsellpercentage: "" })
        var quantity = e.target.value;
        if (curarray.includes(this.state.curcontract)) {
          var orderprice = this.state.ordertype == 'Market' ? this.state.markprice : this.state.sellprice;
        }
        else {
          var orderprice = this.state.ordertype == 'Market' ? (this.state.buyorsell == 'buy') ? this.state.selllimit : this.state.buylimit : this.state.sellprice;
        }

      }
      if (e.target.id == 'sellprice') {
        var buyselltype = this.state.buyorsell;
        var quantity = this.state.sellquantity;
        var orderprice = e.target.value;
        console.log('orderproce hihi', orderprice)
        this.setState({ "orderPrice": orderprice })
      }
      var order_value = parseFloat(quantity * orderprice).toFixed(8);
      if (quantity != '' && orderprice != '') {
        if (isNaN(order_value)) {
          this.setState({ selltotal: 0 });

        } else {
          this.setState({ selltotal: order_value });

        }
      }

    }

    if (e.target.id == 'stoplimitsellstopprice' || e.target.id == 'stoplimitbuystopprice') {
      var stprice = e.target.value
      this.setState({ tempstopprice: stprice })

    }

  };

  onOrderValueChange = e => {
    // console.log(e.target.value);
    this.setState({ ordervalue: e.target.value })
  }


  changetimeinforce = timeinforcetype => {
    this.setState({ timeinforcetype });
    //console.log(`Option selected:`, timeinforcetype);
  };

  orderbookshow = e => {
    this.setState({ orderbookshow: e.target.id })
  };

  calculation = (value = 0) => {
    var orderprice = this.state.ordertype == 'Market' ? this.state.markprice : this.state.price;
    var order_value = parseFloat(this.state.quantity * orderprice).toFixed(8);
    this.setState({ order_value: parseFloat(order_value).toFixed(8) });
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    this.getData();
    if (!localStorage.getItem('curpair1')) {
      localStorage.setItem('curpair1', 'BTC-USDT');
      // console.log((localStorage.getItem("curpair1")),"local_curpair1")
    }

  };

  getData() {
    let themeData = localStorage.getItem('theme');
    this.setState({ theme: themeData == "darktheme" ? "Dark" : "White" })

    this.props.getspotPertual();

    this.getTradeData();
    this.getuserTradeData();

    //console.log(this.props.auth.user.id, 'createroom')
    this.socket.emit('CREATEROOM', this.props.auth.user.id);

  }


  getTradeData() {
    let userid = this.props.auth.user.id;
    console.log(this.state.firstcurrency, 'this.state.firstcurrencythis.state.firstcurrency')
    console.log(this.state.secondcurrency, 'this.state.secondcurrency.state.secondcurrency')
    var findObj = {
      userid: userid,
      firstCurrency: this.state.firstcurrency,
      secondCurrency: this.state.secondcurrency
    };
    this.props.getspotTradeData(findObj);
  }


  getuserTradeData() {
    let userid = this.props.auth.user.id;
    console.log("USERID", userid)
    var findObj = {
      userid: userid,
      firstCurrency: this.state.firstcurrency,
      secondCurrency: this.state.secondcurrency
    };
    this.props.getspotuserTradeData(findObj);

    this.props.getspotPricevalue(findObj);
  }


  render() {
    const { user } = this.props.auth;
    const { value, records, assetOverview, timeinforcetype, errors, orderHistory, Histroydetails, Filleddetails, perpetual, Conditional_details, orderHistoryCnt, marketpairValue, filledDetailsCnt, histroyDetailsCnt } = this.state
    if (Conditional_details.length > 0) {
      var cindex = Conditional_details.findIndex(x => (x.trigger_ordertype) === 'takeprofit');
      var tpprice = (cindex == -1) ? '-' : Conditional_details[cindex].trigger_price;

      var sindex = Conditional_details.findIndex(x => (x.trigger_ordertype) === 'stop');
      var stprice = (sindex == -1) ? '-' : Conditional_details[sindex].trigger_price;
    }


    // console.log(window.location.pathname.split("/")[2],"urlnew");
    if (window.location.pathname.split("/")[2] != "" || window.location.pathname.split("/")[2] != "undefined") {
      const pathcoin = window.location.pathname.split("/")[2];
      var pathcoinsplit = window.location.pathname.split("/")[2].split("-").join("_");

      var allDivTd = document.getElementsByClassName("all_pair_tr");

      for (var i = 0; i < allDivTd.length; i++) {
        var td = allDivTd[i];
        if (td.getAttribute("id") == pathcoinsplit) {
          td.classList.add("active_pair")
        }
        else {
          td.classList.remove("active_pair")

        }

      }
    }
    const { t } = this.props;
    return (
      <div className='headerdrop'>
        {console.log("THEMESSSS", this.state.theme)}
        {console.log('data', this.state)}
        <Modal show={this.state.confirmcancel} onHide={this.confirmcancelClose} aria-labelledby="contained-modal-title-vcenter" centered className="primary-modal" backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>{t("CANCEL_ORDER")}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className='text-white'>{t("ARE_YOU_CONFIRM_TO_CANCEL_THIS_ORDER")}</p>

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary btnDefaultNewBlue" onClick={this.confirmcancelClose}>
              {t("CANCEL")}
            </Button>
            <Button variant="primary btnDefaultNew" onClick={
              () => {
                let userid = this.props.auth.user.id;
                var iddetails = { id: this.state.id, userid: userid };
                this.props.spotcancelTrade(iddetails);
                this.setState({ confirmcancel: false });
              }
            }>
              {t("CONFIRM")}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={this.state.show} onHide={this.handleClose} aria-labelledby="contained-modal-title-vcenter" centered className="primary-modal" backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title>{t("ORDER_DETAILS")} <small>{this.state.ordertype} {this.state.buyorsell}</small></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="table-responsive">
              <table className="table table-bordered tradeBuy tradeConfirm">
                <tr>
                  <td align="right" width="50%">{t("ORDER_PRICE")}</td>
                  <td><span>{(this.state.ordertype == 'Market') ? parseFloat(this.state.markprice).toFixed(this.state.floating) + ' ' + this.state.secondcurrency : this.state.orderprice + ' ' + this.state.secondcurrency}</span></td>
                </tr>
                <tr>
                  <td align="right" width="50%">{t("ORDER_VALUE")}</td>
                  <td>{this.state[this.state.buyorsell + "total"] + ' ' + this.state.secondcurrency}</td>
                </tr>

                <tr>
                  <td align="right" width="50%">{t("AVAILABLE_BALANCE")}</td>
                  {(this.state.buyorsell == 'buy') ?
                    <td>{parseFloat(this.state.secondbalance).toFixed(8) + ' ' + this.state.secondcurrency}</td> :
                    <td>{parseFloat(this.state.firstbalance).toFixed(8) + ' ' + this.state.firstcurrency}</td>
                  }
                </tr>

                <tr>
                  <td align="right" width="50%">{t("MARK_PRICE")}</td>
                  <td>{parseFloat(this.state.markprice).toFixed(6) + ' ' + this.state.secondcurrency}</td>
                </tr>

                {/*<tr>
               <td align="right" width="50%">Time in Force</td>
               <td>GoodTillCancelled</td>
             </tr>*/}
              </table>
            </div>

            {(this.state.alertmessage1 != '' && this.state.stopreadOnly == false) ?
              <div className="quadrat">{this.state.alertmessage1}</div> : ''
            }
            {(this.state.alertmessage2 != '' && this.state.tpreadOnly == false) ?
              <div className="quadrat">{this.state.alertmessage2}</div> : ''
            }

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary btnDefaultNewBlue" onClick={this.handleClose}>
              {t("CANCEL")}
            </Button>
            <Button variant="primary btnDefaultNew" onClick={this.orderPlacing}>
              {t("CONFIRM")}
            </Button>
          </Modal.Footer>
        </Modal>
        <div className="container-fluid px-0 main_bg_trade_new spot_trade_main">
          <Navtradebar />
          <section className="tradeMain tradeMain_margin_sm">
            <div className=" px-0">
              <div className="row mx-0 mt-md-0 mt-3">
                <div className="col-lg-9 px-0">
                  <div className="tradeLeft">
                    <div className="row mx-0 ">
                      <div className="col-md-4 px-md-0 px-3 pr-md-1 mb-bot-new-sm mb-md-0 mb-3">

                        <div className="darkBox tradeTabBoxStyle pb-0 pt-2 h-100 rounded-0">

                          <div className="vertical-tab" role="tabpanel">

                            <ul className="nav nav-tabs" role="tablist">



                              <li role="presentation"><a href="#Section0" onClick={this.Showfavourites.bind('Fav')} aria-controls="allPair" role="tab" className="pTobBott" data-toggle="tab"><i className="fas fa-star"></i></a></li>


                              {/*}<li role="presentation"><a href="" onClick={this.Showfavourites.bind('Fav')}  aria-controls="allPair" role="tab" data-toggle="tab"><i class="fas fa-star"></i></a></li>*/}
                              <li role="presentation">
                                <a
                                  href="#Section1"
                                  onClick={this.marketpair.bind('All')}
                                  // className="active"
                                  className={marketpairValue == 'All' ? "active" : ''}
                                  aria-controls="allPair"
                                  role="tab"
                                  data-toggle="tab"
                                >
                                  {t("ALL")}
                                </a>
                              </li>
                              {/* <li role="presentation">
                                <a
                                  href="#Section1"
                                  onClick={this.marketpair.bind('ETH')}
                                  className={marketpairValue == 'ETH' ? "active" : ''}
                                  aria-controls="allPair"
                                  role="tab"
                                  data-toggle="tab"
                                >
                                  ETH
                                  </a>
                              </li> */}

                              {
                                this.state.marketlist ?
                                  this.state.marketlist.map((item, i) => {
                                    return <li
                                      role="presentation"
                                      key={i}
                                      onClick={this.marketpair.bind(item.second_currency)}
                                    >
                                      <a
                                        href="#Section1"
                                        aria-controls="btcPair"
                                        role="tab"
                                        data-toggle="tab"
                                        className={marketpairValue == item.second_currency ? "active" : ''}
                                      >
                                        {item.second_currency}
                                      </a>
                                    </li>;
                                  }) : ''

                              }
                            </ul>
                            <div className="tab-content tabs searchbg">
                              <div className="pairSearch">
                                <div class="input-group">
                                  <input type="search" name="" className="form-control" placeholder={t("SEARCH_PAIRS")} id="searchpair" value={this.state.searchpair} onChange={this.searchpair} />
                                  <div class="input-group-append">
                                    <span class="input-group-text"><i class="fas fa-search"></i></span>
                                  </div>
                                </div>
                              </div>
                              <div role="tabpane0" className="tab-pane fade" id="Section0">
                                <div className="table-responsive">

                                  <table className="table pairTableMain mb-0">
                                    <thead>
                                      <tr>
                                        <th>{t("PAIR")}</th>
                                        <th>{t("LAST_PRICE")}</th>
                                        <th>{t("CHANGE")}</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {/*} <tr>
                                    <td><i className="fas fa-star favStarIcon"></i> <button className="btn btnFavorite ml-1" id="ETH_USDT">BTC/ETH</button></td>
                                    <td>461.16</td>
                                    <td>2.26</td>
                                 </tr>
                                 <tr>
                                    <td><i className="fas fa-star favStarIcon"></i> <button className="btn btnFavorite ml-1" id="BTC_USDT">BTC/USDT</button></td>
                                    <td>15603.33</td>
                                    <td>2.18</td>
                                 </tr>*/}
                                      {
                                        this.state.favrecords ?
                                          this.state.favrecords.map((item, i) => {
                                            var fromlocal = JSON.parse(localStorage.getItem("favouritepairs"));
                                            var lastprice = isNaN(parseFloat(item.last)) ? 0 : item.last
                                            var change = isNaN(parseFloat(item.change)) ? 0 : item.change
                                            var starclass = "no"
                                            if (fromlocal != null) {
                                              if (fromlocal.includes(item.tiker_root)) {
                                                starclass = "yes"
                                                return <tr key={i} id={item.first_currency + "_" + item.second_currency} >
                                                  <td>
                                                    <i
                                                      id={item.first_currency + item.second_currency}
                                                      onClick={this.Addtofavourites.bind(this)}
                                                      className={starclass == "yes" ? "fas fa-star favStarIcon" : "far fa-star favStarIcon"}
                                                    ></i>
                                                    <button
                                                      className="btn btnFavorite ml-1"
                                                      id={item.first_currency + "_" + item.second_currency}
                                                      onClick={this.pairChange.bind(this)}
                                                    >
                                                      {item.first_currency + "/" + item.second_currency}
                                                    </button>
                                                  </td>
                                                  <td>{parseFloat(lastprice).toFixed(8)}</td>
                                                  <td>{parseFloat(change).toFixed(2)}</td>
                                                </tr>
                                              }
                                            }
                                            ;
                                          }) : ''
                                      }
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div role="tabpanel" className="tab-pane fade in active show lastpricepair" id="Section1">
                                <Scrollbars ref='scrollbar' style={{ width: "100%", height: 850 }} renderTrackVertical={props => <div className="track-vertical" />}>
                                  <div className="table-responsive">
                                    <table className="table pairTableMain mb-0">
                                      <thead>
                                        <tr>
                                          {this.state.sortpair == "Normal" ? (
                                            <th>{t("PAIR")} {" "}<i id={"Pair-Normal"} onClick={this.Marketsorting.bind(this)} class="fas fa-sort"></i></th>
                                          ) : this.state.sortpair == "Ascending" ? (
                                            <th>{t("PAIR")}{" "}<i id={"Pair-Ascending"} onClick={this.Marketsorting.bind(this)} class="fas fa-sort-up"></i></th>
                                          ) : <th>{t("PAIR")} {" "}<i id={"Pair-Descending"} onClick={this.Marketsorting.bind(this)} class="fas fa-sort-down"></i></th>}

                                          {this.state.sortprice == "Normal" ? (
                                            <th>{t("LAST_PRICE")} {" "}<i id={"Price-Normal"} onClick={this.Marketsorting.bind(this)} class="fas fa-sort"></i></th>
                                          ) : this.state.sortprice == "Ascending" ? (
                                            <th>{t("LAST_PRICE")} {" "}<i id={"Price-Ascending"} onClick={this.Marketsorting.bind(this)} class="fas fa-sort-up"></i></th>
                                          ) : <th>{t("LAST_PRICE")} {" "}<i id={"Price-Descending"} onClick={this.Marketsorting.bind(this)} class="fas fa-sort-down"></i></th>}

                                          {this.state.sortchange == "Normal" ? (
                                            <th>{t("CHANGE")} {" "}<i id={"Change-Normal"} onClick={this.Marketsorting.bind(this)} class="fas fa-sort"></i></th>
                                          ) : this.state.sortchange == "Ascending" ? (
                                            <th>{t("CHANGE")} {" "}<i id={"Change-Ascending"} onClick={this.Marketsorting.bind(this)} class="fas fa-sort-up"></i></th>
                                          ) : <th>{t("CHANGE")} {" "}<i id={"Change-Descending"} onClick={this.Marketsorting.bind(this)} class="fas fa-sort-down"></i></th>}



                                          {/*}  <th>Pair {" "}<i class={this.state.sortpair=="Normal"?"fas fa-sort":this.state.sortpair=="Ascending"?"fas fa-sort-up":"fas fa-sort-down"}></i></th>
                                    <th>Last Price{" "}<i class={this.state.sortprice=="Normal"?"fas fa-sort":this.state.sortpair=="Ascending"?"fas fa-sort-up":"fas fa-sort-down"}></i></th>
                                    <th>Change{" "}<i class={this.state.sortchange=="Normal"?"fas fa-sort":this.state.sortpair=="Ascending"?"fas fa-sort-up":"fas fa-sort-down"}></i></th>  */}
                                        </tr>
                                      </thead>
                                      <tbody>

                                        {
                                          this.state.records ?
                                            this.state.records.map((item, i) => {
                                              var fromlocal = JSON.parse(localStorage.getItem("favouritepairs"));
                                              var lastprice = isNaN(parseFloat(item.last)) ? 1 : item.last
                                              var change = isNaN(parseFloat(item.change)) ? 0 : item.change
                                              var starclass = "no"
                                              if (fromlocal != null) {
                                                if (fromlocal.includes(item.tiker_root)) {
                                                  starclass = "yes"
                                                }
                                              }

                                              //   this.setState({ spotpricecolor: "greenText" });
                                              // }
                                              // else {
                                              //   this.setState({ spotpricecolor: "pinkText" });

                                              let floatingDigits = (item.floatingDigits) ? (item.floatingDigits <= 8 ? item.floatingDigits : 8) : 8;

                                              return (
                                                <tr key={i} id={item.first_currency + "_" + item.second_currency} className="all_pair_tr">
                                                  <td>
                                                    <i
                                                      id={item.first_currency + item.second_currency}
                                                      onClick={this.Addtofavourites.bind(this)}
                                                      className={starclass == "yes" ? "fas fa-star favStarIcon" : "far fa-star favStarIcon"}
                                                    ></i>
                                                    <span
                                                      className="btn btnFavorite ml-1"
                                                      id={item.first_currency + "_" + item.second_currency}
                                                      onClick={this.pairChange.bind(this)}
                                                    >
                                                      {item.first_currency + "/" + item.second_currency}
                                                    </span>
                                                  </td>
                                                  <td>{parseFloat(lastprice).toFixed(floatingDigits)}</td>
                                                  <td className={(change >= 0 ? 'greenText' : 'pinkText')}>{parseFloat(change).toFixed(2)}</td>
                                                </tr>);
                                            }) : ''
                                        }

                                      </tbody>
                                    </table>

                                  </div>
                                </Scrollbars>
                              </div>

                            </div>
                          </div>
                        </div>

                      </div>
                      <div className="col-md-8 px-md-0 px-3 pr-md-new-sm">
                        <div className="header-overview pt-2 mb-md-0 mb-3">
                          <div className="headerOverviewGroup">
                            <div className="headerOverviewStatus">
                              <h5><small>{t("SPOT_PRICE")}</small>
                                {
                                  console.log("marketpriceeeeeee", this.state.markprice)
                                }
                                <span className={this.state.spotpricecolor}>
                                  {(
                                    (typeof this.state.markprice != 'undefined') ?
                                      parseFloat(this.state.markprice).toFixed(this.state.floating) : 0) + ' '
                                  }
                                </span>
                              </h5>
                            </div>
                            {/* <div className="headerOverviewStatus">
                              <h5><small>Last Price</small>{((typeof this.state.pricedet != 'undefined' && this.state.pricedet.length > 0) ? parseFloat(this.state.pricedet[0].close).toFixed(this.state.floating) : 0) + ' '}</h5>
                            </div> */}
                            <div className="headerOverviewStatus">
                              {
                                (this.state.change < 0) ?
                                  <h5 className="pinkText"><small>{t("24H_CHANGE")}</small>{((this.state.change != '') ? parseFloat(this.state.change).toFixed(2) : 0)}%</h5> :
                                  <h5 className="greenText"><small>{t("24H_CHANGE")}</small>{((this.state.change != '') ? parseFloat(this.state.change).toFixed(2) : 0)}%</h5>

                              }
                            </div>
                            <div className="headerOverviewStatus">
                              <h5><small>{t("24H_HIGH")}</small>{((typeof this.state.pricedet != 'undefined' && this.state.pricedet.length > 0) ? parseFloat(this.state.pricedet[0].high).toFixed(this.state.floating) : parseFloat(this.state.markprice).toFixed(this.state.floating)) + ' '}</h5>
                            </div>
                            <div className="headerOverviewStatus">
                              <h5><small>{t("24H_LOW")}</small>{((typeof this.state.pricedet != 'undefined' && this.state.pricedet.length > 0) ? parseFloat(this.state.pricedet[0].low).toFixed(this.state.floating) : parseFloat(this.state.markprice).toFixed(this.state.floating)) + ' '}</h5>
                            </div>
                            {/*}<div className="headerOverviewStatus">
                         <h5><small>24H Turnover</small>{ (typeof this.state.pricedet != 'undefined' && this.state.pricedet.length>0)?parseFloat(Math.abs(this.state.btcvolume)).toFixed(3):0 } {this.state.firstcurrency}</h5>
                        </div>*/}

                            <div className="headerOverviewStatus">
                              <h5><small>{t("24H_LOW")}24H Turnover</small>{(typeof this.state.pricedet != 'undefined' && this.state.pricedet.length > 0) ? parseFloat(Math.abs(this.state.pricedet[0].secvolume)).toFixed(3) : 0} {this.state.secondcurrency}</h5>
                            </div>
                          </div>
                        </div>
                        <div className="chartTradeSection">
                          <TVChartContainer
                            className={'chartcontainer'}
                            theme={this.state.theme}
                            // pair={this.state.curarray.includes(this.state.curcontract) ? this.state.chartpair.replace('USDT', 'USD') : this.state.chartpair}
                            pair={this.state.curarray.includes(this.state.curcontract) ? this.state.chartpair : this.state.chartpair}
                            pairdatachange={this.pairdatachange}
                          />
                        </div>
                        <div className="spotTradingBox tradeTabBoxStyle pt-0 mt-sm-new  mb-md-0 mb-3">
                          <div className="mt-1 tableHead spotLimitHead border-bottom-one pt-3">
                            <nav>
                              <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                <a
                                  className={this.state.ordertype == "Limit" ? "nav-item nav-link active" : "nav-item nav-link"}
                                  id="nav-profile-tab"
                                  // onClick={this.ordertype_changes.bind(this)}
                                  onClick={() => this.ordertype_changes('Limit')}
                                >
                                  {t("LIMIT")}
                                </a>

                                <a
                                  className={this.state.ordertype == "Market" ? "nav-item nav-link active" : "nav-item nav-link"}
                                  id="nav-home-tab"
                                  onClick={() => this.ordertype_changes('Market')}
                                // onClick={this.ordertype_changes.bind(this)}
                                >
                                  {t("MARKET")}
                                </a>

                                <a
                                  className={this.state.ordertype == "Stop Limit" ? "nav-item nav-link active" : "nav-item nav-link"}
                                  id="nav-home-tab"
                                  onClick={() => this.ordertype_changes('Stop Limit')}
                                >
                                  {t("STOP_LIMIT")}
                                </a>
                              </div>
                            </nav>
                            <div className='d-flex'>
                              <p className="mb-0">{t("MAKER_FEE")}: <span>{this.state.maker_fee}%</span></p>
                              <p className="mb-0">{t("TAKER_FEE")}: <span>{this.state.taker_fee}%</span></p>
                            </div>
                          </div>
                          <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
                            <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                              <div className="row mx-0">
                                <div className="col-xl-6 border-right-one">
                                  <div className="spotForm">

                                    {
                                      (this.state.ordertype == "Stop Limit") ?
                                        <div className="form-group">
                                          <div className="row mx-0">
                                            <div className="col-md-5 px-0">
                                              <label> {t("STOP")}</label>
                                            </div>
                                            <div className="col-md-7 px-0">
                                              <div className="input-group">
                                                <input type="text" className="form-control" id="stoplimitbuystopprice" value={this.state.stoplimitbuystopprice} onChange={this.onChange} />
                                                <div className="input-group-append">
                                                  <span className="input-group-text" id="basic-addon2">{this.state.secondcurrency}</span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div> : ''
                                    }


                                    {
                                      (this.state.ordertype == "Limit" || this.state.ordertype == "Stop Limit") ?
                                        <div className="form-group">
                                          <div className="row mx-0">
                                            <div className="col-md-5 px-0">
                                              <label> {(this.state.ordertype == "Stop Limit") ? 'Limit' : 'Price'}</label>
                                            </div>
                                            <div className="col-md-7 px-0">
                                              <div className="input-group">
                                                <input type="text" className="form-control" id="buyprice" value={this.state.buyprice} onChange={this.onChange} />
                                                <div className="input-group-append">
                                                  <span className="input-group-text" id="basic-addon2">{this.state.secondcurrency}</span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div> : ''
                                    }

                                    {
                                      // (this.state.ordertype == "Limit" || this.state.ordertype == "Stop Limit") ?

                                      <div className="form-group">
                                        <div className="row mx-0">
                                          <div className="col-md-5 px-0">
                                            {console.log('enter this one')}
                                            <label>{t("QUANTITY")}</label>
                                          </div>
                                          <div className="col-md-7 px-0">
                                            <div className="input-group">
                                              <input
                                                type="text"
                                                className="form-control"
                                                id="buyquantity"
                                                value={this.state.buyquantity}
                                                onChange={this.onChange}
                                              />
                                              {console.log('buyqunatnity', this.state.buyquantity)}
                                              <div className="input-group-append">
                                                <span className="input-group-text" id="basic-addon2">{this.state.firstcurrency}</span>
                                              </div>
                                            </div>


                                            {
                                              this.state.ordertype != "Stop Limit" && this.state.ordertype != "Market" &&
                                              <div>
                                                <div className="btn-group special mt-2">
                                                  <button class={this.state.limitbuypercentage == "25" ? "btn btn-borderButton mr-1 active" : "btn btn-borderButton mr-1"} id={"25-buy-Limit"} onClick={this.percentagequantity.bind(this)}>25%</button>
                                                  <button class={this.state.limitbuypercentage == "50" ? "btn btn-borderButton mr-1 active" : "btn btn-borderButton mr-1"} id={"50-buy-Limit"} onClick={this.percentagequantity.bind(this)}>50%</button>
                                                  <button class={this.state.limitbuypercentage == "75" ? "btn btn-borderButton mr-1 active" : "btn btn-borderButton mr-1"} id={"75-buy-Limit"} onClick={this.percentagequantity.bind(this)}>75%</button>
                                                  <button class={this.state.limitbuypercentage == "100" ? "btn btn-borderButton mr-1 active" : "btn btn-borderButton mr-1"} id={"100-buy-Limit"} onClick={this.percentagequantity.bind(this)}>100%</button>
                                                </div>
                                              </div>
                                            }
                                          </div>
                                        </div>
                                      </div>
                                      // : ''
                                    }

                                    {
                                      // (this.state.ordertype == "Limit" || this.state.ordertype == "Stop Limit") ?

                                      <div className="form-group">
                                        <div className="row mx-0">
                                          <div className="col-md-5 px-0">
                                            <label>{t("ORDER_VALUE")}</label>
                                          </div>
                                          <div className="col-md-7 px-0">
                                            <div className="input-group">
                                              <input
                                                type="text"
                                                className="form-control"
                                                id="buytotal"
                                                value={this.state.buytotal}

                                              />
                                              <div className="input-group-append">
                                                <span className="input-group-text" id="basic-addon2">{this.state.secondcurrency}</span>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      //: ''
                                    }

                                    {/* {
                                     (this.state.ordertype == "Market") ?

                                    <div className="form-group">
                                      <div className="row mx-0">
                                        <div className="col-md-5 px-0">
                                          <label>Order Value</label>
                                        </div>
                                        <div className="col-md-7 px-0">
                                          <div className="input-group">
                                            <input
                                              type="number"
                                              className="form-control"
                                              id="ordervalue"
                                              value={this.state.ordervalue}
                                              onChange={this.onOrderValueChange}
                                            />
                                            <div className="input-group-append">
                                              <span className="input-group-text" id="basic-addon2">{this.state.secondcurrency}</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div> : ''
                                  } */}
                                    <div className="form-group">
                                      <div className="row mx-0">
                                        <div className="col-md-5 px-0">
                                          <label>{t("BALANCE")}</label>
                                        </div>
                                        <div className="col-md-7 px-0">
                                          <div className="input-group">
                                            <input type="text" className="form-control" value={this.state.secondbalance} />
                                            <div className="input-group-append">
                                              <span className="input-group-text" id="basic-addon2">{this.state.secondcurrency}</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {(typeof user.email != 'undefined') ? (
                                      <div className="form-group">
                                        <button id={`buy ${this.state.firstcurrency}`}
                                          className="btn btn-btnBuy mr-2 btn-block"
                                          onClick={this.handleShow22.bind(this)}
                                        >
                                          {t("BUY")}  {this.state.firstcurrency}</button>
                                      </div>
                                    ) : (
                                      <div className="form-group">
                                        <Link to="/login" className="btn btn-btnBuy mr-2 btn-block">{t("LOGIN")}</Link>
                                      </div>
                                    )}

                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="spotForm">

                                    {
                                      (this.state.ordertype == "Stop Limit") ?
                                        <div className="form-group">
                                          <div className="row mx-0">
                                            <div className="col-md-5 px-0">
                                              <label> {t("STOP")}</label>
                                            </div>
                                            <div className="col-md-7 px-0">
                                              <div className="input-group">
                                                <input type="text" className="form-control" id="stoplimitsellstopprice" value={this.state.stoplimitsellstopprice} onChange={this.onChange} />
                                                <div className="input-group-append">
                                                  <span className="input-group-text" id="basic-addon2">{this.state.secondcurrency}</span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div> : ''
                                    }


                                    {
                                      (this.state.ordertype == "Limit" || this.state.ordertype == "Stop Limit") ?
                                        <div className="form-group">
                                          <div className="row mx-0">
                                            <div className="col-md-5 px-0">
                                              <label> {(this.state.ordertype == "Stop Limit") ? 'Limit' : 'Price'}</label>
                                            </div>
                                            <div className="col-md-7 px-0">
                                              <div className="input-group">
                                                <input type="text" className="form-control" id="sellprice" value={this.state.sellprice} onChange={this.onChange} />
                                                <div className="input-group-append">
                                                  <span className="input-group-text" id="basic-addon2">{this.state.secondcurrency}</span>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div> : ''
                                    }

                                    {
                                      // (this.state.ordertype == "Limit" || this.state.ordertype == "Stop Limit") ?
                                      <div className="form-group">
                                        <div className="row mx-0">
                                          <div className="col-md-5 px-0">
                                            <label>{t("QUANTITY")}</label>
                                          </div>
                                          <div className="col-md-7 px-0">
                                            <div className="input-group">
                                              <input
                                                type="text"
                                                className="form-control"
                                                id="sellquantity"
                                                value={this.state.sellquantity}
                                                onChange={this.onChange}
                                              />
                                              <div className="input-group-append">
                                                <span className="input-group-text" id="basic-addon2">{this.state.firstcurrency}</span>
                                              </div>
                                            </div>
                                            {
                                              this.state.ordertype != "Stop Limit" && this.state.ordertype != "Market" &&
                                              <div>
                                                <div className="btn-group special mt-2">
                                                  <button class={this.state.limitsellpercentage == "25" ? "btn btn-borderButton mr-1 active" : "btn btn-borderButton mr-1"} id={"25-sell-Limit"} onClick={this.percentagequantity.bind(this)}>25%</button>
                                                  <button class={this.state.limitsellpercentage == "50" ? "btn btn-borderButton mr-1 active" : "btn btn-borderButton mr-1"} id={"50-sell-Limit"} onClick={this.percentagequantity.bind(this)}>50%</button>
                                                  <button class={this.state.limitsellpercentage == "75" ? "btn btn-borderButton mr-1 active" : "btn btn-borderButton mr-1"} id={"75-sell-Limit"} onClick={this.percentagequantity.bind(this)}>75%</button>
                                                  <button class={this.state.limitsellpercentage == "100" ? "btn btn-borderButton mr-1 active" : "btn btn-borderButton mr-1"} id={"100-sell-Limit"} onClick={this.percentagequantity.bind(this)}>100%</button>
                                                </div>
                                              </div>}
                                          </div>
                                        </div>
                                      </div>
                                      // : ''
                                    }

                                    <div className="form-group">
                                      <div className="row mx-0">
                                        <div className="col-md-5 px-0">
                                          <label>{t("ORDER_VALUE")}</label>
                                        </div>
                                        <div className="col-md-7 px-0">
                                          <div className="input-group">
                                            <input
                                              type="text"
                                              className="form-control"
                                              id="selltotal"
                                              value={this.state.selltotal}
                                            // onChange={this.onChange}
                                            />
                                            <div className="input-group-append">
                                              <span className="input-group-text" id="basic-addon2">{this.state.secondcurrency}</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-group">
                                      <div className="row mx-0">
                                        <div className="col-md-5 px-0">
                                          <label>{t("BALANCE")}</label>
                                        </div>
                                        <div className="col-md-7 px-0">
                                          <div className="input-group">
                                            <input type="text" className="form-control" value={this.state.firstbalance} />
                                            <div className="input-group-append">
                                              <span className="input-group-text" id="basic-addon2">{this.state.firstcurrency}</span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    {(typeof user.email != 'undefined') ? (

                                      <div className="form-group">
                                        <button id={`sell ${this.state.firstcurrency}`} className="btn btn-btnSell mr-2 btn-block" onClick={this.handleShow22.bind(this)}>{t("SELL")} {this.state.firstcurrency}</button>
                                      </div>
                                    ) : (
                                      <div className="form-group">
                                        <Link to="/login" className="btn btn-btnSell mr-2 btn-block">{t("LOGIN")}</Link>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>

                            </div>

                          </div>

                        </div>
                      </div>

                    </div>
                  </div>
                </div>
                <div className="col-lg-3 px-0 mb-md-0 mb-3 ">
                  <div className="tradeRight mx-md-0 mx-3">
                    <div className="row mx-0">
                      <div className="col-md-12 px-0">
                        <div className="tradeTableLeftSide darkBox orderBook tradeTabBoxStyle pb-0 pt-0">
                          <div className="tableHead pt-4">
                            <nav className=''>
                              <div className="nav nav-tabs nav-fill" id="nav-tab-book-trades" role="tablist">
                                <a
                                  className={this.state.showTable == 'order' ? "nav-item nav-link active show" : "nav-item nav-link"}
                                  id="nav-orderBook-tab"
                                  data-toggle="tab"
                                  href="#nav-orderBook"
                                  role="tab"
                                  aria-controls="nav-orderBook"
                                  aria-selected="true"
                                  onClick={() => { this.setState({ showTable: 'order' }) }}
                                >
                                  {t("ORDER_BOOK")}
                                </a>

                                <a
                                  className={this.state.showTable == 'recent' ? "nav-item nav-link active show" : "nav-item nav-link"}
                                  id="nav-recentTrade-tab"
                                  data-toggle="tab"
                                  href="#nav-recentTrade"
                                  role="tab"
                                  aria-controls="nav-recentTrade" aria-selected="false"
                                  onClick={() => { this.setState({ showTable: 'recent' }) }}
                                >
                                  {t("RECENT_TRADE")}
                                </a>
                              </div>
                            </nav>
                            {this.state.showTable == "order" ?
                              <div className="inputGroup buy_sell_icon_grp">
                                <button className="btn"><img src={GreenPinkDot} id="greenpink" onClick={this.orderbookshow.bind(this)} /></button>
                                <button className="btn"><img src={PinkDot} id="pink" onClick={this.orderbookshow.bind(this)} /></button>
                                <button className="btn"><img src={GreenDot} id="green" onClick={this.orderbookshow.bind(this)} /></button>
                              </div> : <></>
                            }
                          </div>
                          <div className="tab-content px-0" id="nav-tabContent">
                            {
                              this.state.showTable == 'order' && <div className="tab-pane fade show active" id="nav-orderBook" role="tabpanel" aria-labelledby="nav-orderBook-tab">
                                {/*<div className="tradeInfoText">
                        <small className="pinkText"> Total {this.state.firstcurrency + " " + parseFloat(this.state.sellsumvalue).toFixed(4)}</small>
                        <small className="greenText"> Total {this.state.secondcurrency + " " + parseFloat(this.state.buysumvalue).toFixed()}</small>
                      </div>*/}
                                <div className="tradeTableTitle row mx-auto w-100">
                                  <span className="col-4">{t("PRICE")}</span>
                                  <span className="col-4">{t("QTY")}</span>
                                  <span className="col-4">{t("TOTAL")}</span>
                                </div>
                                <Scrollbars ref='scrollbar' style={{ width: '100%', height: 838 }} renderTrackVertical={props => <div className="track-vertical" />}>
                                  <div className="orderBookMiddle">
                                    {
                                      (this.state.orderbookshow == 'greenpink' || this.state.orderbookshow == 'pink') ?
                                        this.state.sellOrder.map((item, i) => {
                                          // console.log('itemitemitemitemitem',item)
                                          var toam = this.state.sellOrder[0].total;
                                          var precentage = item.total / toam * 100;
                                          var classs = (i % 2) ? 'tradeTableBodyRow odd row mx-auto' : 'tradeTableBodyRow even row mx-auto';
                                          var ordvalue = parseFloat(item.quantity) / parseFloat(item._id);
                                          var index = (this.state.prevsellOrder).findIndex(x => (x._id) === item._id);
                                          index = (this.state.prevsellOrder.length > 0) ? index : -2;
                                          var prevquantity = (this.state.prevsellOrder.length > 0 && index != -1) ? this.state.prevsellOrder[index].quantity : 0;
                                          // var quanfloat = (this.state.floating == 2) ? 8 : 2;
                                          var quanfloat = this.state.spot_floating ? this.state.spot_floating : 8;
                                          var highLightcls = (index == -1) ? "highlight" : (prevquantity != 0 && prevquantity != item.quantity) ? "highlight" : '';
                                          var quty = displayingDigits(item.total, quanfloat)
                                          return <div className={classs + ' ' + highLightcls} >
                                            <span className="col-4 pinkText" onClick={this.fillpriceforsell.bind(this)}>{parseFloat(item._id).toFixed(quanfloat)}</span>
                                            <span className="col-4" onClick={this.fillquantityforsell.bind(this)}>{quty}</span>
                                            <span className="col-4"> <span className="bgProgress1" style={{ width: precentage + '%' }}></span> <span className="bgProgressText1">{parseFloat(Math.abs(item.total)).toFixed(8)}</span></span>
                                          </div>;
                                        })
                                        : ''
                                    }



                                    {/* {
                                      (typeof this.state.pricedet != 'undefined' && this.state.pricedet.length > 0) ? (
                                          // className={(this.state.pricedet[0].close) ? ('col-6 pinkText') : ('col-6 pl-5 yellowText')}


                                      ) : ('')} */}
                                    <div className="tradeTableBodyRow even highLight row mx-auto" >
                                      <span
                                        className='col-6 pinkText'
                                      >
                                        <i className="fas fa-caret-down "></i>
                                        {/* {parseFloat(this.state.pricedet[0].close).toFixed(this.state.floating)}{" "}{(this.state.secondcurrency == 'USDT') ? '$' : this.state.secondcurrency} */}
                                        {/* {parseFloat(this.state.last_price).toFixed(this.state.floating)}{" "}{(this.state.secondcurrency == 'USDT') ? '$' : this.state.secondcurrency} */}
                                        {parseFloat(this.state.markprice).toFixed(this.state.floating)}{" "}{(this.state.secondcurrency == 'USDT') ? '$' : this.state.secondcurrency}
                                      </span>

                                      <span
                                        className={this.state.spotpricecolor + ' col-6 text-center'}
                                        data-toggle="tooltip"
                                        data-placement="right"
                                        data-html="true"
                                        title="Spot price"
                                      >
                                        {parseFloat(this.state.markprice).toFixed(this.state.floating)}{" "}{(this.state.secondcurrency == 'USDT') ? '$' : this.state.secondcurrency}
                                      </span>

                                    </div>


                                    {
                                      (this.state.orderbookshow == 'greenpink' || this.state.orderbookshow == 'green') ?
                                        this.state.buyOrder.map((item, i) => {
                                          var lastindex = this.state.buyOrder.length;
                                          var toam = this.state.buyOrder[lastindex - 1].total;
                                          var precentage = item.total / toam * 100;
                                          var classs = (i % 2) ? 'tradeTableBodyRow even row mx-auto' : 'tradeTableBodyRow odd row mx-auto';
                                          var ordvalue = parseFloat(item.quantity) / parseFloat(item._id);
                                          var index = (this.state.prevbuyOrder).findIndex(x => (x._id) === item._id);
                                          // var quanfloat = (this.state.floating == 2) ? 8 : 2;
                                          var quanfloat = this.state.spot_floating ? this.state.spot_floating : 8;
                                          index = (this.state.prevbuyOrder.length > 0) ? index : -2;
                                          var prevquantity = (this.state.prevbuyOrder.length > 0 && index != -1) ? this.state.prevbuyOrder[index].quantity : 0;
                                          var highLightcls = (index == -1) ? "highlight" : (prevquantity != 0 && prevquantity != item.quantity) ? "highlight" : '';
                                          var quty = displayingDigits(item.total, quanfloat)
                                          return <div className={classs + ' ' + highLightcls}>
                                            <span className=" col-4 greenText" onClick={this.fillpriceforbuy.bind(this)}>{parseFloat(item._id).toFixed(quanfloat)}</span>
                                            <span className="col-4" onClick={this.fillquantityforbuy.bind(this)}>{quty}</span>
                                            <span className="col-4"> <span className="bgProgress" style={{ width: precentage + '%' }}></span> <span className="bgProgressText">{parseFloat(item.total).toFixed(8)}</span></span>
                                          </div>;
                                        })
                                        : ''
                                    }
                                  </div>
                                </Scrollbars>
                              </div>
                            }

                          </div>

                        </div>
                      </div>
                      <div className="col-md-12 px-0">
                        {
                          this.state.showTable == 'recent' && <div className="tab-pane fade show active" id="nav-orderBook" role="tabpanel" aria-labelledby="nav-orderBook-tab">
                            <div className="tradeTableTitle row mx-auto">
                              <span className="col-4">{t("PRICE")}</span>
                              <span className="col-4">{t("QTY")}</span>
                              <span className="col-4">{t("TIME")}</span>
                            </div>
                            <Scrollbars ref='scrollbar' style={{ width: '100%', height: 838 }} renderTrackVertical={props => <div className="track-vertical" />}>
                              <div className="tradeTableBody customScroll">

                                {
                                  this.state.Rescentorder.map((item, i) => {
                                    // console.log(item,'lllllllllllllll')
                                    var data = new Date(item.created_at);
                                    let date1 = data.getHours() + ':' + data.getMinutes() + ':' + data.getSeconds();
                                    var classs = (i % 2) ? 'tradeTableBodyRow even row mx-auto' : 'tradeTableBodyRow odd row mx-auto';
                                    var priceclass = item.Type == "buy" ? "green" : "red";
                                    return <div className={classs} data-toggle="tooltip">
                                      <span className="col-4">{parseFloat(item.Price).toFixed(this.state.floating)}</span>
                                      <span className="col-4" style={{ color: priceclass }}>{parseFloat(Math.abs(item.filledAmount)).toFixed(8)}</span>
                                      <span className="col-4">{date1}</span>

                                    </div>;

                                  })
                                }
                                {(this.state.Rescentorder.length > 20) ?
                                  <div className="tradeTableBodyRow odd row mx-auto ">
                                    <a onClick={this.loadmoreRescentorder} className="d-block text-center mx-auto"><i className="fas fa-sort-amount-down-alt"></i> {t("LOADMORE")}</a></div> : ''}

                              </div>

                            </Scrollbars>
                          </div>
                        }

                      </div>

                    </div>
                  </div>
                </div>
                <div className="col-md-12 px-md-0 mb-md-0 mb-3">
                  <div className="darkBox tradeFulltabbedTable border-bottom-zero border-right-zero pt-0 rounded-0">
                    <nav className='pt-3'>
                      <div className="nav nav-tabs" id="nav-tab" role="tablist">

                        <a className="nav-item nav-link active show" id="nav-active0-tab" data-toggle="tab" href="#nav-active0" role="tab" aria-controls="nav-active0" aria-selected="false">{t("PENDING")} ({orderHistoryCnt})</a>

                        <a className="nav-item nav-link" id="nav-filledOrder-tab" data-toggle="tab" href="#nav-filledOrder" role="tab" aria-controls="nav-filledOrder" aria-selected="false">{t("FILLED_ORDER")} ({filledDetailsCnt})</a>
                        <a className="nav-item nav-link" id="nav-tradeHistory-tab" data-toggle="tab" href="#nav-tradeHistory" role="tab" aria-controls="nav-tradeHistory" aria-selected="false">{t("HISTORY")}({histroyDetailsCnt})</a>
                      </div>
                    </nav>
                    <div className="tab-content px-0" id="nav-tabContent">

                      <div className="tab-pane fade active show" id="nav-active0" role="tabpanel" aria-labelledby="nav-active0-tab">
                        <div className="table-responsive">

                          <table id="active0Table" className="table table-striped">
                            <thead>
                              <tr>
                                <th>{t("CONTRACTS")}</th>
                                <th>{t("QUANTITY")}</th>
                                <th>{t("PRICE")}</th>
                                <th>{t("FILLED_REMAINING")}</th>
                                <th>{t("ORDER_VALUE")}</th>
                                <th>{t("TP/SL")}</th>
                                <th>{t("TYPE")}</th>
                                <th>{t("STATUS")}</th>
                                <th>{t("ORDER")}#</th>
                                <th>{t("BUYORSELL")}</th>
                                <th>{t("ORDER_TIME")}</th>
                                <th>{t("ACTION")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                orderHistory.map((item, i) => {
                                  var pairName = item.pairName ? item.pairName : '';
                                  var quantity = item.quantity ? item.quantity : 0;
                                  var price = item.quantity ? item.price : 0;
                                  var filledAmount = item.filledAmount ? item.filledAmount : 0;
                                  var orderValue = item.orderValue ? item.orderValue : 0;
                                  var orderType = item.orderType ? item.orderType : 0;
                                  var orderDate = item.orderDate ? item.orderDate : 0;
                                  var buyorsellType = item.buyorsell ? item.buyorsell : "";
                                  var classs = (quantity > 0) ? 'greenText' : 'pinkText';
                                  var classsname = (buyorsellType == "buy") ? 'greenText' : 'pinkText';
                                  var _id = item._id ? item._id : 0;
                                  var status = item.status == '0' ? 'New' : item.status == '4' ? 'Conditional' :'Partial';
                                  var Remaining = parseFloat(quantity) - parseFloat(filledAmount);
                                  // console.log(item,'item');
                                  var data1 = new Date(orderDate);
                                  let date12 = data1.getFullYear() + '-' + (data1.getMonth() + 1) + '-' + data1.getDate() + ' ' + data1.getHours() + ':' + data1.getMinutes() + ':' + data1.getSeconds();


                                  // //console.log(date12,'datezxc');
                                  return <tr>
                                    <td className="yellowText">{item.firstCurrency + "/" + item.secondCurrency}</td>
                                    <td><span className={classsname}>{parseFloat(quantity).toFixed(8)}</span></td>
                                    <td>{parseFloat(price).toFixed(this.state.floating)}</td>
                                    <td>{parseFloat(filledAmount).toFixed(8) + '/' + parseFloat(Remaining).toFixed(8)}</td>
                                    <td>{parseFloat(orderValue).toFixed(8)}</td>
                                    <td>{'-/-'}</td>
                                    <td>{orderType}</td>
                                    <td>{status}</td>
                                    <td>{_id}</td>
                                    <td>{item.buyorsell}</td>
                                    <td>{date12}</td>
                                    <td>
                                      <button className="btn btn-borderButton mr-1" onClick={this.cancelFunction.bind(this)} id={_id}> {t("CANCEL")} </button>
                                    </td>
                                  </tr>
                                })
                              }
                            </tbody>
                            {/* {
                                orderHistory && orderHistory.length != 0 && orderHistory.length < orderHistoryCnt && 
                                
                                <button className="btn btn-borderButton" onClick={this.orderHistoryLoadMore}> Load More </button>
                               
                              } */}

                          </table>
                        </div>
                        {
                          orderHistory && orderHistory.length != 0 && orderHistory.length < orderHistoryCnt &&

                          <div className='load_more_sec_btn'>
                            <button className="btn btn-borderButton" onClick={this.orderHistoryLoadMore}> {t("LOADMORE")} </button>
                          </div>

                        }

                      </div>
                      <div className="tab-pane fade" id="nav-conditional0" role="tabpanel" aria-labelledby="nav-conditional0-tab">
                        <div className="table-responsive">
                          <Scrollbars style={{ width: '100%', height: 200 }} renderTrackVertical={props => <div className="track-vertical" />}>
                            <table id="conditional0Table" className="table table-striped">
                              <thead>
                                <tr>
                                  <th>{t("CONTRACTS")}</th>
                                  <th>{t("QUANTITY")}</th>
                                  <th>{t("PRICE")}</th>
                                  <th>{t("TRIGGER_PRICE")}</th>
                                  <th>{t("ORDER_VALUE")}</th>
                                  <th>{t("PRICE_DISTANCE")}</th>
                                  <th>{t("TYPE")}</th>
                                  <th>{t("STATUS")}</th>
                                  <th>{t("ORDER")}#</th>
                                  <th>{t("ORDER_TIME")}</th>
                                  <th>{t("ACTION")}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  Conditional_details.map((item, i) => {
                                    var pairName = item.pairName ? item.pairName : '';
                                    var quantity = item.quantity ? item.quantity : 0;
                                    var price = item.quantity ? item.price : 0;
                                    var filledAmount = item.filledAmount ? item.filledAmount : 0;
                                    var orderValue = item.orderValue ? item.orderValue : 0;
                                    var orderType = item.orderType ? item.orderType : 0;
                                    var trigger_price = item.orderType ? item.trigger_price : 0;
                                    var trigger_type = item.orderType ? item.trigger_type : 0;
                                    var orderDate = item.orderDate ? item.orderDate : 0;
                                    var classs = (quantity > 0) ? 'greenText' : 'pinkText';
                                    var _id = item._id ? item._id : 0;
                                    var status = item.status == '0' ? 'New' : 'Partial';
                                    var Remaining = parseFloat(quantity) - parseFloat(filledAmount);
                                    var price_distance = parseFloat(this.state.last_price) - parseFloat(price);

                                    var data1 = new Date(orderDate);
                                    let date12 = data1.getFullYear() + '-' + (data1.getMonth() + 1) + '-' + data1.getDate() + ' ' + data1.getHours() + ':' + data1.getMinutes() + ':' + data1.getSeconds();

                                    return <tr>
                                      <td className="yellowText">{item.firstCurrency + "/" + item.secondCurrency}</td>
                                      <td><span className={classs}>{parseFloat(quantity).toFixed(8)}</span></td>
                                      <td>{parseFloat(price).toFixed(this.state.floating)}</td>
                                      <td>{(price_distance > 0) ? '<=' + trigger_price + ' (' + trigger_type + ')' : '>=' + trigger_price + ' (' + trigger_type + ')'}</td>
                                      <td>{parseFloat(orderValue).toFixed(8)}</td>
                                      <td>{this.state.last_price + '(' + price_distance + ')'}</td>
                                      <td>{orderType}</td>
                                      <td>{status}</td>
                                      <td>{_id}</td>
                                      <td>{date12}</td>
                                      <td><button className="btn btn-borderButton mr-1" onClick={this.cancelFunction.bind(this)} id={_id}> {t("CANCEL")} </button></td>
                                    </tr>
                                  })
                                }
                              </tbody>
                            </table>
                          </Scrollbars>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="nav-filledOrder" role="tabpanel" aria-labelledby="nav-filledOrder-tab">
                        <div className="table-responsive">
                          <Scrollbars style={{ width: '100%', height: 200 }} renderTrackVertical={props => <div className="track-vertical" />}>
                            <table id="filledOrderTable" className="table table-striped rembordor">
                              <thead>
                                <tr>
                                  <th>{t("CONTRACTS")}</th>
                                  {/*<th>Quantity</th>*/}
                                  <th>{t("FILLED")}</th>
                                  <th>{t("EXEC_PRICE")}</th>
                                  <th>{t("QUANTITY")}</th>
                                  <th>{t("ORDER_VALUE")}</th>
                                  <th>{t("BUYORSELL")}</th>
                                  <th>{t("TYPE")}</th>
                                  <th>{t("ORDER")}#</th>
                                  <th>{t("TIME")}</th>
                                </tr>
                              </thead>

                              {
                                Filleddetails.map((item, i) => {
                                  var quantity = item.quantity ? item.quantity : 0;
                                  var price = item.price ? item.price : 0;
                                  var _id = item._id ? item._id : 0;
                                  var classs = (quantity > 0) ? 'greenText' : 'pinkText';
                                  var filledAmount = item.filledAmount ? item.filledAmount : 0;
                                  var Remaining = parseFloat(quantity) - parseFloat(filledAmount);
                                  return (<tbody>
                                    {item.filled.map((itemdata, i) => {
                                      var created_at = itemdata.created_at ? itemdata.created_at : '';
                                      var data1 = new Date(created_at);
                                      let date12 = data1.getFullYear() + '-' + (data1.getMonth() + 1) + '-' + data1.getDate() + ' ' + data1.getHours() + ':' + data1.getMinutes() + ':' + data1.getSeconds();
                                      return (<tr>
                                        <td className="yellowText">{itemdata.pairname}</td>
                                        {/*<td>{parseFloat(quantity).toFixed(8)}</td>*/}
                                        {/*<td>{parseFloat(filledAmount).toFixed(8) + '/' + parseFloat(Remaining).toFixed(8)}</td>*/}
                                        <td>{parseFloat(filledAmount).toFixed(8)}</td>
                                        <td>{parseFloat(itemdata.Price).toFixed(this.state.floating)}</td>
                                        <td>{parseFloat(quantity).toFixed(this.state.floating)}</td>
                                        <td>{item.orderValue}</td>
                                        <td>{item.buyorsell}</td>
                                        <td>{item.orderType}</td>
                                        <td>{_id}</td>
                                        <td>{date12}</td>
                                      </tr>
                                      );
                                    })
                                    }
                                  </tbody>);
                                })
                              }



                            </table>
                          </Scrollbars>
                        </div>
                        {
                          Filleddetails && Filleddetails.length < filledDetailsCnt &&
                          <div className='load_more_sec_btn'>
                            <button className="btn btn-borderButton" onClick={this.spotFilledDetailsLoadMore}> {t("LOADMORE")}  </button>
                          </div>
                        }

                      </div>
                      <div className="tab-pane fade" id="nav-tradeHistory" role="tabpanel" aria-labelledby="nav-tradeHistory-tab">
                        <div className="table-responsive">
                          <Scrollbars style={{ width: '100%', height: 200 }} renderTrackVertical={props => <div className="track-vertical" />}>
                            <table id="tradeHistoryTable" className="table table-striped">
                              <thead>
                                <tr>
                                  <th>{t("CONTRACTS")}</th>
                                  <th>{t("QUANTITY")}</th>
                                  <th>{t("FILLED_REMAINING")}</th>
                                  <th>{t("EXEC_PRICE")}</th>
                                  <th>{t("PRICE")}</th>
                                  <th>{t("TRIGGER_PRICE")}</th>
                                  <th>{t("ORDER_VALUE")}</th>
                                  <th>{t("FEES")}</th>
                                  <th>{t("TYPE")}</th>
                                  <th>{t("STATUS")}</th>
                                  <th>{t("BUYORSELL")}</th>
                                  <th>{t("ORDER")}#</th>
                                  <th>{t("ORDER_TIME")}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  Histroydetails.map((item, i) => {
                                    var pairName = item.pairName ? item.pairName : '';
                                    var quantity = item.quantity ? item.quantity : 0;
                                    var filledAmount = item.filledAmount ? item.filledAmount : 0;
                                    var e_price = item.filled.length > 0 ? item.filled[0].Price : 0;
                                    var Fee = item.filled.length > 0 ? item.filled[0].Fees : 0;
                                    var price = item.quantity ? item.price : 0;
                                    var orderValue = item.orderValue ? item.orderValue : 0;
                                    var orderType = item.orderType ? item.orderType : 0;
                                    var orderDate = item.orderDate ? item.orderDate : 0;
                                    var classs = item.buyorsell == 'buy' ? 'greenText' : 'pinkText';
                                    var _id = item._id ? item._id : 0;
                                    var status1 = item.status;
                                    var Remaining = parseFloat(quantity) - parseFloat(filledAmount);
                                    var data = new Date(orderDate);
                                    let date1 = data.getFullYear() + '-' + (data.getMonth() + 1) + '-' + data.getDate() + ' ' + data.getHours() + ':' + data.getMinutes() + ':' + data.getSeconds();
                                    var trigger_price = item.trigger_price ? item.trigger_price : 0;
                                    return <tr>
                                      <td>{item.firstCurrency + "/" + item.secondCurrency}</td>
                                      <td><span className={classs}>{parseFloat(quantity).toFixed(8)}</span></td>
                                      <td>{parseFloat(filledAmount).toFixed(8) + '/' + parseFloat(Remaining).toFixed(8)}</td>
                                      <td>{e_price}</td>
                                      <td>{price}</td>
                                      <td>{trigger_price}</td>
                                      <td>{orderValue}</td>
                                      <td>{Fee}</td>
                                      <td>{orderType}</td>
                                      {(status1 == 0) ?
                                        <td >{t("NEW")}</td>
                                        : ''}
                                      {(status1 == 1) ?
                                        <td >{t("COMPLETED")}</td>
                                        : ''}
                                      {(status1 == 2) ?
                                        <td >{t("PARTIAL")}</td>
                                        : ''}
                                      {(status1 == 3) ?
                                        <td >{t("CANCEL")}</td>
                                        : ''}
                                      {(status1 == 4) ?
                                        <td >{t("CONDITIONAL")}</td>
                                        : ''}
                                      <td>{item.buyorsell}</td>
                                      <td>{_id}</td>
                                      <td>{date1}</td>
                                    </tr>
                                  })
                                }
                              </tbody>

                            </table>
                          </Scrollbars>
                        </div>
                        {
                          Histroydetails && Histroydetails.length < histroyDetailsCnt &&
                          <div className='load_more_sec_btn'>
                            <button className="btn btn-borderButton" onClick={this.spotHistoryDetailsLoadMore}> {t("LOADMORE")}  </button>
                          </div>
                        }

                      </div>
                    </div>

                  </div>
                </div>



              </div>

            </div>
          </section>
          <div className="tradeFooterBox">
            <Footer />
          </div>
        </div>
      </div >
    );
  }
}

Trade.propTypes = {
  changeopenpositions: PropTypes.func.isRequired,
  triggerstop: PropTypes.func.isRequired,
  getspotPricevalue: PropTypes.func.isRequired,
  logoutUser: PropTypes.func.isRequired,
  spotcancelTrade: PropTypes.func.isRequired,
  getspotPertual: PropTypes.func.isRequired,
  getspotTradeData: PropTypes.func.isRequired,
  getspotuserTradeData: PropTypes.func.isRequired,
  spotorderPlacing: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
};
const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors,
  theme: state.theme
});
export default connect(

  mapStateToProps,
  {
    changeopenpositions,
    getspotPertual,
    spotcancelTrade,
    spotorderPlacing,
    getspotTradeData,
    getspotuserTradeData,
    logoutUser,
    triggerstop,
    getspotPricevalue,
  }
)(withTranslation()(Trade), withRouter(Trade));
